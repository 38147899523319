import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import User from "_class/User";
import Moment from 'moment';

import { getAttendanceColor } from 'helpers/attendance';

import {
	deleteAbsence,
	getUserAttendance,
	submitAbsence,
	updateAbsence,
	updateAttendance,
} from 'actions/absence';

import { addError } from 'actions/index';
import { clearStudentAttendance } from 'actions/sections';

import Modal from 'containers/Modals/Modal';
import ReportAbsenceForm from 'containers/Forms/Absence/ReportAbsence';
import EditAttendanceForm from 'containers/Forms/EditAttendance';

import RowItem, { RowCell } from 'UI/Elements/List/RowItem';
import { DataList, ColorLabelContainer, ColorLabel, Skeleton, Spinner } from 'UI';
import { TooltipMenu, Block, Flex, Button, Checkbox, Icon } from '@haldor/ui';

class AttendanceList extends Component {

	constructor(props) {
		super(props);

		let activeFilters = localStorage.getItem('attendance-filters');
		if (activeFilters != null) {
			activeFilters = JSON.parse(activeFilters);
		} else {
			activeFilters = [];
		}

		this.state = {
			filters: [
				'present',
				'invalid_absence',
				'valid_absence',
				'invalid_late',
				'valid_late',
				'not_reported'
			],
			activeFilters,
			absenceModal: false,
			filterCourse: '0',
			activeAttendance: null,
			loading: true,
		};
	}

	componentDidMount = async () => {
		const { start, end, user, sectionId } = this.props;

		await this.props.getUserAttendance(user, start, end, sectionId);
		this.setState({ loading: false });
	}

	componentWillUnmount = () => {
		this.props.clearStudentAttendance();
	}

	UNSAFE_componentWillReceiveProps = async (nextProps) => {
		const { start, end, user, sectionId } = nextProps;

		if (nextProps.start == this.props.start && nextProps.end == this.props.end) {
			return false;
		}

		this.setState({ loading: true });

		if (this.props.start != start || this.props.end != end) {
			this.props.clearStudentAttendance();
			await this.props.getUserAttendance(user, start, end, sectionId);
			this.setState({ loading: false });
		}
	}

	onFilterToggle = (filter) => {
		let { activeFilters } = this.state;
		const isActive = activeFilters.findIndex(activeFilter => {
			return activeFilter == filter;
		});

		if (isActive > -1) {
			activeFilters.splice(isActive, 1);
		} else {
			activeFilters.push(filter);
		}

		localStorage.setItem('attendance-filters', JSON.stringify(activeFilters));

		this.setState({ activeFilters });
	}

	onFilterCourseChange = (event) => this.setState({ filterCourse: event.target.value });

	renderRow = (attendance, index) => {
		const { translate } = this.props;
		const currentUser = new User(this.props.currentUser);

		return <RowItem key={this.props.user + index}>
			<RowCell title={translate('date')} shrink>
				{Moment.utc(attendance.startTime).local().format('YYYY/MM/DD HH:mm')}
			</RowCell>

			<RowCell title={translate('section')}>
				{attendance.sectionTitle}
			</RowCell>

			<RowCell title={translate('label-course-section-form')} shrink>
				{attendance.courseCode != null ?
					attendance.courseCode
					: attendance.title}
			</RowCell>

			<RowCell title={translate('absent')}>
				{attendance.attendanceType == 'LATE' ?
					attendance.lateDuration + ' ' + translate('minutes').toLowerCase()
					: null}

				{attendance.attendanceType == 'ABSENT' ?
					attendance.length + ' ' + translate('minutes').toLowerCase()
					: null}
			</RowCell>

			<RowCell title={translate('present')}>
				{attendance.attendanceType == 'PRESENT' ?
					attendance.length + ' ' + translate('minutes').toLowerCase()
					: null}

				{attendance.attendanceType == 'LATE' ?
					attendance.length - attendance.lateDuration + ' ' + translate('minutes').toLowerCase()
					: null}
			</RowCell>

			<RowCell title={translate('Status')}>
				<Flex center space>
					<ColorLabelContainer inline>
						<ColorLabel
							tooltip={translate(attendance.type.toLowerCase() === 'not_reported' ? 'Not reported' : attendance.type.toLowerCase())}
							color={getAttendanceColor(attendance)}
						/>
					</ColorLabelContainer>

					{!currentUser.isStudent() && (currentUser.isMentor() || currentUser.isAdministrator()) && attendance.attendanceType !== 'NOT_REPORTED' ?
						<TooltipMenu
							trigger={(
								<Button type="secondary">
									{this.props.translate('change')}
								</Button>
							)}
						>
							<TooltipMenu.Item onClick={() => this.setAttendanceStatus(attendance, 'PRESENT')}>
								{this.props.translate('attending')}
							</TooltipMenu.Item>

							<TooltipMenu.Item onClick={() => this.setAttendanceStatus(attendance, 'VALID_ABSENCE')}>
								{this.props.translate('VALID_ABSENCE')}
							</TooltipMenu.Item>

							<TooltipMenu.Item onClick={() => this.setAttendanceStatus(attendance, 'INVALID_ABSENCE')}>
								{this.props.translate('INVALID_ABSENCE')}
							</TooltipMenu.Item>

							<TooltipMenu.Item onClick={() => this.onEditAttendance(attendance)}>
								{this.props.translate('detailed-changes')}
							</TooltipMenu.Item>
						</TooltipMenu>
						: null}
				</Flex>
			</RowCell>
		</RowItem>
	}

	renderListActions = () => {
		const { translate } = this.props;

		return this.state.filters.map((filter, index) => {
			const isActive = this.state.activeFilters.find(activeFilter => {
				return activeFilter == filter;
			});

			return <div key={index} style={{ marginTop: '.35rem' }}>
				<Checkbox
					value={isActive != null}
					onChange={() => this.onFilterToggle(filter)}
					label={translate(filter === 'not_reported' ? 'unreported' : filter)}
				/>
			</div>
		});
	}

	onAttendanceSubmit = (values) => {
		const { start, end, user, sectionId } = this.props;

		return new Promise((resolve) => {
			this.props.updateAttendance([values], values.calendarEventId).then(() => {
				Promise.all([
					this.props.getUserAttendance(user, start, end, sectionId),
				]).then(() => {
					resolve(1);
				});
			});
		});
	}

	setAttendanceStatus = (attendance, status) => {
		let values = { ...attendance };
		values.type = status;

		if (status.indexOf('ABSENCE')) {
			values.attendanceType = 'ABSENT';
		} else {
			values.attendanceType = 'PRESENT'
		}

		this.onAttendanceSubmit(values).then(() => {
			this.props.addError(this.props.translate('attendance-saved'), 'info');
		});
	}

	onEditAttendance = (attendance) => this.setState({ activeAttendance: attendance });
	closeAttendanceModal = () => this.setState({ activeAttendance: null });

	closeAbsenceModal = () => {
		const { start, end, user, sectionId } = this.props;
		this.props.getUserAttendance(user, start, end, sectionId);

		this.setState({ absenceModal: false });
	}

	openAbsenceModal = () => this.setState({ absenceModal: true });

	onAbsenceSubmit = (values) => {
		return new Promise((resolve, reject) => {
			let data = [values];

			if (values.id != null) {
				this.props.updateAbsence(data).then(() => {
					resolve(1);
				}).catch(() => {
					reject(0);
				});
			} else {
				this.props.submitAbsence(data).then(() => {
					resolve(1);
				}).catch(() => {
					reject(0);
				});
			}
		});
	}

	onAbsenceDelete = (id) => {
		this.props.deleteAbsence(id).then(() => {
			this.closeAbsenceModal();
		})
	}

	getAttendanceSum = (attendances, type) => {
		let total = 0;
		attendances.forEach(attendance => {
			if (attendance.type == type) {
				if (type == 'INVALID_LATE' || type == 'VALID_LATE') {
					total += attendance.lateDuration;
				} else {
					total += attendance.length;
				}
			}

			if (type == 'PRESENT' && attendance.attendanceType == 'LATE') {
				total += attendance.length - attendance.lateDuration;
			}
		})

		return total;
	}

	calculateAttendance = (attendances) => {
		const { translate } = this.props;

		if (attendances != null) {
			let invalidAbsence, validAbsence, invalidLate, validLate, present = 0;

			invalidAbsence = this.getAttendanceSum(attendances, 'INVALID_ABSENCE');
			validAbsence = this.getAttendanceSum(attendances, 'VALID_ABSENCE');

			invalidLate = this.getAttendanceSum(attendances, 'INVALID_LATE');
			validLate = this.getAttendanceSum(attendances, 'VALID_LATE')

			present = this.getAttendanceSum(attendances, 'PRESENT');

			const total = invalidAbsence + invalidLate + present + validAbsence + validLate;

			let attendance = {
				'percentage': {
					'attendance': 0,
					'validAbsence': 0,
					'invalidAbsence': 0,
				},
				'minutes': {
					'present': present,
					'validAbsence': validAbsence + validLate,
					'invalidAbsence': invalidAbsence + invalidLate,
				},
				'display': {
					'present': '0 ' + translate('minutes').toLowerCase(),
					'validAbsence': '0 ' + translate('minutes').toLowerCase(),
					'invalidAbsence': '0 ' + translate('minutes').toLowerCase(),
				},
			};

			if (total > 0) {
				attendance.percentage = {
					attendance: Math.round(((present / total) * 100) * 10) / 10,
					validAbsence: Math.round((((validAbsence + validLate) / total) * 100) * 10) / 10,
					invalidAbsence: Math.round((((invalidAbsence + invalidLate) / total) * 100) * 10) / 10,
				};

				if (attendance.minutes.present > 600) {
					attendance.display.present = Math.floor(attendance.minutes.present / 60) + ' ' + translate('hours').toLowerCase();
				} else {
					attendance.display.present = attendance.minutes.present + ' ' + translate('minutes');
				}

				if (attendance.minutes.validAbsence > 600) {
					attendance.display.validAbsence = Math.floor(attendance.minutes.validAbsence / 60) + ' ' + translate('hours').toLowerCase();
				} else {
					attendance.display.validAbsence = attendance.minutes.validAbsence + ' ' + translate('minutes').toLowerCase();
				}

				if (attendance.minutes.invalidAbsence > 600) {
					attendance.display.invalidAbsence = Math.floor(attendance.minutes.invalidAbsence / 60) + ' ' + translate('hours').toLowerCase();
				} else {
					attendance.display.invalidAbsence = attendance.minutes.invalidAbsence + ' ' + translate('minutes').toLowerCase();
				}
			}

			return attendance;
		}

		return null;
	}

	renderAttendanceStatistics = (attendances) => {
		if (attendances == null) {
			return null;
		}

		let attendance = this.calculateAttendance(attendances);

		if (attendance == null) {
			return <Skeleton />
		}

		const { translate } = this.props;

		return (
			<div>
				<ColorLabelContainer>
					<ColorLabel
						content={attendance.percentage.attendance + '% (' + attendance.display.present + ') ' + translate('present')}
						color='#19DB6C'
					/>
				</ColorLabelContainer>

				<div style={{ marginTop: '0.35rem' }} />

				<ColorLabelContainer>
					<ColorLabel
						content={attendance.percentage.validAbsence + '% (' + attendance.display.validAbsence + ') ' + translate('valid_absence')}
						color='#FF883E'
					/>
				</ColorLabelContainer>

				<div style={{ marginTop: '0.35rem' }} />

				<ColorLabelContainer>
					<ColorLabel
						content={attendance.percentage.invalidAbsence + '% (' + attendance.display.invalidAbsence + ') ' + translate('invalid_absence')}
						color='#F25252'
					/>
				</ColorLabelContainer>
			</div>
		);
	}

	render() {
		const { translate } = this.props;

		let courses = [];
		let data = this.props.attendance;
		if (this.state.activeFilters.length > 0 && data != null && data.length > 0) {
			data = data.filter(attendance => {
				// S 
				if (this.state.activeFilters.indexOf(attendance.type.toLowerCase()) > -1) {
					return true;
				}

				return false;
			});
		}

		if (this.state.filterCourse != '0' && data != null && data.length > 0) {
			data = data.filter(attendance => {
				if (attendance.courseCode == null) {
					return attendance.title == this.state.filterCourse;
				} else {
					return attendance.courseCode == this.state.filterCourse;
				}
			});
		}

		if (this.props.attendance != null && this.props.attendance.length > 0) {
			this.props.attendance.forEach(attendance => {
				if (attendance.courseCode == null) {
					if (courses.indexOf(attendance.title) == -1) {
						courses.push(attendance.title)
					}

					return true;
				}

				if (courses.indexOf(attendance.courseCode) == -1) {
					courses.push(attendance.courseCode);
				}
			});

			courses.sort((a, b) => {
				return a > b ? 1 : -1;
			});
		}

		let userObject = null;
		let user = null;
		let currentUser = new User(this.props.currentUser);

		if (currentUser.isStudent() && currentUser.isAuthoritative()) {
			userObject = this.props.currentUser;
		} else {
			if (this.props.users != null && this.props.users.length > 0) {
				userObject = this.props.users.find(usr => {
					return usr.userId == this.props.user;
				});
			}
		}

		if (userObject != null) {
			user = new User(userObject);
		}

		return (
			<div>
				{userObject != null ?
					<Modal isOpen={this.state.absenceModal} onClose={this.closeAbsenceModal} title={translate('manage-absence-for') + ' ' + userObject.firstName + ' ' + userObject.lastName}>
						<ReportAbsenceForm
							onModalClose={this.closeAbsenceModal}
							onSubmit={this.onAbsenceSubmit}
							user={userObject}
							absence={null}
							onDelete={this.onAbsenceDelete}
						/>
					</Modal>
					: null}

				{this.state.activeAttendance != null ?
					<Modal isOpen={true} onClose={this.closeAttendanceModal} title={translate('Attendance')}>
						<EditAttendanceForm
							attendance={this.state.activeAttendance}
							onClose={this.closeAttendanceModal}
							onSubmit={this.onAttendanceSubmit}
						/>
					</Modal>
					: null}

				{(!currentUser.isStudent() && (currentUser.isMentor() || currentUser.isAdministrator())) || (currentUser.isStudent() && currentUser.isAuthoritative()) ?
					<Button onClick={() => this.openAbsenceModal()}>
						{translate('report-absence')}
					</Button>
					: null}

				<div className="no-print pull-right">
					<div onClick={() => window.print()} style={{ cursor: 'pointer' }} className="color--meta">
						<span style={{ marginRight: '0.25rem', position: 'relative', top: 2 }}>
							<Icon name="Printer" />
						</span>

						{this.props.translate("print")}
					</div>
				</div>

				<div className="clearfix" style={{ marginBottom: '.75rem' }} />

				<Flex>
					<div style={{ flex: 1, marginRight: '0.55rem' }}>
						<Block>
							<span className="title">{translate('filter')}</span>
							{this.renderListActions()}
						</Block>
					</div>

					<div style={{ flex: 1, marginLeft: '0.55rem' }}>
						<Block>
							<span className="title">{translate('statistics')}</span>
							{this.renderAttendanceStatistics(data)}
						</Block>

						{courses.length > 0 ?
							<div style={{ marginTop: '1rem' }}>
								<Block>
									<span className="title">{translate('label-course-section-form')}</span>

									<div className="select">
										<select value={this.state.filterCourse} onChange={this.onFilterCourseChange}>
											<option value="0">
												{translate('select-an-alternative')}
											</option>

											{courses.map((course, index) => {
												return <option key={index} value={course}>
													{course}
												</option>
											})}
										</select>

										{this.state.filterCourse != '0' ?
											<div
												style={{ marginTop: '.25rem', cursor: 'pointer' }}
												onClick={() => this.setState({ filterCourse: '0' })}
												className="color--meta no-print"
											>
												{this.props.translate('restore-filter')}
											</div>
											: null}
									</div>
								</Block>
							</div>
							: null}
					</div>
				</Flex>

				<div className="clearfix" />

				{this.state.loading ?
					<Spinner center />
					:
					<DataList
						title={translate('attendance')}
						data={data}
						renderRow={this.renderRow}
						emptyData={() => (
							<h3 style={{ textAlign: 'center' }}>
								{translate('no-results')}
							</h3>
						)}
					/>
				}
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		attendance: state.Absence.userAttendance,
		groups: state.user.sections,
		attendanceStats: state.sections.studentAttendanceStats,
		users: state.user.info,
		currentUser: state.user.currentUser,
	};
}

export default connect(mapStateToProps, {
	getUserAttendance,
	submitAbsence,
	updateAbsence,
	deleteAbsence,
	clearStudentAttendance,
	updateAttendance,
	addError,
})(AttendanceList);