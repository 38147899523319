import React, { Component } from "react";
import { translate } from '@haldor/ui';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

import { getRootUrl } from "helpers/url";
import { isUserStudent } from "helpers/user";
import {
	COMPULSORY_SCHOOL,
	PRESCHOOL,
	ADULT_EDUCATION,
	UPPER_SECONDARY_EDUCATION,
} from "constants/schoolTypes";
import User from '_class/User';

import { getAssessment, getPlanAssessments } from "actions/assessments";
import { addError } from "actions/index";
import { getPlanDetails } from "actions/plans";
import { fetch_preschoolgoals } from "actions/preschoolform";
import { getSection } from "actions/sections";
import { getBlocksByReference } from 'actions/blocks';

import SelectedCoreContent from "containers/CoreContent/Selected";

import UserPlanning from "components/List/UserPlanning";
import DateSpan from "components/Presentation/Date/DateSpan";
import BlockConsumer from 'components/BlockEditor/Consumer';
import AssessmentsConsumer from 'components/BlockAssessments/Consumer';

import { Icon, Button, Block as ContentBlock } from '@haldor/ui';
import { List, Spinner } from "UI";
import Block, { BlockContent, BlockWrapper } from "UI/Elements/Block";

class PrintPlan extends Component {

	componentDidMount = () => {
		const user = new User(this.props.currentUser);
		const isPreschool = user.getActiveSchool().type == PRESCHOOL;

		if (this.props.match.params.planId > 0) {
			this.props.getPlanDetails(this.props.match.params.planId).then(() => {
				this.props.getBlocksByReference(this.props.match.params.planId, 'PLAN');
				this.props.getSection(this.props.plan.sectionId);
			});

			this.props.getPlanAssessments(this.props.match.params.planId);
		}

		if (this.props.goals.length < 1 && isPreschool) {
			this.props.fetch_preschoolgoals();
		}
	};

	goBack = (event) => {
		event.preventDefault();
		window.history.go(-1);

		setTimeout(function () {
			if (this != null && this.props != null) {
				const { match } = this.props;

				window.location.href = `${getRootUrl()}plan/${match.params.planId}`;
			}
		}, 200);

		return false;
	};

	backButton = () => {
		const { match } = this.props;

		if (match.params.planId) {
			return (
				<Link
					onClick={this.goBack}
					style={{
						cursor: "pointer",
						color: "black",
						textDecoration: "none",
					}}
					className="teams-icon-go-back"
					to="#"
				>
					<span className="chevron" style={{ paddingLeft: "0.5em" }}>
						{this.props.translate("back")}
					</span>
				</Link>
			);
		} else {
			return null;
		}
	};

	renderStatus = (status) => {
		if (status === "PLANNING_OPEN") {
			return (
				<span className="single-dot dot-green">
					{this.props.translate("Open")}
				</span>
			);
		} else if (status === "PLANNING_CLOSED") {
			return (
				<span className="single-dot dot-red">
					{this.props.translate("Closed")}
				</span>
			);
		} else if (status === "PLANNING_DRAFT") {
			return (
				<span className="single-dot dot-gray">
					{this.props.translate("Draft")}
				</span>
			);
		}
	};

	pupilStatus = () => {
		let status = null;
		if (this.props.plan != null && this.props.plan.students.length > 0) {
			status = this.props.plan.students[0].planningStatus;
		}

		if (status === "PLANNING_OPEN") {
			return (
				<span className="single-dot dot-green">
					{this.props.translate("open")}
				</span>
			);
		} else if (status === "PLANNING_CLOSED") {
			return (
				<span className="single-dot dot-red">
					{this.props.translate("closed")}
				</span>
			);
		} else if (status === "ASSESSMENT_PUBLISHED") {
			return (
				<span className="single-dot dot-green">
					{this.props.translate("assessed")}
				</span>
			);
		}

		return (
			<span className="single-dot dot-red">
				{this.props.translate("closed")}
			</span>
		);
	};

	printDialog = () => {
		window.print();
	};

	printGoals = () => {
		const { plan, goals } = this.props;

		if (!plan || goals.length < 1) {
			return null;
		}

		if (plan.associatedGoals && plan.associatedGoals.length > 0) {
			return (
				<Block>
					<BlockContent>
						{plan.associatedGoals.map((goal) => {
							let foundGoal = goals.find(function (g) {
								return g.id == goal.goalId;
							});

							if (foundGoal) {
								return (
									<div
										key={`goal-${foundGoal.id}`}
										style={{ backgroundColor: foundGoal.colorCode }}
										className="goal-preview"
									>
										{foundGoal.title}
									</div>
								);
							}

							return null;
						})}
					</BlockContent>
				</Block>
			);
		}
	};

	planContent = () => {
		var schoolType = this.props.currentUser.schools.getActiveSchool(
			this.props.currentUser.userId
		).type;
		const isStudent = isUserStudent(this.props.currentUser);
		const teacherOwnsPlan = this.props.section.owners?.find((owner) => {
			return owner.userId == this.props.currentUser.userId;
		}) != null;
		let assessmentBlocks = [];
		let assessment = null;

		let coreContents = [];
		if (this.props.section != null && this.props.plan.associatedCoreContent != null) {
			this.props.plan.associatedCoreContent.map((coreContent) => {
				let findCourse = this.props.section.courses.find((c) =>
					coreContent.coreContents && coreContent.coreContents.length > 0 && c.details[0].id == coreContent.coreContents[0].courseDetailsId
				);

				let courseTitle = "";
				let courseId = "";
				if (findCourse != null) {
					courseTitle = findCourse.title;
					courseId = findCourse.id;
				}

				coreContents.push({
					courseTitle: courseTitle,
					courseId: courseId,
					coreContents: coreContent.coreContents
				});
			})
		}

		if (this.props.blocks != null) {
			assessmentBlocks = [...this.props.blocks].filter((block) =>
				block.type == 'Haldor.Blocks.AssessmentBlock' && block.resources.length > 0
			);

			if (isStudent && this.props.assessments.length > 0) {
				assessment = this.props.assessments[0];
			}
		}

		return (
			<div className="single-plan single-task" key={this.props.plan.id}>
				<div className="single-section no-print">
					<Button type="secondary" onClick={this.printDialog}>
						<Icon name="Printer" />
						{this.props.translate("print")}
					</Button>
				</div>

				<div className="single-section">
					<div style={{ display: "block" }}>
						<h4 style={{ marginTop: 0 }}>
							{this.props.translate("Status")}
						</h4>

						<div>
							{isStudent ?
								this.pupilStatus()
								: this.renderStatus(this.props.plan.status)}

							<div style={{ clear: "both" }} />
						</div>
					</div>

					<div style={{ marginTop: "2em" }}>
						<h4>{this.props.translate("information")}</h4>

						<DateSpan
							start={this.props.plan.timeStart}
							end={this.props.plan.timeEnd}
						/>
						<div className="clearfix" />
					</div>
				</div>

				<div className="single-section form">
					<div className="single-assignment-meta">
						<span>{this.props.section.title}</span>
					</div>

					<h1
						style={{
							display: "inline-block",
							fontWeight: "600",
							fontSize: "2.2em",
						}}
					>
						<span style={{ color: "black" }}>
							{this.props.plan.title}
						</span>
					</h1>
				</div>

				<div style={{ clear: "both" }} />

				{this.props.blocks != null ?
					<ContentBlock>
						<BlockConsumer
							fields={this.props.blocks}
						/>
					</ContentBlock>
					: null}

				<div
					className="single-section-divider"
					style={{ marginBottom: "3rem" }}
				/>

				{this.props.plan.students?.length > 0 && !isStudent && teacherOwnsPlan ?
					<List title={this.props.translate("students")}>
						<UserPlanning
							items={this.props.plan.students}
							planId={this.props.match.params.planId}
							printable={true}
						/>
					</List>
					: null}

				{(!isStudent || this.props.plan.viewableCoreContent) && this.props.plan.associatedCoreContent?.length > 0 ?
					<div className="single-section print-core-contents" style={{ paddingTop: 0 }}>
						<SelectedCoreContent
							associatedCoreContent={this.props.plan.associatedCoreContent}
							assessmentBlocks={assessmentBlocks}
						/>
					</div>
					: null}

				{(schoolType == COMPULSORY_SCHOOL || schoolType == ADULT_EDUCATION || schoolType == UPPER_SECONDARY_EDUCATION) && assessmentBlocks.length > 0 ?
					<div className="single-section print-core-contents">
						<AssessmentsConsumer
							fields={assessmentBlocks}
							assessment={assessment}
							isStudent={isStudent}
						/>
					</div>
					: null}

				{schoolType == PRESCHOOL ?
					<BlockWrapper cols="2">
						{this.printGoals()}
					</BlockWrapper>
					: null}

				<div style={{ clear: "both" }} />
			</div>
		);
	};

	render() {
		if (this.props.plan != null && this.props.section != null) {
			return (
				<div>
					<div className="back-button single-section no-print">
						{this.backButton()}
					</div>

					<div className="container">
						{this.planContent()}
					</div>
				</div>
			);
		} else {
			return (
				<div className="container">
					<Spinner center />
				</div>
			);
		}
	}

}

function mapStateToProps(state) {
	return {
		plan: state.planning.active_plan,
		currentUser: state.user.currentUser,
		section: state.sections.activeSection,
		assessments: state.assessments.assessments,
		translate: translate(state.Languages.translations),
		goals: state.PreschoolGoals.preschoolGoals,
		blocks: state.Blocks.reference,
	};
}

export default connect(mapStateToProps, {
	getPlanDetails,
	addError,
	getAssessment,
	getSection,
	getPlanAssessments,
	fetch_preschoolgoals,
	getBlocksByReference,
})(PrintPlan);