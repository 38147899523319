import React, { Component } from 'react';
import swal from 'sweetalert2';
import { withRouter } from 'react-router-dom';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import User from '_class/User';

import getEnvironment from 'lib/env';
import { getToken, loginRequest, http } from 'lib/msal';

import { submit_preschooldocument, update_preschooldocument, deleteDocumentationFile } from 'actions/documents';
import { getPlanDetails, getPlansBySectionId } from 'actions/plans';
import { getSection, getSections } from 'actions/sections';

import DropzoneComponent from 'react-dropzone-component';
import FileItem from 'components/FileItem';
import GroupUserSelect from 'components/List/GroupUserSelect';
import UserSelect from 'components/List/UserSelect';

import SearchPreschoolGoals from './SearchPreschoolGoals';

import { Expandable, List } from 'UI';
import { Close, Image } from 'UI/Icons';
import { Editor } from 'UI/Inputs';
import { Button, Icon, Radio } from '@haldor/ui';

class Documentation extends Component {

	constructor(props) {
		super(props);
		const isEditing = (props.initialValues != null && Object.keys(props.initialValues).length > 1);

		let uploadedFiles = [];
		let defaultPlan = null;

		if (props.initialValues != null && props.initialValues.associatedFiles != null) {
			uploadedFiles = props.initialValues.associatedFiles;
		}

		if (props.initialValues != null && props.initialValues.planId != null) {
			defaultPlan = props.initialValues.planId;
		} else if (props.planId != null) {
			defaultPlan = props.planId;
		}

		this.state = {
			selectedGoals: isEditing ? props.initialValues.associatedGoals : [],
			Files: [],
			PlanId: defaultPlan,
			uploadedFiles: uploadedFiles,
			removedFiles: [],
			sectionRelations: [],
			error: null,
			uploadProgress: null,
			sectionPlans: null,
		};

		this.dropzone = null;

		/* Configure dropzone component */
		this.componentConfig = {
			iconFiletypes: ['.jpg', '.png', '.gif'],
			showFiletypeIcon: false,
			postUrl: http.defaults.baseURL + 'fileItems',
			dropzoneSelector: '.dropzone-trigger',
		};

		this.djsConfig = {
			autoProcessQueue: false,
			parallelUploads: 1,
			timeout: 99900000,
			headers: {},
		};

		let request = loginRequest;
		request.scopes = [
			...getEnvironment().scopes,
			...request.scopes
		];

		getToken(request).then((response) => {
			this.djsConfig.headers['Authorization'] = 'Bearer ' + response.accessToken;
		});

		this.dropzoneEvents = {
			init: this.dropzoneInit,
			addedfile: this.onAddFile,
			success: () => {
				this.dropzone.processQueue();
			},
		};
	}

	/* Redux form functions */
	required = (value) => {
		if (value != null && value !== '') {
			if (value.length > 199) {
				// Field failed validation, return error for this field (String)
				return this.props.translate('field-max-200-chars');
			}

			// Field passes validation, return a undefined error for this field
			// Rule: Not undefined and not an empty string and not over 200 characters
			return undefined;
		}

		// Field failed validation, return error for this field (String)
		return this.props.translate('field-needs-input');
	}

	quillValidation = (value) => {
		var strValue = value;

		var text = '';
		if (typeof (strValue) == 'string') {
			text = strValue.replace(/<(?:.|\n)*?>/gm, '');
		}

		if (text == "" || text == null) {
			return this.props.translate('needs-description');
		}

		return undefined;
	}

	validateRelationships = (value) => {
		if (value == null || value == '') {
			return this.props.translate('select-relation-error');
		}

		if (value.length == 0) {
			return this.props.translate('select-relation-error');
		}

		return undefined;
	}

	componentDidMount = () => {
		if (this.props.sections == null) {
			this.props.getSections();
		}

		let sectionId = null;
		if (this.props.section != null) {
			sectionId = this.props.section.id;
		}

		if (this.props.sectionId != null) {
			sectionId = this.props.sectionId;
		}

		if (sectionId != null) {
			this.props.getPlansBySectionId(sectionId);
		}

		if (this.props.planId != null) {
			this.props.getPlanDetails(this.props.planId).then(() => {
				const { plan } = this.props;
				let goals = [];
				plan.associatedGoals.map((goal) => {
					goals.push({ goalId: goal.goalId });
				});

				this.setState({ selectedGoals: goals });
			});
		}
	}

	onCancel = (event) => {
		event.preventDefault();

		if (this.props.onModalClose) {
			this.props.onModalClose();
		}
	}

	submit = (values) => {
		return new Promise(resolve => {
			if (values.relationships == null || values.relationships.length == 0) {
				this.setState({ error: this.props.translate('select-relation-error') + '!' });
				resolve(0);
				return false;
			}

			const { initialValues } = this.props;
			const isEditing = (initialValues != null && Object.keys(initialValues).length > 1 && initialValues.id != null);
			const user = new User(this.props.currentUser);

			if (this.dropzone && this.dropzone.getQueuedFiles().length > 0) {
				this.dropzone.on('totaluploadprogress', (progress) => {
					this.setState({ uploadProgress: progress });
				});

				const that = this;
				this.readyForSubmit(values).then((data) => {
					let url = `${http.defaults.baseURL}documentations/${data}/files?schoolId=${user.getActiveSchool().id}`;
					if (isEditing) {
						url = `${http.defaults.baseURL}documentations/${initialValues.id}/files?schoolId=${user.getActiveSchool().id}`;
					}

					this.componentConfig.postUrl = url;
					this.dropzone.options.url = url;
					this.dropzone.processQueue();

					this.dropzone.on("queuecomplete", (images) => {
						if (isEditing) {
							if (that.props.onModalClose) {
								that.props.onModalClose(true, true);
							}
						} else {
							this.props.history.push(`/preschooldocument/${data}`);
						}

						resolve(data);
					});
				});
			} else {
				const that = this;
				this.readyForSubmit(values).then((data) => {
					if (isEditing) {
						if (that.props.onModalClose) {
							that.props.onModalClose(true, true);
						}
					} else {
						this.props.history.push(`/preschooldocument/${data}`);
					}

					resolve(data);
				});
			}
		});
	}

	readyForSubmit = (values, callback) => {
		return new Promise((resolve, reject) => {
			const {
				selectedGoals,
				PlanId,
				removedFiles,
			} = this.state;

			const {
				submit_preschooldocument,
				update_preschooldocument,
				initialValues,
				currentUser,
			} = this.props;

			let goalIds = selectedGoals;

			/* Add custom inputs to the values object that redux form creates */
			values.associatedGoals = goalIds;
			values.planId = PlanId;

			if (values.status == null) {
				values.status = 'DOCUMENTATION_PUBLISHED';
			}

			if (values.sender != null) {
				if (values.sender == 'me') {
					values.author = currentUser.firstName + ' ' + currentUser.lastName;
				}

				delete values.sender;
			}

			// Check for initialValues and make sure we have an id
			if (Object.keys(initialValues).length > 1 && initialValues.id != null) {
				// If the goals hasn't changed during the update we
				// just fetch the existing goals on the documentation and send them back
				if (goalIds.length < 1) {
					goalIds = initialValues.associatedGoals;
				}

				if (removedFiles.length > 0) {
					removedFiles.map((file) => {
						this.props.deleteDocumentationFile(values.id, file.id);
					});
				}

				values.associatedGoals = goalIds;

				update_preschooldocument(values, (data) => {
					resolve(data);
				});
			} else {
				submit_preschooldocument(values, (data) => {
					resolve(data);
				});
			}
		});
	}

	onGoalsChange = (selectedGoals) => {
		this.setState({ selectedGoals });
	}

	onPlanChange = (event) => {
		if (event.target.value != this.state.PlanId) {
			this.props.getPlanDetails(event.target.value).then(() => {
				const { plan } = this.props;
				let goals = [];
				plan.associatedGoals.map(goal => {
					goals.push({ goalId: goal.goalId });
				});

				this.setState({ selectedGoals: goals });
			});

			this.setState({ PlanId: event.target.value });
		}
	}

	dropzoneInit = (dropzone) => {
		this.dropzone = dropzone;
	}

	onAddFile = (file) => {
		let { Files } = this.state;

		if (file.size > 101000024) {
			this.dropzone.removeFile(file);

			// File over 100MB
			swal.fire({
				title: this.props.translate('file-too-big'),
				showCancelButton: false,
				confirmButtonText: this.props.translate('okey'),
			});

			return false;
		}

		Files.push(file);

		setTimeout(() => {
			this.setState({ Files });
		}, 40);
	}

	onRemoveFile = (file) => {
		let { Files } = this.state;

		const fileIndex = Files.findIndex(function (f) {
			return f.upload.filename === file.upload.filename;
		});

		Files.splice(fileIndex, 1);
		this.setState({ Files }, () => {
			if (this.dropzone) {
				this.dropzone.removeFile(file);
			}
		});
	}

	removeFileFromDocument = (file) => {
		let { removedFiles, uploadedFiles } = this.state;

		if (file != null) {
			uploadedFiles = uploadedFiles.filter((f) => f.id != file.id);
			removedFiles.push(file);
			this.setState({ removedFiles, uploadedFiles });
		}
	}

	renderInput = ({ input, label, type, placeholder, meta: { touched, error, warning } }) => {
		return (
			<div>
				<input
					placeholder={placeholder || label}
					type={type}
					style={touched && error ? {
						border: '1px solid red'
					} : {}}
					{...input}
				/>

				{touched && ((error &&
					<span className="error">{error}</span>) || (warning &&
						<span className="error">{warning}</span>))
				}
			</div>
		);
	}

	renderTextarea = ({ input, label, type, placeholder, meta: { touched, error, warning } }) => {
		return (
			<div>
				<textarea {...input} placeholder={placeholder || label} type={type}>
					{input.value}
				</textarea>

				{touched && ((error &&
					<span style={{ marginTop: '1rem' }}>{error}</span>) || (warning &&
						<span style={{ marginTop: '1rem' }}>{warning}</span>))}
			</div>
		);
	}

	relationChange = (event) => {
		const { PlanId } = this.state;
		let sectionRelations = [];
		let relations = Object.values(event);

		relations.map(rel => {
			if (rel.referenceType == 'SECTION') {
				sectionRelations.push(rel);
			}

			if (rel.referenceType == 'USER') {
				sectionRelations.push(rel);
			}
		});

		if (sectionRelations.length > 0) {
			const firstReference = sectionRelations[0];
			let sectionId = null;

			if (firstReference.referenceType == 'USER') {
				sectionId = firstReference.parentId;
			} else {
				sectionId = firstReference.referenceId;
			}

			if (this.state.sectionPlans != sectionId) {
				this.props.getPlansBySectionId(sectionId);
				this.setState({ sectionPlans: sectionId })
			}
		}

		if (sectionRelations.length > 1 && PlanId != null) {
			if (this.props.section == null) {
				this.setState({ PlanId: null, selectedGoals: [], });
			}
		}

		this.setState({ sectionRelations });
	}

	triggerDropzone = () => {
		if (this.dropzone != null) {
			this.dropzone.hiddenFileInput.click();
		}
	}

	render() {
		const {
			sections,
			initialValues,
			currentUser,
		} = this.props;

		let { Files, uploadedFiles, PlanId, sectionRelations } = this.state;
		const isEditing = (initialValues != null && initialValues.id != null);

		if (sectionRelations != null && sectionRelations.length > 0) {
			sectionRelations = sectionRelations.filter(rel =>
				rel.referenceType == 'SECTION'
			);
		}

		if (isEditing) {
			const { author } = this.props.initialValues;
			initialValues.sender = author == currentUser.firstName + ' ' + currentUser.lastName ? 'me' : 'custom';
		} else {
			initialValues.sender = 'me';
		}

		return (
			<div className="form-container">
				<Form
					onSubmit={this.submit}
					initialValues={initialValues}
					initialValuesEqual={() => true}
					mutators={{
						setStatusDraft: (args, state, utils) => {
							utils.changeValue(state, 'status', () => 'DOCUMENTATION_DRAFT');
						},
						setStatusPublished: (args, state, utils) => {
							utils.changeValue(state, 'status', () => 'DOCUMENTATION_PUBLISHED');
						},
					}}
					render={({ form, valid, handleSubmit, submitting }) => {
						const senderValue = form.getFieldState('sender')?.value;
						return <form onSubmit={handleSubmit} className="form form-component">
							<Expandable
								contentStyles={{ padding: '1em 0rem' }}
								contentStylesClosed={{ padding: '0' }}
								headerStyles={this.state.error != null ? { border: '1px solid red' } : {}}
								whiteBackground={true}
								title={this.props.translate('groups-and-children') + '*'}
								open={!isEditing}
								ignorePropsUpdate
							>
								<List style={{ border: '0', margin: '0', boxShadow: 'none' }}>
									{this.props.section != null ?
										<List style={{ border: '0', margin: '0', boxShadow: 'none' }}>
											<Field
												component={GroupUserSelect}
												name="relationships"
												section={this.props.section}
												onChange={this.relationChange}
												validate={this.validateRelationships}
											/>
										</List>
										:
										<List style={{ border: '0', margin: '0', boxShadow: 'none' }}>
											<Field
												component={UserSelect}
												name="relationships"
												sections={sections}
												userSelectDisabled={false}
												selectMultipleSchools={this.props.selectMultipleSchools}
												onChange={this.relationChange}
												validate={this.validateRelationships}
											/>
										</List>
									}
								</List>
							</Expandable>

							{this.state.error != null ?
								<div className="form-row error">
									{this.state.error}
								</div>
								: null}

							<div className="form-row">
								<label>{this.props.translate('planning')}</label>
								<div className="select">
									<select
										onChange={this.onPlanChange}
										value={PlanId}
										disabled={this.props.planId || isEditing ? true : sectionRelations.length > 1 ? true : false}
									>
										<option value="">{this.props.translate('pick-a-plan')}</option>

										{this.props.sectionPlans !== null ?
											this.props.sectionPlans.map((plan) => {
												return <option key={plan.id} value={plan.id}>
													{plan.title}
												</option>
											})
											: null}
									</select>
								</div>
							</div>

							<div className="form-row">
								<label>{this.props.translate('Sender')}</label>

								<div style={{ display: 'inline-block' }}>
									<Field
										component={Radio}
										name="sender"
										label={this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName}
										optionValue="me"
									/>
								</div>

								<div style={{ display: 'inline-block', marginLeft: '.85rem' }}>
									<Field
										component={Radio}
										name="sender"
										optionValue="custom"
										label={this.props.translate('Free text') + (senderValue == 'custom' ? '*' : '')}
									/>
								</div>
							</div>

							{senderValue == 'custom' ?
								<div className="form-row" style={{ marginTop: '.35rem' }}>
									<Field
										name="author"
										type="text"
										component={this.renderInput}
										validate={this.required}
									/>
								</div>
								: null}

							<div className="form-row">
								<label>{this.props.translate('title')}*</label>
								<Field
									name="title"
									type="text"
									component={this.renderInput}
									placeholder={this.props.translate('title')}
									validate={this.required}
								/>
							</div>

							<div className="form-row">
								<label>{this.props.translate('description')}</label>

								<Field
									component={Editor}
									name="description"
									placeholder={this.props.translate('description')}
									translate={this.props.translate}
								/>
							</div>

							<div className="form-row">
								<label>{this.props.translate('add-images-and-files')}</label>

								<div className="dropzone-trigger icon-container" style={{ height: 'auto', width: '100%' }}>
									<div onClick={this.triggerDropzone} className="icon">
										<Image />
									</div>

									<div className="text" onClick={this.triggerDropzone}>
										{this.props.translate('drop-files-or-click-here')}
									</div>

									<DropzoneComponent
										djsConfig={this.djsConfig}
										eventHandlers={this.dropzoneEvents}
										config={this.componentConfig}
									/>
								</div>

								<div style={{ clear: 'both' }}></div>
							</div>

							<div className="form-row">
								{Files && Files.length > 0 ?
									<div>
										<div>
											<label>{this.props.translate('images-needs-uploading')}</label>
										</div>

										<div style={{ marginLeft: '-0.5rem', marginRight: '-0.5em', display: 'flex' }}>
											{Files.map(file => {
												return (
													<div
														key={file.lastModified}
														style={file.type.indexOf('image') > -1 ? {
															background: `url(${file.dataURL})`
														} : {}}
														className={`dropzone-file-preview ${!file.accepted ? " not-accepted" : ""} ${file.upload.progress > 0 ? ' upload-started' : ' not-started'} ${file.type.indexOf('image') > -1 ? ' image' : ''}`}
													>
														<div className="file-name">
															<div style={{ flex: 1, maxWidth: 'calc(100% - 1rem)', wordWrap: 'break-word' }}>
																{file.name + ' '}

																({file.size > 1048576 ?
																	Math.ceil(file.size / 1024 / 1024) + 'MB'
																	:
																	Math.max(Math.ceil(file.size / 1024), 1) + 'KB'
																})
															</div>

															<div onClick={e => { this.onRemoveFile(file) }} className="file-delete-btn">
																<Close />
															</div>
														</div>

														{file.accepted && file.size > 7500000 ?
															<div className="file-warning">
																<Icon name="Info_Blue" />

																<div style={{ flex: 1 }}>
																	{this.props.translate('size-warning')}
																</div>
															</div>
															: null}

														{!file.accepted ?
															<div className="file-warning">
																<Icon name="Alert" />

																<div style={{ flex: 1 }}>
																	{this.props.translate('file-too-big')}
																</div>
															</div>
															: null}

														{file.type.indexOf('image') > -1 ?
															<div className="overlay"></div>
															: null}
													</div>
												);
											})}
										</div>
									</div>
									: null}
							</div>

							{isEditing && initialValues.associatedFiles != null ?
								<div className="form-row" style={{ marginTop: '1rem' }}>
									{uploadedFiles != null && uploadedFiles.length > 0 && initialValues?.associatedFiles?.length > 0 ?
										<div>
											<div>
												<label>{this.props.translate('images-attached')}</label>
											</div>

											<div style={{ marginLeft: '-0.5rem', marginRight: '-0.5em', display: 'flex' }}>
												{uploadedFiles.map((file) => {
													return <FileItem element="div" file={file} key={file.id} className={`dropzone-file-preview ${file.type.indexOf('image') > -1 ? ' image' : ''}`}>
														<div className="file-name">
															<div style={{ flex: 1 }}>
																{file.name}
															</div>

															<div onClick={(e) => { this.removeFileFromDocument(file) }} className="file-delete-btn">
																<Close />
															</div>
														</div>

														{file.type.indexOf('image') > -1 ?
															<div className="overlay"></div>
															: null}
													</FileItem>
												})}
											</div>
										</div>
										: null}
								</div>
								: null}

							<div className="form-row">
								<label>{this.props.translate('select-goals')}</label>

								<SearchPreschoolGoals
									selectedGoals={this.state.selectedGoals}
									onChange={this.onGoalsChange}
								/>
							</div>

							<div className="form-divider" />

							<div className="form-row spacing submit">
								<Button
									onClick={e => {
										e.preventDefault();
										form.mutators.setStatusPublished();
										form.submit();
									}}
									disabled={submitting || !valid}
								>
									{this.props.translate('publish')}
								</Button>

								{isEditing && initialValues.status != "DOCUMENTATION_DRAFT" ? null : (
									<Button
										onClick={e => {
											e.preventDefault();
											form.mutators.setStatusDraft();
											form.submit();
										}}
										type="secondary"
										disabled={submitting || !valid}
									>
										{this.props.translate("save-draft")}
									</Button>
								)}

								<div className="align-right">
									<Button onClick={this.onCancel} disabled={submitting} type="secondary">
										{this.props.translate('Cancel')}
									</Button>
								</div>
							</div>

							{submitting ?
								<div className="is_sending">
									<p>
										<span className="loading-spinner" />
										<br />

										{this.state.uploadProgress != null ?
											<div className="upload-progress">
												<div style={{ width: Math.round(this.state.uploadProgress) + '%' }} className="upload-progress-bar" />
											</div>
											: null}
									</p>
								</div>
								: null}
						</form>
					}}
				/>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	let values = {
		...ownProps.values,
		...state.Documents.activeDocument
	};

	return {
		currentUser: state.user.currentUser,
		initialValues: values,
		sectionPlans: state.planning.sectionPlans,
		plan: state.planning.active_plan,
		translate: translate(state.Languages.translations),
		sections: state.sections.educationGroups,
		preschoolDocuments: state.Posts.documents,
	};
}

export default withRouter(connect(mapStateToProps, {
	submit_preschooldocument,
	update_preschooldocument,
	getPlansBySectionId,
	getSection,
	getSections,
	deleteDocumentationFile,
	getPlanDetails,
})(Documentation));
