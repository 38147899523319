import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import swal from 'sweetalert2';

import DateTime from '_class/DateTime';
import User from '_class/User';

import { getDescription } from 'helpers/content';
import { setPageTitle, setPageActions } from 'actions/header';

import { getArchivedEducationGroups, updateArchived } from 'actions/sections';
import { getMySections, getAllSections } from 'actions/user';
import { getSchoolDataForSections } from 'actions/schools';
import { searchGroups, clearSearch, searchMySections } from 'actions/search';

import SectionForm from 'containers/Forms/SectionForm';
import Modal from 'containers/Modals/Modal';

import { Button, Card, Checkbox, Flex, Tabs, Tab, Icon, translate } from '@haldor/ui';
import { Spinner, Expandable } from 'UI';
import { Search as SearchInput } from 'UI/Inputs';

import './_Groups.scss';
import SectionOptionForm from 'containers/Forms/SectionOptionForm';
import { getActiveSchool } from 'helpers/localstorage';

class MySections extends Component {

	constructor(props) {
		super(props);

		let activeTab = 'linked';
		let filters = ['education'];
		let sort = 'TITLE';
		const user = new User(props.currentUser);

		if (!user.isStudent()) {
			filters.push('archived');
		}

		if (user.isMentor()) {
			filters.push('mentor');
		}

		if (window.location.hash.replace('#', '') != '') {
			activeTab = window.location.hash.replace('#', '');
		}

		if (localStorage.getItem('group-filters:' + props.currentUser.id) != null) {
			filters = JSON.parse(localStorage.getItem('group-filters:' + props.currentUser.id));
		}

		if (localStorage.getItem('group-sort:' + props.currentUser.id) != null) {
			sort = localStorage.getItem('group-sort:' + props.currentUser.id);
		}

		this.state = {
			activeTab,

			expand: false,
			loading: false,
			selectedGroup: null,
			loadingGroups: [],
			nextPageLoading: false,

			page: 1,
			sort,
			filters,

			optionsModalVisible: false
		};
	}

	UNSAFE_componentWillMount = () => {
		this.props.setPageTitle(this.props.translate('sections-header-overview'));
	}

	componentDidMount = () => {
		const user = new User(this.props.currentUser);
		if (user.isAdministrator()) {
			this.props.setPageActions([
				{
					value: this.props.translate('Options'),
					type: 'button',
					onClick: this.toggleOptionModal,
					icon: 'cog_bw',
				},
			]);
		}

		this.getSchoolDataForSection();
		this.getSections();

		window.addEventListener('hashchange', this.handleHashChange);
	}

	componentWillUnmount = () => {
		window.removeEventListener('hashchange', this.handleHashChange);
		this.props.clearSearch();
	}

	getFilter = () => {
		let filter = '';

		if (this.state.activeTab == 'all') {
			filter += 'UNLINKED;';
		}

		if (this.state.activeTab == 'notconnected') {
			filter += 'UNLINKED;';
		} else {
			if (this.state.filters.includes('mentor')) {
				filter += 'MENTOR_GROUP;';
			}

			if (this.state.filters.includes('education')) {
				filter += 'EDUCATION_GROUP;';
			}

			// WE do this because user must select mentor groups or education group in the filter
			if ((filter.includes('MENTOR_GROUP') || filter.includes('EDUCATION_GROUP')) && this.state.activeTab == 'connected') {
				filter += 'LINKED;';
			}
		}

		// if (this.state.filters.includes('groups_owned_by_me')) {
		// 	filter += 'OWNED_BY_ME;';
		// }

		if (!this.state.filters.includes('archived')) {
			filter += 'ACTIVE;';
		}

		return filter;
	}

	toggleOptionModal = () => {
		this.setState({ optionsModalVisible: !this.state.optionsModalVisible });
	}

	getSchoolDataForSection = () => {
		const activeSchool = getActiveSchool(this.props.currentUser.id);

		this.props.getSchoolDataForSections(activeSchool);
	}

	showConnectButton = () => {
		const user = new User(this.props.currentUser);
		if (this.props.schoolDataForSections == null) {
			return false;
		}

		if (user.isTeacher() && !user.isMentor()) {
			return this.props.schoolDataForSections.allowManualEducationGroupConnection
		}

		if (!user.isTeacher() && user.isMentor()) {
			return this.props.schoolDataForSections.allowManualMentorGroupConnection
		}


		if (user.isTeacher() && user.isMentor()) {

			return this.props.schoolDataForSections.allowManualEducationGroupConnection
				|| this.props.schoolDataForSections.allowManualMentorGroupConnection;
		}

		return false;
	}

	getSections = (nextPage = false) => {
		const user = new User(this.props.currentUser);
		this.setState({ loading: !nextPage, nextPageLoading: nextPage });
		let filter = this.getFilter();
		if (user.isAdministrator()) {
			if (this.state.activeTab == "allgroupsfromschool") {
				this.props.getAllSections({
					pageIndex: this.state.page,
					filter: filter,
					sortBy: this.state.sort,
				}).then(() => {
					this.setState({ loading: false, nextPageLoading: false });
				});
			} else {
				this.props.getMySections({
					pageIndex: this.state.page,
					filter: filter,
					sortBy: this.state.sort,
				}).then(() => {
					this.setState({ loading: false, nextPageLoading: false });
				});
			}
		} else {
			this.props.getMySections({
				pageIndex: this.state.page,
				filter: filter,
				sortBy: this.state.sort,
			}).then(() => {
				this.setState({ loading: false, nextPageLoading: false });
			});
		}
	}

	getNextPage = async () => {
		await this.setState({ page: this.state.page + 1 });
		this.getSections(true);
	}

	handleHashChange = async () => {
		await this.setState({ activeTab: window.location.hash.replace('#', ''), page: 1 });

		if (this.state.searchQuery != null) {
			this.onSearch(this.state.searchQuery);
		} else {
			this.getSections();
		}
	}

	noGroupsFound = () => {
		return (
			<div>
				<h3 style={{ textAlign: 'center' }}>
					{this.props.translate('no-sections-found')}
				</h3>
			</div>
		);
	}

	noSearchResultFound = () => {
		return (
			<div>
				<h3 style={{ textAlign: 'center' }}>
					{this.props.translate('no-search-was-found')}
				</h3>
			</div>
		);
	}

	cutDescription = (description) => {
		if (description == null) {
			return '';
		}

		let strippedDescription = getDescription(description).replace(/(<([^>]+)>)/ig, '');
		if (strippedDescription.length > 99) {
			return strippedDescription.slice(0, 100) + '…';
		}

		return strippedDescription;
	}

	closeModal = () => {
		this.setState({ selectedGroup: null });
	}

	closeOptionsModal = () => {
		this.setState({ optionsModalVisible: false });
	}

	onSearch = async (values) => {
		if (values.query.length < 3) {
			return false;
		}

		const user = new User(this.props.currentUser);
		this.setState({ loading: true, searchQuery: values });
		const filter = this.getFilter();
		this.setState({ loading: true });

		if (user.isAdministrator() && this.state.activeTab == "allgroupsfromschool") {
			this.props.getAllSections({
				filter: filter,
				query: values.query,
				sortBy: this.state.sort,
			}).then(() => {
				this.setState({ loading: false });
			});
		} else {
			await this.props.searchMySections({
				query: values.query,
				filter: filter,
				sortBy: this.state.sort,
			}).then(() => {
				this.setState({ loading: false });
			});
		}

		// this.setState({ loading: false });
	}

	searchOnChange = async (values) => {
		if (values == null || values.length == 0) {
			const user = new User(this.props.currentUser);
			await this.setState({ searchQuery: null, page: 1 });
			this.props.clearSearch();

			if (user.isAdministrator() && this.state.activeTab == "allgroupsfromschool") {
				this.getSections();
			}
		}
	}

	toggleArchived = async (group) => {
		if (!group.archived) {
			const result = await swal.fire({
				title: this.props.translate('are-you-sure'),
				html: this.props.translate('Both the group and the Microsoft Team will be archived.<br /><br />Do you want to archive this group?'),
				showCancelButton: true,
				focusConfirm: false,
				cancelButtonText: this.props.translate('No'),
				confirmButtonText: this.props.translate('Yes'),
			});

			if (result.dismiss != null) {
				return true;
			}
		} else {
			const result = await swal.fire({
				title: this.props.translate('are-you-sure'),
				text: this.props.translate('Are you sure you want to reset this group?'),
				showCancelButton: true,
				cancelButtonText: this.props.translate('No'),
				confirmButtonText: this.props.translate('Yes'),
			});

			if (result.dismiss != null) {
				return true;
			}
		}

		group.courses = null;

		this.setState({ loadingGroups: [...this.state.loadingGroups, group.id] });

		this.props.updateArchived(group)
			.then(() => {
				this.setState({ loadingGroups: [...this.state.loadingGroups.filter(lg => lg != group.id)] });
			});
	}

	toggleFilter = async (filter) => {
		let filters = this.state.filters;
		if (filters.includes(filter)) {
			filters = filters.filter(f => f != filter);
		} else {
			filters.push(filter);
		}

		await this.setState({ filters, page: 1 }, () => {
			const { currentUser } = this.props;
			localStorage.setItem('group-filters:' + currentUser.id, JSON.stringify(this.state.filters));
		});

		this.setState({ loading: true });
		this.getSections();
	}

	onSortChange = async (event) => {
		await this.setState({ sort: event.target.value, page: 1 }, () => {
			const { currentUser } = this.props;
			localStorage.setItem('group-sort:' + currentUser.id, this.state.sort);
		});

		this.setState({ loading: true });
		this.getSections();
	}

	connectGroup = (group) => {
		this.setState({ selectedGroup: group });
	}

	renderSearchResult = () => {
		const { searchResults } = this.props;

		if (this.state.loading) {
			return <div>
				<Spinner center />
			</div>
		}

		return (<div>
			{searchResults != null && searchResults.length == 0 ?
				this.noSearchResultFound()
				: null}

			{searchResults != null && searchResults.length > 0 ?
				searchResults.map(this.renderGroup)
				: null}
		</div>)
	}

	renderGroupResult = () => {
		const { groups } = this.props;
		let filter = this.getFilter();
		let showResult = filter.includes('MENTOR_GROUP') || filter.includes('EDUCATION_GROUP') || filter.includes('UNLINKED');

		if (this.state.loading) {
			return <div>
				<Spinner center />
			</div>
		}

		return (
			<div>
				{(!this.state.loading && groups != null && groups.length == 0) || !showResult ?
					this.noGroupsFound()
					: null}

				{groups != null && groups.length > 0 && showResult ?
					groups.map(this.renderGroup)
					: null}
			</div>
		);
	}

	renderGroup = (group, index) => {
		const user = new User(this.props.currentUser);
		const loading = this.state.loadingGroups.indexOf(group.id) > -1;
		let events = group.events;
		let element = Link;
		if (group.id == 0) {
			element = null;
		}

		if (this.state.filters == null || this.state.filters.length == 0) {
			return null;
		}

		let cardTitle = (
			<div className="group-header">
				<span className="header-title">
					{group.title}
				</span>

				{!user.isStudent() ? group.id != 0 ?
					<>
						<div className="card-meta outline" style={{ marginLeft: '.75rem', borderColor: '#19db6c' }}>
							{this.props.translate('Connected')}
						</div>
						{group.lastSynced != null ?
							<div className="card-meta" style={{ marginLeft: '.75rem' }}>
								<Icon name="Sync" />
								{`${new DateTime(group.lastSynced).getDateStamp()}, ${new DateTime(group.lastSynced).getTime()}`}
							</div>
							: null}
					</>
					:
					<div className="card-meta outline" style={{ marginLeft: '.75rem', borderColor: '#efc24c' }}>
						{this.props.translate('Not connected')}
					</div>
					: null}

				{group.archived ?
					<div className="card-meta" style={{ marginLeft: '.75rem' }}>
						{this.props.translate('Archived')}
					</div>
					: null}
			</div>
		);

		return <Card element={element} to={`/groups/${group.id}`} style={{ marginBottom: '0.75rem' }} key={'group-' + group.id + '-' + index}>
			<div style={{ flex: 1 }}>

				<Expandable title={cardTitle} open={this.state.expand}>
					<Flex top space>
						<div className="description size-14">
							{this.cutDescription(group.description)}
						</div>

						{!user.isStudent() ?
							<div className="size-14">
								{events != null ?
									events.map((event, index) => {
										return <Flex key={index}>
											<div className="event-date">{new DateTime(event.created).getDateStamp()}</div>

											<div className="event-text">
												{this.props.translate(event.event)}
												{event.createdBy != null ?
													' • ' + event.createdBy.firstName + ' ' + event.createdBy.lastName
													: null}
											</div>
										</Flex>
									})
									: null}
							</div>
							: null}
					</Flex>
				</Expandable>

				{!user.isStudent() ?
					<div className="meta-container" style={{ marginTop: '.5rem' }}>
						{group.type != null ?
							<div className="card-meta" style={{ marginRight: '.75rem' }}>
								{this.props.translate(group.type)}
							</div>
							: null}

						{group.userRole == 'OWNER' ?
							<div className="card-meta" style={{ marginRight: '.75rem' }}>
								{this.props.translate('Owner')}
							</div>
							:
							null
						}
					</div>
					: null}
			</div>

			{!user.isStudent() && (user.isAdministrator() || group.userRole == 'OWNER') ?
				<div className="group-actions">
					{group.id == 0 && this.showConnectButton() && (user.isAdministrator || group.userRole == 'OWNER') ?
						<div style={{ marginBottom: 5 }}>
							<Button type="secondary" onClick={(e) => { e.preventDefault(); this.connectGroup(group) }}>
								{this.props.translate('Connect')}
							</Button>
						</div>
						: null}

					{group.id != 0 && group.userRole == 'OWNER' ?
						<Fragment>
							<div style={{ marginBottom: 5 }}>
								<Button type="secondary" disabled={loading} onClick={(e) => { e.preventDefault(); this.toggleArchived(group) }}>
									{loading ?
										<Spinner />
										: null}

									{group.archived ?
										this.props.translate('Restore')
										: this.props.translate('Archive')}
								</Button>
							</div>
						</Fragment>
						: null}
				</div>
				: null}
		</Card>
	}

	toggleExpand = () => this.setState({ expand: !this.state.expand });

	renderFoldButton = () => {
		return <Flex end>
			<div className={`all-fold-button color--meta ${this.state.expand ? 'open' : 'closed'}`} onClick={this.toggleExpand}>
				<Icon name="ArrowLeft" />

				{this.state.expand ?
					this.props.translate('Hide description')
					:
					this.props.translate('Show description')
				}
			</div>
		</Flex>
	}

	renderFilters = () => {
		const user = new User(this.props.currentUser);

		return <div className="filter-container">
			<div className="form">
				<div className="form-row">
					<div className="form-row-half">
						<br />

						<SearchInput
							autoSearch
							placeholder={this.props.translate('Search group')}
							onSubmit={this.onSearch}
							onChange={this.searchOnChange}
						/>
					</div>

					<div className="form-row-half last">
						<div className="select">
							<label style={{ margin: 0 }}>
								{this.props.translate('Sort')}
							</label>

							<select name="sortorder" onChange={this.onSortChange} value={this.state.sort}>
								<option value="TITLE">{this.props.translate('Group name ascending (A-Z)')}</option>
								<option value="TITLE_DESC">{this.props.translate('Group name descening (Z-A)')}</option>
								<option value="LINKED_DATE">{this.props.translate('Connection date, ascending A-Z')}</option>
								<option value="LINKED_DATE_DESC">{this.props.translate('Connection date, descending Z-A')}</option>
								<option value="CREATED_DATE">{this.props.translate('Created date, ascending A-Z')}</option>
								<option value="CREATED_DATE_DESC">{this.props.translate('Created date, descending Z-A')}</option>
							</select>
						</div>

						<div style={{ marginTop: '1rem' }}>
							<label style={{ margin: 0 }}>
								{this.props.translate('Filter')}
							</label>

							{(user.isMentor() && user.isTeacher()) || user.isAdministrator() ?
								<Fragment>
									<Checkbox
										label={this.props.translate('Education groups')}
										value={this.state.filters.indexOf('education') > -1}
										onChange={() => this.toggleFilter('education')}
									/>

									<Checkbox
										label={this.props.translate('Mentor groups')}
										value={this.state.filters.indexOf('mentor') > -1}
										onChange={() => this.toggleFilter('mentor')}
									/>
								</Fragment>
								: null}

							{this.state.filters.indexOf('mentor') > -1 || this.state.filters.indexOf('education') > -1 ?
								<Checkbox
									label={this.props.translate('Show archived')}
									value={this.state.filters.indexOf('archived') > -1}
									onChange={() => this.toggleFilter('archived')}
								/>
								: null}

							{/* {user.isAdministrator() && (this.state.filters.indexOf('mentor') > -1 || this.state.filters.indexOf('education') > -1) ?
								<Checkbox
									label={this.props.translate('Groups owned by me')}
									value={this.state.filters.indexOf('groups_owned_by_me') > -1}
									onChange={() => this.toggleFilter('groups_owned_by_me')}
								/>
								: null} */}
						</div>
					</div>

					<div className="clearfix" />
				</div>
			</div>
		</div>
	}

	renderNotConnectedErrorMessages = () => {
		const user = new User(this.props.currentUser);

		if (user != null && !user.isMentor() && !user.isTeacher()) {
			return (<div className="disabled-connect-button">
				<Icon name="Alert" bw />
				{this.props.translate('To connect groups, you must have the role of teacher or mentor.')}
			</div>)
		}

		if (!this.showConnectButton()) {
			return (<div className="disabled-connect-button">
				<Icon name="Alert" bw />
				{this.props.translate('You are not allowed to connect groups to this school because the administrator has disabled that functionality.')}
			</div>)
		}

		return null;
	}

	renderGroupList = () => {
		const user = new User(this.props.currentUser);
		let { searchQuery } = this.state;

		if (user.isStudent()) {
			return (
				<div>
					{this.renderFoldButton()}

					{this.renderGroupResult()}
				</div>
			);
		}

		return (
			<Tabs>
				<Tab route="connected" title={this.props.translate('Connected groups')}>
					{this.state.loading ?
						<Spinner center />
						:
						<>
							{this.renderFoldButton()}
							{searchQuery != null ? this.renderSearchResult() : this.renderGroupResult()}
						</>
					}
				</Tab>

				<Tab route="notconnected" title={this.props.translate('Not connected groups')}>
					{this.state.loading ?
						<Spinner center />
						:
						<>
							{this.renderNotConnectedErrorMessages()}
							{this.renderFoldButton()}
							{searchQuery != null ? this.renderSearchResult() : this.renderGroupResult()}
						</>
					}
				</Tab>

				{user.isAdministrator() ?
					<Tab route="allgroupsfromschool" title={this.props.translate('All groups in this school')}>
						{this.state.loading ?
							<Spinner center />
							:
							<>
								{this.renderFoldButton()}
								{this.renderGroupResult()}
							</>
						}
					</Tab>
					: null}
			</Tabs>
		);
	}

	render() {
		const user = new User(this.props.currentUser);
		let filter = this.getFilter();

		let showResult = filter.length > 0;

		return (
			<div className="page groups">
				{!user.isStudent() ?
					<Modal
						isOpen={this.state.selectedGroup != null}
						onClose={this.closeModal}
						title={this.props.translate('Connect')}
					>
						<SectionForm
							onAbort={this.closeModal}
							section={this.state.selectedGroup}
							connectView
						/>
					</Modal>
					: null}

				{user.isAdministrator() ?
					<Modal
						isOpen={this.state.optionsModalVisible}
						onClose={this.closeOptionsModal}
						title={this.props.translate('Options')}
						type="small news"
					>
						<SectionOptionForm
							onAbort={this.closeOptionsModal}
							allowManualConnection={this.props.schoolDataForSections}
						/>
					</Modal>
					: null}

				{!user.isStudent() ?
					this.renderFilters()
					: null}

				{this.renderGroupList()}

				{location.hash != '#search' && (this.state.searchQuery == null && this.props.groups?.length % 20 < 1 && this.props.groups?.length > 0 && this.state.filters.length > 0 && showResult) ?
					<div className="df jcc" style={{ marginTop: '2rem' }}>
						<Button type="secondary" onClick={this.getNextPage}>
							{this.state.nextPageLoading ?
								<Spinner />
								: null}

							{this.props.translate('Show more')}
						</Button>
					</div>
					: null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		educationGroups: state.sections.educationGroups,
		archivedEducationGroups: state.sections.archivedEducationGroups,
		currentUser: state.user.currentUser,
		translate: translate(state.Languages.translations),
		groups: state.user.mySections,
		searchResults: state.Search.groups,
		schoolDataForSections: state.School.schoolDataForSection
	};
}

export default connect(mapStateToProps, {
	getArchivedEducationGroups,
	setPageTitle,
	updateArchived,
	getMySections,
	searchGroups,
	clearSearch,
	getAllSections,
	searchMySections,
	getSchoolDataForSections,
	setPageActions
})(MySections);
