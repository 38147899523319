import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { setActiveSchool } from 'actions/user';

import { Icon, ActionSheet, TooltipMenu, translate } from '@haldor/ui';
import * as microsoftTeams from "@microsoft/teams-js";

import './_device.scss';

class DeviceHeader extends Component {

	isIndex = () => {
		return window.location.pathname == '/';
	}

	goBack = (event) => {
		event.preventDefault();
		window.history.go(-1);
	}

	getBadgeForSchool = (school) => {
		const { activeSchool } = this.props;

		if (this.props.userUnread == null || school.id == activeSchool) {
			return null;
		}

		return this.props.userUnread.reduce((value, item) => {
			if ((item.schoolId == 0 || item.schoolId == school.id) && item.type == 'CONVERSATION') {
				if (value == null)
					value = 0;

				return value += item.unread;
			}

			return value;
		}, null);
	};

	getBadgeForItem = () => {
		if (this.props.userUnread == null) {
			return null;
		}

		const { activeSchool } = this.props;

		return this.props.userUnread.reduce((value, item) => {
			if (item.schoolId == activeSchool && item.type != 'CONVERSATION') {
				return value;
			}

			if (value == null)
				value = 0;

			return value += item.unread;
		}, null);
	};

	selectSchool = () => {
		const { user } = this.props;

		const schools = user.schools.map((school) => ({
			label: (
				<div>
					{this.getBadgeForSchool(school) != null ?
						<div
							className="badge"
							style={{
								display: 'inline-flex',
								justifyContent: 'center',
								transform: 'none',
								borderRadius: '100%',
								position: 'static',
								marginRight: '.4rem'
							}}>
							{this.getBadgeForSchool(school)}
						</div>
						: null}

					{school.title}
				</div>
			),
			value: school.id,
		}));

		ActionSheet.prompt({
			items: schools,
		}).then((school) => {
			localStorage.setItem("hal.school" + user.id, school);
			this.props.setActiveSchool(school);

			this.props.history.push("/switch");
		}).catch(() => null);
	};

	renderAction = (action) => {
		if (action.type == 'dropDown')
			return (
				<div>
					{action.subButtons ?
						action.subButtons.map((subAction, index) => (
							<TooltipMenu.Item
								onClick={() => subAction.onClick(true)}
								key={'subAction-' + index}
							>
								{subAction.value}
							</TooltipMenu.Item>
						))
						: null}
				</div>
			);

		return (
			<TooltipMenu.Item onClick={action.onClick}>
				{action.icon != null ?
					<Icon name={action.icon} />
					: null}

				{action.value}
			</TooltipMenu.Item>
		);
	}


	inIframe() {
		try {
			return window.self !== window.top || window.navigator.userAgent.indexOf('TeamsMobile') > -1;
		} catch (e) {
			return true;
		}
	}

	openDeviceMenu = () => {
		let renderedCategories = new Set();
		let menuItems = [];

		if (this.props.items.length > 0) {
			this.props.items.map((item, index) => {
				let categoryDiv = null;

				if (item.type == 'EXTERNAL') {
					return null;
				}

				// Check if the category has not been rendered yet
				if (item.parentId != null && !renderedCategories.has(item.parentId)) {
					renderedCategories.add(item.parentId); // Mark this category as rendered
					categoryDiv = (
						<div className="divider-header">
							<span className='label'>{this.props.translate(item.parentId.toLowerCase())}</span>
						</div>
					);
				}

				let elementDiv = (<div className='menu-divider'>
					{categoryDiv}

					<div className="menu-item-container" key={'item-' + index}>
						<div className='menu-item-device'>
							<div className="menu-item-icon">
								<Icon name={item.iconId} />
							</div>

							<span>{this.props.translate(item.text.toLowerCase())}</span>
						</div>
					</div>
				</div>)

				menuItems.push({
					label: elementDiv,
					value: item
				});
			});


			this.props.items.map((item, index) => {
				if (item.parentId != null && item.parentId != '') {
					return null;
				}

				if (item.type != 'EXTERNAL') {
					return null;
				}

				let elementDiv = (<div className='menu-divider'>
					<div className="menu-item-container" key={'item-' + index}>
						<div className='menu-item-device'>
							<div className="menu-item-icon">
								<Icon name={item.iconId} />
							</div>

							<span>{this.props.translate(item.text.toLowerCase())}</span>
						</div>
					</div>
				</div>)

				menuItems.push({
					label: elementDiv,
					value: item
				});
			})

		}

		ActionSheet.prompt({
			items: menuItems,
		}).then(item => {
			if (this.inIframe() && item.type === 'EXTERNAL') {
				microsoftTeams.getContext((context) => {
					if (context.hostClientType === "ios" || context.hostClientType === "android") {
						// Use Teams SDK to open the link within Teams mobile app
						microsoftTeams.executeDeepLink(item.url);
					} else {
						// Fallback for web and desktop, open in new tab
						this.props.history.push(item.url);
					}
				});
			} else {
				// Fallback for web and desktop, open in new tab
				this.props.history.push(item.url);
			}
		}, () => {
			// This method only runs if user closes the prompt without pressing an option
			// This method is also optional
			console.log('prompt was closed');
		});
	}

	render() {
		const school = this.props.user.schools.find(school => {
			return school.id === this.props.activeSchool;
		});

		const actionTrigger = (
			<div>
				<Icon name="Bullets" />
			</div>
		);

		return (
			<div className="device-menu">
				<div className="left">
					{this.props.settings.backButton ?
						<NavLink to="#" onClick={this.goBack} className="c--button back">
							<i className="cl-container">
								<svg xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(90deg)' }} className="a-90">
									<path
										id="Path_50"
										data-name="Path 50"
										className="cls-1"
										d="M11.361,1.4,6.38,5.9,1.4,1.4"
									/>
								</svg>
							</i>
						</NavLink>
						: <NavLink to="#" onClick={this.openDeviceMenu} activeClassName="is-active" className="c--button hamburger-menu">
							<div className="menu-item-icon">
								<Icon name="Burger" />
							</div>
						</NavLink>}
				</div>

				<div className="content">
					{this.isIndex() ?
						<div onClick={this.selectSchool}>
							<div style={{ position: 'relative' }}>
								{this.getBadgeForItem() != null ?
									<span className="badge" style={{ justifyContent: 'center' }}>
										{this.getBadgeForItem()}
									</span>
									: null}

								{school.title}
							</div>

							<Icon name="Chevron" />
						</div>
						: this.props.title}
				</div>

				<div className="right">
					{this.props.actions != null && this.props.actions.length > 0 ?
						<TooltipMenu trigger={actionTrigger}>
							{this.props.actions.map(this.renderAction)}
						</TooltipMenu>
						:
						<NavLink to="/search">
							<Icon name="Magnify" />
						</NavLink>
					}
				</div>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		actions: state.Header.actions,
		title: state.Header.title,
		settings: state.Header,
		activeSchool: state.user.activeSchool,
		user: state.user.currentUser,
		items: state.Menu.items,
		userUnread: state.Notifications.userUnread,
	}
}

export default withRouter(connect(mapStateToProps, {
	setActiveSchool,
})(DeviceHeader));
