import React, { Component } from "react";
import { translate } from '@haldor/ui';
import { connect } from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import swal from "sweetalert2";

import DateTime from "_class/DateTime";

import { getPlanAssessments } from "actions/assessments";
import { setPageTitle } from "actions/header";
import { addError } from "actions/index";
import { getBlocksByReference, clearBlocksOnReference } from 'actions/blocks';

import {
	deletePlan,
	getPlanDetails,
	setActivePlan,
	updatePlan,
	getPlanSection,
} from "actions/plans";

import { getReaderParameters } from "actions/user";

import { trackImmersiveReaderError } from "helpers/insights";
import { getRootUrl } from "helpers/url";
import { isUserStudent, isUserTeacher } from "helpers/user";
import { getImmersiveReaderDataFromBlocks } from "helpers/content";

import UserPlanning from "components/List/UserPlanning";
import DateSpan from "components/Presentation/Date/DateSpan";
import SelectedCoreContent from "containers/CoreContent/Selected";
import TeacherAdjustments from "containers/AdditionalAdjustments/Display/TeacherAdjustments";

import Modal from "containers/Modals/Modal";
import SinglePlanForm from "containers/Forms/PlanningForm/SinglePlanForm";

import CloneButton from "containers/SinglePages/Partials/CloneButton";
import Message from "containers/SinglePages/Partials/Message";
import Bookmark from 'containers/SinglePages/Partials/Bookmark';

import BlockConsumer from 'components/BlockEditor/Consumer';
import AssessmentsConsumer from 'components/BlockAssessments/Consumer';

import { Block as ContentBlock, Button, Icon } from "@haldor/ui";
import { Immersive_Reader } from "UI/Icons";
import { Collapsible, Expandable, List, Spinner } from "UI";
import moment from "moment";
import BlockPurpose from "components/Inputs/BlockPurposeField";
import User from "_class/User";

const ImmersiveReader = require("@microsoft/immersive-reader-sdk");

class CompulsoryPlan extends Component {

	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false,
			loading: false,
			loadingSection: false,
			loadingBlocks: true,
			studentConversation: null,
			notFound: false,
			skolonScriptLoaded: false
		};

		this.closeModal.bind(this);
	}

	loadSkolonScript = (url) => {
		const script = document.createElement('script');
		script.src = url;
		script.async = false;
		script.onload = () => {
			this.setState({ skolonScriptLoaded: true });
		};
		script.onerror = () => {
			this.setState({ skolonScriptLoaded: true });
			console.error(`Failed to load ${url}`);
		};
		document.head.appendChild(script);
	};

	componentDidMount = () => {
		this.loadSkolonScript('https://api.skolon.com/v1/plugins/appPicker.js?client_id=1QKopAdjkfxc5cvHrrOcKDMQH4CegEQo');
		if (this.props.match.params.planId > 0) {
			this.reload();
		}
	};

	componentWillUnmount = () => {
		ImmersiveReader.close();
		this.props.clearBlocksOnReference();
	}

	reload = () => {
		if (!this.state.loading) {
			this.setState({ loading: true, loadingSection: true, loadingBlocks: true });

			this.props.getPlanDetails(this.props.match.params.planId, null)
				.then(() => {
					this.setState({ loading: false });

					if (this.props.plan == null || this.props.plan === "") {
						return false;
					}

					this.props.setPageTitle(this.props.plan.title);
					this.props.getPlanSection(this.props.plan.sectionId).then(() => {
						this.setState({ loadingSection: false });
					});
				}).catch(error => {
					if (error.response != null) {
						if (error.response.data != null && error.response.data.errorCode) {
							switch (error.response.data.errorCode) {
								case 'PLANNINGBL-001':
									this.setState({ loading: false, notFound: true });
									break;
							}
						}
					}
				});

			this.props.getBlocksByReference(this.props.match.params.planId, 'PLAN')
				.then(() => {
					this.setState({ loadingBlocks: false });
				})

			this.props.getPlanAssessments(this.props.match.params.planId);
		}
	};

	runImmersiveReader = async () => {
		const { plan, currentUser } = this.props;
		const localLanguage = localStorage.getItem("language");

		await this.props.getReaderParameters();
		const { reader } = this.props;

		if (reader == null) {
			return;
		}

		let data = getImmersiveReaderDataFromBlocks(plan.title, this.props.blocks);

		data.chunks.unshift({
			content: `<h3>
				${this.props.translate('Content')}
			</h3>`,
			mimeType: "text/html",
		})

		ImmersiveReader.launchAsync(reader.token, reader.subdomain, data, {
			uiLang: localLanguage, // Set immersive reader UI language to selected lang in Haldor Edu
		}).catch((error) => {
			console.error("error", error);
			trackImmersiveReaderError(error, currentUser.id);
		});
	};

	readPlanInformation = async () => {
		const { plan, currentUser, translate } = this.props;
		const localLanguage = localStorage.getItem("language");

		await this.props.getReaderParameters();
		const { reader } = this.props;

		if (reader == null) {
			return;
		}

		const start = new DateTime(plan.timeStart).getShortDate();
		const end = new DateTime(plan.timeEnd).getShortDate();

		let status = translate('open');
		if (plan.status == 'PLANNING_CLOSED') {
			status = translate('Closed');
		}

		if (plan.status == 'PLANNING_DRAFT') {
			status = translate('Draft');
		}

		let data = {
			title: plan.title,
			chunks: [
				{
					content: `<h3>
						${translate('Plan information')}
					</h3>`,
					mimeType: "text/html",
				},
				{
					content: `
						${translate('Plan is active between')}:
						${start} - ${end} and the current status is: ${status}
						</br>
					`,
					mimeType: "text/html",
				},
				{
					content: `
						${translate('It belongs to the group named')}:
						${plan.section.title}
						</br>
					`,
					mimeType: "text/html",
				},
			],
		};

		ImmersiveReader.launchAsync(reader.token, reader.subdomain, data, {
			uiLang: localLanguage, // Set immersive reader UI language to selected lang in Haldor Edu
		}).catch((error) => {
			console.error("error", error);
			trackImmersiveReaderError(error, currentUser.id);
		});
	}

	toggleDeletion = () => {
		swal.fire({
			title: this.props.translate("Delete"),
			text: this.props.translate("delete-plan-prompt"),
			showCancelButton: true,
			cancelButtonText: this.props.translate("Cancel"),
			confirmButtonText: this.props.translate("Delete"),
		}).then((response) => {
			if (response.value != null) {
				this.props.deletePlan(this.props.match.params.planId).then(() => {
					if (getRootUrl() != "/") {
						this.props.history.push("/planning-tab");
					} else {
						this.props.history.push("/");
					}
				});
			}
		});
	};

	markStudentForConversation = (student) => {
		this.setState({ studentConversation: student });
	};

	onConversationClose = () => {
		this.setState({ studentConversation: null });
	};

	openStudentConversation = () => {
		const { currentUser, plan } = this.props;

		if (this.props.activeSection != null) {
			let owner = this.props.activeSection.owners.find((owner) => {
				return owner.id == plan.creator;
			});

			const teacher = {
				id: owner.id,
				firstname: owner.firstName,
				lastname: owner.lastName,
				email: owner.email,
				userType: owner.userType,
			};

			if (teacher != null) {
				this.setState({ studentConversation: teacher });
			}
		}
	};

	//#region  Modal Methods
	closeModal = (reRender, skipPrompt) => {
		if (reRender) {
			this.reload();
		}

		this.setState({ modalIsOpen: false });
	};

	openModal = () => {
		this.setState({ modalIsOpen: true });
	};
	//#endregion

	renderPlanStatus = () => {
		if (this.props.plan != null) {
			if (this.props.plan.locked) {
				return (
					<span className="single-dot dot-red">
						{this.props.translate("plan-is-processing")}
					</span>
				);
			}
			var status = this.props.plan.status;
			var assessed = false;
			if (this.props.assessments != null) {
				if (this.props.assessments.length > 0) {
					assessed = true;
				}
			}
			if (assessed == false) {
				if (status === "PLANNING_OPEN") {
					return (
						<span className="single-dot dot-green">
							{this.props.translate("open")}
						</span>
					);
				} else if (status === "PLANNING_CLOSED") {
					return (
						<span className="single-dot dot-red">
							{this.props.translate("Closed")}
						</span>
					);
				} else if (status === "PLANNING_DRAFT") {
					return (
						<span className="single-dot dot-gray">
							{this.props.translate("draft")}
						</span>
					);
				}
			} else {
				return (
					<span className="single-dot dot-green">
						{this.props.translate("assessed")}
					</span>
				);
			}
		}
	};

	renderModals = () => {
		const { translate } = this.props;

		return (
			<div>
				<Modal
					isOpen={this.state.modalIsOpen}
					onClose={this.closeModal}
					title={translate("edit-plan")}
				>
					<SinglePlanForm
						onAbort={this.closeModal}
						editView={true}
						cloneView={false}
					/>
				</Modal>
			</div>
		);
	};

	renderPrintButton = () => {
		let url = `/print/plan/${this.props.match.params.planId}`;
		if (getRootUrl() != "/") {
			url = `/print/planning-tab/${this.props.match.params.planId}`;
		}

		return (
			<div style={{ marginTop: 5 }}>
				<Link style={{ display: 'inline' }} to={url}>
					<Button type="secondary">
						<Icon name="Printer" />
						{this.props.translate("printable-view")}
					</Button>
				</Link>
			</div>
		);
	};

	planContent = () => {
		const { plan } = this.props;

		var user = new User(this.props.currentUser)
		const isStudent = user.isStudent();
		const isTeacher = user.isTeacher();
		let teacherOwnsPlan = false;
		if (this.props.activeSection != null) {
			teacherOwnsPlan = this.props.activeSection.owners?.find((owner) => {
				return owner.userId == this.props.currentUser.userId;
			}) != null;
		}

		let subjects = [];
		let assessmentBlocks = [];
		let assessment = null;

		let conversation = null;
		if (this.props.conversations != null && !isTeacher) {
			conversation = this.props.conversations.find((conv) => {
				return conv.memberships.find((member) => {
					return member.membershipID == this.props.currentUser.id;
				});
			});
		}

		if (isStudent && this.props.assessments.length > 0) {
			assessment = this.props.assessments[0];
		}

		if (this.props.blocks != null) {
			assessmentBlocks = [...this.props.blocks].filter((block) =>
				block.type == 'Haldor.Blocks.AssessmentBlock' && block.resources.length > 0
			);
		}

		let expiredCourses = [];

		if (assessmentBlocks?.length > 0) {
			assessmentBlocks?.forEach((content) => {
				content?.resources?.forEach(resources => {

					switch (resources.referenceType) {
						case 'matrix':
							var matrixId = resources.referenceId;
							var detail = resources.value?.details.find(detail => {
								return detail.matrixID == matrixId;
							})

							if (detail != null && detail.endDate != null && moment(detail.endDate).isBefore(moment())) {
								expiredCourses.push(detail.courseID);
							}

							break;
						default:
							break;
					}

					if (resources?.value != null) {
						if (subjects.indexOf(resources.value?.subjectId) == -1) {
							subjects.push(parseInt(resources.value?.subjectId));
						}
					}
				})
			});
		}

		return (
			<div className="single-plan single-task" key={plan.id}>
				{isStudent && !teacherOwnsPlan ? null : this.renderModals()}

				<div className="single-actions right-side">
					<div className="action-section">
						<h3>{this.props.translate("tools")}</h3>

						{this.props.services.conversations && isStudent ?
							<Button type={conversation == null ? "secondary" : "primary"} onClick={this.openStudentConversation}>
								<Icon name="Message" bw />
								{this.props.translate("conversation")}

								{conversation != null && conversation.unreadMessages > 0 ?
									<div className="badge">
										<span>{conversation.unreadMessages}</span>
									</div>
									: null}
							</Button>
							: null}

						{isStudent || !teacherOwnsPlan || this.state.loadingBlocks ? null : (
							<div>
								<Button type="secondary" style={{ marginTop: 5 }} onClick={this.openModal} disabled={plan.locked}>
									<Icon name="Pen_Small" /> {this.props.translate("Edit")}
								</Button>
							</div>
						)}

						{this.renderPrintButton()}

						{isStudent || !teacherOwnsPlan || plan.isDeletable == false ? null :
							<div>
								<Button type="secondary" style={{ marginTop: 5 }} onClick={this.toggleDeletion}>
									<Icon name="Bin" /> {this.props.translate("Delete")}
								</Button>
							</div>
						}

						{user.isTeacher() ?
							<div style={{ marginTop: 5 }}>
								<CloneButton onClose={this.reload} object={plan} type="planning">
									{this.props.translate('column-copy')}
								</CloneButton>
							</div>
							: null}
					</div>
					<div style={{ marginTop: 5 }}></div>
				</div>

				<div className="single-section form left-side">
					<ContentBlock>
						<Expandable title={this.props.translate('Planning information')} open>
							<div style={{ marginBottom: '.7rem' }}>
								{this.props.services.immersiveReaderPlans ?
									<Link to="#" onClick={this.readPlanInformation}>
										<div className="reader trigger">
											<Immersive_Reader />
											{this.props.translate("immersive-reader")}
										</div>
									</Link>
									: null}

								<div style={{ float: 'right' }}>
									<Bookmark
										referenceType="PLAN"
										referenceId={plan.id}
									/>
								</div>

								<div className="clearfix" />
							</div>

							<div>
								<DateSpan
									start={plan.timeStart}
									end={plan.timeEnd}
								/>

								{this.props.plan.status == 'PLANNING_DRAFT' ?
									<div className="card-meta" style={{ marginLeft: 5 }}>
										{this.props.translate('Draft')}
									</div>
									: null}
							</div>

							<div style={{ marginTop: "1rem" }}>
								<span className="title">
									{this.props.translate("section")}
								</span>

								{plan.section.title}
							</div>

							<div style={{ marginTop: "1rem" }}>
								<span className="title">
									{this.props.translate("Status")}
								</span>

								{this.renderPlanStatus()}
								<div className="clearfix" />
							</div>

							{plan.courses.length > 0 ? (
								<div style={{ marginTop: "1rem" }}>
									<span className="title">
										{this.props.translate("Courses")}
									</span>

									<div className="courses">
										{plan.courses.map((course) => {
											let text = course.title;

											if (course.year != null) {
												text += " " + course.year
											}

											return (
												<div className="course" key={'course' + course.id}>
													{expiredCourses.indexOf(course.id) > -1 ? <div style={{ width: '1.75rem', position: 'relative' }}> <Icon name="Alert_Red" ></Icon> </div> : <span className="indicator" style={{ backgroundColor: course.colorCode }} />}

													{text}
												</div>
											);
										})}
									</div>
								</div>
							) : null}

							{this.props.services.additionalAdjustments && isTeacher && this.props.activeSection && this.props.activeSection?.owners?.filter(ss => ss.id == this.props.currentUser.id).length > 0 ? (
								<div style={{ marginTop: "1rem" }}>
									<Collapsible trigger={this.props.translate("additional-adjustments")}>
										<TeacherAdjustments groupId={plan.sectionId} subjects={subjects} />
									</Collapsible>
								</div>
							) : null}
						</Expandable>
					</ContentBlock>
				</div>

				<div style={{ clear: "both" }} />

				{this.props.blocks != null && !this.state.loadingBlocks ?
					<ContentBlock>
						<Expandable title={this.props.translate("Content")} open>
							{this.props.services.immersiveReaderPlans ?
								<Link to="#" onClick={this.runImmersiveReader}>
									<div className="reader trigger">
										<Immersive_Reader />
										{this.props.translate("immersive-reader")}
									</div>
								</Link>
								: null}

							<BlockConsumer
								fields={this.props.blocks}
							/>
						</Expandable>
					</ContentBlock>
					:
					<ContentBlock>
						<Expandable title={this.props.translate("Content")} open>
							<Spinner center />
						</Expandable>
					</ContentBlock>
				}

				<div
					style={{ marginBottom: "3em" }}
					className="single-section-divider"
				/>

				{this.props.services.conversations ?
					<div>
						<Message
							plan={plan}
							activeUser={this.state.studentConversation}
							onClose={this.onConversationClose}
						/>
					</div>
					: null}

				{isStudent == false && teacherOwnsPlan && this.props.plan.students != null && this.props.plan.students.length > 0 ?
					<List title={this.props.translate("students")}>
						<UserPlanning
							items={this.props.plan.students}
							planId={this.props.match.params.planId}
							reload={this.reload}
							printable={false}
							onConversation={this.markStudentForConversation}
						/>
					</List>
					: null}

				{(isTeacher || this.props.plan.viewablePurpose) && assessmentBlocks.length > 0 ?
					<div className="single-section">
						<h3 style={{ marginBottom: '1rem', fontWeight: 500 }}>
							{this.props.translate('Purpose')}
						</h3>

						<BlockPurpose assessmentBlocks={assessmentBlocks} />
					</div>
					: null}

				{(isTeacher || this.props.plan.viewableCoreContent) && this.props.plan.associatedCoreContent.length > 0 ?
					<div className="single-section">
						<SelectedCoreContent
							associatedCoreContent={this.props.plan.associatedCoreContent}
							assessmentBlocks={assessmentBlocks}
						/>
					</div>
					: null}

				{this.state.loadingBlocks ?
					<div className="single-section">
						<h3 style={{ marginBottom: '1rem', fontWeight: 500 }}>
							{this.props.translate('Assessment')}
						</h3>

						<Spinner center />
					</div>
					: null}

				{assessmentBlocks.length > 0 ?
					<div className="single-section">
						<AssessmentsConsumer
							fields={assessmentBlocks}
							assessment={assessment}
							isStudent={isStudent}
						/>
					</div>
					: null}

				<div style={{ clear: "both" }} />
			</div>
		);
	};

	render() {
		if (this.state.loading || !this.state.skolonScriptLoaded) {
			return (
				<div>
					<Spinner center />
				</div>
			)
		} else if (this.state.notFound) {
			return <div className="single-task center-container"><h3>{this.props.translate('not-found')}</h3></div>
		}

		// Check if plan is null
		if (this.props.plan == null) {
			return <div className="single-task">
				<div className="single-section left-side">
					<ContentBlock>
						<div style={{ padding: '1rem 0' }} className="text--center color--meta weight--bold">
							{this.props.translate('plan-has-been-deleted')}
						</div>
					</ContentBlock>
				</div>
				<div className="single-actions right-side"></div>
			</div>
		}

		if (Object.keys(this.props.plan).length > 0) {
			return <div>
				{this.planContent()}
			</div>
		} else {
			return (
				<div>
					<Spinner center />
				</div>
			);
		}
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		services: state.Services.availableServices,
		plan: state.planning.active_plan,
		currentUser: state.user.currentUser,
		sections: state.sections.educationGroups,
		activeSection: state.planning.section,
		assessments: state.assessments.assessments,
		conversations: state.Conversation.referenceConversations,
		reader: state.user.reader,
		blocks: state.Blocks.reference,
	};
}

export default withRouter(connect(mapStateToProps, {
	getPlanDetails,
	getPlanAssessments,
	addError,
	deletePlan,
	getPlanSection,
	setActivePlan,
	updatePlan,
	setPageTitle,
	getReaderParameters,
	getBlocksByReference,
	clearBlocksOnReference,
})(CompulsoryPlan));