import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Moment from 'moment';

import { PRESCHOOL } from 'constants/schoolTypes';

import { getFileUrl } from 'actions/files';

import {
	clearDocumentsFromStore,
	clearSectionDocumentsFromStore,
	getDocumentsPage,
} from 'actions/documents';

import {
	clearPostsFromStore,
	getPostsBySectionId,
	getPostsPage,
	setPostCollectionInfo
} from 'actions/posts';

import { fetch_preschoolgoals } from 'actions/preschoolform';
import { getMentorGroups } from 'actions/sections';
import { setPageTitle, setPageActions } from 'actions/header';

import { getDescription } from 'helpers/content';

import User from '_class/User';

import LoadingCards from 'components/Cards/LoadingCard';
import Date from 'components/Presentation/Date/Date';
import SectionTitle from 'components/Presentation/SectionTitle';
import Documentation from 'containers/Forms/Documentation';
import Post from 'containers/Forms/Post';
import Modal from 'containers/Modals/Modal';

import Block, { BlockWrapper } from 'UI/Elements/Block';
import { Icon, TooltipMenu, Button, Radio } from '@haldor/ui';
import FromNow from 'components/Presentation/Date/FromNow';

class MyPosts extends Component {

	static defaultProps = {
		limit: 3,
	};

	constructor(props) {
		super(props);

		this.state = {
			documentModal: false,
			eventModal: false,
			sendAllmodal: false,
			loadingDocuments: false,
			loadingPosts: false,
			page: 1,
			startDate: moment().subtract('3', 'months').format('YYYY-MM-DD'),
			endDate: moment().format('YYYY-MM-DD'),
		};
	}

	componentDidMount = async () => {
		const user = new User(this.props.currentUser);
		const isPreschool = user.getActiveSchool().type == PRESCHOOL;

		this.props.clearDocumentsFromStore();
		this.props.clearPostsFromStore();
		this.fetchData(this.state.page);

		if (this.props.preschoolGoals.length < 1 && isPreschool) {
			this.props.fetch_preschoolgoals();
		}
	}

	fetchData = (page, loadState = true) => {
		return new Promise((resolve) => {
			const user = new User(this.props.currentUser);
			const isPreschool = user.getActiveSchool().type == PRESCHOOL;

			const pageSize = this.props.limit === 3 ? 6 : 15;

			// Offset start and end date by 1 day bc <>
			const start = this.props.userRole === "student" ? null : moment(this.state.startDate).subtract('1', 'days').format('YYYY-MM-DD');
			const end = moment(this.state.endDate).add('1', 'days').format('YYYY-MM-DD');

			let promises = [];

			if (loadState) {
				this.setState({ loadingPosts: true });
			}

			const getPostsPage = this.props.getPostsPage(page, 'published', start, end, pageSize);
			promises.push(getPostsPage);

			if (isPreschool) {
				if (loadState) {
					this.setState({ loadingDocuments: true });
				}

				const getDocumentsPage = this.props.getDocumentsPage(page, 'DOCUMENTATION_PUBLISHED', start, end, pageSize);
				promises.push(getDocumentsPage);
			}

			Promise.all(promises).then(() => {
				this.setState({ loadingDocuments: false, loadingPosts: false });
				resolve(1);
			});
		})
	}

	toggleDocumentModal = () => {
		this.setState({ documentModal: !this.state.documentModal });
	}

	toggleEventModal = () => {
		this.setState({ eventModal: !this.state.eventModal });
	}

	toggleSendAllModal = () => {
		this.setState({ sendAllModal: !this.state.sendAllModal });
	}

	renderContent = () => {
		const { combined } = this.props;

		let i = 0;
		let renderedItems = 0;
		if (combined.length > 0) {
			var returnDOM =
				<BlockWrapper cols={3}>
					{combined ? combined.map((document) => {
						if (i < this.props.limit) {
							const result = this.renderDocument(document)

							if (result != null) {
								i++;
								renderedItems++;
							}
							return result;
						} else {
							return null
						}
					}) : null}
				</BlockWrapper>;

			if (renderedItems > 0) {
				return returnDOM;
			}
		}

		return (
			<div className="color--meta text--center weight--bold" style={{ textAlign: 'center' }}>
				{this.props.translate('no-items-found')}
			</div>
		);
	}

	headerContent = (document) => {
		return (
			<div>
				<div style={{ float: 'left' }}>
					{document.author != null ?
						typeof document.author == 'string' ?
							document.author
							:
							`${document.author.firstName != null ? document.author.firstName : ''} ${document.author.lastName != null ? document.author.lastName : ''}`
						: ' '}
				</div>

				<div style={{ float: 'right' }}>
					{document.published != null ?
						moment.utc(document.published).isAfter(moment()) ?
							<FromNow>{moment.utc(document.published).local().format()}</FromNow>
							:
							<Date>{moment.utc(document.published).local()}</Date>
						: null}
				</div>

				{document.important && document.eventDate != null ?
					<div style={{ float: 'left' }}>
					</div>
					: null}

				{document.status == 'POST_DRAFT' || document.status == 'DOCUMENTATION_DRAFT' ?
					<div style={{ float: 'right', marginRight: 8 }}>
						<span style={{ color: 'red', fontSize: '0.9em' }}>
							{this.props.translate('Draft')}
						</span>
					</div>
					: null}

				{document.important && document.eventDate != null && moment() <= moment(document.eventDate) ?
					<div style={{ float: 'right' }}>
						<span style={{ color: 'red', fontSize: '0.9em', marginRight: '8px' }}>
							{this.props.translate('important!')}
						</span>
					</div>
					: null}

				<div className="clearfix" />
			</div>
		);
	}

	content = (document) => {
		if (document.ReferenceType == 'post') {
			let strippedDescription = "";

			if (document.abbreviatedDescription != null) {
				strippedDescription = getDescription(document.abbreviatedDescription).replace(/(<([^>]+)>)/ig, '');
				if (strippedDescription.length > 99) {
					strippedDescription = strippedDescription.slice(0, 100) + '…';
				}
			}

			return (
				<div>
					<h3>{document.title}</h3>

					<p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
						dangerouslySetInnerHTML={{ __html: strippedDescription }}
					/>
				</div>
			);
		} else {
			if (document.description) {
				let strippedDescription = getDescription(document.description).replace(/(<([^>]+)>)/ig, '');
				if (strippedDescription.length > 99) {
					strippedDescription = strippedDescription.slice(0, 100) + '…';
				}

				return (
					<div>
						<h3>{document.title}</h3>

						<p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
							dangerouslySetInnerHTML={{ __html: strippedDescription }}
						/>
					</div>
				);
			}

			return (
				<div>
					<h3>{document.title}</h3>
				</div>
			);
		}
	}

	footerContent = (document) => {
		const { preschoolGoals } = this.props;

		if (document.associatedGoals && document.associatedGoals.length > 0) {
			let groups = [];

			if (preschoolGoals && preschoolGoals.length > 0) {
				preschoolGoals.map(goal => {
					const inDocument = document.associatedGoals.find(function (goalOb) {
						return goalOb.goalId == goal.id;
					});

					if (typeof (inDocument) != 'undefined') {
						let inArray = groups.findIndex(i => i.name == goal.group);

						if (inArray > -1) {
							return groups[inArray].count++;
						} else {
							let group = {
								'name': goal.group,
								'color': goal.colorCode,
								'count': 1,
							};

							return groups.push(group);
						}
					}
				});
			}

			return (
				<div>
					<div>{this.props.translate('goals')}</div>

					{groups.length > 0 ?
						<div className="goal-preview-container">
							{groups.map(group => {
								return (
									<div key={"goal-" + group.name} className="goal-preview"
										style={{ backgroundColor: group.color }}>
										{group.name} ({group.count})
									</div>
								)
							})}
						</div>
						: null}
				</div>
			);
		} else {
			return null;
		}
	}

	renderDocument = (document) => {
		let link = '';
		const { limit } = this.props;

		if (document.ReferenceType == 'post') {
			link = 'post/' + document.id;
		} else {
			link = 'preschooldocument/' + document.id;
		}

		// Do not show important posts if this is for dashboard.
		// Assuming it is for dashboard if there is a limit (also since that is what decides if Importat is to be shown)
		if (limit &&
			document.important &&
			document.ReferenceType == "post" &&
			moment().isSameOrAfter(moment(document.published), 'day') &&
			moment(document.eventDate).isSameOrAfter(moment(), 'day') &&
			moment().isSameOrAfter(moment(document.eventDate).subtract(7, "days"), 'day')) {
			return null;
		}

		let expireDate = moment(document.validUntil).hour(23).minute(59);
		if (this.props.limit != false && document.validUntil != null && expireDate.diff(moment()) < 0) {
			return null;
		}

		let file = null;
		if (document.associatedFiles != null && document.associatedFiles.length > 0) {
			file = document.associatedFiles.find(file => {
				return file.type && file.type.indexOf('image') > -1;
			})
		}

		return (
			<Block
				key={document.id}
				headerContent={this.headerContent(document)}
				image={file}
				content={this.content(document)}
				footerContent={this.footerContent(document)}
				link={link}
			/>
		);
	}

	renderCreateButton = () => {
		let isPreschool = false;

		if (this.props.currentUser.schools.getActiveSchool(this.props.currentUser.userId).type == PRESCHOOL) {
			isPreschool = true;
		}

		const user = new User(this.props.currentUser);

		if ((user.isTeacher() || user.isMentor() || user.isAdministrator() || user.isSchoolLeader()) && isPreschool) {
			return (
				<div className="create-container" style={{ display: 'inline-block' }}>
					<TooltipMenu trigger={(
						<Button type="secondary">
							<Icon name="Plus" /> {this.props.translate('Create')}
						</Button>
					)}>
						<TooltipMenu.Item onClick={this.toggleDocumentModal}>
							{this.props.translate('Create documentation')}
						</TooltipMenu.Item>

						<TooltipMenu.Item onClick={this.toggleEventModal}>
							{this.props.translate('Create post')}
						</TooltipMenu.Item>

						{user.isOperationManager() ?
							<TooltipMenu.Item onClick={this.toggleSendAllModal}>
								{this.props.translate('Create schoolwide announcement')}
							</TooltipMenu.Item>
							: null}
					</TooltipMenu>
				</div>
			);
		} else {
			if (user.isOperationManager()) {
				return (
					<div className="create-container" style={{ display: 'inline-block' }}>
						<TooltipMenu trigger={(
							<Button type="secondary">
								<Icon name="Plus" /> {this.props.translate('Create')}
							</Button>
						)}>
							<TooltipMenu.Item onClick={this.toggleEventModal}>
								{this.props.translate('Create post')}
							</TooltipMenu.Item>

							<TooltipMenu.Item onClick={this.toggleSendAllModal}>
								{this.props.translate('Create schoolwide announcement')}
							</TooltipMenu.Item>
						</TooltipMenu>
					</div>
				);
			}

			if (user.isTeacher() || user.isMentor() || user.isAdministrator() || user.isSchoolLeader()) {
				return (
					<Button type="secondary" onClick={this.toggleEventModal}>
						<Icon name="Plus" /> {this.props.translate('Create')}
					</Button>
				);
			}

			return null;
		}
	}

	render() {
		const { loadingDocuments, loadingPosts } = this.state;
		let isPreschool = false;

		const user = new User(this.props.currentUser);

		if (user.getActiveSchool().type == PRESCHOOL) {
			isPreschool = true;
		}

		let sections = [];
		if (user.isMentor() || user.isAdministrator() || user.isSchoolLeader() || user.isOperationManager()) {
			sections = this.props.groups.concat(this.props.mentorGroups);
		} else {
			sections = this.props.groups;
		}

		if (!(user.isStudent() || user.isSchoolLeader() || user.isAdministrator() || user.isOperationManager())) {
			sections = sections.filter(section => {
				return section.userRole === "OWNER";
			});
		}

		var importantPosts = [];
		this.props.combined.forEach((post) => {
			let SevenDaysAgo = moment(post.eventDate).subtract(7, "days");
			if (post.important && post.ReferenceType == "post" && moment().isSameOrAfter(moment(post.published), 'day') && moment(post.eventDate).isSameOrAfter(moment(), 'day') && moment().isSameOrAfter(SevenDaysAgo, 'day')) {
				importantPosts.push(post);
			}
		});

		importantPosts.sort((a, b) => {
			return moment(a.eventDate).unix() - moment(b.eventDate).unix();
		});

		return (
			<div className="preschooldocuments">
				{this.props.combined.find((post) =>
					post.important &&
					post.ReferenceType == "post" &&
					moment().isSameOrAfter(moment(post.published), 'day') &&
					moment(post.eventDate).isSameOrAfter(moment(), 'day') &&
					moment().isSameOrAfter(moment(post.eventDate).subtract(7, "days"), 'day')
				) != null ?
					<div style={{ borderBottom: '1px solid #DFE1E2', marginBottom: '3.2em', paddingBottom: '1.9em' }}>
						<SectionTitle>
							{this.props.overview ?
								<Icon name="Post" />
								: null}

							<span>
								{this.props.translate('important-posts')}
							</span>
						</SectionTitle>

						{importantPosts.map((post) => {
							return (
								<Link to={'/post/' + post.id} style={{ textDecoration: 'none', color: 'inherit' }} key={post.id}>
									<div className="block-container" style={{ display: 'inline-block', margin: '5px' }}>
										<div className="block-content" style={{ padding: '0.8rem' }}>
											<h3>{post.title}</h3>
										</div>
									</div>
								</Link>
							)
						})}
					</div>
					: null}

				{!user.isStudent() && isPreschool ?
					<Modal
						isOpen={this.state.documentModal}
						onClose={this.toggleDocumentModal}
						title={this.props.translate('create-new-documentation')}>
						<Documentation onModalClose={this.toggleDocumentModal} />
					</Modal>
					: null}

				{!user.isStudent() ?
					<Modal
						isOpen={this.state.eventModal}
						onClose={this.toggleEventModal}
						title={this.props.translate('create-new-post')}>
						<Post onModalClose={this.toggleEventModal} />
					</Modal>
					: null}

				{!user.isStudent() ?
					<Modal
						isOpen={this.state.sendAllModal}
						onClose={this.toggleSendAllModal}
						title={this.props.translate('Create schoolwide announcement')}>
						<Post selectMultipleSchools onModalClose={this.toggleEventModal} />
					</Modal>
					: null}

				<div className="section" style={{ margin: 0, padding: 0, border: 0 }}>
					<SectionTitle>
						<Icon name="Post" />

						<span>
							{this.props.translate('preschooldocument-posts')}
						</span>

						{this.renderCreateButton()}

						<Link to="posts" className="see-more">
							{this.props.translate('show-all')}
						</Link>
					</SectionTitle>

					<div className="clearfix" />
				</div>

				<div className="posts-section-container">
					{loadingDocuments || loadingPosts ?
						<LoadingCards count={3} />
						:
						this.renderContent()
					}

					<div style={{ clear: 'both' }} />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user.user,
		currentUser: state.user.currentUser,
		translate: translate(state.Languages.translations),
		documentations: state.CombinedEvents.documentations,
		posts: state.CombinedEvents.posts,
		collectionInformation: state.Posts.collectionInformation,
		preschoolGoals: state.PreschoolGoals.preschoolGoals,
		groups: state.sections.educationGroups,
		combined: state.CombinedEvents.combined,
		groupCombined: state.CombinedEvents.sectionCombined,
		mentorGroups: state.sections.mentorGroups
	}
}

export default connect(mapStateToProps, {
	getDocumentsPage,
	getPostsPage,
	getFileUrl,
	fetch_preschoolgoals,
	getPostsBySectionId,
	clearSectionDocumentsFromStore,
	clearDocumentsFromStore,
	clearPostsFromStore,
	setPageTitle,
	setPageActions,
	getMentorGroups,
	setPostCollectionInfo
})(MyPosts);