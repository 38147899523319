import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getUserUnreadNotifications } from 'actions/notifications';
import moment from 'moment';

const NotificationProvider = () => {
	const dispatch = useDispatch();

	let isFocused = false;
	const onFocusFunction = () => {
		// do whatever when focus is gained
		isFocused = true;
	};

	const onBlurFunction = () => {
		// do whatever when focus is lost
		isFocused = false;
	};

	useEffect(() => {
		function fetchCount() {
			if (isFocused) {
				console.log('Fetch Count', moment().format('hh:mm:ss'))
				dispatch(getUserUnreadNotifications());
			}
		};

		onFocusFunction();

		// Commented out due to issues in planning form when reinitialized
		// const interval = setInterval(() => fetchCount(), 45 * 1000);
		document.addEventListener('route_change', fetchCount);
		fetchCount();

		window.addEventListener("focus", onFocusFunction);
		window.addEventListener("blur", onBlurFunction);

		return () => {
			// Clean up events
			clearInterval(interval);
			document.removeEventListener('route_change', fetchCount);
			window.removeEventListener("focus", onFocusFunction);
			window.removeEventListener("blur", onBlurFunction);
		};
	}, []);

	return null;
};

export default NotificationProvider;
