import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';

import DateTime from '_class/DateTime';
import User from '_class/User';

import {
	updateStudentAdditionalAdjustmentComment,
	deleteStudentAdditionalAdjustmentComment,
} from 'actions/additional_adjustments';

import { Flex, Button, Icon, translate } from '@haldor/ui';
import { Spinner, Collapsible } from 'UI';

class Comments extends Component {

	static defaultProps = {
		hideDeleted: false,
	};

	constructor(props) {
		super(props);

		this.state = {
		};
	}

	required = (value) => {
		if (typeof (value) !== 'undefined' && value !== '') {
			// Field passes validation, return a undefined error for this field
			// Rule: Not undefined and not an empty string
			return undefined;
		}

		// Field failed validation, return error for this field (String)
		return this.props.translate('field-needs-input');
	}

	/* Event methods - JEVEN */
	submit = (values) => {
		return new Promise((resolve, reject) => {
			values.origin = 'USER';

			if (this.props.onSubmit != null) {
				this.props.onSubmit(values).then(() => {
					this.props.reset();
					resolve(1);
				});
			} else {
				resolve(1);
			}
		});
	}

	restoreDeletedComment = (comment) => {
		let values = { ...comment };
		values.status = '';

		this.props.updateStudentAdditionalAdjustmentComment(values);
	}

	deleteComment = (comment) => {
		this.props.deleteStudentAdditionalAdjustmentComment(comment);
	}

	onKeyDown = (e) => {
		if (e.keyCode == 13) {
			e.preventDefault();

			this.props.submit();
		}
	}

	/* Render methods - JREND */
	renderUpdate = (comment, index) => {
		const user = new User(this.props.user);
		const deleteAllowed = user.isTeacher() || user.isMentor() || user.isPedagogue();

		return <div className="comment" key={comment.id + '-' + index}>
			<div className="createdBy color--meta">
				{comment.createdBy.firstName} {comment.createdBy.lastName}

				{' • ' + comment.text}

				{' • ' + new DateTime(comment.created).getShortDate(true)}

				{(comment.creator == this.props.user.id && this.props.onSubmit != null) || deleteAllowed ?
					<span className="delete--trigger" onClick={() => { this.deleteComment(comment) }}>
						<Icon name="Bin" />
					</span>
					: null}

				{(comment.creator == this.props.user.id && this.props.onSubmit != null && comment.status == "DELETED") || (comment.status == "DELETED" && user.isPedagogue()) ?
					<span className="delete--trigger" onClick={() => { this.restoreDeletedComment(comment) }}>
						<Icon name="Undo" />
					</span>
					: null}
			</div>
		</div>
	}

	renderComment = (comment, index) => {
		const user = new User(this.props.user);
		const deleteAllowed = user.isTeacher() || user.isMentor() || user.isPedagogue();

		return <div className="comment" key={comment.id + '-' + index}>
			{comment.text}

			<div className="createdBy color--meta">
				{comment.createdBy.firstName} {comment.createdBy.lastName}

				{' • ' + new DateTime(comment.created).getShortDate(true)}

				{(comment.creator == this.props.user.id && this.props.onSubmit != null) || deleteAllowed ?
					<span className="delete--trigger" onClick={() => { this.deleteComment(comment) }}>
						<Icon name="Bin" />
					</span>
					: null}

				{(comment.creator == this.props.user.id && this.props.onSubmit != null && comment.status == "DELETED") || (comment.status == "DELETED" && user.isPedagogue()) ?
					<span className="delete--trigger" onClick={() => { this.restoreDeletedComment(comment) }}>
						<Icon name="Undo" />
					</span>
					: null}
			</div>
		</div>
	}

	render() {
		const { adjustment, handleSubmit, submitting } = this.props;
		const user = new User(this.props.user);

		let comments = [];
		let allComments = [];
		let deleted = [];

		if (adjustment.comments.length > 0) {
			comments = [...adjustment.comments];

			comments.sort((a, b) => {
				return b.id > a.id ? 1 : -1;
			});

			if (user.isPedagogue()) {
				deleted = [...comments].filter(comment => comment.status == 'DELETED');
			}

			comments = [...comments].filter(comment => comment.status != 'DELETED');
		}

		return (
			<div className="comment-form">
				<Collapsible trigger={this.props.translate('Comments')} open>
					<div style={{ paddingLeft: '1.65rem' }}>
						{this.props.onSubmit != null ?
							<Form onSubmit={handleSubmit(this.submit)} className="form form-component">
								<Flex center>
									<div className="input-container">
										<Field
											component="textarea"
											type="text"
											name="text"
											placeholder={this.props.translate('Write comment')}
											rows={1}
											onKeyDown={this.onKeyDown}
											disabled={this.props.disabled}
											validate={this.required}
										/>
									</div>

									<Button disabled={!this.props.valid}>
										{this.props.translate('save')}

										{submitting ?
											<Spinner />
											: null}
									</Button>
								</Flex>
							</Form>
							: null}

						<div className="comments">
							{comments.map((comment, index) => {
								if (comment.origin == 'USER') {
									return this.renderComment(comment, index);
								}
							})}

							{deleted != null && deleted.length > 0 && !this.props.hideDeleted ?
								<Collapsible trigger={this.props.translate('Deleted comments')}>
									<div style={{ paddingLeft: '1.65rem' }}>
										{deleted.map((comment, index) => {
											if (comment.origin == 'USER') {
												return this.renderComment(comment, index);
											}
										})}
									</div>
								</Collapsible>
								: null}
						</div>
					</div>
				</Collapsible>

				<Collapsible trigger={this.props.translate('History')}>
					<div style={{ paddingLeft: '1.65rem' }} className="comments">
						{comments.map((comment, index) => {
							if (comment.origin == 'SYSTEM') {
								return this.renderUpdate(comment, index);
							}
						})}

						{deleted != null && deleted.length > 0 && !this.props.hideDeleted ?
							<Collapsible trigger={this.props.translate('Deleted history')}>
								<div style={{ paddingLeft: '1.65rem' }}>
									{deleted.map((comment, index) => {
										if (comment.origin == 'SYSTEM') {
											return this.renderUpdate(comment, index);
										}
									})}
								</div>
							</Collapsible>
							: null}
					</div>

				</Collapsible>

			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		user: state.user.currentUser,
	};
}

export default connect(mapStateToProps, {
	deleteStudentAdditionalAdjustmentComment,
	updateStudentAdditionalAdjustmentComment,
})(reduxForm({
	destroyOnUnmount: true,
})(Comments));