import React from 'react';
import DateTime from '_class/DateTime';
import { getRootUrl } from 'helpers/url';
import { Link } from 'react-router-dom';
import { Icon } from '@haldor/ui';
import { useTranslate } from 'lib/translate';

const DisplayCell = ({ cell, color, assessmentDetails, isSelected }) => {
	const translate = useTranslate();

	/**
	 * @param {object} detail
	 */
	const getDetailLink = (detail) => {
		if (detail.assessment.referenceType == 'Plan') {
			return getRootUrl() + `plan/${detail.navigationObject.referenceId}`;
		}

		if (detail.assessment.referenceType == 'AssignmentTask') {
			return getRootUrl() + `assignment/${detail.navigationObject.referenceId}`;
		}
	}

	return (
		<div className="cell-container">
			<div className={isSelected ? "cell selected" : "cell"} style={{ '--level-color': color }}>
				{cell.text != null ?
					cell.text
					: cell.value}
			</div>

			{assessmentDetails.map((detail) => {
				return (
					<Link to={getDetailLink(detail)} key={detail.id} className="assessment-detail">
						<div className="cell selected" style={{ '--level-color': color }}>
							{detail.assessment.referenceType == 'Plan' ?
								translate('Plan')
								: translate('Assignment')}

							<div className="size-12">
								{detail.navigationObject.title}
							</div>

							<div className="size-12" style={{ marginTop: '.25rem' }}>
								{detail.editorUser != null ?
									detail.editorUser.firstName + ' ' + detail.editorUser.lastName
									: null}

								{' • ' + new DateTime(detail.assessment.published).getLongDate()}
							</div>

							<Icon name="Check" />
						</div>
					</Link>
				);
			})}
		</div>
	);

}

DisplayCell.defaultProps = {
	isSelected: false,
	color: '#ccc',
	assessmentDetails: [],
};

export default DisplayCell;