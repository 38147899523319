import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from '@haldor/ui';

import ReactQuill, { Quill } from 'react-quill-new';
import swal from 'sweetalert2';

//import imageCompression from 'browser-image-compression';

import QuillResizeImage from 'quill-resize-image';
Quill.register("modules/resize", QuillResizeImage);

var BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
	'alt',
	'height',
	'width',
	'style'
];

class ImageFormat extends BaseImageFormat {
	static formats(domNode) {
		return ImageFormatAttributesList.reduce(function (formats, attribute) {
			if (domNode.hasAttribute(attribute)) {
				formats[attribute] = domNode.getAttribute(attribute);
			}
			return formats;
		}, {});
	}
	format(name, value) {
		if (ImageFormatAttributesList.indexOf(name) > -1) {
			if (value) {
				this.domNode.setAttribute(name, value);
			} else {
				this.domNode.removeAttribute(name);
			}
		} else {
			super.format(name, value);
		}
	}
}

Quill.register(ImageFormat, true);

class Editor extends Component {

	constructor(props) {
		super(props);
		let placeHolderText = '';
		if (this.props.placeholder) {
			placeHolderText = this.props.placeholder;
		}

		this.state = {
			placeHolderText,
		};

		this.modules = {
			resize: {
				locale: {},
			},
			toolbar: {
				container: [
					[{ header: [2, 3, false] }],
					['bold', 'italic', 'underline', 'strike', 'blockquote'],
					[
						{ 'color': ['#000000', '#ffffff', '#ffbe40', '#ff3149', '#86b100'] },
						{ 'background': ['#000000', '#ffffff', '#ffbe40', '#ff3149', '#86b100'] },
					],
					[{ list: 'ordered' }, { list: 'bullet' }],
					['link', 'image', 'video', 'clean'],
					['formula'],
				],
				handlers: {
					image: this.imageHandler,
					link: this.urlHandler,
				},
			},
		};

		this.quill = null;
	}

	_setQuill = (element) => this.quill = element;

	disableEvent = (e) => {
		e.preventDefault();
	}

	componentDidMount = () => {
		if (this.props.disabled) {
			const quill = this.quill.getEditor();
			quill.disable();
		}

		const element = document.querySelector('.ql-toolbar');
		if (element) {
			element.addEventListener('mousedown', this.disableEvent);
		}
	}

	componentWillUnmount = () => {
		const element = document.querySelector('.ql-toolbar');
		if (element) {
			element.removeEventListener('mousedown', this.disableEvent);
		}

	}

	render() {
		const { meta } = this.props;

		const formats = [
			'header',
			'bold', 'italic', 'underline', 'strike', 'blockquote', 'style',
			'list', 'bullet', 'color', 'background', /* 'indent', */
			'link', 'image', 'video',
			'formula',
		];

		return (
			<div>
				{this.props.label ?
					<label>{this.props.label}</label>
					: null}

				<ReactQuill
					modules={this.modules}
					formats={formats}
					placeholder={this.state.placeHolderText}
					ref={this._setQuill}
					style={meta?.touched && meta?.error ? {
						border: '1px solid red'
					} : {}}
					onChange={(value, delta, source, editor) => {
						let newValue = editor.getHTML();

						const matches = newValue.match(/&lt;iframe(.*?)\&lt;\/iframe/g);

						if (matches != null) {
							matches.map(match => {
								let result = match;
								result = result.replace('<p>', ' ');
								result = result.replace('</p>', '');
								result = result.replace('&nbsp;', ' ');
								newValue = newValue.replace(match, result);
							});
						}

						if (this.props.input != null) {
							this.props.input.onChange(newValue);
						}

						if (this.props.onChange != null) {
							this.props.onChange(newValue);
						}
					}}
					value={this.props.input ? this.props.input.value : this.props.value}
					onBlur={(range, source, quill) => {
						if (this.props.input) {
							this.props.input.onBlur(quill.getHTML());
						}

						if (this.props.onBlur != null) {
							this.props.onBlur(quill.getHTML());
						}
					}}
				/>

				{meta != null ?
					<div>
						{meta.touched && ((meta.error &&
							<span style={{ marginTop: 0 }} className="error">{meta.error}</span>) || (meta.warning &&
								<span style={{ marginTop: 0 }} className="error">{meta.warning}</span>))}
					</div>
					: null}
			</div>
		);
	}

	imageHandler = () => {
		const quill = this.quill.getEditor();
		const range = quill.getSelection();

		swal.fire({
			title: this.props.translate('embed-image'),
			text: this.props.translate('enter-url'),
			input: 'text',
			inputPlaceHolder: this.props.translate('enter-url'),
			confirmButtonText: this.props.translate('embed'),
			focusConfirm: false,
		})
			.then(result => {
				if (result.value != null || result.value != '') {
					quill.insertEmbed(range.index, 'image', result.value);
				}
			});
	}

	videoHandler = () => {
		const quill = this.quill.getEditor();
		const range = quill.getSelection();

		// We need to figure out how to generate an embed link for all types of videos
		// Its easy to do with youtube but unsure about vimeo etc.
	}

	urlHandler = (promptRange) => {
		const quill = this.quill.getEditor();

		if (promptRange) {
			swal.fire({
				title: this.props.translate('embed-content'),
				text: this.props.translate('enter-url'),
				input: 'text',
				confirmButtonText: this.props.translate('embed'),
				focusConfirm: false,
			})
				.then(result => {
					if (result.value != null && result.value != '') {
						let url = result.value;

						// Add https if the url is missing a protocol
						if (url.indexOf('mailto:') == -1 &&
							url.indexOf('tel:') == -1 &&
							url.indexOf('ftp:') == -1) {
							if (!url.match(/^[a-zA-Z]+:\/\//)) {
								url = 'https://' + url;
							}

							quill.format('link', url);
						} else {
							quill.format('link', false);
						}
					}
				});
		}
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	}
}

export default connect(mapStateToProps)(Editor);