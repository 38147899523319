import React from 'react';

const Progress = ({ percentage, label }) => {
	return (
		<div className="graph progress">
			<div
				style={{
					width: percentage + '%',
				}}
				className="progress-bar"
			>{label}</div>
		</div>
	);
}

Progress.defaultProps = {
	percentage: 0,
	label: null,
};

export default Progress;
