import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import sniffer from 'sniffer';
import MenuPage from "../Menu/Page";
import { Icon } from '@haldor/ui';
import { isMicrosoftTeams } from 'helpers/teams';

class FooterMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMenuOpen: false
		};
		this.toggleMenu = this.toggleMenu.bind(this);
	}

	toggleMenu() {
		this.setState(prevState => ({
			isMenuOpen: !prevState.isMenuOpen
		}));
	}

	/* Render methods */
	render() {
		if (isMicrosoftTeams() || sniffer.isDevice || sniffer.isTablet) {
			return null;
		}

		const { isMenuOpen } = this.state;

		return (
			<div>
				<div className="footer-menu-bar-container">
					<div className="menu-item-container">
						<NavLink onClick={isMenuOpen ? this.toggleMenu : null} to="/" activeClassName="is-active" className="menu-item">
							<div className="menu-item-icon">
								<Icon name="House" />
							</div>

							<span>{this.props.translate('nav_home')}</span>
						</NavLink>
					</div>

					<div className="menu-item-container">
						<NavLink onClick={isMenuOpen ? this.toggleMenu : null} to="/schedule" activeClassName="is-active" className="menu-item">
							<div className="menu-item-icon">
								<Icon name="Calendar" />
							</div>

							<span>{this.props.translate('nav_schedule')}</span>
						</NavLink>
					</div>

					<div className="menu-item-container">
						<NavLink onClick={isMenuOpen ? this.toggleMenu : null} to="/plans" activeClassName="is-active" className="menu-item">
							<div className="menu-item-icon">
								<Icon name="Plans" />
							</div>

							<span>{this.props.translate('nav_plans')}</span>
						</NavLink>
					</div>

					<div className="menu-item-container">
						<NavLink to="#" onClick={this.toggleMenu}
							activeClassName="is-active" className="menu-item">
							<div className="menu-item-icon">
								<Icon name="Burger" />
							</div>
							<span>{this.props.translate('more')}</span>
						</NavLink>
					</div>
				</div>
				{isMenuOpen && <MenuPage onClose={this.toggleMenu} />}
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		user: state.user.currentUser,
		//pathname: document.location.pathname,
	};
}

export default connect(mapStateToProps, null, null, {
	pure: false,
})(FooterMenu);
