import api from 'lib/api';

export const SUBMIT_PRESCHOOL_DOCUMENT = 'SUBMIT_PRESCHOOL_DOCUMENT';
export const UPDATE_PRESCHOOL_DOCUMENT = 'UPDATE_PRESCHOOL_DOCUMENT';
export const SET_ACTIVE_PRESCHOOL_DOCUMENT = 'SET_ACTIVE_PRESCHOOL_DOCUMENT';
export const SET_ACTIVE_PRESCHOOL_DETAILS = 'SET_ACTIVE_PRESCHOOL_DETAILS';
export const GET_DOCUMENTS_PAGE = 'GET_DOCUMENTS_BY_PAGE';
export const GET_SECTION_DOCUMENTS = 'GET_SECTION_DOCUMENTS';
export const GET_PLAN_DOCUMENTS = 'GET_PLAN_DOCUMENTS';
export const CLEAR_DOCUMENTS_FROM_STORE = 'CLEAR_DOCUMENTS_FROM_STORE';
export const CLEAR_SECTION_DOCUMENTS_FROM_STORE = 'CLEAR_SECTION_DOCUMENTS_FROM_STORE';
export const DELETE_DOCUMENT_FROM_STORE = 'DELETE_DOCUMENT_FROM_STORE';
export const DELETE_DOCUMENT = 'DOCUMENT_DELETE_DOCUMENT';
export const GET_DOCUMENT_READ_LIST = "GET_DOCUMENT_READ_LIST";
export const CLEAR_DOCUMENT_READ_LIST = "CLEAR_DOCUMENT_READ_LIST";
export const DELETE_DOCUMENTATION_FILE = 'DOCUMENTATION_DELETE_FILE';

export function submit_preschooldocument(values, callback) {
	return {
		type: SUBMIT_PRESCHOOL_DOCUMENT,
		payload: new Promise(resolve => {
			api.post('documentations', values).then((response) => {
				resolve({ ...values, id: response.data });

				if (callback != null) {
					callback(response.data);
				}
			});
		}),
	}
}

export function update_preschooldocument(values, callback) {
	return {
		type: UPDATE_PRESCHOOL_DOCUMENT,
		payload: new Promise(resolve => {
			api.put('documentations', values).then((response) => {
				resolve(values);

				if (callback != null) {
					callback(response.data);
				}
			});
		}),
	}
}

export function setActivePreschoolDocument(id) {
	return [
		{
			type: SET_ACTIVE_PRESCHOOL_DOCUMENT,
			id: id,
		},
		{
			type: SET_ACTIVE_PRESCHOOL_DETAILS,
			payload: new Promise(resolve => {
				if (id != null) {
					api.get(`documentations/${id}`).then((response) => {
						resolve(response.data);
					});
				}
			})
		},
	]
}

export function getPreschoolDetails(id) {
	return {
		type: SET_ACTIVE_PRESCHOOL_DETAILS,
		payload: new Promise((resolve, reject) => {
			if (typeof (id) !== 'undefined' && id !== null) {
				api.get(`documentations/${id}`).then((response) => {
					if (response.status > 201) {
						reject(response);
						return true;
					}

					resolve(response.data);
				});
			} else {
				reject();
			}
		})
	};
}

export function deleteDocumentFromStore(id) {
	return {
		type: DELETE_DOCUMENT_FROM_STORE,
		payload: id
	}
}

export function getDocumentsBySectionId(id, page, status = null, startDate = null, endDate = null, pageSize = 15) {
	return {
		type: GET_SECTION_DOCUMENTS,
		payload: new Promise(resolve => {
			let url = `documentations?pageIndex=${page}&pageSize=${pageSize}&sectionId=${id}`;
			if (status != null) {
				url += `&filter=${status}`;
			}
			if (startDate != null) {
				url += `&startDate=${startDate}`;
			}
			if (endDate != null) {
				url += `&endDate=${endDate}`;
			}
			api.get(url).then((response) => {
				if (response.data.length > 0) {
					resolve(response.data);
				} else {
					resolve([]);
				}
			});
		}),
	}
}

export function clearSectionDocumentsFromStore() {
	return {
		type: CLEAR_SECTION_DOCUMENTS_FROM_STORE,
		payload: true,
	}
}

export function getDocumentsByPlanId(id) {
	return {
		type: GET_PLAN_DOCUMENTS,
		payload: new Promise(resolve => {
			api.get(`documentations?planId=${id}&filter=PLAN`).then((response) => {
				
				resolve(response.data);
			});
		}),
	}
}

export const getDocumentReadList = (documentationId) => ({
	type: GET_DOCUMENT_READ_LIST,
	payload: new Promise(resolve => {
		api.get(`documentations/${documentationId}/reads`).then(response => {
			resolve(response.data);
		})
	}),
})

export const clearDocumentReadList = () => ({
	type: CLEAR_DOCUMENT_READ_LIST,
	payload: true,
})

export function getDocumentsPage(page, status = null, startDate = null, endDate = null, pageSize = 15) {
	return {
		type: GET_DOCUMENTS_PAGE,
		payload: new Promise(resolve => {
			let url = `documentations?pageIndex=${page}&pageSize=${pageSize}`;
			if (status != null) {
				url += `&filter=${status}`;
			}

			if (startDate != null) {
				url += `&startDate=${startDate}`;
			}

			if (endDate != null) {
				url += `&endDate=${endDate}`;
			}

			api.get(url).then((response) => {
				if (response.data.length > 0) {
					resolve(response.data);
				} else {
					resolve([]);
				}
			});
		}),
	}
};

export const deleteDocument = (id) => ({
	type: DELETE_DOCUMENT,
	payload: new Promise(resolve => {
		api.delete(`documentations/${id}`).then((response) => {
			resolve(id);
		});
	}),
});

export const clearDocumentsFromStore = () => ({
	type: CLEAR_DOCUMENTS_FROM_STORE,
	payload: []
});

export const deleteDocumentationFile = (documentationId, fileId) => ({
	type: DELETE_DOCUMENTATION_FILE,
	payload: new Promise((resolve) => {
		api.delete(`documentations/${documentationId}/files/${fileId}`).then(() => {
			resolve(fileId);
		});
	}),
});
