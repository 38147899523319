import api from 'lib/api';

export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const SEARCH_COURSE = 'SEARCH_COURSE';
export const SEARCH_SECTION = 'SEARCH_SECTION';
export const SEARCH_ASSIGNMENTS = 'SEARCH_ASSIGNMENTS';
export const SEARCH_PLANS = 'SEARCH_PLANS';
export const SEARCH_MENTOR_GROUPS = 'SEARCH_MENTOR_GROUPS';
export const SEARCH_EDUCATION_GROUPS = 'SEARCH_EDUCATION_GROUPS';
export const SEARCH_GROUPS = 'SEARCH_SEARCH_GROUPS';
export const SEARCH_HALDOR_ASSIGNMENTS_IN_GROUP = 'SEARCH_HALDOR_ASSIGNMENTS_IN_GROUP';
export const SEARCH_MICROSOFT_ASSIGNMENTS_IN_GROUP = 'SEARCH_MICROSOFT_ASSIGNMENTS_IN_GROUP';
export const SEARCH_CLEAR_RESULTS = 'SEARCH_CLEAR_SEARCH_RESULTS';
export const SEARCH_POSTS = 'SEARCH_POSTS';
export const SEARCH_SCHOOL_USERS = 'SEARCH_SCHOOL_USERS';
export const CLEAR_SEARCH = 'SEARCH_CLEAR_SEARCH';
export const SEARCH_MY_SECTIONS = 'SEARCH_MY_SECTIONS';

export const CLEAR_COURSE_SEARCH = 'SEARCH_CLEAR_COURSE_SEARCH';

export const setCurrentSearchTerm = (term) => ({
	type: SET_SEARCH_TERM,
	payload: term,
})

export const searchCourseByTitle = (title, schoolType, year, callback) => ({
	type: SEARCH_COURSE,
	payload: new Promise((resolve) => {
		let url = `courses/search/${title}?`;
		if (schoolType != null) {
			url += `typeOfSchooling=${schoolType}`;
		}

		if (year != null) {
			url += `&year=${year}`;
		}

		api.get(url).then((response) => {
			resolve(response.data);
			if (callback != null) {
				callback(response.data);
			}
		});
	}),
});

export const clearCourseSearch = () => ({
	type: CLEAR_COURSE_SEARCH,
	payload: true,
});

export const searchSectionByTitle = (title, filtercreated, callback) => ({
	type: SEARCH_SECTION,
	payload: new Promise((resolve) => {
		api.get(`sections?title=${title}&created=${filtercreated}`).then((response) => {
			resolve(response.data);

			if (callback != null) {
				callback(response.data);
			}
		});
	}),
});

// state.assignments.search_results
export const searchAssignment = (value) => ({
	type: SEARCH_ASSIGNMENTS,
	payload: new Promise((resolve) => {
		api.get(`assignments/search/${value}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const searchMicrosoftAssignmentsInGroup = (value, group) => ({
	type: SEARCH_MICROSOFT_ASSIGNMENTS_IN_GROUP,
	payload: new Promise((resolve) => {
		api.get(`assignments/search/?search=${value}&graphId=${group}&origin=MS_ASSIGNMENTS`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const searchHaldorAssignmentsInGroup = (value, group) => ({
	type: SEARCH_HALDOR_ASSIGNMENTS_IN_GROUP,
	payload: new Promise((resolve) => {
		api.get(`assignments/search/?search=${value}&graphId=${group}&origin=HALDOR_ASSIGNMENTS`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const searchPlans = (value, group) => ({
	type: SEARCH_PLANS,
	payload: new Promise((resolve) => {
		let url = `planning/search/${value}`;
		if (group != null) {
			url += `?graphId=${group}`;
		}

		api.get(url).then((response) => {
			resolve(response.data);
		});
	}),
});

// state.Search.mentors
export const searchMentorGroups = (query) => ({
	type: SEARCH_MENTOR_GROUPS,
	payload: new Promise((resolve) => {
		api.get(`users/me/sections/?query=${query}&filter=MENTOR_GROUP;`).then((response) => {
			resolve(response.data);
		});
	}),
});

// state.Search.educationGroups
export const searchEducationGroups = (query) => ({
	type: SEARCH_EDUCATION_GROUPS,
	payload: new Promise((resolve) => {
		api.get(`users/me/sections/?query=${query}&filter=EDUCATION_GROUP;`).then((response) => {
			resolve(response.data);
		});
	}),
});

// state.Search.groups
export const searchGroups = (parameters) => ({
	type: SEARCH_GROUPS,
	payload: new Promise((resolve) => {
		let url = '';
		let urlParams = {
			pageSize: 99,
			pageIndex: 1,
			filter: '',
			sortBy: 'TITLE_DESC',
			...parameters
		};

		for (const key in urlParams) {
			url += `&${key}=${urlParams[key]}`;
		}

		api.get(`sections/search/?_=${url}`).then((response) => {
			resolve(response.data);
		});
	}),
});


export const searchMySections = (parameters) => ({
	type: SEARCH_MY_SECTIONS,
	payload: new Promise((resolve) => {
		let url = '';
		let urlParams = {
			pageSize: 99,
			pageIndex: 1,
			filter: '',
			sortBy: 'TITLE_DESC',
			...parameters
		};

		for (const key in urlParams) {
			url += `&${key}=${urlParams[key]}`;
		}

		api.get(`users/me/sections?_=${url}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const clearSearchResults = () => ({
	type: SEARCH_CLEAR_RESULTS,
	payload: true,
});

export function searchSchoolUsers(search, userTypes, filter) {
	return {
		type: SEARCH_SCHOOL_USERS,
		payload: new Promise(resolve => {
			let url = `users?search=${search}&userType=${userTypes}&filter=${filter}`;
			if (search == '' || search == null) {
				resolve([]);
				return true;
			}
			api.get(url).then((response) => {
				resolve(response.data);
			});
		})
	};
}

export const searchPosts = (query) => ({
	type: SEARCH_POSTS,
	payload: new Promise((resolve) => {
		api.get(`posts/search/${query}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const clearSearch = () => ({
	type: CLEAR_SEARCH,
	payload: true,
});