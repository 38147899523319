import React, { Component } from 'react';
import { Icon, translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import Moment from "moment";

import { getRootUrl } from 'helpers/url';
import { isUserTeacher } from 'helpers/user';
import { updatePlan, createPlan, getPlanDetails } from 'actions/plans';
import { getSection } from "actions/sections";
import { saveBlocksOnReference, updateBlocksOnReference, deleteBlock } from 'actions/blocks';

import BlockEditor from 'components/BlockEditor';
import TeacherAdjustments from "containers/AdditionalAdjustments/Display/TeacherAdjustments";
import DatePickerFromTo from '../Partials/DatePickerFromTo';
import GroupSelector from '../Partials/GroupSelector';
import SearchPreschoolGoals from '../SearchPreschoolGoals';

import { Editor } from 'UI/Inputs';
import { Checkbox, Button } from '@haldor/ui';
import { Expandable, Spinner } from "UI";
import { Stack } from '@mui/material';

class PreschoolPlanningForm extends Component {
	constructor(props) {
		super(props);

		var students = [];
		if (this.props.editView) {
			if (props.initialValues != null) {
				students = props.initialValues.students;
			}
		}

		this.state = {
			selectedGoals: [],
			attachedFiles: props.editView || props.cloneView ? props.initialValues.attachedFiles : [],
			dateStart: null,
			dateEnd: null,
			sectionId: '0',
			newDocuments: [],
			students: students,
			loading: true,
		}
	}

	componentDidMount = () => {
		if (this.props.cloneView) {
			this.props.getPlanDetails(this.props.plan.id).then(() => {
				this.setState({ loading: false });
			});
		} else {
			this.setState({ loading: false });
		}

		let sectionId = null;
		if (this.props.initialValues != null) {
			sectionId = this.props.initialValues.sectionId;
		}

		if (this.props.sectionId != null) {
			sectionId = this.props.sectionId;
		}

		if (sectionId != null) {
			var find = this.props.sections.find(t => t.id == sectionId);
			if (find == null) {
				this.setState({ sectionId: 0 });
			} else {
				this.setState({ sectionId: sectionId });
				this.props.getSection(sectionId).then(() => {
					if (!this.props.editView) {
						this.setState({ students: this.props.section.students });
					}
				});
			}
		}
	};

	/* Redux form functions */
	required = (value) => {
		if (typeof (value) !== 'undefined' && value !== '') {
			if (value.length > 199) {
				// Field failed validation, return error for this field (String)
				return this.props.translate('field-max-200-chars');
			}

			// Field passes validation, return a undefined error for this field
			// Rule: Not undefined and not an empty string and not over 200 characters
			return undefined;
		}

		// Field failed validation, return error for this field (String)
		return this.props.translate('field-needs-input');
	}

	submit = (values) => {
		return new Promise(resolve => {
			const { selectedGoals, dateStart, dateEnd, sectionId } = this.state;
			const { initialValues } = this.props;
			const { progress, evaluation } = values;
			let attachedFiles = [];
			let blocks = [];
			let deletedBlocks = [];

			if (values.blocks != null) {
				blocks = [...values.blocks];
				blocks.forEach(block => {
					if (block.type == 'Haldor.Blocks.Assignment') {
						if (block.resources != null) {
							let resources = [];
							block.resources.forEach(resource => {
								resources.push({
									id: resource.id,
									'@odata.type': resource['@odata.type'],
								});
							});

							block.resources = resources;
						}
					}

					return block;
				})

				if (initialValues != null && initialValues.blocks != null) {
					deletedBlocks = [...initialValues.blocks].filter(block =>
						!blocks.find(_bl => _bl.id == block.id)
					);
				}
			}

			delete values.blocks;
			delete values.progress;
			delete values.evaluation;

			if (values.files == null) {
				values.fields = [];
			}

			let foundProgress = values.fields.find(field => {
				return field.title == 'heres-how-we-work';
			});

			let foundEvaluation = values.fields.find(field => {
				return field.title == 'evaluation-and-analysis';
			});

			if (foundProgress != null) {
				foundProgress.content = progress;
			}
			else {
				var progressField = {
					title: 'heres-how-we-work',
					content: progress,
					type: 'MULTILINETEXT',
					roles: [{
						'roleID': 'Teacher',
					}],
				};
				values.fields.push(progressField);
			}

			if (foundEvaluation != null) {
				foundEvaluation.content = evaluation;
			}
			else {
				var evaluationField = {
					title: 'evaluation-and-analysis',
					content: evaluation,
					type: 'MULTILINETEXT',
					roles: [{
						'roleID': 'Teacher',
					}],
				};
				values.fields.push(evaluationField);
			}

			/* Add custom inputs to the values object that redux form creates */
			values.associatedGoals = selectedGoals;
			values.timeStart = dateStart;
			values.timeEnd = dateEnd;
			values.students = this.state.students;

			if (values.status == null) {
				values.status = 'PLANNING_OPEN';
			}

			values.sectionId = sectionId;
			values.associatedMatrices = [];
			values.matrix = null;

			if (this.state.attachedFiles != null && this.state.attachedFiles.length > 0) {
				attachedFiles = this.state.attachedFiles;
			}

			values.attachedFiles = attachedFiles;

			if (this.state.newDocuments != null && this.state.newDocuments.length > 0) {
				values.attachedFiles = [...values.attachedFiles, ...this.state.newDocuments];
			}

			/* Make request */
			const that = this;
			if (this.props.editView) {
				let promises = [];
				promises.push(new Promise(resolve => {
					this.props.updatePlan(values).then(() => {
						resolve(1);
					})
				}))

				if (blocks != null && blocks.length > 0) {
					promises.push(new Promise(resolve => {
						this.props.updateBlocksOnReference(blocks, initialValues.id, 'PLAN')
							.then(() => {
								resolve(1);
							})
					}));
				}

				if (deletedBlocks.length > 0) {
					deletedBlocks.forEach(block => {
						promises.push(new Promise(resolve => {
							this.props.deleteBlock(block.id)
								.then(() => {
									resolve(1);
								})
						}));
					})
				}

				Promise.all(promises).then(() => {
					that.props.onAbort(true, true);

					resolve(1);
				});
			} else {
				if (this.props.cloneView) {
					if (values.attachedFiles != null) {
						values.attachedFiles.forEach(element => {
							element.originId = this.props.initialValues.id;
						});
					}

					if (blocks != null) {
						blocks.forEach(block => {
							block.id = 0;
							return block;
						});
					}
				}

				this.props.createPlan(values).then(() => {
					let promises = [];

					if (blocks != null && blocks.length > 0) {
						promises.push(this.props.saveBlocksOnReference(blocks, this.props.createdPlanId.id, 'PLAN'));
					}

					Promise.all(promises).then(() => {
						if (this.props.cloneView) {
							window.location = this.props.createdPlanId.hasOwnProperty('id') ? `${getRootUrl()}plan/${this.props.createdPlanId.id}` : `${getRootUrl()}plan/${this.props.createdPlanId}`;
						} else {
							this.props.history.push(this.props.createdPlanId.hasOwnProperty('id') ? `${getRootUrl()}plan/${this.props.createdPlanId.id}` : `${getRootUrl()}plan/${this.props.createdPlanId}`);
						}

						resolve(1);
					});
				});
			}
		});
	}

	/* Begin custom endpoint input events */
	onGroupChange = (groupId) => {
		if (groupId != 0) {
			this.setState({ sectionId: groupId });

			if (this.props.section?.id != groupId) {
				this.props.getSection(groupId).then(() => {
					this.setState({ students: this.props.section.students });
				});
			}
		}
	}

	onGoalsChange = (selectedGoals) => {
		this.setState({ selectedGoals });
	}

	selectDocument = (files) => {
		this.setState({ newDocuments: files });
	}

	removeInstruction = (instruction, remove) => {
		let { attachedFiles } = this.state;

		let removeFile = attachedFiles.find(file => file.value == instruction);
		removeFile.markedForDeletion = remove;

		this.setState({ attachedFiles });
	}

	onDateChange = (dates) => {
		this.setState({ dateStart: dates.start, dateEnd: dates.end });
	}

	getSectionStudents = () => {
		let sectionStudents = this.props.section.students;
		let missingStudents = this.state.students.filter(
			t => sectionStudents.find(s => s.id == t.id) == null
		);

		missingStudents.forEach(student => {
			student.firstName = student.firstname;
			student.lastName = student.lastname;
			student.missing = true;
		});

		sectionStudents = sectionStudents.concat(missingStudents);

		return sectionStudents;
	}

	onStudentSelect = (student) => {
		let foundStudent = false;
		this.state.students.find(studentPlan => {
			if (studentPlan.id == student.id) {
				foundStudent = true;
			}
		});

		let students = this.state.students;
		if (foundStudent) {
			if (student.missing && !student.remove) {
				student.remove = true;
			} else if (student.missing && student.remove) {
				student.remove = false;
			} else {
				students = students.filter(t => t.id != student.id);
			}
		} else {
			students.push(student);
		}

		this.setState({ students });
	};
	/* End custom input change events */

	/* Redux form input method */
	renderInput = ({ input, label, type, placeholder, meta: { touched, error, warning } }) => {
		return (
			<div>
				<input
					{...input}
					placeholder={placeholder || label}
					type={type}
					style={touched && error ? {
						border: '1px solid red'
					} : {}}
				/>

				{touched && ((error &&
					<span style={{ marginTop: '1rem', color: 'red', }}>{error}</span>) || (warning &&
						<span style={{ marginTop: '1rem', color: 'red', }}>{warning}</span>))
				}
			</div>
		);
	}

	renderStudents = () => {
		const { section } = this.props;
		const { sectionId } = this.state;

		if (section != null && sectionId != "0" && sectionId != null) {
			let sectionStudents = this.getSectionStudents();

			return sectionStudents.map(student => {
				let checked = false;
				let disabled = false;

				this.state.students.forEach(studentPlan => {
					if (studentPlan.id == student.id) {
						checked = true;
						if (studentPlan.remove) {
							checked = false;
						}

						if (this.props.assessments != null && this.props.editView) {
							this.props.assessments.find(assessment => {
								if (assessment.studentId == student.id) {
									disabled = true;
								}
							});
						}
					}
				});

				let title = student.firstName + " " + student.lastName;
				if (disabled && this.props.editView) {
					title += " (" + this.props.translate("assessed") + ")";
				}

				if (student.missing) {
					title += " (" + this.props.translate("removed-from-group") + ")";
				}

				return (
					<div style={{ marginBottom: "1rem" }} key={student.id}>
						<Checkbox
							name="studentSelect"
							value={checked}
							disabled={disabled}
							onChange={() => {
								this.onStudentSelect(student);
							}}
							label={title}
						/>
					</div>
				);
			});
		} else {
			return (
				<div>{this.props.translate("select-a-group-to-continue")}</div>
			);
		}
	};

	/**
 * Render a label with an alert icon
 * @param {string} label 
 * @param {string} alertText
 * @returns 
 */
	renderLabelWithAlertIcon = (label, alertText) => {
		return <Stack direction={'row'} spacing={1} alignItems="center">
			<div>{this.props.translate(label)}</div>
			<Stack sx={{ fontSize: '10px', fontWeight: '100' }} direction={'row'} spacing={0.5} alignItems="center">
				<Icon name="Alert" bw />
				<div>{this.props.translate(alertText)}</div>
			</Stack>
		</Stack>
	}

	/* Begin render */
	render() {
		const {
			handleSubmit,
			submitting,
			valid,
			initialValues,
		} = this.props;

		const { sectionId } = this.state;

		if (this.state.loading) {
			return <Spinner center />;
		}

		return (
			<div className="form-container form-create_task">
				{submitting ?
					<div className="is_sending"><p><span className="loading-spinner" /></p></div>
					: null}

				<form onSubmit={handleSubmit(this.submit)} className="form form-component">
					{!this.props.editView ?
						<div className="form-row">
							<GroupSelector
								disabled={this.props.sectionId && this.props.cloneView == false ? true : false}
								onChange={this.onGroupChange}
								required
								sectionId={this.state.sectionId}
							/>
						</div>
						: null}

					<div style={{ marginTop: "0.5rem" }}>
						<Expandable title={this.props.translate("students")}>
							{this.renderStudents()}
						</Expandable>
					</div>

					<div className="form-row">
						<DatePickerFromTo
							type="plan"
							onChange={this.onDateChange}
							values={initialValues}
							cloneView={this.props.cloneView}
						/>
					</div>

					<div className="form-row input">
						<label>{this.props.translate('title')}*</label>

						<Field
							component={this.renderInput}
							type="text"
							name="title"
							placeholder="Titel"
							validate={this.required}
						/>
					</div>

					<div className="form-row">
						<label>{this.props.translate('select-goals')}</label>

						<SearchPreschoolGoals
							selectedGoals={initialValues.associatedGoals}
							onChange={this.onGoalsChange}
						/>
					</div>

					{isUserTeacher(this.props.currentUser) ?
						<div>
							<div className="form-row">
								<Field
									component={Editor}
									name="evaluation"
									label={this.renderLabelWithAlertIcon('Evaluation and analysis', 'Do not write any personal information here. The content will be visible to all staff who open or copy the plan.')}
									placeholder={this.props.translate("Evaluation and analysis")}
									translate={this.props.translate}
								/>
							</div>

							<div className="form-row">
								<Field
									component={Editor}
									name="progress"
									label={this.renderLabelWithAlertIcon('How to continue our work', 'Do not write any personal information here. The content will be visible to all staff who open or copy the plan.')}
									placeholder={this.props.translate("How to continue our work")}
									translate={this.props.translate}
								/>
							</div>
						</div>
						: null}

					<div className="form-divider" />

					{this.props.services.additionalAdjustments ? (
						<Expandable
							title={this.props.translate(
								"additional-adjustments"
							)}
						>
							<TeacherAdjustments
								disableCommenting={true}
								groupId={sectionId != '0' ? sectionId : null}
							/>
						</Expandable>
					) : null}

					<Field
						component={BlockEditor}
						name="blocks"
					/>

					<div className="form-divider" />

					<div className="form-row spacing submit">
						<Button
							onClick={handleSubmit(values =>
								this.submit({ ...values, status: 'PLANNING_OPEN' })
							)}
							disabled={submitting || !valid || sectionId == '0'}
						>
							{this.props.editView ?
								this.props.translate('save')
								:
								this.props.translate('publish')
							}
						</Button>

						{this.props.editView && initialValues.status != "PLANNING_DRAFT" ?
							null
							:
							<Button
								onClick={handleSubmit(values =>
									this.submit({
										...values,
										status: 'PLANNING_DRAFT'
									}))}
								type="secondary"
								disabled={submitting || !valid || sectionId == '0'}
							>
								{this.props.translate('save-draft')}
							</Button>
						}

						<div className="align-right">
							<Button type="secondary" onClick={e => {
								e.preventDefault();
								this.props.onAbort();
							}}>
								{this.props.translate('Cancel')}
							</Button>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

function mapStateToProps(state) {
	let initialValues = { ...state.planning.active_plan };

	initialValues.progress = '';
	initialValues.evaluation = '';

	if (initialValues != null && initialValues.fields != null) {
		let foundProgress = initialValues.fields.find(field => {
			return field.title == 'heres-how-we-work';
		});

		let foundEvaluation = initialValues.fields.find(field => {
			return field.title == 'evaluation-and-analysis';
		});

		if (foundProgress != null) {
			initialValues.progress = foundProgress.content;
		}

		if (foundEvaluation != null) {
			initialValues.evaluation = foundEvaluation.content;
		}
	}

	if (state.Blocks.reference != null) {
		initialValues.blocks = state.Blocks.reference;
	}

	return {
		initialValues,
		translate: translate(state.Languages.translations),
		sections: state.sections.educationGroups,
		section: state.sections.activeSection,
		currentUser: state.user.currentUser,
		services: state.Services.availableServices,
		createdPlanId: state.planning.createdPlanId,
	};
}

export default withRouter(connect(mapStateToProps, {
	createPlan,
	updatePlan,
	getSection,
	saveBlocksOnReference,
	updateBlocksOnReference,
	deleteBlock,
	getPlanDetails,
})(
	reduxForm({
		form: 'PreschoolPlanningForm',
		destroyOnUnmount: true,
	})(PreschoolPlanningForm)
));