import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import swal from 'sweetalert2';

import User from '_class/User';
import DateTime from '_class/DateTime';

import {
	addComment,
	updateStudentAdjustment,
	deleteStudentAdjustment,
} from 'actions/additional_adjustments';

import Modal from 'containers/Modals/Modal';
import { Collapsible, Spinner } from 'UI';
import { Block, Flex, Select, Icon, Button, translate } from '@haldor/ui';

import PostContent from 'components/Presentation/PostContent';
import EditAdditionalAdjustmentSubjects from '../Form/EditAdditionalAdjustmentSubjects';
import EditStudentAdjustmentForm from '../Form/EditStudentAdjustmentForm';

import Comments from './Partials/Comments';

import './Adjustment.scss';

class StudentAdjustment extends Component {

	constructor(props) {
		super(props);

		this.state = {
			status: props.adjustment.status,
			courseModal: false,
			editAdjustment: false,
			loading: false,
		};
	}

	/* Event methods - JEVEN */
	addComment = (values) => {
		const { adjustment } = this.props;

		return new Promise(resolve => {
			this.props.addComment(values, adjustment.id).then(() => {
				resolve(1);
			});
		});
	}

	onStatusChange = async (status) => {
		this.setState({ status, loading: true, });

		let values = { ...this.props.adjustment };
		values.status = status;

		if (status == 'COMPLETED') {
			values.endDate = moment().format('YYYY-MM-DD');
		} else {
			values.endDate = null;
		}

		await this.props.updateStudentAdjustment(values);
		this.setState({ loading: false });
	}

	onAdjustmentSubmit = (values) => {
		return new Promise(resolve => {
			this.setState({ loading: true });

			this.props.updateStudentAdjustment(values).then(() => {
				this.setState({ loading: false, editAdjustment: false });
				resolve(1);
			})
		})
	}

	onAdjustmentRemove = async () => {
		const { translate } = this.props;
		const user = new User(this.props.user);

		this.setState({ loading: true });

		if (!user.isPedagogue()) {
			const response = await swal.fire({
				title: translate('Are you sure?'),
				text: translate('The adjustment will be removed from the student wherever it is used. Restoration can only be done by a special educational needs teacher.'),
				showCancelButton: true,
				cancelButtonText: translate('Cancel'),
				confirmButtonText: translate('Remove'),
			});

			if (response.value == null) {
				this.setState({ loading: false });
				return false;
			}
		}

		await this.props.deleteStudentAdjustment(this.props.adjustment);
		this.setState({ loading: false });
	}

	toggleCourseModal = () => {
		this.setState({ courseModal: !this.state.courseModal });
	}

	toggleEditModal = () => {
		this.setState({ editAdjustment: !this.state.editAdjustment });
	}

	/* Render methods - JREND */
	render() {
		const { adjustment, translate } = this.props;
		if (adjustment == null) {
			return null;
		}

		const user = new User(this.props.user);
		const statuses = [
			{
				label: translate('Active'),
				value: 'ACTIVE',
			},
			{
				label: translate('adjustment-closed'),
				value: 'COMPLETED',
			}
		];

		let status = statuses.find(st => st.value == this.state.status) ?? null;

		const student = new User(this.props.adjustment.student);

		const collapseTrigger = (
			<strong className="trigger" style={{ cursor: 'pointer' }}>
				<span style={{ whiteSpace: 'nowrap' }}>
					{this.props.translate('subjects')} {adjustment.subjects.length}
				</span>

				{!user.isStudent() && (user.isTeacher() || user.isMentor() || user.isPedagogue()) ?
					<span onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						this.toggleCourseModal()
					}}>
						<Icon name="Pen_Small" />
					</span>
					: null}
			</strong>
		)

		return <div className="adjustment">
			<Modal isOpen={this.state.courseModal} onClose={this.toggleCourseModal} type="small" title={translate('Subjects')}>
				<EditAdditionalAdjustmentSubjects adjustment={adjustment} onClose={this.toggleCourseModal} />
			</Modal>

			<Modal isOpen={this.state.editAdjustment} onClose={this.toggleEditModal} title={translate('edit-adjustment-for') + ' ' + student.getName()}>
				<EditStudentAdjustmentForm
					adjustment={adjustment}
					onClose={this.toggleEditModal}
					onSubmit={this.onAdjustmentSubmit}
				/>
			</Modal>

			<Block>
				<span className="title">
					{adjustment.additionalAdjustment.subTitle != null ?
						adjustment.additionalAdjustment.title + ' ' + adjustment.additionalAdjustment.subTitle
						: adjustment.additionalAdjustment.title}
				</span>

				<Flex>
					<div style={{ width: '100%' }}>
						<div>
							<PostContent>
								{adjustment.description}
							</PostContent>

							<div className="createdBy color--meta">
								{adjustment.createdBy.firstName} {adjustment.createdBy.lastName}
								{' • ' + new DateTime(adjustment.created).getShortDate(true)}
							</div>
						</div>

						{!user.isStudent() && (user.isPedagogue() || user.isTeacher() || user.isMentor()) ?
							<div style={{ marginTop: '1rem' }}>
								<Comments
									form={'comment-' + adjustment.id}
									adjustment={adjustment}
									onSubmit={this.addComment}
								/>
							</div>
							: null}
					</div>

					<div className="adjustment-meta size-14">
						{this.state.status == 'ACTIVE' ?
							<div>
								<strong>{this.props.translate('start-time')}</strong>
								<br />

								{new DateTime(adjustment.startDate).getShortDate()}
							</div>
							:
							<div>
								<strong>{this.props.translate('time-period')}</strong>
								<br />

								{new DateTime(adjustment.startDate).getShortDate()}
								{' - ' + new DateTime(adjustment.endDate).getShortDate()}
							</div>
						}

						{this.state.status != 'DELETED' ?
							<div style={{ marginTop: '1rem' }}>
								<strong >{this.props.translate('Status')}</strong>
								<br />

								{!user.isStudent() && (user.isPedagogue() || user.isTeacher() || user.isMentor()) ?
									<Select onChange={this.onStatusChange} options={statuses}>
										{status?.label}
									</Select>
									: status?.label}
							</div>
							: null}

						<div className="meta-courses" style={{ marginTop: '1rem' }}>
							<Collapsible trigger={collapseTrigger}>
								{adjustment.subjects != null ?
									adjustment.subjects.map(subject => {
										return <div key={subject.id}>
											{subject.title}
										</div>
									})
									: null}
							</Collapsible>

							<br />

							{!user.isStudent() && (user.isPedagogue() || user.isTeacher() || user.isMentor()) && adjustment.status == 'ACTIVE' ?
								<div style={{ marginTop: 5 }}>
									<Button type="secondary" onClick={this.toggleEditModal} disabled={this.state.loading}>
										{this.state.loading ?
											<Spinner center />
											: null}

										<Icon name="Pen_Small" />
										{this.props.translate('Edit')}
									</Button>
								</div>
								: null}

							{!user.isStudent() && (user.isPedagogue() || user.isMentor()) && adjustment.status == 'COMPLETED' ?
								<div style={{ marginTop: 5 }}>
									<Button type="secondary" onClick={this.onAdjustmentRemove} disabled={this.state.loading}>
										{this.state.loading ?
											<Spinner center />
											: null}

										<Icon name="Bin" />
										{this.props.translate('Remove')}
									</Button>
								</div>
								: null}

							{!user.isStudent() && user.isPedagogue() && adjustment.status == 'DELETED' ?
								<div style={{ marginTop: 5 }}>
									<Button type="secondary" onClick={() => this.onStatusChange('COMPLETED')} disabled={this.state.loading}>
										{this.state.loading ?
											<Spinner center />
											: null}

										<Icon name="Undo" />
										{this.props.translate('reset')}
									</Button>
								</div>
								: null}

							{!user.isStudent() && user.isPedagogue() && adjustment.status == 'DELETED' ?
								<div style={{ marginTop: 5 }}>
									<Button type="secondary" onClick={this.onAdjustmentRemove} disabled={this.state.loading}>
										{this.state.loading ?
											<Spinner center />
											: null}

										<Icon name="Bin" />
										{this.props.translate('Remove permanently')}
									</Button>
								</div>
								: null}
						</div>
					</div>
				</Flex>
			</Block>
		</div>
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		user: state.user.currentUser,
	}
}

export default connect(mapStateToProps, {
	addComment,
	updateStudentAdjustment,
	deleteStudentAdjustment,
})(StudentAdjustment);