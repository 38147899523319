import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { getUserStudyPlan } from 'actions/verdicts';
import { getUserSubjects } from 'actions/user';
import {
	getAdditionalAdjustments,
	createAdditionalAdjustment,
	createStudentAdjustment,
} from 'actions/additional_adjustments';
import { searchCourseByTitle } from 'actions/search';
import moment from 'moment';

import { Button, Checkbox, Icon } from '@haldor/ui';
import { Spinner } from 'UI';
import { Editor } from 'UI/Inputs';

class StudentAdjustmentForm extends Component {

	constructor(props) {
		super(props);

		this.state = {
			category: '',
			loading: true,
			selectedSubjects: [],
		};
	}

	componentDidMount = () => {
		this.props.getAdditionalAdjustments();

		if (this.props.userId != null) {
			this.props.getUserSubjects(this.props.userId).then(() => {
				let selectedSubjects = [...this.state.selectedSubjects];

				if (this.props.userSubjects != null && this.props.userSubjects.length > 0) {
					if (this.props.subjectId != null) {
						this.props.userSubjects.forEach(subject => {
							if (subject.id == this.props.subjectId) {
								selectedSubjects.push(subject);
							}
						});
					}

					if (this.props.course != null) {
						this.props.userSubjects.forEach(subject => {
							if (this.props.course.subjectCode == subject.subjectCode) {
								selectedSubjects.push(subject);
							}
						});
					}

					this.setState({ loading: false, selectedSubjects });
				} else {
					this.setState({ loading: false });
				}
			})
		}
	}

	required = (value) => {
		if (typeof (value) != 'undefined' && value != '' && value != null) {
			if (value.length > 199) {
				// Field failed validation, return error for this field (String)
				return this.props.translate('field-max-200-chars');
			}

			// Field passes validation, return a undefined error for this field
			// Rule: Not undefined and not an empty string and not over 200 characters
			return undefined;

		}

		// Field failed validation, return error for this field (String)
		return this.props.translate('field-needs-input');
	}

	editorRequired = (value) => {
		if (typeof (value) != 'undefined' && value != '' && value != null) {
			return undefined;
		}

		return this.props.translate('field-needs-input');
	}

	submit = (values) => {
		return new Promise((resolve) => {
			values.subjects = this.state.selectedSubjects;

			if (this.props.initialValues == null) {
				values.status = 'ACTIVE';
				values.studentId = this.props.userId;
				values.startDate = moment().format('YYYY-MM-DD');
			}

			if (values.subTitle != null) {
				let adjustmentObj = {
					title: this.props.translate('other'),
					subTitle: values.subTitle,
					description: values.adjustmentDescription
				};

				this.props.createAdditionalAdjustment(adjustmentObj).then(() => {
					values.adjustmentId = this.props.createdAdjustment.id;
					this.props.createStudentAdjustment(values).then(() => {
						resolve(1);
						this.props.onClose();
					});
				});
			} else {
				values.adjustmentId = this.state.category;

				this.props.createStudentAdjustment(values).then(() => {
					resolve(1);
					this.props.onClose();
				});
			}
		});
	}

	onCancel = (event) => {
		event.preventDefault();

		if (this.props.onClose != null) {
			this.props.onClose();
		}
	}

	onCategoryChange = (event) => {
		this.setState({ category: event.target.value });

		const category = this.props.additionalAdjustments.find(adjustment => {
			return adjustment.id == parseInt(event.target.value, 10);
		});

		if (category != null) {
			this.props.change('description', category.description, null);
		}
	}

	selectAllSubjects = () => {
		let { selectedSubjects } = this.state;

		if (this.props.userSubjects == null) {
			return false;
		}

		let selectedAmount = selectedSubjects.length;

		selectedSubjects = [];

		if (selectedAmount != this.props.userSubjects.length) {
			this.props.userSubjects.forEach(subject => {
				selectedSubjects.push(subject)
			});
		} else {
			this.props.userSubjects.forEach(subject => {
				let disabled = false;
				if (this.props.course != null) {
					disabled = this.props.course.subjectCode == subject.subjectCode;
				}

				if (this.props.subjectId != null) {
					disabled = this.props.subjectId == subject.id;
				}

				if (disabled) {
					selectedSubjects.push(subject)
				}
			});
		}

		this.setState({ selectedSubjects });
	}

	onSubjectToggle = (subject) => {
		let selectedSubjects = [...this.state.selectedSubjects];
		let found = this.state.selectedSubjects.findIndex(sel =>
			sel.id == subject.id
		)

		if (found > -1) {
			selectedSubjects.splice(found, 1);
		} else {
			selectedSubjects.push(subject);
		}

		this.setState({ selectedSubjects });
	}

	render() {
		const { submitting, handleSubmit, valid } = this.props;

		let allSelected = false;
		if (this.props.userSubjects != null) {
			allSelected = this.state.selectedSubjects.length == this.props.userSubjects.length;
		}

		return (
			<div className="form-container student-adjustment-form">
				{submitting ? <div className="is_sending"><p><span className="loading-spinner" /></p></div> : null}

				<form onSubmit={handleSubmit(this.submit)} className="form form-component">
					<div className="form-row">
						<label>{this.props.translate('choose-adjustment')}*</label>

						<div className="select">
							<select value={this.state.category} onChange={this.onCategoryChange}>
								<option value="">{this.props.translate('choose-adjustment')}</option>

								{this.props.additionalAdjustments != null ?
									this.props.additionalAdjustments.map((adjustment) => {

										if (adjustment.status != 'ACTIVE')
											return;

										return (
											<option key={'adjustment-' + adjustment.id} value={adjustment.id}>
												{adjustment.title}{adjustment.subTitle != null ?
													' - ' + adjustment.subTitle
													: null}
											</option>
										);
									})
									: null}

								<option value="extra_field">
									{this.props.translate('Other (Creates new template)')}
								</option>
							</select>
						</div>
					</div>

					{this.state.category == 'extra_field' ?
						<div>
							<div className="form-row textarea">
								<label>{this.props.translate('Template title')}*</label>

								<Field
									component="input"
									type="text"
									name="subTitle"
									validate={this.required}
								/>
							</div>

							<div className="form-row textarea">
								<label>{this.props.translate('Template description')}*</label>

								<div className="description-alert">
									<Icon name="Alert" bw /> {this.props.translate("Do not write information about a specific student in the template's description text")}
								</div>

								<Field
									component={Editor}
									name="adjustmentDescription"
									validate={this.editorRequired}
									translate={this.props.translate}
								/>
							</div>
						</div>
						: null}

					<div className="form-row textarea">
						<label>{this.props.translate('Description for current student')}*</label>

						<Field
							component={Editor}
							name="description"
							validate={this.editorRequired}
							translate={this.props.translate}
						/>
					</div>

					<div className="form-row">
						{this.state.loading ?
							<Spinner center />
							:
							this.props.userSubjects != null && this.props.userSubjects.length > 0 ?
								<div>
									<label>{this.props.translate('choose-subjects')}</label>

									<div style={{ marginBottom: '.35rem' }}>
										<Checkbox
											onChange={this.selectAllSubjects}
											label={this.props.translate('select-all')}
											value={allSelected}
										/>
									</div>

									{this.props.userSubjects.map((subject) => {
										let disabled = false;
										let selected = this.state.selectedSubjects.findIndex(sel =>
											sel.id == subject.id
										) > -1;

										if (this.props.course != null) {
											disabled = this.props.course.subjectCode == subject.subjectCode;
										}

										if (this.props.subjectId != null) {
											disabled = this.props.subjectId == subject.id;
										}

										let title = subject.title;
										if (subject.typeOfSchooling != null) {
											title += ` (${this.props.translate(subject.typeOfSchooling)})`;
										}

										return (
											<div style={{ marginBottom: '.35rem', marginLeft: '.85rem' }} key={subject.id}>
												<Checkbox
													value={selected}
													onChange={() => this.onSubjectToggle(subject)}
													label={title}
													disabled={disabled}
												/>
											</div>
										)
									})}
								</div>
								:
								<div>
									<label>{this.props.translate('choose-subjects')}</label>

									<div style={{ marginBottom: '.35rem' }}>
										{this.props.translate('No subjects available for this user')}
									</div>
								</div>
						}
					</div>

					<div className="form-divider" />

					<div className="form-row submit spacing">
						<Button disabled={submitting || !valid || this.state.category == ''}>
							{this.props.translate('save')}
						</Button>

						{this.props.onClose != null ?
							<div className="pull-right">
								<Button type="secondary" onClick={this.onCancel}>
									{this.props.translate('Cancel')}
								</Button>
							</div>
							: null}

						<div className="clearfix" />
					</div>
				</form>

			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		studyPlans: state.Verdicts.userStudyPlans,
		additionalAdjustments: state.AdditionalAdjustments.adjustments,
		userSubjects: state.user.userSubjects,
		createdAdjustment: state.AdditionalAdjustments.createdAdjustment
	}
}

export default connect(mapStateToProps, {
	getUserStudyPlan,
	searchCourseByTitle,
	getAdditionalAdjustments,
	getUserSubjects,
	createAdditionalAdjustment,
	createStudentAdjustment,
	change,
})(reduxForm({
	form: 'StudentAdjustmentForm',
	destroyOnUnmount: true,
})(StudentAdjustmentForm));
