import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DateTime from '_class/DateTime';
import ColorScale from '_class/ColorScale';
import { getRootUrl } from 'helpers/url';

import { getSectionAssessmentStatistics } from 'actions/sections';

import { Spinner, Collapsible } from 'UI';
import { Bar, Progress } from 'UI/Graphs';
import { Block, Checkbox, Expandable, Icon, MultiSelect, Table, translate } from '@haldor/ui';
import './UserSection.scss'
import DisplayName from 'components/Presentation/DisplayName';

class UserSection extends Component {

	constructor(props) {
		super(props);

		this.state = {
			filters: ['ASSESSMENTGOAL', 'MATRIX'],
			subjects: [],
			openStudents: [],
			loadingAssessments: false,
			width: 0,
			showMenu: true,
		};

		this.container = null;
		this.barColumn = null;
	}

	_setContainer = (element) => this.container = element;
	_setBarColumn = (element) => this.barColumn = element;

	componentDidMount = () => {
		window.addEventListener('hashchange', this.onHashChange);
		window.addEventListener('resize', this.resize);
		this.setState({ openStudents: this.props.section.students.map(student => student.userId) }, () => {
			this.resize();
		});
	}

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.resize);
		window.removeEventListener('hashchange', this.onHashChange);
	}

	onHashChange = () => {
		if (window.location.hash == '#assessments') {
			this.setState({ showMenu: true })
		} else {
			this.setState({ showMenu: false })
		}
	}

	resize = () => {
		if (this.barColumn != null && this.container != null) {
			this.setState({ width: this.container.scrollWidth - this.barColumn.scrollWidth })
		}
	}

	toggleStudentOpen = (student) => {
		const index = this.state.openStudents.indexOf(student.userId);

		if (index > -1) {
			this.setState({ openStudents: this.state.openStudents.filter(id => id != student.userId) });
		} else {
			this.setState({ openStudents: [...this.state.openStudents, student.userId] });
		}
	}

	toggleStudentsOpen = () => {
		const active = this.props.section.students.length == this.state.openStudents.length;

		if (active) {
			this.setState({ openStudents: [] });
		} else {
			this.setState({ openStudents: this.props.section.students.map(student => student.userId) });
		}
	}

	toggleFilter = (filter) => {
		const index = this.state.filters.indexOf(filter);

		if (index > -1) {
			this.setState({ filters: this.state.filters.filter(id => id != filter) });
		} else {
			this.setState({ filters: [...this.state.filters, filter] });
		}
	}

	onSubjectsChange = (subjects) => {
		this.setState({ subjects });
		const { section, start, end } = this.props;

		this.setState({ loadingAssessments: true });
		this.props.getSectionAssessmentStatistics(section.id, start, end, subjects).then(() => {
			this.setState({ loadingAssessments: false });
		});
	}

	getPercentageFromStatistics = (items) => {
		if (items == null) {
			return 0;
		}

		let percentages = [];

		items.forEach((item) => {
			let item_total = 0;
			let indexed = [];

			item.statistics.forEach((stat) => {
				item_total += stat.count;
				indexed.push({ count: stat.count, index: stat.index, weight: stat.weight });
			});

			for (let i = 0; i <= item_total; i++) {
				indexed.map((entry) => {
					if (entry.count > 0) {
						percentages.push(entry.weight);
						entry.count = entry.count - 1;
					}

					return entry;
				});
			}
		});

		return Math.round(percentages.reduce((a, b) => a + b, 0) / percentages.length);
	}

	renderAssessmentBar = (item, index) => {
		if (!this.state.filters.includes(item.type)) {
			return null;
		}

		if (item.statistics != null) {
			var data = [];
			const isEmpty = item.statistics.find((stat) => stat.count > 0) == null;

			if (isEmpty) {
				return null;
			}

			const colors = new ColorScale(item.statistics.length).get();
			item.statistics.map((stat) => {
				data.push({
					amount: stat.count,
					label: stat.count,
					color: colors[stat.index],
				});
			});

			let line = null;
			if (data.length > 0) {
				line = (
					<Bar data={data} showAmount showAllSteps />
				);
			}

			return (
				<div key={index} className="bar-outer-container">
					<div>
						{this.props.translate(item.type)}
					</div>

					{line}
				</div>
			);
		}
		return <div />;
	}

	getChildHistoricMembers = (historicMember) => {
		const children = this.props.section.historicMembers.filter(el =>
			el.userId == historicMember.userId && el.relationship == 'child'
		);

		return children.map((member) => {
			return (
				<div className="history-member-row child">
					<div style={{ flex: 1 }} className="name">
					</div>

					<div className="connected-duration">
						<span>
							{`${new DateTime(member.startDate).getDateStamp()} - ${new DateTime(member.endDate).getDateStamp()}`}
						</span>

						{member.userRoles.some(el => el == 'OWNER') ?
							<div className="card-meta" style={{ marginLeft: '1.65rem', fontWeight: 500 }}>
								{this.props.translate('Owner')}
							</div>
							: null}
					</div>
				</div>
			);
		});
	}

	renderStudentRoleIndicator = (student) => {
		if (student == null) {
			return null;
		}

		if (student.userRoles != null && student.userRoles.indexOf('STUDENT') == -1) {
			const { translate } = this.props;

			return (
				<Icon name="Alert_Red" title={translate('Missing student role in school')} />
			);
		}

		return null;
	};

	renderGoalBar = (percentage = 0) => {
		if (!this.state.filters.includes('ASSESSMENTGOAL')) {
			return null;
		}

		if (percentage == 0) {
			return null;
		}

		return (
			<div className="bar-outer-container">
				<div>
					{this.props.translate('ASSESSMENTGOAL')}
				</div>

				<div style={{ paddingLeft: '.55rem' }}>
					<Progress percentage={percentage} label={`${percentage}%`} />
				</div>
			</div>
		);
	}

	renderTable = () => {
		const width = this.state.width > 0 ? this.state.width + 'px' : '100%';
		const sortedStudents = this.props.section.students.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || "")
		);

		return <Table>
			<thead>
				<tr>
					<th ref={this._setBarColumn}>
						{this.props.translate('name')}
					</th>

					<th style={{ width }}>
						{this.props.translate('assessment-overview')}
					</th>
				</tr>
			</thead>

			<tbody>
				{sortedStudents.map((student) => {
					let studentStatistics = null;
					let percentage = 0;
					const open = this.state.openStudents.includes(student.userId);

					if (this.props.statistics != null) {
						studentStatistics = this.props.statistics.find((item) => {
							return item.studentId == student.userId;
						});

						const goalStatistics = studentStatistics?.studentStatistics?.filter(stat => stat.type == 'ASSESSMENTGOAL');
						percentage = this.getPercentageFromStatistics(goalStatistics);
					}

					return (
						<tr key={student.id}>
							<td className='name' title={`${this.props.translate('Connected to group')} ${new DateTime(student.connectedToSectionDate).getDateStamp()}`}>
								<Link to={`${getRootUrl()}groups/${this.props.section.id}/progress/${student.id}`}>
									{this.renderStudentRoleIndicator(student)}
									<DisplayName
										firstName={student.firstName}
										lastName={student.lastName}
										email={student.email}
										data={sortedStudents}
									/>
								</Link>
							</td>

							<td className="assessment-bar">
								<Expandable title="" onChange={() => this.toggleStudentOpen(student)} open={open}>
									{percentage > 0 ?
										this.renderGoalBar(percentage)
										: null}

									{studentStatistics != null ?
										studentStatistics.studentStatistics.map((item, index) => {
											if (item.type == 'MATRIX') {
												return this.renderAssessmentBar(item, index);
											}

											return null;
										})
										: <div style={{ height: 23 }} />}
								</Expandable>
							</td>
						</tr>
					);
				})}
			</tbody>
		</Table>
	}

	renderExpand = () => {
		const active = this.props.section.students.length == this.state.openStudents.length;

		return <div className={active ? 'expand-all active' : 'expand-all'} onClick={this.toggleStudentsOpen}>
			<Icon name="ArrowLeft" /> {active ?
				this.props.translate('Close all')
				: this.props.translate('Show all')}
		</div>
	}

	render() {
		this.props.section.historicMembers?.forEach((member, index) => {
			var laterEntries = this.props.section.historicMembers.some((el, someIndex) => el.userId == member.userId && someIndex > index);
			var earlierEntries = this.props.section.historicMembers.some((el, someIndex) => el.userId == member.userId && someIndex < index);
			if (laterEntries && !earlierEntries) {
				member.relationship = 'parent';
			} else if ((laterEntries && earlierEntries) || (!laterEntries && earlierEntries)) {
				member.relationship = 'child';
			} else {
				member.relationship = null;
			}
		});

		let subjects = this.props.subjects;

		if (subjects != null && subjects.find((subject) => subject.id == 0) == null) {
			subjects.push({ id: 0, title: this.props.translate('Assessments without subjects') })
		}

		return (
			<div ref={this._setContainer} className="list user-mentor">
				<Block>
					<div className="title-container">
						<span className="title" style={{ marginBottom: 0 }}>
							{this.props.translate('Students')}
						</span>
						{this.state.showMenu ?
							<div className="filter-container">
								{this.state.loadingAssessments ?
									<Spinner small />
									: null}

								<Checkbox
									label={this.props.translate('MATRIX')}
									onChange={() => this.toggleFilter('MATRIX')}
									value={this.state.filters.includes('MATRIX')}
								/>

								<Checkbox
									label={this.props.translate('ASSESSMENTGOAL')}
									onChange={() => this.toggleFilter('ASSESSMENTGOAL')}
									value={this.state.filters.includes('ASSESSMENTGOAL')}
								/>

								{subjects != null ?
									<MultiSelect
										displaySelectedCount={false}
										trigger={this.props.translate('Showing') + ' ' + this.state.subjects.length + ' ' + this.props.translate('of') + ' ' + subjects.length}
										options={subjects.map((subject) => ({
											label: subject.title,
											value: subject.id,
										}))}
										value={subjects.map((subject) => subject.id)}
										onChange={this.onSubjectsChange}
										selectAllLabel={this.props.translate('Select all')}
									/>
									: null}

								{this.renderExpand()}
							</div>
							: null}
					</div>

					<div style={{ position: 'relative', margin: '0 -1.7rem' }}>
						{this.renderTable()}
					</div>

					{this.props.section.historicMembers && this.props.section.historicMembers.length > 0 &&
						<div className="previous-members-card">
							<Collapsible trigger={(
								<div className="previous-members-title">{this.props.translate('Previous members')}</div>
							)}>
								<div className="previous-member-list">
									{this.props.section.historicMembers?.map((student) => {
										return (
											<div>
												{student.relationship == 'parent' ?
													<Collapsible trigger={(
														<div className="history-member-row">
															<div className="name">
																{student.displayName}
															</div>
															<div className="connected-duration">
																<span>{`${new DateTime(student.startDate).getDateStamp()} - ${new DateTime(student.endDate).getDateStamp()}`}</span>
																{student.userRoles.some(el => el == 'OWNER') ?
																	<div className="card-meta" style={{ marginLeft: '1.65rem', fontWeight: 500 }}>
																		{this.props.translate('Owner')}
																	</div>
																	: null
																}
															</div>
														</div>
													)}>
														{this.getChildHistoricMembers(student)}
													</Collapsible>
													:
													student.relationship == null ?
														<div className="history-member-row child">
															<div style={{ flex: 1 }} className="name">
																{student.displayName}
															</div>
															<div className="connected-duration">
																{`${new DateTime(student.startDate).getDateStamp()} - ${new DateTime(student.endDate).getDateStamp()}`}
															</div>
														</div>
														: null
												}
											</div>
										);
									})}
								</div>
							</Collapsible>
						</div>
					}
				</Block>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		statistics: state.sections.assessmentStatistics,
	};
}

export default connect(mapStateToProps, {
	getSectionAssessmentStatistics,
})(UserSection);
