import React, { Component } from "react";
import { Form, Field } from "react-final-form";
import Moment from "moment";

import { createBulkPlans } from 'actions/plans';
import { saveBlocksOnReference } from 'actions/blocks';

import { isUserTeacher } from "helpers/user";
import { connect } from "react-redux";
import { Expandable, List } from "UI";
import { Editor } from "UI/Inputs";
import { Button, Icon, translate } from '@haldor/ui';

import DatePickerFromTo from "../Partials/DatePickerFromTo";
import MultipleSectionSelector from "../Partials/MultipleSectionSelector";
import SearchPreschoolGoals from "../SearchPreschoolGoals";
import TeacherAdjustments from "containers/AdditionalAdjustments/Display/TeacherAdjustments";

import BlockEditor from 'components/BlockEditor';
import { Stack } from "@mui/material";

class MultiplePreschoolPlanForms extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedGoals: [],
			attachedFiles: [],
			dateStart: null,
			dateEnd: null,
			sections: [],
		};
	}

	/* Redux form functions */
	required = value => {
		if (typeof value !== "undefined" && value !== "") {
			if (value.length > 199) {
				// Field failed validation, return error for this field (String)
				return this.props.translate("field-max-200-chars");
			}

			// Field passes validation, return a undefined error for this field
			// Rule: Not undefined and not an empty string and not over 200 characters
			return undefined;
		}

		// Field failed validation, return error for this field (String)
		return this.props.translate("field-needs-input");
	};

	submit = values => {
		return new Promise(resolve => {
			const { selectedGoals, dateStart, dateEnd, sections } = this.state;
			const { progress, evaluation } = values;
			let attachedFiles = [];
			let blocks = [];

			if (values.blocks != null) {
				blocks = [...values.blocks];
			}

			blocks.forEach(block => {
				if (block.type == 'Haldor.Blocks.Assignment') {
					if (block.resources != null) {
						let resources = [];
						block.resources.forEach(resource => {
							resources.push({
								id: resource.id,
								'@odata.type': resource['@odata.type'],
							});
						});

						block.resources = resources;
					}
				}

				return block;
			})

			delete values.blocks;
			delete values.progress;
			delete values.evaluation;

			if (this.state.attachedFiles != null && this.state.attachedFiles.length > 0) {
				attachedFiles = this.state.attachedFiles;
			}

			values.fields = [
				{
					title: "evaluation-and-analysis",
					content: evaluation,
					type: "MULTILINETEXT",
					roles: [
						{
							roleID: "Teacher"
						}
					]
				},
				{
					title: "heres-how-we-work",
					content: progress,
					type: "MULTILINETEXT",
					roles: [
						{
							roleID: "Teacher"
						}
					]
				}
			];

			/* Add custom inputs to the values object that redux form creates */
			values.associatedGoals = selectedGoals;
			values.timeStart = dateStart;
			values.timeEnd = dateEnd;
			if (values.status == null) {
				values.status = "PLANNING_OPEN";
			}

			values.sections = sections;
			values.associatedMatrices = [];
			values.matrix = null;
			values.attachedFiles = attachedFiles;

			if (this.state.newDocuments != null && this.state.newDocuments.length > 0) {
				values.attachedFiles = [...values.attachedFiles, ...this.state.newDocuments];
			}

			/* Make request */
			const that = this;

			this.props.createBulkPlans(values).then(() => {
				let promises = [];
				if (this.props.createdPlans != null && blocks != null && blocks.length > 0) {
					this.props.createdPlans.forEach(createdPlan => {
						promises.push(new Promise(resolve => {
							this.props.saveBlocksOnReference(blocks, createdPlan.id, 'PLAN')
								.then(() => {
									resolve(1);
								});
						}));
					});
				}

				Promise.all(promises).then(() => {
					that.props.onAbort(true, true);
					resolve(1);
				});
			});
		});
	};

	onGoalsChange = selectedGoals => {
		this.setState({ selectedGoals });
	};

	selectDocument = files => {
		this.setState({ newDocuments: files });
	};

	onDateChange = dates => {
		this.setState({ dateStart: dates.start, dateEnd: dates.end });
	};

	onSectionsChange = state => {
		this.setState({ sections: state });
	};

	/* End custom input change events */

	/* Redux form input method */
	renderInput = ({ input, label, type, placeholder, meta: { touched, error, warning } }) => {
		return (
			<div>
				<input
					placeholder={placeholder || label}
					type={type}
					style={touched && error ? {
						border: "1px solid red"
					} : {}}
					{...input}
				/>

				{touched &&
					((error && (
						<span style={{ marginTop: "1rem", color: "red" }}>
							{error}
						</span>
					)) ||
						(warning && (
							<span style={{ marginTop: "1rem", color: "red" }}>
								{warning}
							</span>
						)))}
			</div>
		);
	};

	/**
	 * Render a label with an alert icon
	 * @param {string} label 
	 * @param {string} alertText
	 * @returns 
	 */
	renderLabelWithAlertIcon = (label, alertText) => {
		return <Stack direction={'row'} spacing={1} alignItems="center">
			<div>{this.props.translate(label)}</div>
			<Stack sx={{ fontSize: '10px', fontWeight: '100' }} direction={'row'} spacing={0.5} alignItems="center">
				<Icon name="Alert" bw />
				<div>{this.props.translate(alertText)}</div>
			</Stack>
		</Stack>
	}

	/* Begin render */
	render() {
		let groupId = null;

		if (this.state.sections.length == 1) {
			groupId = this.state.sections[0].id;
		}

		return (
			<div className="form-container form-create_task">
				<Form
					onSubmit={this.submit}
					mutators={{
						setStatusDraft: (args, state, utils) => {
							utils.changeValue(state, 'status', () => 'PLANNING_DRAFT');
						},
						setStatusOpen: (args, state, utils) => {
							utils.changeValue(state, 'status', () => 'PLANNING_OPEN');
						},
					}}
					render={({ handleSubmit, submitting, valid, form }) => {
						return (
							<form onSubmit={handleSubmit} className="form form-component">
								{submitting ?
									<div className="is_sending">
										<p>
											<span className="loading-spinner" />
										</p>
									</div>
									: null}

								<Expandable
									contentStyles={{ padding: "1em 0rem" }}
									contentStylesClosed={{ padding: "0" }}
									headerStyles={this.state.error != null ?
										{ border: "1px solid red" }
										: {}}
									whiteBackground={true}
									title={this.props.translate("choose-groups")}
									open
									ignorePropsUpdate
								>
									<List style={{ border: "0", margin: "0", boxShadow: "0" }}>
										<MultipleSectionSelector
											onChange={this.onSectionsChange}
										/>
									</List>
								</Expandable>

								<div className="form-row">
									<DatePickerFromTo
										type="plan"
										onChange={this.onDateChange}
									/>
								</div>

								<div className="form-row input">
									<label>{this.props.translate("title")}*</label>

									<Field
										component={this.renderInput}
										type="text"
										name="title"
										placeholder={this.props.translate("title")}
										validate={this.required}
									/>
								</div>

								<div className="form-row">
									<label>{this.props.translate("select-goals")}</label>

									<SearchPreschoolGoals
										selectedGoals={null}
										onChange={this.onGoalsChange}
									/>
								</div>

								{isUserTeacher(this.props.currentUser) ? (
									<div>
										<div className="form-row">
											<Field
												component={Editor}
												name="evaluation"
												label={this.renderLabelWithAlertIcon('Evaluation and analysis', 'Do not write any personal information here. The content will be visible to all staff who open or copy the plan.')}
												placeholder={this.props.translate("Evaluation and analysis")}
												translate={this.props.translate}
											/>
										</div>

										<div className="form-row">
											<Field
												component={Editor}
												name="progress"
												label={this.renderLabelWithAlertIcon('How to continue our work', 'Do not write any personal information here. The content will be visible to all staff who open or copy the plan.')}
												placeholder={this.props.translate("How to continue our work")}
												translate={this.props.translate}
											/>
										</div>
									</div>
								) : null}

								<div className="form-divider" />

								{this.props.services.additionalAdjustments ? (
									<Expandable title={this.props.translate("additional-adjustments")}>
										<TeacherAdjustments disableCommenting={true} groupId={groupId} />
									</Expandable>
								) : null}

								<Field
									component={BlockEditor}
									name="blocks"
								/>

								<div className="form-divider" />

								<div className="form-row spacing submit">
									<Button
										onClick={(e) => {
											e.preventDefault();
											form.mutators.setStatusOpen();
											form.submit();
										}}
										disabled={submitting || !valid || this.state.sections.length == 0}
									>
										{this.props.translate("publish")}
									</Button>

									<Button
										onClick={(e) => {
											e.preventDefault();
											form.mutators.setStatusDraft();
											form.submit();
										}}
										type="secondary"
										disabled={submitting || !valid || this.state.sections.length == 0}
									>
										{this.props.translate("save-draft")}
									</Button>

									<div className="align-right">
										<Button
											type="secondary"
											onClick={e => {
												e.preventDefault();
												this.props.onAbort(false);
											}}
										>
											{this.props.translate("Cancel")}
										</Button>
									</div>
								</div>
							</form>
						)
					}}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		sections: state.sections.educationGroups,
		currentUser: state.user.currentUser,
		services: state.Services.availableServices,
		createdPlans: state.planning.createdPlans,
	};
}

export default connect(mapStateToProps, {
	createBulkPlans,
	saveBlocksOnReference,
})(MultiplePreschoolPlanForms);