import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Moment from 'moment';
import DateTime from '_class/DateTime';
import swal from 'sweetalert2';

import { isUserStudent, getUserAssessmentDates } from 'helpers/user';

import {
	getSection,
	updateArchived,
	getArchievedMentorGroups,
	getEducationGroups,
	getMentorGroups,
	getArchivedEducationGroups,
	clearSectionAttendanceStats,
	getSectionAttendance,
	clearStudentAttendance
} from 'actions/sections';
import { setPageTitle } from 'actions/header';

import MentorSectionForm from 'containers/Forms/MentorSectionForm';
import SectionForm from 'containers/Forms/SectionForm';
import Modal from 'containers/Modals/Modal';

import UserMentorSection from 'components/List/UserMentorSection';
import SectionProgress from 'containers/Progress/Section';
import TeacherSectionProgress from 'containers/Progress/TeacherSection';
import SectionInformation from '../Functionality/SectionInformation';
import SectionPlans from '../Functionality/SectionPlans';
import SectionPosts from '../Functionality/SectionPosts';
import SectionTools from '../Functionality/SectionTools';
import DatePickers from 'containers/Progress/Partials/DatePickers';

import { Spinner } from 'UI';
import { Button, translate } from '@haldor/ui';
import MyAssignmentsWrapper from 'containers/Assignments/MyAssignmentsWrapper';

class UpperSecondarySchoolSection extends Component {
	constructor(props) {
		super(props);

		let hasHaldorAssignments = null;
		let hasMicrosoftAssignments = null;

		this.state = {
			modalIsOpen: false,
			loading: false,
			start: getUserAssessmentDates().start,
			end: getUserAssessmentDates().end,
			currentEnd: getUserAssessmentDates().end,
			currentStart: getUserAssessmentDates().start,
		};
	}

	componentDidMount() {
		const { groupId } = this.props.match.params;

		if (groupId != null && groupId != undefined && groupId != "") {
			this.setState({ loading: true });
			this.props.getSection(groupId, true, 'members_expand').then(() => {
				this.setState({ loading: false });

				if (this.props.group != null) {
					this.props.setPageTitle(this.props.group.title);

					if (window.location.hash == '#attendance') {
						if (this.props.group.type == 'MENTOR_GROUP') {
							this.getAttendanceData();
						}
					}
				}
			});
		}

		window.addEventListener('hashchange', this.onHashChange, false);
	}

	componentWillUnmount = () => {
		window.removeEventListener('hashchange', this.onHashChange)
	}

	onHashChange = () => {
		if (window.location.hash == '#attendance') {
			if (this.props.group.type == 'MENTOR_GROUP') {
				this.getAttendanceData();
			}
		}
	}

	getAttendanceData = () => {
		const start = Moment(this.state.start).format("YYYY-MM-DD");
		const end = Moment(this.state.end).format("YYYY-MM-DD");

		this.props.clearSectionAttendanceStats();
		this.props.getSectionAttendance(this.props.group.id, start, end);
	}

	// Delays request to prevent flooding sql   
	delayLoop = (fn, delay) => {
		return (x, i) => {
			setTimeout(() => {
				fn(x);
			}, i * delay);
		}
	};

	isUserOwner = () => {
		const { currentUser, group } = this.props;

		if (currentUser == null || group == null) {
			return false;
		}

		let foundOwner = group.owners.find(owner => {
			return currentUser.id == owner.id;
		});

		if (foundOwner != null) {
			return true;
		}

		return false;
	}

	EditButton = () => {
		const isStudent = isUserStudent(this.props.currentUser);
		let isOwner = this.props.group.owners.find(owner => {
			return owner.userId == this.props.currentUser.id;
		});

		if (!isStudent && isOwner != null) {
			return (
				<span>
					<Button onClick={this.openModal} type="secondary" disabled={this.props.group.archived}>
						{this.props.translate('Edit')}
					</Button>
				</span>
			);
		}

		return null;
	}

	openModal = () => {
		this.setState({ modalIsOpen: true });
	}

	ArchiveButton = () => {
		const isStudent = isUserStudent(this.props.currentUser);

		if (isStudent == false) {
			return (
				<Button type="secondary" onClick={this.makeArchive} >
					{this.props.translate(this.props.group.archived ? 'Restore' : 'Archive')}
				</Button>
			);
		}

		return null;
	}

	makeArchive = () => {
		const { groupId } = this.props.match.params;
		const { group, translate } = this.props;

		swal.fire({
			title: translate('are-you-sure'),
			html: group.archived ?
				translate('Are you sure you want to reset this group?') :
				translate('Both the group and the Microsoft Team will be archived.<br /><br />Do you want to archive this group?'),
			showCancelButton: true,
			cancelButtonText: translate('No'),
			confirmButtonText: translate('Yes'),
		}).then((result) => {
			if (result.value != null) {
				this.props.updateArchived(group).then((response) => {
					if (response.status < 300) {
						this.setState({ loading: true });
						this.props.getSection(groupId).then(() => {
							this.setState({ loading: false });
						});

						if (group.type == 'EDUCATION_GROUP') {
							this.props.getEducationGroups();
						}

						if (group.type == 'MENTOR_GROUP') {
							this.props.getMentorGroups();
						}
					}
				});
			}
		});
	}

	closeModal = (reRender, skipPrompt) => {
		if (skipPrompt) {
			const { groupId } = this.props.match.params;
			if (reRender) {
				this.setState({ loading: true });
				this.props.getSection(groupId).then(() => {
					this.setState({ loading: false });
				});
			}

			this.setState({ modalIsOpen: false });
		} else {
			this.setState({ modalIsOpen: false });
		}
	}

	onUpdateStats = () => {
		const { start, end } = this.state;

		this.props.clearSectionAttendanceStats();
		this.props.getSectionAttendance(this.props.group.id, start, end);

		this.setState({ currentStart: start, currentEnd: end });
	}

	renderMentorGroupActions = () => {
		return (
			<div className="single-section">
				<div style={{ marginBottom: '2em' }}>
					<div className="form">
						<div className="form-row">
							<div className="form-row-half static">
								<DatePickers
									startDate={this.state.start}
									endDate={this.state.end}
									onChange={this.onDateChange}
								/>

								<Button style={{ marginLeft: 0, marginTop: '1em' }} onClick={this.onUpdateStats}>
									{this.props.translate('update')}
								</Button>
							</div>
						</div>
					</div>
				</div>

				<div className="clearfix" />

				{this.renderDateFilterText()}
			</div>
		);
	}

	renderGroupContent = () => {
		const { group } = this.props;
		const isStudent = isUserStudent(this.props.currentUser);
		const hasAssignments = this.props.services.haldor || this.props.services.microsoft;

		return (
			<div className="single-group single-task">
				<Modal isOpen={this.state.modalIsOpen} onClose={this.closeModal} title={group.title}>
					<SectionForm onAbort={this.closeModal} editView section={group} />
				</Modal>

				<SectionTools EditButton={this.EditButton} ArchiveButton={this.ArchiveButton} group={group} />

				<SectionInformation group={group} />

				<div style={{ clear: 'both' }} />

				{this.props.services.news ?
					<div id="section-posts" className="section" style={{ marginTop: '4rem' }}>
						<SectionPosts
							groupid={group.id}
							archived={this.props.group.archived}
							disableCreate={!this.isUserOwner()}
						/>
					</div>
					: null}

				{this.props.services.plans ?
					<div id="section-planning" style={{ marginTop: '4rem' }}>
						<SectionPlans
							groupid={group.id}
							dateRestriction={false}
							locked={group.locked}
							archived={this.props.group.archived}
							disableCreate={!this.isUserOwner()}
						/>
					</div>
					: null}

				{hasAssignments ?
					<div id="section-groups" style={{ marginTop: '4rem' }}>
						<MyAssignmentsWrapper groupId={group.id} />
					</div>
					: null}

				{group.students && group.students.length > 0 && !isStudent ?
					<div id="section-pupils" className="single-section form" style={{ marginTop: '4rem' }}>
						<TeacherSectionProgress
							groupId={group.id}
						/>
					</div>
					: null}

				{isStudent ?
					<div id="section-assessment" className="single-section form" style={{ marginTop: '4rem' }}>
						<SectionProgress
							groupId={this.props.group.id}
							userId={this.props.currentUser.id}
						/>
					</div>
					: null}
			</div>
		);
	}

	onDateChange = (dates) => {
		const { start, end } = dates;

		localStorage.setItem('assessment-start-date', new DateTime(start).getDateStamp());
		localStorage.setItem('assessment-end-date', new DateTime(end).getDateStamp());

		this.setState({ start, end });
	}

	renderDateFilterText = () => {
		if (Moment().isSame(Moment(this.state.end), 'day')) {
			return null;
		}

		return (
			<div style={{ color: 'red', marginLeft: '1rem', marginTop: '1rem', fontWeight: 'bold' }}>
				{this.props.translate('Showing data between')} {Moment(this.state.start).format('YYYY-MM-DD')} {this.props.translate('to').toLowerCase()} {Moment(this.state.end).format('YYYY-MM-DD')}
			</div>
		)
	}

	renderMentorGroup() {
		const { group } = this.props;
		const isStudent = isUserStudent(this.props.currentUser);

		return (
			<div className="single-group single-task">
				<Modal isOpen={this.state.modalIsOpen} onClose={this.closeModal} title={group.title}>
					<MentorSectionForm onAbort={this.closeModal} editView section={group} />
				</Modal>

				<SectionTools EditButton={this.EditButton} ArchiveButton={this.ArchiveButton} group={group} />

				<SectionInformation group={group} />

				<div style={{ clear: 'both' }} />

				{this.props.services.plans ?
					<div id="section-posts" className="section single-section" style={{ border: 0, marginTop: '4rem' }}>
						<SectionPosts
							groupid={group.id}
							disableCreate={!this.isUserOwner()}
						/>
					</div>
					: null}

				{group.students && group.students.length > 0 && !isStudent ?
					<div id="section-pupils" className="single-section form" style={{ marginTop: '4rem' }}>
						<UserMentorSection
							section={group}
							attendance={this.props.attendance}
							renderActions={this.renderMentorGroupActions}
							start={this.state.currentStart}
							end={this.state.currentEnd}
						/>
					</div>
					: null}
			</div>
		);
	}

	render() {
		const { group } = this.props;


		if (!group || this.state.loading) {
			return (
				<Spinner center />
			);
		}

		switch (group.type) {
			case "EDUCATION_GROUP":
				return this.renderGroupContent();

			case "MENTOR_GROUP":
				return this.renderMentorGroup();

			default:
				return this.renderGroupContent();
		}
	}
}

function mapStateToProps(state) {
	return {
		group: state.sections.activeSection,
		translate: translate(state.Languages.translations),
		attendance: state.sections.sectionAttendance,
		currentUser: state.user.currentUser,
		services: state.Services.availableServices,
	};
}

export default withRouter(connect(mapStateToProps, {
	getSection,
	setPageTitle,
	updateArchived,
	getEducationGroups,
	getMentorGroups,
	getArchivedEducationGroups,
	getArchievedMentorGroups,
	clearSectionAttendanceStats,
	getSectionAttendance,
	clearStudentAttendance,
})(UpperSecondarySchoolSection));