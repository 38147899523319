import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from '@haldor/ui';
import SectionTitle from 'components/Presentation/SectionTitle';
import Modal from 'containers/Modals/Modal';
import StudentDetailsForm from 'containers/Forms/StudentDetailsForm';
import GuardianDetailsForm from 'containers/Forms/GuardianDetailsForm';

import {
	getUserInfo,
	updateUser,
	getUserGuardian,
	setUserGuardian,
	setUserAuthoritative,
	getUserGuardianByEmail,
	linkStudentGuardian,
	addUserGuardian,
	deleteStudentGuardian,
	updateUserPhoneNumber,
} from 'actions/user';

import { Flex, Skeleton, TooltipMenu, Button, Icon } from '@haldor/ui';

import Block, { BlockHeader, BlockContent } from "UI/Elements/Block";
import swal from 'sweetalert2';
import User from '_class/User';

class UserDetails extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			modal: false,
			modalType: "user",
			currentGuardian: null,
			currentStudent: null,
			searchingGuardian: false
		};
	}

	componentDidMount = async () => {
		if (this.props.currentStudent == null) {
			await this.props.getUserInfo(this.props.user, "extended", true)
		}

		if (this.props.user != null) {
			this.setState({ currentStudent: this.props.currentStudent });

			this.props.getUserGuardian(this.props.user).then(() => {
				this.setState({ loading: false });
			});
		}
	}

	toggleModal = (type, object) => {
		if (type == "guardian") {
			this.setState({ modal: !this.state.modal, currentGuardian: object, modalType: "guardian" });
		}
		else if (type == "user") {
			this.setState({ modal: !this.state.modal, currentGuardian: {}, modalType: "user" });
		}
		else {
			this.setState({ modal: !this.state.modal });
		}

		let promises = [];

		promises.push(this.props.getUserGuardian(this.props.user));
		promises.push(this.props.getUserInfo(this.props.user, "extended", true));

		Promise.all(promises).then(() => {
			this.setState({ loading: false, currentStudent: this.props.currentStudent });
		})
	}

	onStudentUpdate = (userItem) => {
		const user = new User(this.props.currentUser);
		this.setState({ loading: true });

		let promises = [];

		this.props.updateUser(userItem).then(() => {
			if (user.isAdministrator()) {
				userItem.guardians.forEach((guardian, index) => {
					promises.push(this.props.setUserGuardian(this.props.user, guardian));
				});
			}

			if (userItem.mobilePhone != null) {
				promises.push(this.props.updateUserPhoneNumber(userItem));
			}

			Promise.all(promises).then((response) => {
				this.props.getUserInfo(this.props.user, "extended", true).then((response) => {
					this.props.getUserGuardian(this.props.user).then(() => {
						this.setState({ loading: false, currentStudent: this.props.currentStudent });
						this.toggleModal("user", null);
					});
				});
			}).catch(error => {
				console.error(error.message);
				this.setState({ loading: false });
			});
		});
	}

	onModalCancel = () => {

		this.toggleModal("user", null);
		this.setState({ loading: true });
	}

	onGuardianModalCancel = () => {
		this.toggleModal("guardian", null);

		this.props.getUserGuardian(this.props.user).then(() => {
			let currentStudent = { ...this.state.currentStudent };
			currentStudent.guardians = this.props.guardians;
			this.setState({ currentStudent: currentStudent });
		});
	}

	onStudentAuthoritativeUpdate = (userId, authoritative) => {
	}

	onGuardianStatusChange = (guardian, status) => {
	}

	onGuardianUpdate = guardian => {
		if (guardian.id != "") {
			this.props.setUserGuardian(this.props.user, guardian).then(() => {
				this.props.getUserGuardian(this.props.user);
				this.props.getUserInfo(this.props.user, "extended");
				this.toggleModal();
			});
		} else {
			this.props.getUserGuardianByEmail(guardian.mail).then(() => {
				if (this.props.guardianByMail != null && this.props.guardianByMail != "" && this.props.guardianByMail.mail.toLowerCase() == guardian.mail.toLowerCase()) {
					let alreadyLinked = this.props.guardians.findIndex(g => {
						return this.props.guardianByMail.id == g.id;
					})
					if (alreadyLinked == -1) {
						this.props.linkStudentGuardian(this.props.user, this.props.guardianByMail.id);
					}
					let guardianByMail = this.props.guardianByMail;
					guardianByMail.active = guardian.active;
					guardianByMail.lcid = guardian.lcid;
					this.props.setUserGuardian(this.props.user, guardianByMail);
					this.toggleModal();
				}
				else {
					this.props.addUserGuardian(this.props.user, guardian).then(() => {
						this.toggleModal();
					});
				}
			});
		}
	}

	onMailChanged = (email) => {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (re.test(String(email).toLowerCase())) {
			this.setState({ searchingGuardian: true });

			this.props.getUserGuardianByEmail(email).then(() => {
				this.setState({ searchingGuardian: false });
				if (this.props.guardianByMail != null && this.props.guardianByMail != "" && this.props.guardianByMail.mail.toLowerCase() == email.toLowerCase()) {
					let guardian = this.props.guardianByMail;
					guardian.id = "";
					guardian.active = true;
					this.toggleModal();
					this.toggleModal("guardian", guardian);
				} else {
					this.toggleModal();
					this.toggleModal("guardian", {
						id: "",
						firstName: "",
						lastName: "",
						mail: email,
						phone: "",
						postal: "",
						street: "",
						active: true
					});
				};
			});
		}
	}

	onGuardianDelete = guardian => {
		swal.fire({
			title: this.props.translate('are-you-sure'),
			showCancelButton: true,
			cancelButtonText: this.props.translate('Cancel'),
			confirmButtonText: this.props.translate('Delete'),
		}).then(response => {
			if (response.value != null) {
				this.props.getUserGuardianByEmail(guardian.mail).then(() => {
					if (this.props.guardianByMail != null && this.props.guardianByMail != "" && this.props.guardianByMail.mail.toLowerCase() == guardian.mail.toLowerCase()) {
						if (this.props.guardianByMail.students.length > 1) {
							swal.fire({
								title: this.props.translate('remove-completely'),
								text: this.props.translate('remove-guardian-completely'),
								showCancelButton: true,
								cancelButtonText: this.props.translate('remove-for-current-student'),
								confirmButtonText: this.props.translate('remove-for-all-students'),
							}).then(response => {
								if (response.value != null) {
									this.props.deleteStudentGuardian(this.props.user, guardian.id, true).then(() => {
										this.props.getUserGuardian(this.props.user).then(() => {
											let currentStudent = this.state.currentStudent;
											currentStudent.guardians = this.props.guardians;
											this.setState({ currentStudent: currentStudent });
										});
									})
								}
								else {
									this.props.deleteStudentGuardian(this.props.user, guardian.id, false).then(() => {
										this.props.getUserGuardian(this.props.user).then(() => {
											let currentStudent = this.state.currentStudent;
											currentStudent.guardians = this.props.guardians;
											this.setState({ currentStudent: currentStudent });
										});
									})
								}
							});
						}
						else {
							this.props.deleteStudentGuardian(this.props.user, guardian.id, true).then(() => {
								this.props.getUserGuardian(this.props.user).then(() => {
									let currentStudent = this.state.currentStudent;
									currentStudent.guardians = this.props.guardians;
									this.setState({ currentStudent: currentStudent });
								});
							})
						}
					}
				});
			}
		});
	}

	isUserSchoolAdmin = () => {
		if (this.props.roles != null && this.props.roles.length > 0) {
			var schoolAdministrator = this.props.roles.find(role => (role.roleId.toUpperCase() == "SCHOOL_ADMINISTRATOR" && role.referenceId == this.props.activeSchool) || (role.roleId.toUpperCase() == "SYSADMIN" && role.referenceId == this.props.activeSchool));

			if (schoolAdministrator != null) {
				return true;
			}
		}

		return false;
	}

	render() {
		const { translate } = this.props;
		const user = new User(this.props.currentUser);

		if (this.state.loading) {
			return (
				<div>
					<SectionTitle>
						<span>
							{this.props.translate('Student')}
						</span>
					</SectionTitle>

					<Block>
						<BlockHeader>
							<div style={{ width: 325 }}>
								<Skeleton />
							</div>

							<div style={{ marginTop: '.95rem' }} />
						</BlockHeader>

						<BlockContent>
							<Flex>
								<div style={{ flex: 1 }}>
									<div style={{ width: 125 }}>
										<Skeleton />
									</div>
								</div>

								<div style={{ flex: 1 }}>
									<div style={{ width: 155 }}>
										<Skeleton />
									</div>
								</div>
							</Flex>
						</BlockContent>
					</Block>

					<div style={{ marginTop: '2rem' }} />

					<SectionTitle>
						<span>
							{this.props.translate('Guardians')}
						</span>
					</SectionTitle>

					<Block>
						<BlockHeader>
							<div style={{ width: 325 }}>
								<Skeleton />
							</div>

							<div style={{ marginTop: '.95rem' }} />
						</BlockHeader>

						<BlockContent>
							<Flex>
								<div style={{ flex: 1 }}>
									<div style={{ width: 125 }}>
										<Skeleton />
									</div>
								</div>

								<div style={{ flex: 1 }}>
									<div style={{ width: 155 }}>
										<Skeleton />
									</div>
								</div>
							</Flex>
						</BlockContent>
					</Block>

					<Block>
						<BlockHeader>
							<div style={{ width: 325 }}>
								<Skeleton />
							</div>

							<div style={{ marginTop: '.95rem' }} />
						</BlockHeader>

						<BlockContent>
							<Flex>
								<div style={{ flex: 1 }}>
									<div style={{ width: 125 }}>
										<Skeleton />
									</div>
								</div>

								<div style={{ flex: 1 }}>
									<div style={{ width: 155 }}>
										<Skeleton />
									</div>
								</div>
							</Flex>
						</BlockContent>
					</Block>
				</div>
			);
		}

		const triggerButton = (
			<Button type="secondary" style={{ marginLeft: 0 }}>
				{"⋯"}
			</Button>
		);

		return (
			<div className="section" style={{ padding: 0, margin: 0, border: 0 }}>
				<SectionTitle>
					<span>
						{this.props.translate('Student')}
					</span>
				</SectionTitle>

				<Modal onClose={this.toggleModal} title={this.state.modalType == "user" ? this.props.translate('edit-student') : this.state.currentGuardian != null && this.state.currentGuardian.id == "" ? this.props.translate('add-guardian') : this.props.translate('edit-guardian')} isOpen={this.state.modal} type="small">
					{this.state.modalType == "user" ?
						<StudentDetailsForm
							currentStudent={this.state.currentStudent}
							cancelModal={this.onModalCancel}
							onModalSave={this.onStudentUpdate}
							onStudentAuthoritativeUpdate={this.onStudentAuthoritativeUpdate}
							onGuardianStatusChange={this.onGuardianStatusChange}
						/>
						:
						<GuardianDetailsForm
							currentGuardian={this.state.currentGuardian}
							currentStudent={this.state.currentStudent}
							cancelModal={this.onGuardianModalCancel}
							onModalSave={this.onGuardianUpdate}
							onMailChanged={this.onMailChanged}
							searchingGuardian={this.state.searchingGuardian}
							errorMessage={this.props.guardianByMail === 'error' ? this.props.translate('error-occured') : null}
						/>}
				</Modal>

				<Block key={'student' + this.props.currentStudent?.userId}>
					<BlockHeader>
						{this.props.currentStudent.firstName} {this.props.currentStudent.lastName}

						{user.isAdministrator() || user.isMentor() || user.isSchoolLeader() ?
							<div style={{ float: "right" }}>
								<TooltipMenu trigger={triggerButton} id={this.props.currentStudent.userId}>
									<TooltipMenu.Item onClick={() => this.toggleModal("user", null)}>
										{this.props.translate('Edit')}
									</TooltipMenu.Item>
								</TooltipMenu>
							</div>
							: null}
					</BlockHeader>

					<BlockContent style={{ paddingTop: '0px' }} >
						<Flex>
							<div style={{ flex: 1 }}>
								{this.props.currentStudent.email != null ?
									<div style={{ marginTop: '.55rem' }}>
										<strong>{translate('e-mail')}</strong>
										<br />
										{this.props.currentStudent.email}
									</div>
									: null}
							</div>

							<div style={{ flex: 1 }}>
								{this.props.currentStudent.mobilePhone != null ?
									<div style={{ marginTop: '.55rem' }}>
										<strong>{translate('Phone number')}</strong>
										<br />
										{this.props.currentStudent.mobilePhone}
									</div>
									: null}
							</div>
						</Flex>
					</BlockContent>
				</Block>

				<div style={{ marginTop: '2rem' }} />

				<SectionTitle>
					<span>
						{this.props.translate('Guardians')}
					</span>

					{user.isAdministrator() ?
						<Button type="secondary" onClick={() => this.toggleModal("guardian", {
							id: "",
							firstName: "",
							lastName: "",
							mail: "",
							phone: "",
							postal: "",
							street: "",
							active: true
						})}>
							<Icon name="Plus" /> {this.props.translate("Add")}
						</Button>
						: null}
				</SectionTitle>

				{this.props.guardians.map(guardian => {
					if (guardian != null && guardian.linkGuardianStudents && guardian.linkGuardianStudents.some(student => student.studentId === this.props.user && student.active === true)) {
						return <Block key={'guardian' + guardian.id}>
							<BlockHeader>{guardian.firstName} {guardian.lastName != null ? guardian.lastName : ''}
								{user.isAdministrator() && !guardian.synced ?
									<div style={{ float: "right" }}>
										<TooltipMenu trigger={triggerButton} id={guardian.id}>
											<TooltipMenu.Item onClick={() => this.toggleModal("guardian", guardian)}>
												{this.props.translate('Edit')}
											</TooltipMenu.Item>
											<TooltipMenu.Item onClick={() => this.onGuardianDelete(guardian)}>
												{this.props.translate('Delete')}
											</TooltipMenu.Item>
										</TooltipMenu>
									</div>
									: null}
							</BlockHeader>

							<BlockContent style={{ paddingTop: '0px' }} >
								<Flex>
									<div style={{ flex: 1 }}>
										{guardian.phone != null ?
											<div style={{ marginTop: '.55rem' }}>
												<strong>{translate('phone')}</strong>
												<br />
												{guardian.phone}
											</div>
											: null}

										{guardian.mail != null ?
											<div style={{ marginTop: '.55rem' }}>
												<strong>{translate('e-mail')}</strong>
												<br />
												{guardian.mail}
											</div>
											: null}
									</div>

									<div style={{ flex: 1 }}>
										{guardian.street != null && guardian.postal != null ?
											<div style={{ marginTop: '.55rem' }}>
												<strong>{translate('address')}</strong>
												<br />
												{guardian.street}<br />
												{guardian.postal}, {guardian.city}
											</div>
											: null}
									</div>
								</Flex>
							</BlockContent>
						</Block>
					}
				})}

				{this.props.guardians && this.props.guardians.some(g => g != null && g.linkGuardianStudents &&
					g.linkGuardianStudents.some(student => student.studentId === this.props.user && student.active === false)) ?
					<div>
						<div style={{ marginTop: '2rem' }} />

						<SectionTitle>
							<span>
								{this.props.translate('archived-guardian')}
							</span>
						</SectionTitle>

						{this.props.guardians.map(guardian => {
							if (guardian != null && guardian.linkGuardianStudents && guardian.linkGuardianStudents.some(student => student.studentId === this.props.user && student.active === false)) {
								return <Block key={'guardian' + guardian.id}>
									<BlockHeader>
										{guardian.firstName} {guardian.lastName != null ? guardian.lastName : ''} {guardian.synced}

										{user.isAdministrator() && !guardian.synced ?
											<div style={{ float: "right" }}>
												<TooltipMenu trigger={triggerButton} id={guardian.id}>
													<TooltipMenu.Item onClick={() => this.toggleModal("guardian", guardian)}>
														{this.props.translate('Edit')}
													</TooltipMenu.Item>

													<TooltipMenu.Item onClick={() => this.onGuardianDelete(guardian)}>
														{this.props.translate('Delete')}
													</TooltipMenu.Item>
												</TooltipMenu>
											</div>
											: null}
									</BlockHeader>

									<BlockContent style={{ paddingTop: '0px' }} >
										<Flex>
											<div style={{ flex: 1 }}>
												{guardian.phone != null ?
													<div style={{ marginTop: '.55rem' }}>
														<strong>{translate('phone')}</strong>
														<br />
														{guardian.phone}
													</div>
													: null}

												{guardian.mail != null ?
													<div style={{ marginTop: '.55rem' }}>
														<strong>{translate('e-mail')}</strong>
														<br />
														{guardian.mail}
													</div>
													: null}
											</div>

											<div style={{ flex: 1 }}>
												{guardian.street != null && guardian.postal != null ?
													<div style={{ marginTop: '.55rem' }}>
														<strong>{translate('address')}</strong>
														<br />
														{guardian.street}<br />
														{guardian.postal}, {guardian.city}
													</div>
													: null}
											</div>
										</Flex>
									</BlockContent>
								</Block>
							}
						})}
					</div>
					: null
				}
			</div>
		);
	}

}

function mapStateToProps(state, ownProps) {
	return {
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		activeSchool: state.user.activeSchool,
		guardians: state.user.guardians,
		userRoles: state.user.currentUser.userRoles,
		roles: state.user.currentUser.roles,
		guardianByMail: state.user.searchGuardian,
		currentStudent: state.user.info.find(u => {
			return u.userId == ownProps.user;
		}),
	};
}

export default connect(mapStateToProps, {
	getUserInfo,
	getUserGuardian,
	setUserGuardian,
	setUserAuthoritative,
	updateUser,
	getUserGuardianByEmail,
	linkStudentGuardian,
	addUserGuardian,
	deleteStudentGuardian,
	updateUserPhoneNumber,
})(UserDetails);
