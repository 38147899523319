import React, { useEffect } from "react";
import { Prompt } from 'react-router-dom';

export const FormPrompt = ({ hasUnsavedChanges, formPromptMessage }) => {
	useEffect(() => {
		const onBeforeUnload = (e) => {
			if (hasUnsavedChanges) {
				e.preventDefault();
				e.returnValue = "";
			}
		};
		window.addEventListener("beforeunload", onBeforeUnload);
		return () => window.removeEventListener("beforeunload", onBeforeUnload);

	}, [hasUnsavedChanges]);

	const onLocationChange = (location) => {
		return formPromptMessage;
	};

	return <Prompt when={hasUnsavedChanges} message={onLocationChange} />;
};