import {
	GET_USER_VERDICTS,
	GET_PLAN_ASSESSMENTS,
	GET_ASSIGNMENT_ASSESSMENTS,
	GET_SECTION_VERDICTS,
	GET_STUDY_PLAN,
	GET_STUDENT_LAST_VERDICT,
	GET_VERDICT_SCALE,
	CLEAR_VERDICTS,
	CLEAR_VERDCIT_SCALE,
	GET_VERDICTS_FOR_STUDENTS_IN_SECTION,
	RESET_ASSIGNMENT_ASSESSMENTS,
	RESET_PLAN_ASSESSMENTS
} from 'actions/verdicts';

const INITIAL_STATE = {
	scale: null,
	user: null,
	assignments: null,
	plans: null,
	section: null,
	userStudyPlans: null,
	lastVerdicts: [],
	sectionStudents: null,
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_VERDICT_SCALE:
			return { ...state, scale: action.payload };

		case GET_USER_VERDICTS:
			return { ...state, user: action.payload };

		case RESET_ASSIGNMENT_ASSESSMENTS:
			return { ...state, plans: null };

		case RESET_PLAN_ASSESSMENTS:
			return { ...state, assignments: null };

		case GET_PLAN_ASSESSMENTS:
			return { ...state, plans: action.payload };

		case GET_ASSIGNMENT_ASSESSMENTS:
			return { ...state, assignments: action.payload };

		case GET_SECTION_VERDICTS:
			return { ...state, section: action.payload };

		case GET_STUDY_PLAN:
			return { ...state, userStudyPlans: action.payload };

		case GET_STUDENT_LAST_VERDICT:
			if (action.payload != null && action.payload.length > 0) {
				let lastVerdicts = [...state.lastVerdicts];
				lastVerdicts.push(action.payload[0]);

				return { ...state, lastVerdicts };
			}

			return state;

		case CLEAR_VERDICTS:
			return { ...state, lastVerdicts: [], assignments: null, plans: null, user: null, userStudyPlans: null };

		case CLEAR_VERDCIT_SCALE:
			return { ...state, scale: null };

		case GET_VERDICTS_FOR_STUDENTS_IN_SECTION:
			return { ...state, sectionStudents: action.payload }

		default:
			return state;
	}
}
