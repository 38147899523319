import {
	GET_CONVERSATIONS,
	GET_CONVERSATIONS_REFERENCE,
	GET_CONVERSATION,
	GET_ARCHIVED_CONVERSATIONS,
	RESET_CONVERSATION,
	GET_CONVERSATION_MESSAGES,
	GET_USER_CONTACTS,
	SEARCH_CONVERSATIONS,
	CLEAR_SEARCH_CONVERSATIONS,
	UPDATE_CONVERSATION,
	ARCHIVE_CONVERSATION,
	GET_USER_GUARDIAN,
	FLAG_MESSAGE,

	STAGE_CONVERSATION,
	SUBMIT_CONVERSATION_MESSAGE,
	SUBMIT_CONVERSATION,
} from 'actions/conversation';

const INITIAL_STATE = {
	conversations: null,
	conversation: null,
	archived: null,
	messages: [],
	guardians: [],
	contacts: null,
	staged: null,
	new: null,
	searchResults: null,
	referenceConversations: null,
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_CONVERSATIONS:
			return { ...state, conversations: action.payload };

		case GET_CONVERSATIONS_REFERENCE:
			return { ...state, referenceConversations: action.payload };

		case GET_CONVERSATION:
			return { ...state, conversation: action.payload };

		case GET_ARCHIVED_CONVERSATIONS:
			return { ...state, archived: action.payload };

		case GET_CONVERSATION_MESSAGES:
			if (action.payload != null && action.payload.length > 0) {
				return { ...state, messages: state.messages.unshift(...action.payload) };
			}

			return state;

		case SUBMIT_CONVERSATION_MESSAGE:
			if (state.conversation != null) {
				let conversationMessage = { ...state.conversation };
				conversationMessage.messages.push(action.payload);

				return { ...state, conversation: conversationMessage };
			}

			return state;

		case GET_USER_CONTACTS:
			return { ...state, contacts: action.payload };

		case SEARCH_CONVERSATIONS:
			return { ...state, searchResults: action.payload };

		case CLEAR_SEARCH_CONVERSATIONS:
			return { ...state, searchResults: INITIAL_STATE.searchResults };

		case STAGE_CONVERSATION:
			return { ...state, staged: action.payload };

		case SUBMIT_CONVERSATION:
			return { ...state, new: action.payload };

		case UPDATE_CONVERSATION:
			let update = { ...state.conversations };
			let found = update.findIndex(conversation => {
				return conversation.id == action.payload.id;
			});

			if (found != null) {
				update.splice(found, 1, action.payload);
				return { ...state, conversations: update };
			}

			return state;

		case ARCHIVE_CONVERSATION:
			let remove = [...state.conversations];
			let archived = [...(state.archived ?? [])];
			let foundConversation = remove.findIndex(conversation => {
				return conversation.id == action.payload.id;
			});

			if (archived == null) {
				archived = [];
			}

			if (foundConversation != null) {
				remove.splice(foundConversation, 1);
				archived.push(action.payload);

				return { ...state, conversations: remove, archived: archived };
			}

			return state;

		case RESET_CONVERSATION:
			return { ...state, conversation: INITIAL_STATE.conversation };

		case FLAG_MESSAGE:
			if (state.conversation != null) {
				if (state.conversation.messages != null && state.conversation.messages.length > 0) {
					let conversation = { ...state.conversation };
					let foundMessage = conversation.messages.findIndex(message => {
						return message.id == action.payload.messageId;
					});

					if (foundMessage > -1) {
						let newMessage = { ...conversation.messages[foundMessage] };
						newMessage.flagID = action.payload.flagID;
						newMessage.text = '';

						conversation.messages.splice(foundMessage, 1, newMessage);
						return { ...state, conversation: conversation };
					}
				}
			}

			return state;

		case GET_USER_GUARDIAN:
			let currentGuardians = state.guardians;
			const foundInGuardians = currentGuardians.findIndex(userGuardians => {
				return userGuardians.userId == action.payload.userId;
			});

			if (foundInGuardians > -1) {
				currentGuardians.splice(foundInGuardians, 1, action.payload);
			} else {
				currentGuardians.push(action.payload);
			}

			return { ...state, guardians: currentGuardians };

		default:
			return state;
	}
}
