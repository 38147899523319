import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import api from "lib/api";
import * as microsoftTeams from "@microsoft/teams-js";

import DateTime from "_class/DateTime";
import User from "_class/User";

import {
	createAssessments,
	updateAssessment,
	getAssignmentAssessment,
	updateAssessmentStatus,
} from "actions/assessments";

import {
	createAssessmentBlock,
	updateAssessmentBlock,
	getBlocksByReference,
	clearBlocksOnReference,
	deleteBlock,
} from 'actions/blocks';

import { addError } from "actions";
import { getMicrosoftAssignmentDetails, getAssignmentSection, updateBulkAssignmentObject } from "actions/assignments";
import { setPageActions, setPageTitle } from "actions/header";
import { getTeamChannels } from "actions/sections";
import { getReaderParameters } from "actions/user";

import { trackImmersiveReaderError } from "helpers/insights";
import { getDescription } from "helpers/content";
import { generateMicrosoftAssignmentDeepLink } from "helpers/teams";
import { getRootUrl } from "helpers/url";

import Message from "../Partials/Message";
import Bookmark from '../Partials/Bookmark';

import AssessmentsConsumer from 'components/BlockAssessments/Consumer';
import SimpleSelect from "components/Inputs/SimpleSelect";
import PostContent from "components/Presentation/PostContent";
import KnowledgeRequirements from "containers/Forms/AssignmentForm/KnowledgeRequirements";
import Modal from "containers/Modals/Modal";

import { DataList, Skeleton } from "UI";
import RowItem, { RowCell } from "UI/Elements/List/RowItem";
import { Immersive_Reader } from "UI/Icons";
import { Block as ContentBlock, Button, translate } from "@haldor/ui";
import AssessmentForm from "containers/Forms/AssessmentForm";
import DisplayName from "components/Presentation/DisplayName";

const ImmersiveReader = require("@microsoft/immersive-reader-sdk");

class MicrosoftAssignment extends Component {

	constructor(props) {
		super(props);

		this.state = {
			groupId: null,
			assessmentOpen: false,
			formModal: false,
			loadingDetails: true,
			loadingAssessments: false,
			isMobileOrTablet: false,
		};
	}

	isFrame() {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	}

	onFormToggle = () => {
		this.setState({ formModal: !this.state.formModal });
	};

	onFormSubmit = (values) => {
		let assignmentId = this.props.assignment.id;
		if (this.props.assignment.externalID != null) { //Assignment might be deleted in Teams. We need to fetch by Haldor Assignment Object Type
			assignmentId = this.props.assignment.externalID;
		}

		return new Promise((resolve) => {
			let startDate = this.props.assignment.assignedDateTime;
			let assessmentBlocks = [];
			let deletedBlocks = [];
			if (startDate == null) {
				startDate = this.props.assignment.assignDateTime;
			}

			if (values.assessmentBlocks != null) {
				assessmentBlocks = [...values.assessmentBlocks];
			}

			if (this.props.blocks) {
				deletedBlocks = [...this.props.blocks].filter((block) =>
					!values.assessmentBlocks.find(_bl => _bl.id == block.id)
				);
			}

			values.startDate = startDate;
			delete values.assessmentBlocks;

			// PUT the values here
			api.put("assignments", values).then(async () => {
				let promises = [];
				await this.props.getMicrosoftAssignmentDetails(assignmentId);

				if (this.props.details != null) {
					assessmentBlocks.forEach((block) => {
						promises.push(new Promise(async (resolve) => {
							const initialBlock = this.props.blocks?.find((b) => b.id == block.id);

							if (initialBlock != null) {
								await this.props.updateAssessmentBlock(block, initialBlock);
							} else {
								await this.props.createAssessmentBlock(block, this.props.details.id, 'ASSIGNMENT');
							}

							resolve(1);
						}));
					});
				}

				if (deletedBlocks.length > 0) {
					deletedBlocks.forEach(block => {
						let deletePromise = new Promise(resolve => {
							this.props.deleteBlock(block.id).then(() => {
								resolve(1);
							})
						});

						promises.push(deletePromise);
					})
				}

				await Promise.all(promises);

				this.props.updateBulkAssignmentObject(assignmentId, true);

				this.onFormToggle();

				resolve(1);

				if (this.props.details != null) {
					this.props.getBlocksByReference(this.props.details.id, 'ASSIGNMENT');
				}
			});
		});
	};

	runImmersiveReader = async () => {
		const { assignment, currentUser } = this.props;
		const localLanguage = localStorage.getItem("language");

		const data = {
			title: assignment.displayName,
			chunks: [
				{
					content: "<h1>" + assignment.displayName + "</h1>",
					mimeType: "text/html",
				},
				{
					content: getDescription(assignment.instructions.content),
					mimeType: "text/html",
				},
			],
		};

		await this.props.getReaderParameters();
		const { reader } = this.props;

		ImmersiveReader.launchAsync(reader.token, reader.subdomain, data, {
			uiLang: localLanguage, // Set immersive reader UI language to selected lang in Haldor Edu
		}).catch((error) => {
			trackImmersiveReaderError(error, currentUser.id);
		});
	};

	isMobileOrTablet() {
		const userAgent = navigator.userAgent || navigator.vendor;

		// Control android-devices
		if (/android/i.test(userAgent)) {
			return true;
		}
		// Control (iPad, iPhone, iPod)
		if (/iPad|iPhone|iPod/.test(userAgent) && !(navigator.userAgent.includes("Windows") && navigator.userAgent.includes("Touch"))) {
			return true;
		}

		return false;
	};

	componentDidMount = () => {
		this.loadData();

		if (this.isMobileOrTablet()) {
			this.setState({ isMobileOrTablet: true });
		}
	};

	componentWillUnmount = () => {
		ImmersiveReader.close();
	}

	loadData = () => {
		const group = this.props.groups.find((group) => {
			return group.graphId == this.props.assignment.classId;
		});

		let groupId = 0;
		let assignmentId = this.props.assignment.id;

		if (group != null) {
			groupId = group.id;
			this.props.getAssignmentSection(group.id);
		} else {
			if (this.props.assignment.sectionId != null) {
				groupId = this.props.assignment.sectionId;
				this.props.getAssignmentSection(this.props.assignment.sectionId);
			}
		}

		if (this.props.assignment.externalID != null) { //Assignment might be deleted in Teams. We need to fetch by Haldor Assignment Object Type
			assignmentId = this.props.assignment.externalID;
		}

		this.setState({ groupId: groupId, loadingAssessments: true });

		this.props.getMicrosoftAssignmentDetails(assignmentId).then(() => {
			if (this.props.details != null) {
				this.setActions();
				this.props.getBlocksByReference(this.props.details.id, 'ASSIGNMENT');
				this.props.getAssignmentAssessment(this.props.details.id).then(() => {
					this.setState({ loadingAssessments: false, loadingDetails: false });
				});
			} else {
				this.setState({ loadingAssessments: false, loadingDetails: false });
			}
		});

		this.setActions();
	};

	setActions = () => {
		const currentUser = new User(this.props.currentUser);
		if (this.props.assignment != null) {
			if (this.props.assignment.displayName != null) {
				this.props.setPageTitle(this.props.assignment.displayName);
			} else if (this.props.assignment.title != null) {
				this.props.setPageTitle(this.props.assignment.title);
			}

			let actions = [];

			if (!currentUser.isStudent() && this.props.services.microsoft && this.props.details != null && this.props.assignment.status != "ASSIGNMENT_DELETED") {
				actions.push({
					type: "button",
					onClick: this.onFormToggle,
					value: this.props.translate("edit-knowledge-requirements"),
				});
			}


			if (this.isFrame() || this.state.isMobileOrTablet) {
				microsoftTeams.app.getContext().then((context) => {
					if (context.team) {
						actions.push({
							type: "button",
							value: this.props.translate("open-workspace"),
							onClick: this.onTeamsClick,
						});

						this.props.setPageActions(actions);
					} else {
						this.props.getTeamChannels(this.props.assignment.classId).then(() => {
							var generalChannel = this.props.teamChannels.find(t => t.displayName == "General");
							if (generalChannel != null) {

								this.setState({ generalChannel });

								actions.push({
									type: "button",
									value: this.props.translate("open-workspace"),
									onClick: this.onTeamsClick,
								});
							}
							this.props.setPageActions(actions);
						});
					}
				});
			} else {
				this.props.setPageActions(actions);
			}
		}
	};

	onTeamsClick = (e) => {
		const { assignment } = this.props;

		microsoftTeams.app.getContext().then((context) => {
			let groupId = context.team.groupId;
			let channelId = context.channel.id;

			if (this.state.generalChannel != null) {
				channelId = this.state.generalChannel.id;
				groupId = this.props.assignment.classId;
			}

			const link = generateMicrosoftAssignmentDeepLink(assignment.id, groupId, channelId);
			microsoftTeams.app.openLink(link);
		});
	};

	closeAssessment = () => {
		this.setState({ assessmentOpen: false, loadingDetails: true }, () => {
			this.loadData();
		});
	};

	onAssessmentSubmit = (values) => new Promise((resolve) => {
		const students = this.filterStudentList();

		values.externalId = this.props.details.externalID;

		if (values.id != null) {
			const existing = this.props.assessments.find((assessment) => assessment.id == values.id);
			this.props.updateAssessment(values, existing).then(() => {
				resolve(1);
				this.closeAssessment();
			});

			return true;
		}

		const selectedUsers = students.filter(t => t.selected);
		let data = [];

		selectedUsers.forEach((user) => {
			data.push({
				...values,
				studentId: user.id,
			});
		});

		this.props.createAssessments(data).then(() => {
			resolve(1);
			this.closeAssessment();
		});
	})

	renderModals(students) {
		if (this.state.loadingDetails) {
			return null;
		}

		const student = students?.find((student) => student.selected);
		if (student == null) {
			return null;
		}

		const assessment = this.props.assessments.find((t) => t.studentId == student.id);
		let assessmentBlocks = [];

		if (this.props.blocks != null) {
			assessmentBlocks = [...this.props.blocks].filter((block) =>
				block.type == 'Haldor.Blocks.AssessmentBlock' && block.resources.length > 0
			);
		}

		return (
			<div>
				<Modal
					isOpen={this.state.assessmentOpen}
					onClose={this.closeAssessment}
					title={this.props.translate("assessment")}
				>
					<AssessmentForm
						onClose={this.closeAssessment}
						onSubmit={this.onAssessmentSubmit}
						blocks={assessmentBlocks}
						initialValues={assessment}

						items={[student].map((student) => ({
							firstname: student.firstName,
							lastname: student.lastName,
							...student
						}))}
						referenceId={this.props.details.id}
						referenceType="TeamsAssignment"
						section={this.props.group}
					/>
				</Modal>
			</div>
		);
	}

	onAssessmentStatusSelect = (item, value) => {
		const assessment = this.props.assessments.find((t) => t.studentId == item.id);
		if (assessment != null) {
			this.props.updateAssessmentStatus(assessment.id, value.value).then(() => {
				this.props.addError(this.props.translate("changes-saved"), "info");
			});
		}
	};

	renderStudentRow = (student) => {
		const { assignment, translate } = this.props;
		const user = new User(student);
		const assessment = this.props.assessments.find((t) => t.studentId == student.id);
		var submission = this.props.assignment.submissions.find((t) => t.recipient.userId == student.id);

		let assignmentId = assignment.id;
		if (assignment.externalID != null) { //Assignment might be deleted in Teams. We need to fetch by Haldor Assignment Object Type
			assignmentId = assignment.externalID;
		}

		return (
			<RowItem key={student.id}>
				<RowCell title={this.props.translate("name")}>
					<DisplayName
						firstName={student.firstName}
						lastName={student.lastName}
						email={student.email}
						data={this.filterStudentList()}
					/>
				</RowCell>

				{submission != null ?
					<RowCell shrink title={this.props.translate("Work status")}>
						{translate("microsoft-assignment-" + submission.status)}
					</RowCell>
					: null}

				{!user.isStudent() ?
					<RowCell title={this.props.translate("assessment-status")}>
						{submission != null && (submission.status == "submitted" || submission.status == "returned" || submission.status == "reassigned") && assessment != null && !this.state.loadingAssessments ? (
							<SimpleSelect
								selectedValue={assessment.status}
								name="assessment-status"
								item={student}
								type="assessment"
								onChange={this.onAssessmentStatusSelect}
							/>
						) : null}
					</RowCell>
					: null}

				<RowCell shrink title=" ">
					{submission != null ?
						<Link
							to={`${getRootUrl()}assignment/${assignmentId}/submission/${submission.id}/documents`}
						>
							{translate("view-files")}
						</Link>
						: null}
				</RowCell>

				{!user.isStudent() ?
					<RowCell shrink title={translate("assess")}>
						{submission != null && (submission.status == "submitted" || submission.status == "returned" || submission.status == "reassigned") ?
							<Button
								onClick={() => {
									student.selected = true;
									this.setState({ assessmentOpen: true });
								}}
								type="secondary"
							>
								{translate("assess")}
							</Button>
							:
							<span></span>
						}
					</RowCell>
					: null}
			</RowItem>
		);
	};

	filterStudentList = () => {
		const { group, assignment } = this.props;
		const currentUser = new User(this.props.currentUser);
		let students = [];

		if (assignment == null) {
			return;
		}

		if (assignment.submissions != null && assignment.submissions.length > 0) {
			if (group != null && currentUser.isTeacher()) {
				group.students.forEach((groupStudent) => {
					let student = assignment.submissions.find((submission) =>
						submission.recipient.userId == groupStudent.id
					);

					if (student != null) {
						students.push(groupStudent);
					}
				});
			}
			return students.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""));
		}

		if (assignment.assignTo == null || assignment.assignTo.recipients == null) {
			return;
		}

		if (group != null && currentUser.isTeacher()) {
			group.students.forEach((groupStudent) => {
				if (this.props.assignment.assignTo == null || this.props.assignment.assignTo.recipients == null) {
					return;
				}

				let student = this.props.assignment.assignTo.recipients.find((assignmentStudentId) =>
					assignmentStudentId == groupStudent.id
				);

				if (student != null) {
					students.push(groupStudent);
				}
			});
		}
		return students.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""));
	};

	renderDescription = (assignment) => {
		if (assignment.instructions != null) {
			return assignment.instructions.content;
		} else if (assignment.description != null) {
			return assignment.description;
		}

		return "";
	}

	renderDate = (assignment) => {
		if (assignment.assignDateTime != null) {
			return (
				<div>
					{new DateTime(assignment.assignDateTime).getShortDate(true)}
				</div>
			);
		} else if (assignment.assignedDateTime != null) {
			return (
				<div>
					{new DateTime(assignment.assignedDateTime).getShortDate(true)}
				</div>
			)
		} else if (assignment.startDate != null) {
			return (
				<div>
					{new DateTime(assignment.startDate).getShortDate(true)}
				</div>
			)
		}

		return "";
	}

	renderDueDate = (assignment) => {
		if (assignment.dueDateTime != null) {
			return new DateTime(assignment.dueDateTime).getShortDate(true);
		} else if (assignment.dueDate != null) {
			return new DateTime(assignment.dueDate).getShortDate(true);
		}

		return "";
	}

	render() {
		const { assignment, translate, group } = this.props;
		const currentUser = new User(this.props.currentUser);
		const description = this.renderDescription(assignment);

		let assessment = null;
		let assessmentBlocks = [];
		let students = this.filterStudentList();

		if (this.props.blocks != null) {
			assessmentBlocks = [...this.props.blocks].filter((block) =>
				block.type == 'Haldor.Blocks.AssessmentBlock' && block.resources.length > 0
			);
		}

		if (currentUser.isStudent() && this.props.assessments.length > 0) {
			assessment = this.props.assessments[0];
		}

		return (
			<div className="single-task">
				{this.renderModals(students)}

				<Modal
					isOpen={this.state.formModal}
					title={translate("connect-knowledge-requirements")}
					onClose={this.onFormToggle}
				>
					<KnowledgeRequirements
						group={group}
						onSubmit={this.onFormSubmit}
					/>
				</Modal>

				<div className="single-section form left-side">
					{!this.state.loadingDetails ?
						<ContentBlock>
							{description != '' ?
								<div>
									{this.props.services.immersiveReaderAssignments ?
										<Link to="#" onClick={this.runImmersiveReader}>
											<div className="reader trigger">
												<Immersive_Reader />
												{this.props.translate("immersive-reader")}
											</div>
										</Link>
										: null}

									{this.props.details != null ?
										<div style={{ float: 'right' }}>
											<Bookmark
												referenceType="ASSIGNMENT"
												referenceId={this.props.details.id}
											/>
										</div>
										: null}

									<PostContent>
										{description}
									</PostContent>
								</div>
								: null}

							{assignment.status == "ASSIGNMENT_DELETED" ?
								<div style={{ marginTop: "1rem" }}>
									<span style={{ color: 'red', fontWeight: 'bold' }} >{this.props.translate('teams-assignment-deleted')}</span>
								</div>
								: null}

							<div style={{ marginTop: "1rem" }}>
								<span className="title">
									{translate("start")}
								</span>

								{this.renderDate(assignment)}
							</div>

							<div style={{ marginTop: "1rem" }}>
								<span className="title">
									{translate("end")}
								</span>

								<div>
									{this.renderDueDate(assignment)}
								</div>
							</div>

							{group != null ?
								<div style={{ marginTop: "1rem" }}>
									<span className="title">
										{translate("section")}
									</span>

									{group.title}
								</div>
								: null}
						</ContentBlock>
						:
						<Skeleton />
					}

					{currentUser.isStudent() && this.props.services.conversations ?
						<div>
							<Message assignment={assignment} />
						</div>
						: null}
				</div>

				<div className="clearfix"></div>

				{assessmentBlocks.length > 0 ?
					<div className="single-section">
						<AssessmentsConsumer
							fields={assessmentBlocks}
							assessment={assessment}
							isStudent={currentUser.isStudent()}
						/>
					</div>
					: null}

				{currentUser.isTeacher() && !this.state.loadingDetails ?
					<div className="single-section">
						<DataList
							title={translate("students")}
							data={group != null ? students : []}
							renderRow={this.renderStudentRow}
						/>
					</div>
					: null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		blocks: state.Blocks.reference,
		services: state.Services.availableServices,
		group: state.assignments.section,
		groups: state.sections.educationGroups,
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		assessments: state.assessments.assessments,
		details: state.assignments.teamsAssignmentDetails,
		reader: state.user.reader,
		teamChannels: state.sections.teamChannels
	};
}

export default connect(mapStateToProps, {
	getAssignmentSection,
	getMicrosoftAssignmentDetails,
	getAssignmentAssessment,
	addError,
	updateAssessmentStatus,
	setPageTitle,
	setPageActions,
	getReaderParameters,
	getTeamChannels,
	createAssessments,
	updateAssessment,

	getBlocksByReference,
	clearBlocksOnReference,
	createAssessmentBlock,
	updateAssessmentBlock,
	deleteBlock,
	updateBulkAssignmentObject
})(MicrosoftAssignment);