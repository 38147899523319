import { Flex } from '@haldor/ui';
import {
	archiveConversation,
	clearSearch,
	getArchivedConversations,
	getConversations,
	searchConversation,
	stageConversation
} from 'actions/conversation';
import { getUserNotificationSettings } from 'actions/user';

import Redirect from 'components/Redirect/Redirect';
import Modal from 'containers/Modals/Modal';
import Moment from 'moment';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import swal from 'sweetalert2';
import { Collapsible, Person } from 'UI';
import { Search } from 'UI/Inputs';
import { Icon, Button } from '@haldor/ui';
import LoadingCards from 'components/Cards/LoadingCard';
import User from '_class/User';
import '../Conversation.scss';
import SelectRecipientForm from '../Form/SelectRecipient';
import { getConversationParticipants } from '../Helpers';
import { setPageTitle, setPageActions } from 'actions/header';
import NotificationOptionForm from 'containers/Forms/NotificationOptionForm';


class ConversationsPage extends Component {

	/* Class setup */
	constructor(props) {
		super(props);

		this.state = {
			modal: false,
			searching: false,
			searchView: false,
			loading: false,
			loadingArchievedConversation: false,
			optionsModalVisible: false,
			user: new User(this.props.user)
		};
	}

	/* Lifecycle events */
	UNSAFE_componentWillMount = async () => {
		this.setState({ loading: true })
		this.props.getConversations().then(() => {
			this.setState({ loading: false })
		});

		this.props.setPageTitle(this.props.translate('messages'));

		let headerButtons = [];

		if (this.state.user.isMentor() || this.state.user.isTeacher() || this.state.user.isStudent()) {
			headerButtons.push({
				value: this.props.translate('Write new'),
				onClick: this.toggleModal,
				type: 'button',
				icon: 'plus',
			});
		}

		if (this.state.user.isMentor() || this.state.user.isTeacher()) {
			await this.props.getUserNotificationSettings();

			if (this.props.userNotificationSettings != null && this.props.userNotificationSettings.length > 0) {
				headerButtons.push({
					value: this.props.translate('Options'),
					type: 'button',
					onClick: this.toggleOptionModal,
					icon: 'cog_bw',
				});
			}
		}

		this.props.setPageActions(headerButtons);
	}

	componentWillUnmount = () => {
		this.props.clearSearch();
	}

	toggleOptionModal = () => {
		this.setState({ optionsModalVisible: !this.state.optionsModalVisible });
	}


	closeOptionsModal = () => {
		this.setState({ optionsModalVisible: false });
	}

	/* Events */
	toggleModal = () => {
		this.setState({ modal: !this.state.modal });
	}

	onSelectRecipients = (values) => {
		return new Promise(() => {
			// Create conversation API request hereee
			let conversation = {
				title: '',
				status: 'ACTIVE',
				memberships: values.memberships,
				messages: [],
				relationships: values.relationships,
				id: 'new',
			};

			this.props.stageConversation(conversation)
				.then(() => {
					this.setState({ modal: false });
					this.props.history.push(`/conversations/new`);
				});
		});
	}

	onSearch = (query) => {
		if (query.query.length == 0) {
			this.props.clearSearch();
			this.setState({ searchView: false });
			this.props.setPageTitle(this.props.translate('conversations'));
			return;
		}

		if (query.query.length < 2) {
			return;
		}

		this.setState({ searching: true, searchView: true });
		this.props.searchConversation(query.query).then(() => {
			this.props.setPageTitle(this.props.translate('search-result'));
			this.setState({ searching: false });
		});
	}

	archiveConversation = (event, conversation) => {
		event.preventDefault();
		event.stopPropagation();

		if (conversation == null) {
			return;
		}

		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('conversation-archive-message'),
			showCancelButton: true,
			cancelButtonText: this.props.translate('Cancel'),
			confirmButtonText: this.props.translate('Remove'),
		}).then(response => {
			if (response.value != null) {
				this.props.archiveConversation(conversation);
			}
		});
	}

	/* Render methods */
	renderConversation = (conversation, index) => {
		const participants = getConversationParticipants(conversation, this.props.user);
		const user = new User(this.props.user);
		let message = null;
		let timeOfLastMessage = null;
		if (conversation.messages.length > 0 && conversation.messages[conversation.messages.length - 1] != null) {
			let lastMessage = conversation.messages[conversation.messages.length - 1];
			timeOfLastMessage = lastMessage.created;
			if (lastMessage.flagID != null) {
				message = <span style={{ fontStyle: 'italic' }}>
					{this.props.translate('Message is reported as inappropriate.')}
				</span>
			} else {
				message = lastMessage.text.replace(/(<([^>]+)>)/ig, '');
				if (message.length > 155) {
					message = message.substring(0, 155) + '...';
				}
			}
		}
		return <Link key={'conversation' + index} to={`/conversations/${conversation.id}`} className="conversation-container">
			<div className="participants">
				<div>
					{participants.map((participant, index) => {
						
						if (participant.membershipType == 'SECTION_OWNERS') {
							return <Person
								person={{
									firstName: participant.title + ' (' + this.props.translate('Staff') + ')',
									lastName: '',
									...participant
								}}
								key={participant.id}
							/>
						}

						if (participant != null) {
							if (participant.membershipType == 'GUARDIAN') {
								if (participant?.userId === undefined) {
									participant.lastName = ' (' + this.props.translate('inactive') + ' ' + this.props.translate('guardian') + ')';
								} else {
									participant.lastName = (participant.lastName != null ? participant.lastName : '') + ' (' + this.props.translate('Guardian') + ')';
								}
							}

							return <Person person={participant} key={'person-' + index + '-' + participant.userId} showEmail={true}/>

						}
					})}
				</div>

				{conversation.status.toUpperCase() == 'ACTIVE' && !user.isStudent() ?
					<div>
						<span onClick={(e) => this.archiveConversation(e, conversation)}>
							<Icon name="Bin" />
						</span>
					</div>
					: null}
			</div>

			<div>
				<div className="latest-message">
					{conversation.unreadMessages > 0 ?
						<span className="badge" style={{ display: 'inline-flex', transform: 'none', position: 'static', justifyContent: 'center', borderRadius: '100%', marginRight: '0.4rem' }}>{conversation.unreadMessages}</span>
						: null}

					{message != null ?
						message
						: null}
				</div>
				{timeOfLastMessage != null ?
					<div className='card-meta' style={{ marginTop: '-1.5rem', float: 'right' }}>{Moment.utc(timeOfLastMessage).local().format('yyyy-MM-DD HH:mm')}</div>
					: null}
			</div>
		</Link >
	}

	async toggleOpen() {
		if (this.props.archived == null) {
			this.setState({ loadingArchievedConversation: true });
			await this.props.getArchivedConversations();
			this.setState({ loadingArchievedConversation: false });
		}
	}

	render() {
		if (!this.props.services.conversations) {
			return <Redirect to="/" />
		}

		let notificationSettings = [];

		if (this.props.userNotificationSettings != null && this.props.userNotificationSettings.length > 0) {
			// Filter the notification settings for "NOTIFICATION_GUARDIANS_MESSAGE"
			notificationSettings = this.props.userNotificationSettings.filter((setting) => {
				return setting.settingName === "NOTIFICATION_GUARDIANS_MESSAGE";
			}).map((setting) => {
				// Set 'enabled' to true if setting.id == 0, because by default it will send notification.
				setting.enabled = setting.id === 0 ? true : setting.enabled;
				return setting;
			});
		}

		return (
			<div className="section" style={{ padding: 0, margin: 0, border: 0 }}>
				<Modal onClose={this.toggleModal} overridePrompt title={this.props.translate('new-message')} isOpen={this.state.modal} type="small">
					<SelectRecipientForm onSubmit={this.onSelectRecipients} />
				</Modal>

				<Modal
					isOpen={this.state.optionsModalVisible}
					onClose={this.closeOptionsModal}
					title={this.props.translate('Options')}
					type="small news"
				>
					<NotificationOptionForm
						onAbort={this.closeOptionsModal}
						values={notificationSettings}
					/>
				</Modal>

				<div className="overview">
					<div className="conversation conversation--wrapper">
						<div className="conversation-list">
							<Flex center space>
								<div style={{ flex: '1' }}>
									<Search
										onSubmit={this.onSearch}
										loading={this.state.searching}
										placeholder={this.props.translate('search-for-conversation')}
										autoSearch
										allowEmpty
									/>
								</div>
							</Flex>
							{this.state.loading ?
								<LoadingCards count={1} />
								:
								this.props.results != null && this.state.searchView ?
									this.props.results.map((conversation, index) => {
										return this.renderConversation(conversation, index);
									})
									:
									this.props.conversations != null ?
										this.props.conversations.map((conversation, index) => {
											return this.renderConversation(conversation, index);
										})
										: null
							}


							<div className="archived-conversations">
								<Collapsible onChange={(open) => this.toggleOpen()} trigger={this.props.translate('Removed')}>
									{this.state.loadingArchievedConversation ? <LoadingCards count={1} /> : null}

									{!this.state.loadingArchievedConversation && this.props.archived != null && this.props.archived.length > 0 ? <div>
										{this.props.archived.map((conversation, index) => {
											return this.renderConversation(conversation, index);
										})}
									</div> : null}
								</Collapsible>
							</div>


						</div>
					</div>
				</div>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		user: state.user.currentUser,
		conversations: state.Conversation.conversations,
		archived: state.Conversation.archived,
		results: state.Conversation.searchResults,
		services: state.Services.availableServices,
		userNotificationSettings: state.user.userNotificationSettings
	}
}

export default withRouter(connect(mapStateToProps, {
	getConversations,
	stageConversation,
	searchConversation,
	clearSearch,
	archiveConversation,
	getArchivedConversations,
	setPageTitle,
	setPageActions,
	getUserNotificationSettings
})(ConversationsPage));