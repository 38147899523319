import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import moment from 'moment';
import Moment from 'moment';

import { PRESCHOOL } from 'constants/schoolTypes';

import { getFileUrl } from 'actions/files';

import {
	clearDocumentsFromStore,
	clearSectionDocumentsFromStore,
	getDocumentsBySectionId,
	getDocumentsPage,
} from 'actions/documents';

import {
	clearPostsFromStore,
	getPostsBySectionId,
	getPostsPage,
	setPostCollectionInfo
} from 'actions/posts';

import { fetch_preschoolgoals } from 'actions/preschoolform';
import { getMentorGroups } from 'actions/sections';
import { setPageTitle, setPageActions } from 'actions/header';

import { getDescription } from 'helpers/content';
import { getParameterByName } from 'helpers/url';

import User from '_class/User';

import LoadingCards from 'components/Cards/LoadingCard';
import Date from 'components/Presentation/Date/Date';
import Documentation from 'containers/Forms/Documentation';
import Post from 'containers/Forms/Post';
import Modal from 'containers/Modals/Modal';
import DatePickerFromTo from 'containers/Forms/Partials/DatePickerFromTo';

import Block, { BlockWrapper } from 'UI/Elements/Block';
import { Icon, TooltipMenu, Button, Radio } from '@haldor/ui';
import FromNow from 'components/Presentation/Date/FromNow';

class AllPosts extends Component {

	constructor(props) {
		super(props);

		this.state = {
			documentModal: false,
			eventModal: false,
			sendAllmodal: false,
			appending: false,
			page: 1,
			groupFilter: 0,
			statusFilter: 'published',
			startDate: moment().subtract('3', 'months').format('YYYY-MM-DD'),
			endDate: moment().format('YYYY-MM-DD'),
			combined: []
		};

		this.collections = [
			{
				type: "POST",
				items: [],
				fetched: 0,
				page: 1,
				fetchPage: this.props.getPostsPage,
			},
			{
				type: "DOCUMENTATION",
				items: [],
				fetched: 0,
				page: 1,
				fetchPage: this.props.getDocumentsPage,
			}
		];

		this.pageSize = 15;
	}

	componentDidMount = async () => {
		const user = new User(this.props.currentUser);
		const isPreschool = user.getActiveSchool().type == PRESCHOOL;
		let actions = [];

		if ((user.isMentor() || user.isAdministrator() || user.isSchoolLeader()) && this.props.mentorGroups.length == 0) {
			this.props.getMentorGroups();
		}

		if (getParameterByName('group') != null) {
			const groupId = parseInt(getParameterByName('group'), 10);
			this.collections[0].fetchPage = this.props.getPostsBySectionId;
			this.collections[1].fetchPage = this.props.getDocumentsBySectionId;
			await this.setState({ groupFilter: groupId });
		}

		await this.props.clearPostsFromStore();
		this.fetchData(this.state.page);

		if (this.props.preschoolGoals.length < 1 && isPreschool) {
			this.props.fetch_preschoolgoals();
		}

		if (!user.isStudent()) {
			actions.push({
				type: 'button',
				value: this.props.translate('Create post'),
				onClick: this.toggleEventModal,
				buttonType: 'primary',
				icon: 'plus',
			});

			if (isPreschool) {
				actions.push({
					type: 'button',
					value: this.props.translate('Create documentation'),
					onClick: this.toggleDocumentModal,
					buttonType: 'primary',
					icon: 'plus',
				});
			}

			if (user.isOperationManager()) {
				actions.push({
					type: 'button',
					value: this.props.translate('Create schoolwide announcement'),
					onClick: this.toggleSendAllModal,
					buttonType: 'primary',
					icon: 'plus',
				});
			}
		}

		this.props.setPageTitle(this.props.translate('preschooldocument-posts'));
		this.props.setPageActions(actions);
	}

	fetchPages = (collections) => {
		const user = new User(this.props.currentUser);
		const { statusFilter, groupFilter } = this.state;
		const isPreschool = user.getActiveSchool().type == PRESCHOOL;
		const start = this.props.userRole === "student" ? null : moment(this.state.startDate).subtract('1', 'days').format('YYYY-MM-DD');
		const end = moment(this.state.endDate).add('1', 'days').format('YYYY-MM-DD');
		const foundInCombined = (obj) => {
			let added = this.state.combined.findIndex(combined => combined.id == obj.id);
			return added == -1;
		}

		return new Promise((resolve) => {
			let promises = [];

			collections.map((collection) => {
				if (collection.items.length <= 0 && collection.page > collection.fetched) {
					if (collection.type == 'DOCUMENTATION' && !isPreschool) {
						return collection;
					}

					let filter = statusFilter;
					if (collection.type == 'DOCUMENTATION' && filter != null) {
						filter = statusFilter == 'published' ? 'DOCUMENTATION_PUBLISHED' : 'DOCUMENTATION_DRAFT';
					}

					collection.fetched = collection.page;
					let promise = null;

					if (groupFilter > 0) {
						promise = collection.fetchPage(groupFilter, collection.page, filter, start, end, this.pageSize);
					} else {
						promise = collection.fetchPage(collection.page, filter, start, end, this.pageSize);
					}

					promises.push(promise);
				}

				return collection;
			});

			Promise.all(promises).then(() => {
				collections.map((collection) => {
					let posts = this.props.posts;
					let documentations = this.props.documentations;

					if (groupFilter > 0) {
						posts = this.props.groupPosts;
						documentations = this.props.groupDocuments;
					}

					switch (collection.type) {
						case "POST":
							collection.items = posts.map(
								(obj) => ({ ...obj, type: 'POST' })
							).filter(foundInCombined);
							return collection;
							break;

						case "DOCUMENTATION":
							if (!isPreschool)
								return null;
							collection.items = documentations.map(
								(obj) => ({ ...obj, type: 'DOCUMENTATION' })
							).filter(foundInCombined);
							return collection;
							break;
					}
				});
				
				resolve(collections);
			});
		});
	}

	paginationSort = (page = 1) => new Promise(async (resolve) => {
		// Offset start and end date by 1 day bc <>
		const sortItems = (a, b) => {
			return Moment(b.published).unix() - Moment(a.published).unix();
		};

		let combined = JSON.parse(JSON.stringify(this.state.combined));
		let collection = [...this.collections[0].items, ...this.collections[1].items].sort(sortItems);

		if (collection.length == 0) {
			this.collections = await this.fetchPages(this.collections);
			collection = [...this.collections[0].items, ...this.collections[1].items].sort(sortItems);
		}

		while (combined.length < (this.pageSize * page)) {
			const item = collection.shift();

			if (item != null) {
				combined.push(item);

				let collectionInfo = this.collections.find((obj) => {
					return obj.type == item.type;
				});

				if (collectionInfo != null) {
					let index = collectionInfo.items.findIndex((obj) => {
						return obj.id == item.id && obj.type == item.type
					});

					if (index > -1) {
						collectionInfo.items.splice(index, 1);
					}

					if (item.type == 'POST') {
						this.collections[0] = collectionInfo;
					} else {
						this.collections[1] = collectionInfo;
					}

					if (collectionInfo.items.length <= 0) {
						//Fetch next page
						collectionInfo.page += 1;
						await this.setState({ combined });
						this.collections = await this.fetchPages(this.collections);
						collection = [...this.collections[0].items, ...this.collections[1].items].sort(sortItems);
					}
				}
			} else {
				break;
			}
		}

		await this.setState({ combined });
		resolve(1);
	})

	fetchData = async (page) => {
		this.setState({ appending: true });
		await this.paginationSort(page);
		this.setState({ appending: false });
	}

	loadMore = async () => {
		const { page } = this.state;

		if (this.state.appending) {
			return false;
		}

		let newPage = page + 1;

		this.setState({ appending: true });
		await this.paginationSort(newPage);
		this.setState({ page: newPage, appending: false });
	}

	resetCollectionProgress = () => {
		this.collections = this.collections.map((collection) => {
			collection.page = 1;
			collection.fetched = 0;
			collection.items = [];
			return collection;
		});
	}

	toggleDocumentModal = () => {
		this.setState({ documentModal: !this.state.documentModal });
	}

	toggleEventModal = () => {
		this.setState({ eventModal: !this.state.eventModal });
	}

	toggleSendAllModal = () => {
		this.setState({ sendAllModal: !this.state.sendAllModal });
	}

	filterOnGroup = async (event) => {
		const groupId = parseInt(event.target.value, 10);

		this.resetCollectionProgress();

		if (groupId < 1) {
			this.collections[0].fetchPage = this.props.getPostsPage;
			this.collections[1].fetchPage = this.props.getDocumentsPage;

			await this.setState({ combined: [], groupFilter: 0, page: 1 });
		} else {
			this.collections[0].fetchPage = this.props.getPostsBySectionId;
			this.collections[1].fetchPage = this.props.getDocumentsBySectionId;

			await this.setState({ combined: [], groupFilter: groupId, page: 1 });
		}

		this.fetchData(1);
	}

	clearGroupFilter = async (event) => {
		event.preventDefault();

		this.resetCollectionProgress();
		this.collections[0].fetchPage = this.props.getPostsPage;
		this.collections[1].fetchPage = this.props.getDocumentsPage;

		let page = 1;

		await this.setState({ combined: [], groupFilter: 0, page });

		this.fetchData(1);
	}

	onFilterDateChange = async (dates) => {
		var changedStartDate = moment(dates.start);
		var changedEndDate = moment(dates.end);

		const { startDate, endDate } = this.state;
		if (changedStartDate.format('YYYY-MM-DD') == startDate && changedEndDate.format('YYYY-MM-DD') == endDate) {
			return false;
		}

		this.resetCollectionProgress();
		await this.setState({
			combined: [],
			startDate: changedStartDate.format('YYYY-MM-DD'),
			endDate: changedEndDate.format('YYYY-MM-DD'),
			page: 1,
		});

		await this.props.clearPostsFromStore();
		this.fetchData(1);
	}

	onStatusFilterChange = async (filter) => {
		if (filter == this.state.statusFilter) {
			return false;
		}

		this.resetCollectionProgress();
		await this.setState({ combined: [], statusFilter: filter, page: 1 });

		await this.props.clearPostsFromStore();
		this.fetchData(1);
	}

	renderContent = () => {
		const { combined } = this.state;

		if (combined.length > 0) {
			return <BlockWrapper cols={3}>
				{combined != null ?
					combined.map((document) => {
						return this.renderDocument(document)
					})
					: null}
			</BlockWrapper>;
		}

		if (this.state.appending) {
			return null;
		}

		return (
			<h3 style={{ textAlign: 'center' }}>
				{this.props.translate('no-items-found')}
			</h3>
		);
	}

	headerContent = (document) => {
		return (
			<div>
				<div style={{ float: 'left' }}>
					{document.author != null ?
						typeof document.author == 'string' ?
							document.author
							:
							`${document.author.firstName != null ? document.author.firstName : ''} ${document.author.lastName != null ? document.author.lastName : ''}`
						: ' '}
				</div>

				<div style={{ float: 'right' }}>
					{document.published != null ?
						moment.utc(document.published).isAfter(moment()) ?
							<FromNow>{moment.utc(document.published).local().format()}</FromNow>
							:
							<Date>{moment.utc(document.published).local()}</Date>
						: null}
				</div>

				{document.important && document.eventDate != null ?
					<div style={{ float: 'left' }}>
					</div>
					: null}

				{document.status == 'POST_DRAFT' || document.status == 'DOCUMENTATION_DRAFT' ?
					<div style={{ float: 'right', marginRight: 8 }}>
						<span style={{ color: 'red', fontSize: '0.9em' }}>
							{this.props.translate('Draft')}
						</span>
					</div>
					: null}

				{document.important && document.eventDate != null && moment() <= moment(document.eventDate) ?
					<div style={{ float: 'right' }}>
						<span style={{ color: 'red', fontSize: '0.9em', marginRight: '8px' }}>
							{this.props.translate('important!')}
						</span>
					</div>
					: null}

				<div className="clearfix" />
			</div>
		);
	}

	content = (document) => {
		if (document.type == 'POST') {
			let strippedDescription = "";

			if (document.abbreviatedDescription != null) {
				strippedDescription = getDescription(document.abbreviatedDescription).replace(/(<([^>]+)>)/ig, '');
				if (strippedDescription.length > 99) {
					strippedDescription = strippedDescription.slice(0, 100) + '…';
				}
			}
			return (
				<div>
					<h3>{document.title}</h3>

					<p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
						dangerouslySetInnerHTML={{ __html: strippedDescription }}
					/>
				</div>
			);
		} else {
			if (document.description) {
				let strippedDescription = getDescription(document.description).replace(/(<([^>]+)>)/ig, '');
				if (strippedDescription.length > 99) {
					strippedDescription = strippedDescription.slice(0, 100) + '…';
				}

				return (
					<div>
						<h3>{document.title}</h3>

						<p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
							dangerouslySetInnerHTML={{ __html: strippedDescription }}
						/>
					</div>
				);
			}

			return (
				<div>
					<h3>{document.title}</h3>
				</div>
			);
		}
	}

	footerContent = (document) => {
		const { preschoolGoals } = this.props;

		if (document.associatedGoals && document.associatedGoals.length > 0) {
			let groups = [];

			if (preschoolGoals && preschoolGoals.length > 0) {
				preschoolGoals.map(goal => {
					const inDocument = document.associatedGoals.find(function (goalOb) {
						return goalOb.goalId == goal.id;
					});

					if (typeof (inDocument) != 'undefined') {
						let inArray = groups.findIndex(i => i.name == goal.group);

						if (inArray > -1) {
							return groups[inArray].count++;
						} else {
							let group = {
								'name': goal.group,
								'color': goal.colorCode,
								'count': 1,
							};

							return groups.push(group);
						}
					}
				});
			}

			return (
				<div>
					<div>{this.props.translate('goals')}</div>

					{groups.length > 0 ?
						<div className="goal-preview-container">
							{groups.map(group => {
								return (
									<div key={"goal-" + group.name} className="goal-preview"
										style={{ backgroundColor: group.color }}>
										{group.name} ({group.count})
									</div>
								)
							})}
						</div>
						: null}
				</div>
			);
		} else {
			return null;
		}
	}

	renderDocument = (document) => {
		let link = '';

		if (document.type == 'POST') {
			link = 'post/' + document.id;
		} else {
			link = 'preschooldocument/' + document.id;
		}

		let file = null;
		if (document.associatedFiles != null && document.associatedFiles.length > 0) {
			file = document.associatedFiles.find(file => {
				return file.type && file.type.indexOf('image') > -1;
			})
		}
		if (document.status != 'POST_PUBLISHED') {
			document.published = null;
		}

		return (
			<Block
				key={document.id}
				headerContent={this.headerContent(document)}
				image={file}
				content={this.content(document)}
				footerContent={this.footerContent(document)}
				link={link}
			/>
		);
	}

	renderCreateButton = () => {
		let isPreschool = false;

		if (this.props.currentUser.schools.getActiveSchool(this.props.currentUser.userId).type == PRESCHOOL) {
			isPreschool = true;
		}

		const user = new User(this.props.currentUser);

		if (!user.isStudent() && isPreschool) {
			return (
				<div className="create-container" style={{ display: 'inline-block' }}>
					<TooltipMenu trigger={(
						<Button type="secondary">
							<Icon name="Plus" /> {this.props.translate('Create')}
						</Button>
					)}>
						<TooltipMenu.Item onClick={this.toggleDocumentModal}>
							{this.props.translate('Create documentation')}
						</TooltipMenu.Item>

						<TooltipMenu.Item onClick={this.toggleEventModal}>
							{this.props.translate('Create post')}
						</TooltipMenu.Item>

						{user.isOperationManager() ?
							<TooltipMenu.Item onClick={this.toggleSendAllModal}>
								{this.props.translate('Create schoolwide announcement')}
							</TooltipMenu.Item>
							: null}
					</TooltipMenu>
				</div>
			);
		} else {
			if (!user.isStudent()) {
				if (user.isOperationManager()) {
					return (
						<div className="create-container" style={{ display: 'inline-block' }}>
							<TooltipMenu trigger={(
								<Button type="secondary">
									<Icon name="Plus" /> {this.props.translate('Create')}
								</Button>
							)}>
								<TooltipMenu.Item onClick={this.toggleEventModal}>
									{this.props.translate('Create post')}
								</TooltipMenu.Item>

								<TooltipMenu.Item onClick={this.toggleSendAllModal}>
									{this.props.translate('Create schoolwide announcement')}
								</TooltipMenu.Item>
							</TooltipMenu>
						</div>
					);
				}

				return (
					<Button type="secondary" onClick={this.toggleEventModal}>
						<Icon name="Plus" /> {this.props.translate('Create')}
					</Button>
				);
			}

			return null;
		}
	}

	render() {
		const { appending, groupFilter } = this.state;

		let isPreschool = false;
		const user = new User(this.props.currentUser);

		if (user.getActiveSchool().type == PRESCHOOL) {
			isPreschool = true;
		}

		let sections = [];

		if (user.isMentor() || user.isAdministrator() || user.isSchoolLeader() || user.isOperationManager()) {
			sections = this.props.groups.concat(this.props.mentorGroups);
		} else {
			sections = this.props.groups;
		}

		if (!(user.isStudent() || user.isSchoolLeader() || user.isAdministrator() || user.isOperationManager())) {
			sections = sections.filter(section => {
				return section.userRole === "OWNER";
			});
		}

		return (
			<div className="preschooldocuments">
				{!user.isStudent() && isPreschool ?
					<Modal
						isOpen={this.state.documentModal}
						onClose={this.toggleDocumentModal}
						title={this.props.translate('create-new-documentation')}>
						<Documentation onModalClose={this.toggleDocumentModal} />
					</Modal>
					: null}

				{!user.isStudent() ?
					<Modal
						isOpen={this.state.eventModal}
						onClose={this.toggleEventModal}
						title={this.props.translate('create-new-post')}>
						<Post onModalClose={this.toggleEventModal} />
					</Modal>
					: null}

				{!user.isStudent() ?
					<Modal
						isOpen={this.state.sendAllModal}
						onClose={this.toggleSendAllModal}
						title={this.props.translate('Create schoolwide announcement')}>
						<Post selectMultipleSchools onModalClose={this.toggleEventModal} />
					</Modal>
					: null}

				<div className="section" style={{ margin: 0, padding: 0, border: 0, marginBottom: '1.5rem' }}>
					<div className="section-header form">
						<div className="option-container filter-container">
							<div className="form-row select">
								<label>{this.props.translate('Group')}</label>
								<select value={this.state.groupFilter} onChange={this.filterOnGroup}>
									<option value="0">{this.props.translate('select-an-alternative')}</option>

									{sections != null && sections.map(group => {
										return <option value={group.id} key={group.id}>
											{group.title}
										</option>;
									})}
								</select>
							</div>

							{groupFilter > 0 ?
								<div className="clear-filter-container" onClick={this.clearGroupFilter}>
									<Icon name="Close" /> {this.props.translate('clear-filter')}
								</div>
								: null}
						</div>

						{!user.isStudent() ?
							<div className="filter-container">
								<div className="form-row">
									<DatePickerFromTo
										values={{
											start: this.state.startDate,
											end: this.state.endDate,
										}}
										onChange={this.onFilterDateChange}
									/>
								</div>
							</div>
							: null}

						{!user.isStudent() ?
							<div className="filter-container">
								<div className="form-row">
									<label>{this.props.translate('Status')}</label>

									<div>
										<Radio
											input={{
												onChange: this.onStatusFilterChange,
												value: this.state.statusFilter,
											}}
											disabled={appending}
											optionValue="published"
											label={this.props.translate('Published')}
										/>
									</div>

									<div style={{ marginTop: '.35rem' }}>
										<Radio
											input={{
												onChange: this.onStatusFilterChange,
												value: this.state.statusFilter,
											}}
											disabled={appending}
											optionValue="draft"
											label={this.props.translate('Draft')}
										/>
									</div>
								</div>
							</div>
							: null}
					</div>

					<div className="clearfix" />
				</div>

				<div className="posts-section-container">
					<div className="posts-content">
						{this.renderContent()}

						{appending ?
							<LoadingCards count={3} />
							: null}

						{(this.state.combined.length % this.pageSize < 1 || this.state.combined.length == this.pageSize * this.state.page) && this.state.combined.length > 1 ?
							<div>
								<Button style={{
									margin: '2em auto',
									display: 'block',
								}} type="secondary" onClick={this.loadMore}>
									{this.props.translate('load-more')}
								</Button>
							</div>
							: null}
					</div>

					<div style={{ clear: 'both' }} />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user.user,
		currentUser: state.user.currentUser,
		translate: translate(state.Languages.translations),
		documentations: state.CombinedEvents.documentations,
		posts: state.CombinedEvents.posts,
		preschoolGoals: state.PreschoolGoals.preschoolGoals,
		groups: state.sections.educationGroups,
		mentorGroups: state.sections.mentorGroups,
		groupPosts: state.CombinedEvents.sectionPosts,
		groupDocuments: state.CombinedEvents.sectionDocumentations,
	}
}

export default connect(mapStateToProps, {
	getDocumentsPage,
	getPostsPage,
	getFileUrl,
	fetch_preschoolgoals,
	getPostsBySectionId,
	getDocumentsBySectionId,
	clearSectionDocumentsFromStore,
	clearDocumentsFromStore,
	clearPostsFromStore,
	setPageTitle,
	setPageActions,
	getMentorGroups,
	setPostCollectionInfo
})(AllPosts);