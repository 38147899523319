import * as actions from 'actions/schedule';
import { SUBMIT_ATTENDANCE } from 'actions/absence';

const INITIAL_STATE = {
	scheduleToday: [],
	userSchedule: [],
	usersSchedule: {},
	userActivites: [],
	activeScheduleItem: null,
	schedulePositions: [],
	selectedLessons: [],
	nonOwnerActivities: [],
	studentActivities: [],
	groupActivities: {
		id: null,
		data: [],
		startDate: null,
		endDate: null
	},
	groupSchedules: {
		id: null,
		data: [],
		startDate: null,
		endDate: null
	},
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case actions.SCHEDULE_TODAY:
			return { ...state, scheduleToday: action.payload };

		case actions.CLEAR_SCHEDULE_FROM_STORE:
			return { ...state, scheduleToday: [] };

		case actions.CLEAR_SCHEDULE_ITEM:
			return { ...state, activeScheduleItem: null };

		case actions.CLEAR_SEARCHED_SCHEDULES:
			return { ...state, selectedLessons: [] };
		case actions.GET_SCHEDULE_ITEM:
			return { ...state, activeScheduleItem: action.payload };

		case actions.GET_SCHEDULES_BY_ID:
			return { ...state, selectedLessons: [...state.selectedLessons, action.payload] }
		case actions.GET_SCHEDULE_POSITION_WITH_NO_REPORTED_ATTENDANCE:
			return { ...state, schedulePositions: action.payload };

		case SUBMIT_ATTENDANCE:
			let schedulePositions = state.schedulePositions;
			const scheduleId = action.payload.result;

			let foundIndex = schedulePositions.findIndex((sch) => {
				return sch.id == scheduleId;
			});

			if (foundIndex > -1) {
				schedulePositions.splice(foundIndex, 1);
			}

			return { ...state, schedulePositions };

		case actions.CLEAR_SCHEDULE_POSITION_WITH_NO_REPORTED_ATTENDANCE:
			return { ...state, schedulePositions: [] };
		case action.GET_SCHEDULE_FOR_USERS_REQUEST:
			return;
		case actions.GET_SCHEDULE_FOR_USERS:
			if (state.groupSchedules.id == null || state.groupSchedules.id != action.payload.id) {
				return {
					...state,
					groupSchedules: {
						...state.groupSchedules,
						data: action.payload.documents,
						startDate: action.payload.startDate,
						endDate: action.payload.endDate,
						id: action.payload.id
					}
				};
			}
			else {
				var startDate = state.groupSchedules.startDate < action.payload.startDate ? state.groupSchedules.startDate : action.payload.startDate;
				var endDate = state.groupSchedules.endDate > action.payload.endDate ? state.groupSchedules.endDate : action.payload.endDate;
				var dataArray = state.groupSchedules.data;
				const hasId = (id) => dataArray.some(obj => obj.id === id);
				action.payload.documents.forEach(obj => {
					if (!hasId(obj.id)) {
						dataArray.push(obj);
					}
				});
				return {
					...state,
					groupSchedules: {
						...state.groupSchedules,
						data: dataArray,
						startDate: startDate,
						endDate: endDate,
						id: action.payload.id
					}
				};
			}

		case actions.GET_SCHEDULE_FOR_USER:
			let usersSchedule = state.usersSchedule;
			usersSchedule[action.payload.userId] = action.payload.documents;

			return {
				...state,
				userSchedule: action.payload.documents,
				usersSchedule,
			};
		case actions.GET_ACTIVITIES_BY_ID:
			return {
				...state,
				studentActivities: action.payload.documents,
			};
		case actions.GET_ACTIVITIES_FOR_USER:
			return {
				...state,
				userActivites: action.payload.documents,
			};
		case actions.GET_ACTIVITIES_FOR_GROUPS:
			if (state.groupActivities.id == null || state.groupActivities.id != action.payload.id) {
				return {
					...state,
					groupActivities: {
						...state.groupActivities,
						data: action.payload.documents,
						startDate: action.payload.startDate,
						endDate: action.payload.endDate,
						id: action.payload.id
					}
				};
			}
			else {
				var startDate = state.groupActivities.startDate < action.payload.startDate ? state.groupActivities.startDate : action.payload.startDate;
				var endDate = state.groupActivities.endDate > action.payload.endDate ? state.groupActivities.endDate : action.payload.endDate;
				var dataArray = state.groupActivities.data;
				const hasId = (id) => dataArray.some(obj => obj.id === id);
				action.payload.documents.forEach(obj => {
					if (!hasId(obj.id)) {
						dataArray.push(obj);
					}
				});
				return {
					...state,
					groupActivities: {
						...state.groupActivities,
						data: dataArray,
						startDate: startDate,
						endDate: endDate,
						id: action.payload.id
					}
				};

			}
		case actions.CLEAR_ACTIVITIES_FOR_OTHERS_STORE:
			return {
				...state, groupActivities: [], groupSchedules: {
					...state.groupSchedules,
					id: null,
					data: [],
					startDate: null,
					endDate: null
				}
			};
		default:
			return state;
	}
}
