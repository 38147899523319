import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addError } from 'actions';

import { appInsights } from 'lib/appInsights';
import api from 'lib/api';
import getEnvironment from 'lib/env';
import { getOnBehalfOfAccessToken } from 'actions/user';

import Select from './select';
import { GraphFileBrowser } from '@microsoft/file-browser';
import Modal from 'containers/Modals/Modal';
import OneDrivePicker from 'containers/OneDrivePicker/';

import { Expandable, Spinner } from 'UI';
import { MS_OneDrive } from 'UI/Icons';
import { Button, Icon, translate } from '@haldor/ui';
import { isMicrosoftTeams } from 'helpers/teams';

const modalStyles = {
	content: {
		width: '60%',
		margin: '0 auto',
		marginBottom: '40px',
		padding: '0',
		borderRadius: '7px',
	},
};

class Upload extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false,
			fileTypes: [
				{ value: 1, text: props.translate('instructions') },
				{ value: 2, text: props.translate('template-document') },
			],
			forceUpdate: false,
			newPicker: true,
			graphPickerUrl: 'https://graph.microsoft.com/beta/me',
			activeSelection: 'me',
			pickedItems: [],
			loadingPicker: false,
		};
	}

	componentDidMount = () => { }

	getFile = (file) => {
		return new Promise((resolve) => {
			api.getOneDriveFile('https://graph.microsoft.com/beta/me/drives/' + file.driveItem_203[1] + '/items/' + file.driveItem_203[2], this.props.access_token)
				.then((response) => {
					if (response.data != null && response.data.package != null) {
						if (response.data.package.type != null && response.data.package.type == "oneNote") {
							resolve();
						}
					}

					resolve({
						key: response.data.name,
						value: response.data['@microsoft.graph.downloadUrl'],
						parentReference: response.data['parentReference'],
						id: response.data['id'],
						mimeType: response.data['file'].mimeType,
						fileType: 0,
					});
				});
		})
	}

	getAuthenticationToken = async () => {
		return new Promise(async (resolve) => {
			await this.props.getOnBehalfOfAccessToken(this.props.user.userId, "Files.ReadWrite.All");
			if (this.props.access_token == null) {
				await this.props.getOnBehalfOfAccessToken(this.props.user.userId, "Sites.ReadWrite.All");
			}
			resolve(this.props.access_token);
		});
	}

	launchOneDrivePicker = () => {
		const that = this;

		const odOptions = {
			clientId: getEnvironment().clientId,
			action: 'query',
			multiSelect: true,
			openInNewWindow: true,
			advanced: {
				//redirectUri: `${window.location.origin}/fileInputRedirect.html`,
				queryParameters: 'select=id,name,size,file,folder,photo,webUrl,webDavUrl,location',
			},
			success(files) {
				let pickedItems = [];

				files.value.forEach((file) => {
					const oneDriveFile = { key: file.name, value: file.webDavUrl, fileType: 0 };
					pickedItems.push(oneDriveFile);
				});

				that.setState({ forceUpdate: true, pickedItems }, () => {
					that.props.onUpdate(pickedItems);
				});
			},
			cancel() { },
			error(e) {
				console.log(e);
			},
		};

		OneDrive.open(odOptions);
	}

	selectFileType = (event, file) => {
		file.fileType = event.target.value;

		this.setState({}, () => {
			this.props.onUpdate(this.state.pickedItems);
		});
	}

	onRemove = (file) => {
		let pickedItems = [...this.state.pickedItems].filter(doc =>
			doc.value !== file.value
		);

		this.setState({ pickedItems: pickedItems }, () => {
			this.props.onUpdate(pickedItems);
		});
	}

	filesList = () => {
		const { pickedItems } = this.state;

		let foundDocument = pickedItems.find((file) => {
			if (file.fileType != null) {
				return file.fileType == 0;
			}

			return false;
		});

		let documentsPassing = true;
		if (foundDocument != null) {
			documentsPassing = false;
		}

		if (!pickedItems) {
			return <div />;
		}

		return (
			<div>
				{!documentsPassing && this.props.form == 'assignment' ?
					<div style={{ paddingTop: '1rem' }}>
						{this.props.translate('need-to-select-file-types')}!
					</div>
					: null}

				{pickedItems.map((file, i) => {
					if (file.key != "" && file.key != null) {
						return <div key={file.value} className="attached-file">

							{this.props.lockedStatus && file.fileType === '2' ?
								<div style={{ transform: 'scale(1.1)' }}>
									<Icon name='Lock' />
								</div>

								:
								<Icon name="File" />
							}
							<span className="file-name">{file.key}</span>

							{this.props.form == 'assignment' ?
								<Select name="filetype"
									options={this.state.fileTypes}
									selectedValue={file.fileType}
									onChange={event => this.selectFileType(event, file)}
									placeholder={this.props.translate('choose-filetype')}
								/>
								: null}

							<Button type="secondary" onClick={() => { this.onRemove(file); }}>
								{this.props.translate('Delete')}
							</Button>
						</div>
					}
				})}
			</div>
		);
	}

	onBrowserSuccess = (keys) => {
		let { pickedItems } = this.state;
		let { addError } = this.props;

		this.setState({ loadingPicker: true });

		Promise.all(keys.map((file) => {
			return new Promise((resolve, reject) => {
				this.getFile(file).then(data => {
					resolve(data);
				});
			})
		}
		)).then(selected => {
			selected.map(function (sel) {
				if (sel != null) {
					pickedItems.push(sel);
				} else {
					addError("OneNote files is not allowed", "error");
				}
			});

			// Filter duplicate items with the same key property
			pickedItems = pickedItems.filter((obj, pos, arr) => {
				return arr.map(mapObj => mapObj['key']).indexOf(obj['key']) === pos;
			});

			this.setState({ pickedItems, modalIsOpen: false, loadingPicker: false }, () => {
				this.props.onUpdate(this.state.pickedItems);
			});
		});
	}

	onGroupSelect = (group) => {
		this.setState({ graphPickerUrl: 'https://graph.microsoft.com/beta/groups/' + group.graphId, newPicker: null, activeSelection: group.graphId }, () => {
			this.setState({ newPicker: true });
		});
	}

	onOneDrivePickerChange = (files) => {
		let parsedFiles = [];

		files.map(file => {
			if (file.url != null) {
				let add = {
					key: file.name,
					value: file.url,
					markedForDeletion: false,
					fileType: 0,
				};

				parsedFiles.push(add);
			}
		});

		this.setState({ pickedItems: parsedFiles }, () => {
			this.props.onUpdate(this.state.pickedItems);
		});
	}

	onGoOneDrive = () => {
		this.setState({ graphPickerUrl: 'https://graph.microsoft.com/beta/me/', newPicker: null, activeSelection: 'me' }, () => {
			this.setState({ newPicker: true });
		});
	}

	openModal = () => {
		this.setState({ modalIsOpen: true }, () => {
			this.props.onUpdate([]);
		});
		this.hideNewMenuItem();
	}

	hideNewMenuItem = () => {
		var i = 0;
		var interval = setInterval(function () {
			var newBtn = document.getElementsByName("New");
			if (++i == 10 || newBtn.length > 0) {
				if (newBtn[0].style.display !== 'none') {
					newBtn[0].style.display = 'none';
				}
			}
		}, 100)
	}

	closeModal = () => {
		this.setState({ modalIsOpen: false });
	}

	render() {
		let meClassname = 'section-item';
		if (this.state.activeSelection == 'me') {
			meClassname = meClassname + ' active';
		}

		return (
			<div className="upload-wrapper" key="upload-wrapper-picker">
				{ /* New file picker (Needs reconsent) */}
				{this.state.newPicker == true ?
					<Modal isOpen={this.state.modalIsOpen} type="file-picker" onClose={this.closeModal} style={modalStyles}>
						{this.state.loadingPicker ?
							<div className="is_sending"><p><span className="loading-spinner" /></p></div>
							: null}

						<h3 style={{ padding: '0.5rem 0' }}>{this.props.translate('places')}</h3>

						<div style={{ marginBottom: '1.5rem' }}>
							<div onClick={this.onGoOneDrive} className={meClassname}>
								My OneDrive
							</div>

							{this.props.groups.map(group => {
								let classname = 'section-item';
								if (this.state.activeSelection == group.graphId) {
									classname = classname + ' active';
								}

								return <div key={'group-' + group.graphId} className={classname} onClick={e => { this.onGroupSelect(group) }}>
									{group.title}
								</div>
							})}
						</div>

						<div className="ms-graph-file-picker">
							<GraphFileBrowser
								getAuthenticationToken={this.getAuthenticationToken}
								itemMode="multiple"
								endpoint={this.state.graphPickerUrl}
								onSuccess={this.onBrowserSuccess}
								onCancel={this.closeModal}
								key="upload-wrapper-new_picker-element"
							/>
						</div>
					</Modal>
					: null}

				{ /* TEAMS */}
				{isMicrosoftTeams() ?
					<Expandable title={this.props.translate('attach-files')}>
						{this.state.newPicker == null ?
							<Spinner small center />
							:
							<div>
								{ /* Old file picker ( inline for teams ) */}
								{!this.state.newPicker ?
									<div>
										<OneDrivePicker onChange={this.onOneDrivePickerChange} />

										<div className="upload-wrapper">
											{this.state.pickedItems.length > 0 ?
												<div className="upload-container">
													<label>{this.props.translate('attached-files')}</label>

													<div className="selected-files">
														{this.filesList()}
													</div>
												</div>
												: null}
										</div>
									</div>
									: null}

								{ /* New file picker */}
								{this.state.newPicker ?
									<div id="upload" className="upload-container">
										<Button type="secondary" onClick={(e) => { e.preventDefault(); this.openModal(); }}>
											{this.props.translate('open-sharepoint-onedrive')}
										</Button>

										<div className="selected-files">
											{this.filesList()}
										</div>
									</div>
									: null}
							</div>
						}
					</Expandable>
					: null}

				{ /* NOT TEAMS */}
				{!isMicrosoftTeams() ?
					<Expandable title={this.props.translate('attach-files')}>
						{this.state.newPicker == null ?
							<Spinner small center />
							:
							<div>
								{this.props.isLoading ?
									<div className="is_sending"><p><span className="loading-spinner" /></p></div>
									: null}

								{ /* New file picker */}
								{this.state.newPicker ?
									<div id="upload" className="upload-container">
										<Button type="secondary" onClick={(e) => { e.preventDefault(); this.openModal(); }}>
											{this.props.translate('open-sharepoint-onedrive')}
										</Button>

										<div className="selected-files">
											{this.filesList()}
										</div>
									</div>
									: null}

								{ /* Old file picker (Needs reconsent) (popup) */}
								{!this.state.newPicker ?
									<div id="upload" className="upload-container">
										<div className="upload-buttons">
											<Button type="secondary" onClick={(e) => { e.preventDefault(); this.launchOneDrivePicker(); }}>
												<MS_OneDrive /> {this.props.translate('open-sharepoint-onedrive')}
											</Button>
										</div>

										<div className="selected-files">
											{this.filesList()}
										</div>
									</div>
									: null}
							</div>
						}
					</Expandable>
					: null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		tenant: state.user.tenant,
		translate: translate(state.Languages.translations),
		groups: state.sections.educationGroups,
		user: state.user.currentUser,
		access_token: state.user.accessToken
	};
}

export default connect(mapStateToProps, {
	addError,
	getOnBehalfOfAccessToken
})(Upload);
