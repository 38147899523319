import api from 'lib/api';

export const GET_SUBJECTS = 'SUBJECTS_GET_SUBJECTS';
export const GET_TENANT_SUBJECTS = 'SUBJECTS_GET_TENANT_SUBJECTS';
export const SEARCH_SUBJECTS = 'SUBJECTS_SEARCH_SUBJECTS';
export const CREATE_SUBJECT = 'SUBJECTS_CREATE_SUBJECT';
export const GET_SECTION_SUBJECTS = 'SUBJECTS_GET_SECTION_SUBJECTS';
export const CLEAR_SECTION_SUBJECTS = 'SUBJECTS_CLEAR_SECTION_SUBJECTS';

export const getSubjects = () => ({
	type: GET_SUBJECTS,
	payload: new Promise((resolve) => {
		api.get(`subjects`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getTenantSubjects = (tenantId) => ({
	type: GET_TENANT_SUBJECTS,
	payload: new Promise((resolve) => {
		api.get(`subjects/tenants/${tenantId}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const searchSubjects = (query) => ({
	type: SEARCH_SUBJECTS,
	payload: new Promise((resolve) => {
		api.get(`subjects/search/${query}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const createSubject = (values) => ({
	type: CREATE_SUBJECT,
	payload: new Promise((resolve, reject) => {
		api.post(`subjects`, values).then((response) => {
			resolve(response.data);
		}).catch((error) => {
			reject(error)
		});
	}),
});

export const getSectionSubjects = (sectionId, start, end) => ({
	type: GET_SECTION_SUBJECTS,
	payload: new Promise((resolve) => {
		api.get(`assessments/v2/sections/${sectionId}/subjects?startDate=${start}&endDate=${end}`).then((response) => {
			resolve(response.data);
		});
	}),
});
export const clearSectionSubjects = () => ({
	type: CLEAR_SECTION_SUBJECTS,
	payload: true,
});