import {
	ASSIGNMENT_CREATE,
	ASSIGNMENT_UPDATE,
	ACTIVE_TASK,
	GET_FOLDER_FILES,
	SET_ACTIVE_ASSIGNMENT,
	DELETE_ASSIGNMENT_FROM_STORE,
	CLEAR_ASSIGNMENTS_FROM_STORE,
	GET_TEAMS_ASSIGNMENTS,
	GET_TEAMS_ASSIGNMENT_DETAILS,
	GET_LATEST_MICROSOFT_ASSIGNMENTS,
	GET_LATEST_HALDOR_ASSIGNMENTS,
	GET_TEAMS_ASSIGNMENT_SUBMISSION,
	CLEAR_LATEST_ASSIGNMENTS,
	GET_HALDOR_ASSIGNMENTS,
	GET_MICROSOFT_EDUCATION_ASSIGNMENTS,
	SET_ASSIGNMENT_SERVICES,
	SEARCH_ASSIGNMENT,
	GET_ASSIGNMENT_STATUS,
	UPLOAD_USER_ONE_DRIVE_DOCUMENT,
	SET_MY_ASSIGNMENTS_ACTIVE_GROUP,
	GET_ASSIGNMENT_SECTION,
	CLEAR_ASSIGNMENT_SECTION,
	CREATE_ASSIGNMENT_TASK_WORKSPACE,
	GET_ASSIGNMENT_EVENTS_PAGINATION,
	UPDATE_ASSIGNMENT_LIST
} from 'actions/assignments';

import Moment from 'moment';

import { ACTIVE_ASSIGNMENT_KNOWLEDGECOLLECTIONS } from 'actions/knowledge';

import { updateObjectInArray } from 'helpers/array';
import { CREATE_BULK_TASKS } from '../actions/assignments';


const INITIAL_STATE = {
	assignments: null,
	haldorAssignmentsFetchedUTC: null,
	teamsAssignmentsFetchedUTC: null,
	drafts: [],
	upcoming: [],
	overdue: [],
	current: [],
	submitted: [],
	closed: [],
	locked: [],
	teamsAssignments: null,
	active_assignment: null,
	active_knowledgeCollection: [],
	newAssignment: null,
	newAssignments: null,
	search_results: null,
	folderFiles: [],
	teamsAssignmentDetails: null,
	latestMicrosoftAssignments: [],
	latestHaldorAssignments: [],
	activeServices: { haldor: false, microsoft: false },
	uploadError: {},
	activeGroup: null,
	section: null
};

function UpdateExistingAssignmentInList(assignment, list) {
	let assignmentList = list ? [...list] : [];
	if (assignment != null) {
		const index = assignmentList.findIndex(a => a.id == assignment.id);
		if (index !== -1) {
			assignmentList[index] = assignment;
		}
	}

	return assignmentList;
}


export default function (state = INITIAL_STATE, action) {

	switch (action.type) {
		case ACTIVE_TASK:
			var updatedAssignmentList = UpdateExistingAssignmentInList(action.payload, state.assignments);

			return {
				...state,
				active_assignment: action.payload,
				assignments: updatedAssignmentList
			};

		case SET_ASSIGNMENT_SERVICES:
			return { ...state, activeServices: action.payload };

		case CLEAR_LATEST_ASSIGNMENTS:
			return { ...state, latestMicrosoftAssignments: [], latestHaldorAssignments: [] };

		case ACTIVE_ASSIGNMENT_KNOWLEDGECOLLECTIONS:
			return { ...state, active_knowledgeCollection: action.payload };
		case UPDATE_ASSIGNMENT_LIST:
			var updatedAssignmentList = UpdateExistingAssignmentInList(action.payload, state.assignments);
			return { ...state, assignments: updatedAssignmentList };
		case GET_MICROSOFT_EDUCATION_ASSIGNMENTS:
			if (action.payload != null && action.payload.length > 0) {
				let educationDrafts = getDrafts(action.payload, state);
				let educationUpcoming = getUpcoming(action.payload, state);
				let educationOverdue = getOverdue(action.payload, state);
				let educationCurrent = getCurrent(action.payload, state);
				let educationSubmitted = getSubmitted(action.payload, state);
				let educationClosed = getClosed(action.payload, state);
				let educationLocked = getLocked(action.payload, state);
				let teamsAssignmentsFetchedUTC = Moment.utc();
				return {
					...state,
					closed: educationClosed,
					locked: educationLocked,
					submitted: educationSubmitted,
					current: educationCurrent,
					overdue: educationOverdue,
					upcoming: educationUpcoming,
					drafts: educationDrafts,
					teamsAssignments: action.payload,
					teamsAssignmentsFetchedUTC: teamsAssignmentsFetchedUTC
				};
			}

			return state;

		case GET_HALDOR_ASSIGNMENTS:
			let drafts = getDrafts(action.payload, state);
			let upcoming = getUpcoming(action.payload, state);
			let overdue = getOverdue(action.payload, state);
			let current = getCurrent(action.payload, state);
			let submitted = getSubmitted(action.payload, state);
			let closed = getClosed(action.payload, state);
			let locked = getLocked(action.payload, state);
			let haldorAssignmentsFetchedUTC = Moment.utc();
			return {
				...state,
				closed,
				locked,
				submitted,
				current,
				overdue,
				upcoming,
				drafts,
				assignments: action.payload,
				haldorAssignmentsFetchedUTC: haldorAssignmentsFetchedUTC
			};

		case CREATE_ASSIGNMENT_TASK_WORKSPACE:
			let active_assignment = state.active_assignment;
			active_assignment.tasks[0].workspace = action.payload;
			return { ...state, active_assignment: active_assignment };

		case SEARCH_ASSIGNMENT:
			return { ...state, search_results: action.payload };

		case GET_FOLDER_FILES:
			return { ...state, folderFiles: action.payload };

		case ASSIGNMENT_CREATE:
			let createList = state.assignments;

			/**
			 * We need to check if the payload is an array or an object since we have two api's that return different data structures
			 */
			if (Array.isArray(action.payload)) {
				createList = [...state.assignments, ...action.payload];
			} else if (typeof action.payload === 'object') {
				createList = [...state.assignments, action.payload];
			}

			let updatedDrafts = getDrafts(action.payload, state);
			let updatedUpoming = getUpcoming(action.payload, state);
			let updatedOverdue = getOverdue(action.payload, state);
			let updatedCurrent = getCurrent(action.payload, state);
			let updatedSubmitted = getSubmitted(action.payload, state);
			let updatedClosed = getClosed(action.payload, state);
			let updatedLocked = getLocked(action.payload, state);

			return {
				...state,
				newAssignment: action.payload,
				assignments: createList,
				closed: updatedClosed,
				submitted: updatedSubmitted,
				current: updatedCurrent,
				overdue: updatedOverdue,
				upcoming: updatedUpoming,
				drafts: updatedDrafts,
				locked: updatedLocked,
			};

		case CREATE_BULK_TASKS:
			let assignments = state.assignments;
			assignments = assignments.concat(action.payload);

			let updatedBulkDrafts = getDrafts(action.payload, state);
			let updatedBulkUpoming = getUpcoming(action.payload, state);
			let updatedBulkOverdue = getOverdue(action.payload, state);
			let updatedBulkCurrent = getCurrent(action.payload, state);
			let updatedBulkSubmitted = getSubmitted(action.payload, state);
			let updatedBulkClosed = getClosed(action.payload, state);
			let updatedBulkLocked = getLocked(action.payload, state);

			return {
				...state,
				assignments: assignments,
				closed: updatedBulkClosed,
				submitted: updatedBulkSubmitted,
				current: updatedBulkCurrent,
				overdue: updatedBulkOverdue,
				upcoming: updatedBulkUpoming,
				drafts: updatedBulkDrafts,
				newAssignments: action.payload,
				locked: updatedBulkLocked,
			};

		case ASSIGNMENT_UPDATE:
			if (action.payload.tasks != null && action.payload.tasks.length > 0) {
				action.payload.tasks = action.payload.tasks.filter(function (task) {
					return task.markedForDeletion != true;
				});
			}

			let updatedAssignment = action.payload;
			return {
				...state,
				active_assignment: updatedAssignment,
				assignments: updateAssignmentInList(updatedAssignment, state.assignments),
				closed: updateAssignmentInList(updatedAssignment, state.closed),
				submitted: updateAssignmentInList(updatedAssignment, state.submitted),
				current: updateAssignmentInList(updatedAssignment, state.current),
				overdue: updateAssignmentInList(updatedAssignment, state.overdue),
				upcoming: updateAssignmentInList(updatedAssignment, state.upcoming),
				drafts: updateAssignmentInList(updatedAssignment, state.drafts),
			};

		case SET_ACTIVE_ASSIGNMENT:
			if (typeof action.id !== 'undefined' && action.id !== null) {
				let selected = null;

				if (action.id == parseInt(action.id, 10)) {
					selected = state.assignments.haldorAssignments.find((assignment) => {
						return assignment.id == action.id;
					});
				} else {
					selected = state.assignments.microsoftAssignments.find((assignment) => {
						return assignment.id == action.id;
					});
				}

				if (selected != null) {
					return { ...state, active_assignment: selected };
				} else {
					return { ...state, active_assignment: null };
				}
			}

			return { ...state, active_assignment: null };

		case DELETE_ASSIGNMENT_FROM_STORE:
			return {
				...state,
				assignments: [...state.assignments].filter((t) => t.id != action.payload),
				closed: [...state.closed].filter((t) => t.id != action.payload),
				submitted: [...state.submitted].filter((t) => t.id != action.payload),
				current: [...state.current].filter((t) => t.id != action.payload),
				overdue: [...state.overdue].filter((t) => t.id != action.payload),
				upcoming: [...state.upcoming].filter((t) => t.id != action.payload),
				drafts: [...state.drafts].filter((t) => t.id != action.payload),
			};

		case CLEAR_ASSIGNMENTS_FROM_STORE:
			// Clear assignment filter objects from localstorage
			localStorage.removeItem("assignments_filter_objects");

			return {
				...state,
				closed: [],
				submitted: [],
				current: [],
				overdue: [],
				upcoming: [],
				drafts: [],
				assignments: null,
				teamsAssignments: null,
				haldorAssignmentsFetchedUTC: null,
				teamsAssignmentsFetchedUTC: null,
			};

		case GET_TEAMS_ASSIGNMENTS:
			let teamsDrafts = getDrafts(action.payload, state);
			let teamsUpcoming = getUpcoming(action.payload, state);
			let teamsOverdue = getOverdue(action.payload, state);
			let teamsCurrent = getCurrent(action.payload, state);
			let teamsSubmitted = getSubmitted(action.payload, state);
			let teamsClosed = getClosed(action.payload, state);
			let teamsLocked = getLocked(action.payload, state);

			return {
				...state,
				teamsAssignments: action.paylod,
				// assignments: action.payload,
				closed: teamsClosed,
				submitted: teamsSubmitted,
				current: teamsCurrent,
				overdue: teamsOverdue,
				upcoming: teamsUpcoming,
				drafts: teamsDrafts,
				locked: teamsLocked,
			};

		case GET_TEAMS_ASSIGNMENT_DETAILS:
			return { ...state, teamsAssignmentDetails: action.payload };

		case GET_LATEST_HALDOR_ASSIGNMENTS:
			return { ...state, latestHaldorAssignments: action.payload };

		case GET_LATEST_MICROSOFT_ASSIGNMENTS:
			return { ...state, latestMicrosoftAssignments: action.payload };

		case GET_TEAMS_ASSIGNMENT_SUBMISSION:
			return { ...state, submission: action.payload };

		case GET_ASSIGNMENT_STATUS:
			if (action.payload.created == true) {
				let newState = JSON.parse(JSON.stringify(state));

				let currentAssignmentIndex = newState.current.find(
					(ss) => ss.id == action.payload.assigmentId
				);
				if (currentAssignmentIndex != null) {
					currentAssignmentIndex.created = Moment().format();

					if (newState.current != null) {
						newState.current = updateObjectInArray(newState.current, currentAssignmentIndex);
					}
				}

				let overdueAssignmentIndex = newState.overdue.find(
					(ss) => ss.id == action.payload.assigmentId
				);
				if (overdueAssignmentIndex != null) {
					overdueAssignmentIndex.created = Moment().format();

					if (newState.overdue != null) {
						newState.overdue = updateObjectInArray(newState.overdue, overdueAssignmentIndex);
					}
				}

				if (newState.active_assignment != null) {
					newState.active_assignment = {
						...newState.active_assignment,
						created:
							action.payload.created == true ? Moment().format() : '0001-01-01T00:00:00',
						locked: !action.payload.created,
					};
				}

				return newState;
			}

			return state;

		case UPLOAD_USER_ONE_DRIVE_DOCUMENT:
			let requestError = {};
			if (action.error) {
				requestError.error = true;
				requestError.message = action.payload.response.message;
				requestError.data = action.payload.response.data;
			} else {
				requestError.error = false;
			}

			return { ...state, uploadError: requestError };

		case SET_MY_ASSIGNMENTS_ACTIVE_GROUP:
			return { ...state, activeGroup: action.payload };

		case GET_ASSIGNMENT_SECTION:
			return { ...state, section: action.payload };

		case CLEAR_ASSIGNMENT_SECTION:
			return { ...state, section: null };

		default:
			return state;
	}
}

/***
 * Update list of assignments with new assignment
 */
function updateAssignmentInList(assignment, list) {
	let assignmentList = list ? [...list] : [];
	if (assignment != null) {
		const index = assignmentList.findIndex(a => a.id == assignment.id);
		if (index !== -1) {
			assignmentList[index] = assignment;
		}
	}

	return assignmentList;
}

function getDrafts(payload, state) {
	let list = state.drafts;
	if (Array.isArray(payload)) {
		for (var assignment of payload) {
			const foundIndex = list.findIndex(arrayObject => {
				return arrayObject.id == assignment.id;
			});

			// Check if index was found, we need to replace 'object' with existing array entry
			if (foundIndex > -1) {
				list.splice(foundIndex, 1, assignment);
				continue;
			}

			if (assignment.id == parseInt(assignment.id, 10)) {
				if (assignment.published == '0001-01-01T00:00:00' && list.findIndex((t) => t.id == assignment.id) == -1) {
					list.push(assignment);
				}
			} else {
				if (
					assignment.status == 'draft' &&
					assignment.assignedDateTime == null &&
					(assignment.reference == null || !state.activeServices?.haldor)
				) {
					list.push(assignment);
				}
			}
		}
	} else {
		if (payload == null) {
			return list;
		}

		if (payload.id == parseInt(payload.id, 10)) {
			if (
				payload.status == 'ASSIGNMENT_DRAFT' &&
				(payload.reference == null || !state.activeServices?.haldor)
			) {
				list.push(payload);
			}
		} else {
			if (
				payload.status == 'draft' &&
				payload.assignedDateTime == null &&
				(payload.reference == null || !state.activeServices?.haldor)
			) {
				list.push(payload);
			}
		}
	}

	list = sortList(list);

	return list;
}

function getOverdue(payload, state) {
	const timeNow = Moment();
	let list = state.overdue;
	if (Array.isArray(payload)) {
		for (var assignment of payload) {
			const foundIndex = list.findIndex(arrayObject => {
				return arrayObject.id == assignment.id;
			});

			// Check if index was found, we need to replace 'object' with existing array entry
			if (foundIndex > -1) {
				list.splice(foundIndex, 1, assignment);
				continue;
			}
			if (assignment.id == parseInt(assignment.id, 10)) {
				// Haldor assignment
				const isBefore = timeNow.isBefore(Moment.utc(assignment.startDate).local());
				const isAfter = timeNow.isAfter(Moment.utc(assignment.dueDate).local());
				if ((assignment.status == 'ASSIGNMENT_OPEN' || assignment.status == 'ASSIGNMENT_LOCKED') && assignment.published != '0001-01-01T00:00:00' && !isBefore && isAfter) {
					if (assignment.tasks.length > 0) {
						const task = assignment.tasks[0];
						if (
							task.status == 'ASSIGNMENT_TASK_NOT_STARTED' ||
							task.status == 'ASSIGNMENT_TASK_STARTED' ||
							task.status == 'ASSIGNMENT_TASK_CAN_DEVELOP' ||
							task.status == 'ASSIGNMENT_TASK_NOT_COMPLETE'
						) {
							if (list.findIndex((t) => t.id == assignment.id) == -1) {
								list.push(assignment);
							}
						}
					} else {
						if (list.findIndex((t) => t.id == assignment.id) == -1) {
							list.push(assignment);
						}
					}
				}
			} else {
				// Microsoft assignment
				if (
					assignment.status == 'assigned' &&
					timeNow.isAfter(assignment.dueDateTime) &&
					(assignment.reference == null || !state.activeServices?.haldor)
				) {
					list.push(assignment);
				}
			}
		}
	} else {
		if (payload == null) {
			return list;
		}

		if (payload.id == parseInt(payload.id, 10)) {
			// Haldor assignment
			const isBefore = timeNow.isBefore(Moment.utc(payload.startDate).local());
			const isAfter = timeNow.isAfter(Moment.utc(payload.dueDate).local());
			if ((payload.status == 'ASSIGNMENT_OPEN' || assignment.status == 'ASSIGNMENT_LOCKED') && !isBefore && isAfter) {
				if (payload.tasks.length > 0) {
					const task = payload.tasks[0];
					if (
						task.status == 'ASSIGNMENT_TASK_NOT_STARTED' ||
						task.status == 'ASSIGNMENT_TASK_STARTED' ||
						task.status == 'ASSIGNMENT_TASK_CAN_DEVELOP' ||
						task.status == 'ASSIGNMENT_TASK_NOT_COMPLETE'
					) {
						if (payload.reference == null || !state.activeServices?.haldor) {
							list.push(payload);
						}
					}
				} else {
					if (payload.reference == null || !state.activeServices?.haldor) {
						list.push(payload);
					}
				}
			}
		} else {
			// Microsoft assignment
			if (
				payload.status == 'assigned' &&
				timeNow.isAfter(payload.dueDateTime) &&
				(payload.reference == null || !state.activeServices?.haldor)
			) {
				list.push(payload);
			}
		}
	}

	list = sortList(list);

	return list;
}

function getCurrent(payload, state) {
	const timeNow = Moment();

	let list = state.current;
	if (Array.isArray(payload)) {
		for (var assignment of payload) {
			const foundIndex = list.findIndex(arrayObject => {
				return arrayObject.id == assignment.id;
			});

			// Check if index was found, we need to replace 'object' with existing array entry
			if (foundIndex > -1) {
				list.splice(foundIndex, 1, assignment);
				continue;
			}
			if (assignment.id == parseInt(assignment.id, 10)) {
				const isBefore = timeNow.isBefore(Moment.utc(assignment.startDate).local());
				const isAfter = timeNow.isAfter(Moment.utc(assignment.dueDate).local());
				if ((assignment.status == 'ASSIGNMENT_OPEN' || assignment.status == 'ASSIGNMENT_LOCKED') && !isBefore && !isAfter &&
					assignment.published !== '0001-01-01T00:00:00') {
					if (assignment.tasks.length == 0) {
						if (list.findIndex((t) => t.id == assignment.id) == -1) {
							list.push(assignment);
						}
					} else if (
						assignment.tasks[0] != null &&
						assignment.tasks[0].status != 'ASSIGNMENT_TASK_SUBMITTED'
					) {
						if (list.findIndex((t) => t.id == assignment.id) == -1) {
							list.push(assignment);
						}
					}
				}
			} else {
				if (
					assignment.status == 'assigned' &&
					assignment.assignedDateTime != null &&
					timeNow.isBefore(Moment(assignment.dueDateTime))
				) {
					if (assignment.reference == null || !state.activeServices?.haldor) {
						list.push(assignment);
					}
				}
			}
		}
	} else {
		if (payload == null) {
			return list;
		}

		if (payload.id == parseInt(payload.id, 10)) {
			const isBefore = timeNow.isBefore(Moment.utc(payload.startDate).local());
			const isAfter = timeNow.isAfter(Moment.utc(payload.dueDate).local());
			if ((payload.status == 'ASSIGNMENT_OPEN' || assignment.status == 'ASSIGNMENT_LOCKED') && !isBefore && !isAfter && payload?.published !== '0001-01-01T00:00:00') {
				if (payload.tasks.length == 0) {
					if (payload.reference == null || !state.activeServices?.haldor) {
						list.push(payload);
					}
				} else if (
					payload.tasks[0] != null &&
					payload.tasks[0].status != 'ASSIGNMENT_TASK_SUBMITTED'
				) {
					if (payload.reference == null || !state.activeServices?.haldor) {
						list.push(payload);
					}
				}
			}
		} else {
			if (
				payload.status == 'assigned' &&
				payload.assignedDateTime != null &&
				timeNow.isBefore(Moment(payload.dueDateTime))
			) {
				if (payload.reference == null || !state.activeServices?.haldor) {
					list.push(payload);
				}
			}
		}
	}

	list = sortListDesc(list);

	return list;
}

function getUpcoming(payload, state) {
	const timeNow = Moment();

	let list = state.upcoming;
	if (Array.isArray(payload)) {
		for (var assignment of payload) {
			const foundIndex = list.findIndex(arrayObject => {
				return arrayObject.id == assignment.id;
			});

			// Check if index was found, we need to replace 'object' with existing array entry
			if (foundIndex > -1) {
				list.splice(foundIndex, 1, assignment);
				continue;
			}
			if (assignment.id == parseInt(assignment.id, 10)) {
				const isBefore = timeNow.isBefore(Moment.utc(assignment.startDate).local());
				if ((assignment.status == 'ASSIGNMENT_OPEN' || assignment.status == 'ASSIGNMENT_LOCKED') && isBefore) {
					if (list.findIndex((t) => t.id == assignment.id) == -1) {
						list.push(assignment);
					}
				}
			} else {
				if (
					assignment.status == 'assigned' &&
					assignment.assignedDateTime != null &&
					timeNow.isBefore(Moment(assignment.assignedDateTime))
				) {
					if (assignment.reference == null || !state.activeServices?.haldor) {
						list.push(assignment);
					}
				}
			}
		}
	} else {
		if (payload == null) {
			return list;
		}

		if (payload.id == parseInt(payload.id, 10)) {
			const isBefore = timeNow.isBefore(Moment.utc(payload.startDate).local());
			if ((payload.status == 'ASSIGNMENT_OPEN' || assignment.status == 'ASSIGNMENT_LOCKED') && isBefore) {
				if (payload.reference == null || !state.activeServices?.haldor) {
					list.push(payload);
				}
			}
		} else {
			if (
				payload.status == 'assigned' &&
				payload.assignedDateTime != null &&
				timeNow.isBefore(Moment(payload.assignedDateTime))
			) {
				if (payload.reference == null || !state.activeServices?.haldor) {
					list.push(payload);
				}
			}
		}
	}

	list = sortList(list);

	return list;
}

function getSubmitted(payload, state) {
	let list = state.submitted;
	if (Array.isArray(payload)) {
		payload.forEach((assignment) => {
			if (
				assignment.tasks != null &&
				assignment.tasks.length > 0 &&
				assignment.tasks[0] != null &&
				assignment.tasks[0].status == 'ASSIGNMENT_TASK_SUBMITTED'
			) {
				if (list.findIndex((t) => t.id == assignment.id) == -1) {
					list.push(assignment);
				}
			}
		});
	} else {
		if (payload == null) {
			return list;
		}

		if (
			payload.tasks != null &&
			payload.tasks.length > 0 &&
			payload.tasks[0] != null &&
			payload.tasks[0].status == 'ASSIGNMENT_TASK_SUBMITTED'
		) {
			if (payload.reference == null || !state.activeServices?.haldor) {
				list.push(payload);
			}
		}
	}

	sortList(list);

	return list;
}

function getLocked(payload, state) {
	let list = state.locked;
	if (Array.isArray(payload)) {
		payload.forEach((assignment) => {
			if (assignment.status == 'ASSIGNMENT_LOCKED' && assignment.published !== '0001-01-01T00:00:00') {
				if (list.findIndex((t) => t.id == assignment.id) == -1) {
					list.push(assignment);
				}
			}
		});
	} else {
		if (payload == null) {
			return list;
		}

		if (payload.status == 'ASSIGNMENT_LOCKED' && payload?.published !== '0001-01-01T00:00:00') {
			if (payload.reference == null || !state.activeServices?.haldor) {
				list.push(payload);
			}
		}
	}

	list = sortList(list);

	return list;
}

function getClosed(payload, state) {
	let list = state.closed;
	if (Array.isArray(payload)) {
		payload.forEach((assignment) => {
			if (assignment.status == 'ASSIGNMENT_CLOSED' && assignment.published !== '0001-01-01T00:00:00') {
				if (list.findIndex((t) => t.id == assignment.id) == -1) {
					list.push(assignment);
				}
			}
		});
	} else {
		if (payload == null) {
			return list;
		}

		if (payload.status == 'ASSIGNMENT_CLOSED' && payload?.published !== '0001-01-01T00:00:00') {
			if (payload.reference == null || !state.activeServices?.haldor) {
				list.push(payload);
			}
		}
	}

	list = sortList(list);

	return list;
}

function sortList(list) {
	return list.sort(function (a, b) {
		let dateA = a.dueDate;
		let dateB = b.dueDate;
		if (a.displayName != null) {
			dateA = a.dueDateTime;
		}
		if (b.displayName != null) {
			dateB = b.dueDateTime;
		}

		return Moment(dateB).unix() - Moment(dateA).unix();
	});
}

function sortListDesc(list) {
	return list.sort(function (a, b) {
		let dateA = a.dueDate;
		let dateB = b.dueDate;
		if (a.displayName != null) {
			dateA = a.dueDateTime;
		}
		if (b.displayName != null) {
			dateB = b.dueDateTime;
		}

		return Moment(dateA).unix() - Moment(dateB).unix();
	});
}
