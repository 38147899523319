import {
	SET_USER,
	GET_TENANT,
	USER_ROLES,
	CURRENT_USER,
	CURRENT_USER_BASE,
	SET_ROLE,
	SET_SCHOOL,
	SET_SCHOOL_TYPE,
	SEARCH_USER,
	GET_USER_INFO,
	SET_ACTIVE_TEAMS_TAB,
	GET_TENANT_SCHOOLS,
	USER_GET_USER_ABSENCE,
	USER_GET_MENTOR_SECTION,
	CLEAR_SEARCH_USER,
	GET_USER_GUARDIAN,
	GET_GUARDIAN_BY_EMAIL,
	GET_GUARDIAN_BY_ID,
	SET_GUARDIAN_BY_ID,
	LINK_STUDENT_GUARDIAN,
	ADD_USER_GUARDIAN,
	SET_USER_AUTHORITATIVE,
	GET_READER_PARAMS,
	GET_USER_COURSES,
	GET_USER_SUBJECTS,
	UPDATE_USER,
	GET_USER_FILES,
	ADD_USER_ROLE,
	DELETE_USER_ROLE,
	GET_TEACHER_ROLES,
	DELETE_TEACHER_ROLE,
	ADD_TEACHER_ROLE,
	GET_USER_SECTIONS,
	GET_MY_MENTOR_SECTIONS,
	GET_USERS_WITHOUT_MENTOR_GROUPS,
	USER_ADMIN_CHECK,
	GET_MY_SECTIONS,
	GET_ALL_SECTIONS,
	GET_ALL_USER_SECTIONS,
	GET_USER_ACCESSTOKEN,
	DELETE_USER_FILE,
	GET_USER_NOTIFICATION_SETTINGS
} from 'actions/user';

import { SET_ARCHIVING_SECTION } from 'actions/sections';

const INITIAL_STATE = {
	user: null,
	currentUser: null,
	currentBaseUser: null,
	userCourses: [],
	userSubjects: [],
	tenant: null,
	activeRole: null,
	activeSchool: null,
	roles: [],
	searchResults: null,
	info: [],
	activeTab: null,
	absence: null,
	userMentorSections: null,
	guardians: [],
	guardian: null,
	searchGuardian: null,
	reader: null,
	files: [],
	teacherRoles: [],
	sections: null,
	usersWithoutMentorGroups: null,
	onboardingUser: null,
	mySections: null,
	userNotificationSettings: []
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_USER_ACCESSTOKEN:
			return { ...state, accessToken: action.payload }
		case USER_ADMIN_CHECK:
			return { ...state, onboardingUser: action.payload };

		case ADD_USER_ROLE:
			return { ...state };

		case DELETE_USER_ROLE:
			return { ...state };

		case SET_ACTIVE_TEAMS_TAB:
			return { ...state, activeTab: action.payload };

		case SET_USER:
			return { ...state, user: action.payload };

		case GET_TENANT:
			return { ...state, tenant: action.payload };

		case GET_TENANT_SCHOOLS:
			return { ...state, tenant: action.payload };

		case USER_ROLES:
			return { ...state, roles: action.payload };

		case CURRENT_USER:
			return { ...state, currentUser: action.payload };

		case CURRENT_USER_BASE:
			return { ...state, currentBaseUser: action.payload };

		case SET_ROLE:
			var role = action.payload.toUpperCase();
			return { ...state, activeRole: role };

		case SET_SCHOOL:
			return { ...state, activeSchool: action.payload };

		case SET_SCHOOL_TYPE:
			let user = state.currentUser;

			user.schools.map(school => {
				school.type = action.payload;
			});

			return { ...state, currentUser: user };

		case SEARCH_USER:
			return { ...state, searchResults: action.payload };

		case CLEAR_SEARCH_USER:
			return { ...state, searchResults: null };

		case GET_USER_INFO:
			if (action.payload != null) {
				return {
					...state,
					info: [...state.info.filter(t => {
						return t.userId != action.payload.userId;
					}), action.payload],
				};
			}

			return state;

		case SET_USER_AUTHORITATIVE:
			let info = [...state.info];
			if (action.payload != null) {
				let index = info.findIndex(i => {
					return i.userId == action.payload.userId;
				});
				if (index != -1) {
					info.splice(index, 1, action.payload);
				} else {
					info.push(action.payload);
				}
			}
			return { ...state, info };

		case GET_USER_GUARDIAN:
			return { ...state, guardians: action.payload };

		case GET_GUARDIAN_BY_ID:
			return { ...state, guardian: action.payload };

		case SET_GUARDIAN_BY_ID:
			return { ...state, guardian: action.payload };

		case GET_GUARDIAN_BY_EMAIL:
			return { ...state, searchGuardian: action.payload };

		case ADD_USER_GUARDIAN:
			let guardians = [...state.guardians];
			guardians.push(action.payload);
			return { ...state, guardians: guardians };

		case LINK_STUDENT_GUARDIAN:
			guardians = [...state.guardians];
			guardians.push(action.payload);
			return { ...state, guardians: guardians };

		case USER_GET_USER_ABSENCE:
			return { ...state, absence: action.payload };

		case USER_GET_MENTOR_SECTION:
			return { ...state, userMentorSections: action.payload };

		case GET_READER_PARAMS:
			return { ...state, reader: action.payload };

		case GET_USER_COURSES:
			return { ...state, userCourses: action.payload };

		case GET_USER_SUBJECTS:
			return { ...state, userSubjects: action.payload };

		case UPDATE_USER:
			info = [...state.info];
			if (action.payload != null) {
				const index = info.findIndex((i) => i.userId == action.payload.userId);

				if (index == -1) {
					info.push(action.payload);
				}
			}

			return { ...state, info };

		case GET_USER_FILES:
			return { ...state, files: action.payload };

		case DELETE_USER_FILE:
			return {
				...state,
				files: [...state.files].filter((file) => file.id != action.payload),
			};

		case GET_TEACHER_ROLES:
			return { ...state, teacherRoles: action.payload };

		case DELETE_TEACHER_ROLE:
			return { ...state, teacherRoles: action.payload };

		case ADD_TEACHER_ROLE:
			let teacherRoles = [];
			if (state.teacherRoles != null) {
				teacherRoles = [...state.teacherRoles];
			}
			if (action.payload != null) {
				let index = teacherRoles.findIndex(i => {
					return i.subjectCode == action.payload.subjectCode;
				})
				if (index != -1) {
					teacherRoles.splice(index, 1, action.payload);
				}
				else {
					teacherRoles.push(action.payload);
				}
			}

			return { ...state, teacherRoles };

		case GET_USER_SECTIONS:
			return { ...state, sections: action.payload };

		case GET_ALL_USER_SECTIONS:
			if (action.payload != null) {
				if (action.payload.pageIndex > 1) {
					return { ...state, userSections: [...state.userSections, ...action.payload.documents] };
				}

				return { ...state, userSections: [...action.payload.documents] };
			}

			return state;
		case GET_MY_MENTOR_SECTIONS:
			return { ...state, sections: action.payload };

		case GET_USERS_WITHOUT_MENTOR_GROUPS:
			return { ...state, usersWithoutMentorGroups: action.payload };

		case GET_MY_SECTIONS:
			if (action.payload != null) {
				if (action.payload.pageIndex > 1) {
					return { ...state, mySections: [...state.mySections, ...action.payload.documents] };
				}

				return { ...state, mySections: [...action.payload.documents] };
			}

			return state;

		case GET_ALL_SECTIONS:
			if (action.payload != null) {
				if (action.payload.pageIndex > 1) {
					return { ...state, mySections: [...state.mySections, ...action.payload.documents] };
				}

				return { ...state, mySections: [...action.payload.documents] };
			}

			return state;
		case GET_USER_NOTIFICATION_SETTINGS:
			return { ...state, userNotificationSettings: action.payload };
		case SET_ARCHIVING_SECTION:
			if (state.mySections != null) {
				let newMySections = JSON.parse(JSON.stringify(state.mySections));
				let foundGroupIndex = newMySections.findIndex(g => g.id == action.payload.id);

				if (foundGroupIndex == -1) {
					return state;
				}

				newMySections.splice(foundGroupIndex, 1, action.payload);
				return { ...state, mySections: newMySections };
			}

			return state;

		default:
			return state;

	}
}
