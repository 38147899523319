import React, { Component } from 'react';

import ReactQuill from 'react-quill-new';

class QuillEditor extends Component {
	constructor(props) {
		super(props);

		let placeHolderText = '';
		if (this.props.placeholder) {
			placeHolderText = this.props.placeholder;
		}

		this.state = {
			placeHolderText,
			text: this.props.text ? this.props.text : '',
		};
	}

	handleChange = (value) => {
		this.setState({ text: value });
		this.props.onChange(value);
	}


	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.state.text == '' && nextProps.text != '') {
			this.setState({ text: nextProps.text });
		}
	}

	render() {
		// let modules = {
		// 	toolbar: [
		// 		[{ 'header': [2, 3, false] }],
		// 		['bold', 'italic', 'underline','strike', 'blockquote'],
		// 		[{'list': 'ordered'}, {'list': 'bullet'}],
		// 		['link', 'video'],
		// 		['clean']
		// 	]
		// }

		const modules = {
			toolbar: {
				container: [
					[{ header: [2, 3, false] }],
					['bold', 'italic', 'underline', 'strike', 'blockquote'],
					[{ list: 'ordered' }, { list: 'bullet' }],
					['link', 'image', 'video'],
					['clean'],
				],
				handlers: {
					image: this.imageHandler,
				},
			},
		};

		const formats = [
			'header',
			'bold', 'italic', 'underline', 'strike', 'blockquote',
			'list', 'bullet', /* 'indent', */
			'link', 'image', 'video',
		];

		return (
			<div>
				{this.props.label ? <label>{this.props.label}</label> : ''}
				<ReactQuill
					value={this.state.text}
					modules={modules}
					formats={formats}
					onChange={this.handleChange}
					onBlur={this.props.onBlur}
					placeholder={this.state.placeHolderText}
					ref="quill"
				/>
			</div>
		);
	}

	imageHandler = () => {
		const quill = this.refs.quill.getEditor();
		const range = quill.getSelection();
		const value = prompt('Image URL');
		quill.insertEmbed(range.index, 'image', value);
	}
}

export default QuillEditor;
