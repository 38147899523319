import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from "redux-form";
import moment from 'moment';
import DateTime from '_class/DateTime';

import { addError } from 'actions';
import { getMeetingTimeBlocks } from 'actions/timeblocks';
import { updateMeetingInstanceDate } from 'actions/meetings';

import { Collapsible, Spinner } from 'UI';
import { Plus, Minus } from 'UI/Icons'
import { Button, Radio, Flex } from '@haldor/ui';

import './_BookMeeting.scss';

class BookMeeting extends Component {

	constructor(props) {
		super(props);

		this.state = {
			dates: null,
			loading: true
		};
	}

	componentDidMount = () => {
		Promise.all([
			this.props.getMeetingTimeBlocks(this.props.meeting.id)
		]).then(() => {
			this.setState({ loading: false });
		});
	}

	required = (value, values) => {
		if (value == null) {
			return this.props.translate("field-needs-input");
		}

		return undefined;
	}

	submit = (values) => {
		const { onClose } = this.props;

		return new Promise(async (resolve) => {
			var slots = [];

			this.props.meetingTimeBlocks.map((timeBlock) => {
				slots.push(...timeBlock.timeBlockSlots);
			});

			var slot = slots.find(item => { return item.id == values.timeBlockSlotId });

			if (slot != null) {
				let date = moment.utc(slot.startTime);
				let endTime = moment.utc(slot.endTime);

				const obj = {
					id: this.props.instance.id,
					meetingId: this.props.meeting.id,
					timeBlockSlotId: slot.id,
					date: date.format('YYYY-MM-DDTHH:mm:ss'),
					endDate: endTime.format('YYYY-MM-DDTHH:mm:ss'),
				};

				this.setState({ loading: true });
				this.props.updateMeetingInstanceDate(obj).then(() => {
					onClose(null, true);
				});
			} else {
				this.props.addError(this.props.translate('The time you selected is no longer available. Please choose a different time.'), 'error');
				resolve(1);
				return;
			}
		});
	}

	onCancel = (event) => {
		// Prevent button submitting form
		event.preventDefault();
		this.props.onClose(null, false);
	}

	render() {
		if (this.state.loading) {
			return <Spinner center />
		}

		const { handleSubmit, valid } = this.props;

		if (this.props.meetingTimeBlocks == null) {
			return (
				<div className="form-container">
					<div style={{ marginBottom: '2rem' }} className="text--center color--meta">
						{this.props.translate('no-bookable-times')}
					</div>
				</div>
			);
		}

		return (
			<div className="form-container">
				<form onSubmit={handleSubmit(this.submit)} className="form form-component">
					<div className="">
						{this.props.meetingTimeBlocks.map((timeblock) => {
							const trigger = (open) => (
								<div style={{ display: 'flex' }}>
									<div style={{ flex: '1' }}>
										{new DateTime(timeblock.startTime).getDateString().capitalize()}
									</div>

									<Flex center>
										{new DateTime(timeblock.startTime).getTime()}
										{' - '}
										{new DateTime(timeblock.endTime).getTime()}

										<div className="open-indicator">
											{open ?
												<Minus />
												: <Plus />}
										</div>
									</Flex>
								</div>
							);

							const slots = timeblock.timeBlockSlots.filter((t) =>
								moment(t.startTime) < moment(timeblock.endTime) &&
								moment(t.endTime) > moment(timeblock.startTime)
							);

							return (
								<div className="meeting--slots" key={timeblock.id}>
									<Collapsible trigger={trigger}>
										<div className="slots-container">
											{slots.map((slot, i) => {
												const startTime = moment.utc(slot.startTime).local();
												let label = startTime.format('HH:mm');
												let available = slot.available && startTime.unix() > moment().unix();
												let bookable = slot.bookable;

												if (this.props.instance != null) {
													if (this.props.instance.timeBlockSlotId == slot.id) {
														available = true;
														label = label + ' ' + this.props.instance.student.firstName + ' ' + this.props.instance.student.lastName;
													}
												}

												return (
													<div className="slot" key={slot.id}>
														<Field
															component={Radio}
															optionValue={slot.id}
															disabled={!available || !bookable}
															name="timeBlockSlotId"
															label={label}
															validate={this.required}
															key={slot.id}
														/>
													</div>
												)
											})}
										</div>
									</Collapsible>
								</div>
							)
						})}
					</div>

					<div className="form-divider" />

					<div className="form-row spacing submit">
						<Button disabled={!valid}>
							{this.props.translate('save')}
						</Button>

						<div className="pull-right">
							<Button type="secondary" onClick={this.onCancel}>
								{this.props.translate('Cancel')}
							</Button>
						</div>
					</div>

				</form>
			</div>
		);
	}

}

function mapStateToProps(state, ownProps) {
	let initialValues = null;

	if (ownProps.instance != null) {
		initialValues = {
			...ownProps.instance,
			timeBlockSlotId: ownProps.instance.timeBlockSlotId,
			date: moment.utc(ownProps.instance.date).local().format('YYYY-MM-DDTHH:mm:ss'),
		};
	}

	return {
		initialValues,
		translate: translate(state.Languages.translations),
		meetingTimeBlockSlots: state.TimeBlocks.meetingTimeBlockSlots,
		meetingTimeBlocks: state.TimeBlocks.meetingTimeBlocks
	};
};

export default connect(mapStateToProps, {
	getMeetingTimeBlocks,
	updateMeetingInstanceDate,
	addError,
})(reduxForm({
	form: 'BookMeetingBlocks',
	destroyOnUnmount: true,
})(BookMeeting));
