import React, { Component } from 'react';

import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Icon, translate, ActionSheet } from '@haldor/ui';
import { languages, getLanguage, setLanguage } from './LanguageManager';
import * as microsoftTeams from "@microsoft/teams-js";

import './_page.scss';
import { isMicrosoftTeams } from 'helpers/teams';

class MenuPage extends Component {

	constructor(props) {
		super(props);

		this.blocked = [
			'NAV_HOME',
			'NAV_SCHEDULE',
			'NAV_PLANS',
		];
	}

	confirmLogOut = () => {
		let menuItems = [];
		const { translate } = this.props;

		const items = [
			{
				label: translate('Yes'),
				link: '/logout',
				background: "#73d266",
			},
			{
				label: translate('No'),
				link: '#',
				background: "#E6E6E6",
			},
		];

		items.forEach(item => {
			menuItems.push({
				label: item.label == translate("Yes") ?
					(<div>
						<h3 style={{ marginBottom: "25px" }}>{translate('verify_logout')}</h3>
						<a style={{ textTransform: "uppercase", textDecoration: "none", color: "white", display: "inline-flex", justifyContent: "center", backgroundColor: (item.background), width: "100%", padding: "10px", borderRadius: "10px", fontWeight: "bold" }} href={item.link} >{item.label}</a>
					</div>)
					: (<div>
						<a style={{ textTransform: "uppercase", textDecoration: "none", color: "black", display: "inline-flex", justifyContent: "center", backgroundColor: (item.background), width: "100%", padding: "10px", borderRadius: "10px" }} href={item.link} >{item.label}</a>
					</div>),
			});
		});

		ActionSheet.prompt({
			items: menuItems,
		}).then(selection => {

		}, () => {
			// This method only runs if user closes the prompt without pressing an option
		});

	}

	selectLanguage = () => {
		let languageMenu = [];
		const user = this.props.user;

		languages().forEach((language) => {
			languageMenu.push({
				label: user.lcid.toLowerCase() == language.lcid.toLowerCase() ?
					(<div style={{ fontWeight: "bold", display: "flex", justifyContent: "space-between" }}>
						<span style={{ display: "flex", alignItems: "center" }}><img style={{ height: "16px", width: "22px", objectFit: "cover", marginRight: "10px", borderRadius: "2px" }} alt={language.label} src={language.svg_src} /> {language.label}</span>
						<Icon name="check" />
					</div>)
					: (<span onClick={() => setLanguage(language.lcid, user)} style={{ display: "flex", alignItems: "center" }}><img style={{ height: "16px", width: "22px", objectFit: "cover", marginRight: "10px", borderRadius: "2px" }} alt={language.label} src={language.svg_src} /> {language.label}</span>),
			});
		});

		ActionSheet.prompt({
			items: languageMenu,
		}).then(selection => {

		}, () => {
			// This method only runs if user closes the prompt without pressing an option
		});
	}

	getBadgeForItem = (item) => {
		if (this.props.userUnread == null) {
			return null;
		}

		if (isMicrosoftTeams()) {
			this.blocked = [];
		}

		const { user } = this.props;
		const activeSchool = localStorage.getItem('hal.school' + user.id);

		if (item.url == '/conversations') {
			return this.props.userUnread.reduce((value, item) => {
				if ((item.schoolId == 0 || item.schoolId == activeSchool) && item.type == 'CONVERSATION') {
					if (value == null) {
						value = 0;
					}

					return value += item.unread;
				}

				return value;
			}, null);
		}

		if (item.url == '/notifications') {
			return this.props.userUnread.reduce((value, item) => {
				if ((item.schoolId == 0 || item.schoolId == activeSchool) && item.type == 'NOT') {
					if (value == null) {
						value = 0;
					}

					return value += item.unread;
				}

				return value;
			}, null);
		}

		return null;
	};

	inIframe() {
		try {
			return window.self !== window.top || window.navigator.userAgent.indexOf('TeamsMobile') > -1;
		} catch (e) {
			return true;
		}
	}

	handleClick = (url, e) => {
		e.preventDefault(); // Prevent default link behavior

		console.log("Teams", microsoftTeams);

		if (this.inIframe()) {
			microsoftTeams.getContext((context) => {
				console.log("context", context);
				if (context.hostClientType === "ios" || context.hostClientType === "android") {
					// Use Teams SDK to open the link within Teams mobile app
					microsoftTeams.executeDeepLink(url);
				} else {
					// Fallback for web and desktop, open in new tab
					window.open(url, '_blank');
				}
			});
		} else {
			// Fallback for web and desktop, open in new tab
			window.open(url, '_blank');
		}
	}

	render() {
		let categories = [];
		let displayActiveLanguage = getLanguage();
		if (this.props.items != null) {
			this.props.items.forEach(item => {
				if (item.parentId != null && item.parentId != '') {
					if (categories.indexOf(item.parentId) == -1) {
						categories.push(item.parentId);
					}
				}
			});
		}

		return (
			<div className="menu-page">
				<div className="items-container">
					{this.props.items != null ? this.props.items.map((item, index) => {
						if (this.blocked.indexOf(item.text) > -1) {
							return null;
						}

						if (item.type == 'EXTERNAL') {
							return null;
						}

						if (item.parentId != null && item.parentId != '') {
							return null;
						}

						return <div className="menu-item-container" key={'item-' + index}>
							<NavLink onClick={this.props.onClose} to={item.url} activeClassName="is-active" className="menu-item">
								<div className="menu-item-icon">
									<Icon name={item.iconId} />

									{this.getBadgeForItem(item) != null ?
										<span className="badge">{this.getBadgeForItem(item)}</span>
										: null}
								</div>

								<span>{this.props.translate(item.text.toLowerCase())}</span>
							</NavLink>
						</div>
					}) : null}

					{categories.map(category => {
						return <div className="menu-divider">
							<div className="divider-label">
								{this.props.translate(category.toLowerCase())}
							</div>

							<div className="items">
								{this.props.items.map((item, index) => {
									if (this.blocked.indexOf(item.text) > -1) {
										return null;
									}

									if (item.parentId != null && item.parentId == category) {
										return <div className="menu-item-container" key={'item-' + index}>
											<NavLink onClick={this.props.onClose} to={item.url} activeClassName="is-active" className="menu-item">
												<div className="menu-item-icon">
													<Icon name={item.iconId} />

													{this.getBadgeForItem(item) != null ?
														<span className="badge">{this.getBadgeForItem(item)}</span>
														: null}
												</div>

												<span>{this.props.translate(item.text.toLowerCase())}</span>
											</NavLink>
										</div>
									}
								})}
							</div>
						</div>
					})}

					{this.props.items != null ? this.props.items.map((item, index) => {
						if (item.parentId != null && item.parentId != '') {
							return null;
						}

						if (item.type != 'EXTERNAL') {
							return null;
						}

						let label = item.text;
						if (label.indexOf('nav_') > -1) {
							label = this.props.translate(item.text.toLowerCase());
						}

						return <div className="menu-item-container" key={'item-' + index}>
							<a href={item.url} onClick={(e) => this.handleClick(item.url, e)} target="_blank" className="menu-item">
								<div className="menu-item-icon">
									<Icon name={item.iconId} />
								</div>

								<span>{label}</span>
							</a>
						</div>
					}) : null}
					<div className='menu-divider'>
						<div className="divider-label">
							{this.props.translate("Settings")}
						</div>
						{displayActiveLanguage != null ?
							<div className="menu-item-container">
								<NavLink to="#" onClick={this.selectLanguage} className="menu-item">
									<div className="menu-item-icon" style={{ display: "flex", alignItems: "center" }}>
										<img className='flag flag-icon' alt={displayActiveLanguage.label} src={displayActiveLanguage.svg_src} />
									</div>
									<span>{displayActiveLanguage.label} <Icon name="Chevron_Down" style={{ marginLeft: "5px" }} /></span>
								</NavLink>
							</div>
							: null}

						<div className="menu-item-container">
							<NavLink to="#" onClick={this.confirmLogOut} className="menu-item">
								<div className="menu-item-icon">
									<img className="person" src='/dist/svg/person.svg' alt='person' />
								</div>
								{this.props.user != null ?
									<span>{this.props.translate('Sign out')} {this.props.user.firstName + ' ' + this.props.user.lastName}</span>
									: <span>{this.props.translate('Sign out')}</span>
								}
							</NavLink>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		user: state.user.currentUser,
		items: state.Menu.items,
		userUnread: state.Notifications.userUnread,
	};
}

export default connect(mapStateToProps)(MenuPage);
