import React, { Component } from 'react';
import moment from 'moment';
import sniffer from 'sniffer';
import getEnvironment from 'lib/env';

import FooterMenu from './Menu';

import project from '../../../package.json';

class Footer extends Component {

	render() {
		return (
			<div>
				{sniffer.isDesktop || sniffer.isTablet ?
					<div className="site-footer footer">
						<div className="footer-content">
							Haldor - {moment().format('YYYY')}

							{getEnvironment().type == 'production' || getEnvironment().type == 'staging' ?
								<div style={{ marginTop: '0.35em' }}>
									Release {project.version}
								</div>
								: null}

							<div style={{ marginTop: '0.35rem' }}>
								Session ID: {localStorage.getItem('hsid')}
							</div>

							{getEnvironment().type == 'dev' || getEnvironment().type == 'test' ?
								<div style={{ marginTop: '0.35em' }}>
									Release {project.version} Development Preview
								</div>
								: null}
						</div>
					</div>
					: null}

				<FooterMenu />
			</div>
		);
	}

}

export default Footer;
