import React, { Component } from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';

import DateTime from '_class/DateTime';
import { getUserAssessmentDates } from 'helpers/user';

import {
	deleteVerdict,
	getAssignmentAssessments,
	getPlanAssessments,
	getVerdictsForUser,
} from 'actions/verdicts';
import { getStudentAdjustmentsBySubject } from 'actions/additional_adjustments';

import Modal from 'containers/Modals/Modal';
import VerdictAssessmentsDisplay from 'containers/Verdict/Display/VerdictAssessmentsDisplay';
import InputVerdictForm from 'containers/Verdict/Form/VerdictForm';
import VerdictWidget from 'containers/Verdict/Display/VerdictWidget';
import VerdictDisplay from 'containers/Verdict/Display/VerdictDisplay';
import TeacherAdjustments from 'containers/AdditionalAdjustments/Display/TeacherAdjustments';
import StudentAdjustmentForm from 'containers/AdditionalAdjustments/Form/StudentAdjustmentForm';
import DatePickers from "./DatePickers";

import { Collapsible } from 'UI';
import { Button, Checkbox, Expandable, Icon, translate } from '@haldor/ui';

class VerdictForm extends Component {

	constructor(props) {
		super(props);

		this.state = {
			adjustmentModal: false,
			start: getUserAssessmentDates().start,
			end: getUserAssessmentDates().end,
			currentStart: getUserAssessmentDates().start,
			currentEnd: getUserAssessmentDates().end,
			loading: true,
			showLearningGoals: true,
			showKnowledgeRequirements: true,
		}

		this.container = null;
		this.content = null;
	}

	_setContainer = (element) => this.container = element;
	_setContent = (element) => this.content = element;

	componentDidMount = () => {
		const { activeStudent, course } = this.props;
		window.addEventListener('resize', this.resize);

		this.resize();

		let promises = [];
		promises.push(this.props.getAssignmentAssessments(activeStudent.id, course.id));
		promises.push(this.props.getPlanAssessments(activeStudent.id, course.id));
		promises.push(this.props.getVerdictsForUser(activeStudent.id, course.id));

		Promise.all(promises).then(() => {
			this.setState({ loading: false }, () => this.resize());
		});
	}

	reload = () => {
		const { activeStudent, course } = this.props;
		this.setState({ loading: true });

		this.props.getVerdictsForUser(activeStudent.id, course.id).then(() => {
			this.setState({ loading: false }, () => this.resize());
		});

		if (this.props.reload != null) {
			this.props.reload();
		}
	}

	setLoading = (loading) => {
		this.setState({ loading: loading });
	}

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.resize);
	}

	onExpandableChange = () => {
		this.resize();

		// Animation timing
		setTimeout(() => this.resize(), 50);
		setTimeout(() => this.resize(), 100);
		setTimeout(() => this.resize(), 150);
		setTimeout(() => this.resize(), 200);
		setTimeout(() => this.resize(), 250);
	}

	onDateChange = ({ start, end }) => {
		if (start == "Invalid date") {
			start = Moment().subtract(1, "years");
		}
		if (end == "Invalid date") {
			end = Moment().add(1, "years");
		}

		this.setState({
			start: new DateTime(start).getDateStamp(),
			end: new DateTime(end).getDateStamp()
		});
	};

	onSubmitDates = (event) => {
		event.preventDefault();

		this.setState({
			currentStart: this.state.start,
			currentEnd: this.state.end,
		});
	}

	resize = () => {
		if (this.container == null || this.content == null) {
			return false;
		}

		let height = window.innerHeight - 185;
		height = height - this.container.scrollHeight;
		this.content.style.maxHeight = (height) + 'px';
	}

	deleteVerdict = (id) => {
		const { course } = this.props;
		let userId = null;

		if (this.props.userVerdicts != null && this.props.userVerdicts.length > 0) {
			userId = this.props.userVerdicts[0].userId;
		}

		return new Promise((resolve) => {
			this.setState({ loading: true });
			this.props.deleteVerdict(id).then(async () => {
				this.postSubmit();

				if (userId != null) {
					await this.props.getVerdictsForUser(userId, course.id);
				}

				resolve(1);
			});
		});
	}

	onVerdictSubmit = (values) => {
		return new Promise(resolve => {
			const { course } = this.props;

			values.userId = this.state.activeStudent.id;
			values.courses = [{
				"courseCode": course.courseCode,
				"subjectCode": course.subjectCode,
				"courseID": course.id,
			}];

			this.setState({ loading: true });
			if (values.id != null) {
				this.props.updateVerdict(values).then(() => {
					this.postSubmit(resolve);
				});
			} else {
				this.props.createVerdict(values).then(() => {
					this.postSubmit(resolve);
				});
			}
		});
	}

	toggleAdjustmentModal = () => {
		const { course, group } = this.props;
		if (this.state.adjustmentModal == false) {
			this.setState({ adjustmentModal: !this.state.adjustmentModal });
		} else {
			this.setState({ adjustmentModal: !this.state.adjustmentModal });
			this.props.getStudentAdjustmentsBySubject(group.id, course.subjectId, this.props.activeStudent.id);
		}
	}

	renderPublishedInformation = (verdict) => {
		let name = verdict.createdBy.firstName + ' ' + verdict.createdBy.lastName;
		if (verdict.editedBy != null) {
			name = verdict.editedBy.firstName + ' ' + verdict.editedBy.lastName;
		}

		if (new Date(verdict.published) < new Date(this.props.end)) {
			return (
				<div className="info-draft" >
					<span className="draft-badge">
						{this.props.translate('published') + ': ' + name + ' • ' + new DateTime(verdict.published).getTimeStamp()}
					</span>

					<span className="draft-badge" style={{ marginLeft: '5px' }}>
						{this.props.translate('edited') + ": " + name + ' • ' + new DateTime(verdict.edited).getTimeStamp()}
					</span>
				</div>
			)
		} else {
			return (
				<div className="info-draft">
					<span className="draft-badge">
						{this.props.translate('published') + ': ' + name + ' • ' + new DateTime(verdict.published).getTimeStamp()}
					</span>
				</div>
			)
		}
	}

	renderDraftInformation = (userVerdicts) => {
		if (userVerdicts != null && userVerdicts.length > 0 && userVerdicts[0].status == 'VERDICT_DRAFT') {
			return (
				<div className="info-draft">
					<span className="draft-badge" style={{ color: 'red' }}>
						{this.props.translate('Draft')}
					</span>

					<span className="draft-badge" style={{ marginLeft: '5px', color: 'red' }}>
						{this.props.translate('created') + ": " + new DateTime(userVerdicts[0].created).getTimeStamp()}
					</span>

					<span className="draft-badge" style={{ marginLeft: '5px', color: 'red' }}>
						{this.props.translate('edited') + ": " + new DateTime(userVerdicts[0].edited).getTimeStamp()}
					</span>
				</div>
			)
		} else {
			return null;
		}
	}

	renderAdjustmentModal = () => {
		const { translate } = this.props;

		if (!this.props.services.additionalAdjustments) {
			return null;
		}

		return (
			<Modal isOpen={this.state.adjustmentModal} onClose={this.toggleAdjustmentModal} title={translate('assign-adjustment')}>
				<StudentAdjustmentForm
					onClose={this.toggleAdjustmentModal}
					course={this.props.course}
					userId={this.props.activeStudent.id}
				/>
			</Modal>
		)
	}

	renderVerdict = (verdict, index) => {
		if (verdict == null || verdict.status == 'VERDICT_DRAFT') {
			return null;
		}

		let date = this.props.tenant.currentSchoolYear != null ? this.props.tenant.currentSchoolYear : '2017-01-01';
		let start = new DateTime(date).getDateStamp();
		let end = new DateTime(verdict.published).getDateStamp();

		let verdictBefore = this.props.userVerdicts[index + 1];
		if (verdictBefore != null) {
			start = new DateTime(verdictBefore.published).getDateStamp();
		}

		const { course } = this.props;

		const trigger = (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<div style={{ flexGrow: 1 }}>
					{new DateTime(verdict.published).getLongDateWithTime()}
				</div>

				<div style={{ flexGrow: 0 }}>
					<VerdictWidget verdict={verdict} />
				</div>
			</div>
		);

		return <div className="verdict verdict-entry" key={verdict.id}>
			<Collapsible trigger={trigger} onChange={this.onExpandableChange}>
				{this.renderPublishedInformation(verdict)}

				<VerdictDisplay
					verdict={verdict}
					reload={this.reload}
					setLoading={this.setLoading}
					course={course}
				/>
			</Collapsible>
		</div>
	}

	render() {
		const { activeStudent, duplicate, translate } = this.props;

		return (
			<div className="form form-component verdict">
				{this.renderAdjustmentModal()}

				<div ref={this._setContainer}>
					<div className="place-verdict-expandable">
						<Expandable open ignorePropsUpdate title={translate('place-verdict')} onChange={this.onExpandableChange}>
							{this.renderDraftInformation(this.props.userVerdicts)}

							<InputVerdictForm
								onSubmit={this.onVerdictSubmit}
								duplicate={duplicate}
								edit={false}
								activeStudent={activeStudent.id}
								deleteVerdict={this.deleteVerdict}
								userVerdicts={this.props.userVerdicts}
							/>
						</Expandable>
					</div>

					{this.props.services.additionalAdjustments ?
						<Expandable title={translate('additional-adjustments')} onChange={this.onExpandableChange}>
							<TeacherAdjustments
								adjustments={this.props.studentAdjustmentsBySubject}
							/>

							<Button type="secondary" onClick={() => { this.toggleAdjustmentModal() }}>
								<Icon name="Plus" /> {translate('assign-adjustment')}
							</Button>
						</Expandable>
						:
						<div className="form form-row">
							<div className="form-divider" style={{ marginBottom: 40, marginTop: 15 }} />
						</div>
					}

					{this.props.userVerdicts != null && this.props.userVerdicts.length > 0 ?
						<Expandable title={translate('past-verdicts')} onChange={this.onExpandableChange}>
							<div className="past-verdicts">
								{this.props.userVerdicts.map(this.renderVerdict)}
							</div>
						</Expandable>
						: null}
				</div>

				<div className="verdict-assessments-container" ref={this._setContent}>
					<div className="inputs-container" style={{ padding: 0 }}>
						<div className="df aic" style={{ flex: 1 }}>
							<DatePickers
								startDate={this.state.start}
								endDate={this.state.end}
								onChange={this.onDateChange}
							/>

							<Button type="secondary" onClick={this.onSubmitDates}>
								{translate('Update')}
							</Button>
						</div>

						<div className="filters">
							<div>
								<Checkbox
									label={translate('Learning goals')}
									value={this.state.showLearningGoals}
									onChange={(active) => this.setState({ showLearningGoals: active })}
								/>
							</div>

							<div>
								<Checkbox
									label={translate('Knowledge requirements')}
									value={this.state.showKnowledgeRequirements}
									onChange={(active) => this.setState({ showKnowledgeRequirements: active })}
								/>
							</div>
						</div>
					</div>

					<div className="form-row" style={{ marginTop: '1rem' }}>
						{!this.state.loading ?
							<VerdictAssessmentsDisplay
								studentId={activeStudent.id}
								start={this.state.currentStart}
								end={this.state.currentEnd}
								courseId={this.props.course.id}
								showGoals={this.state.showLearningGoals}
								showMatrices={this.state.showKnowledgeRequirements}
							/>
							: null}
					</div>
				</div>

			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		tenant: state.user.tenant,
		services: state.Services.availableServices,
		userVerdicts: state.Verdicts.user,
	}
}

export default connect(mapStateToProps, {
	getStudentAdjustmentsBySubject,
	deleteVerdict,
	getAssignmentAssessments,
	getPlanAssessments,
	getVerdictsForUser,
})(VerdictForm);