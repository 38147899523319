import React, { useEffect, useMemo, useState } from 'react';
import Moment from 'moment';
import DateTime from '_class/DateTime';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'lib/translate';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { setPageTitle } from 'actions/header';
import { getCourse, setActiveCourse } from 'actions/courses';
import { getSection, setActiveSection } from 'actions/sections';
import { getUserInfo } from 'actions/user';
import { getStudentAdjustmentsBySubject } from 'actions/additional_adjustments';
import {
	updateVerdict,
	createVerdict,
	deleteVerdict,
	getVerdictsForUser,
	getSectionVerdicts,
} from 'actions/verdicts';

import { getUserAssessmentDates } from 'helpers/user';

import InputVerdictForm from 'containers/Verdict/Form/VerdictForm';
import TeacherAdjustments from 'containers/AdditionalAdjustments/Display/TeacherAdjustments';
import VerdictWidget from 'containers/Verdict/Display/VerdictWidget';
import VerdictDisplay from 'containers/Verdict/Display/VerdictDisplay';
import DatePickers from "containers/Progress/Partials/DatePickers";
import UserAssessments from 'containers/Progress/Partials/UserAssessments';
import StudentAdjustmentForm from 'containers/AdditionalAdjustments/Form/StudentAdjustmentForm';
import Modal from 'containers/Modals/Modal';

import { Collapsible, Spinner } from 'UI';
import { Button, Checkbox, Expandable, Icon } from '@haldor/ui';

const fixLocalHistory = () => {
	if (localStorage.getItem('history') != null) {
		let history = JSON.parse(localStorage.getItem('history'));
		history.shift();
		localStorage.setItem('history', JSON.stringify(history));
	}
}

const NewVerdictPage = (props) => {
	const dispatch = useDispatch();
	const translate = useTranslate();
	const params = useParams();
	const history = useHistory();

	const tenant = useSelector((state) => state.user.tenant);
	const userVerdicts = useSelector((state) => state.Verdicts.user);
	const course = useSelector((state) => state.Courses.course);
	const adjustments = useSelector((state) => state.AdditionalAdjustments.studentAdjustmentsBySubject);
	const section = useSelector((state) => state.sections.activeSection);
	const activeStudent = useSelector((state) => state.user.info?.find((user) =>
		user.userId == params.userId
	));

	const [showLearningGoals, setLearningGoals] = useState(true);
	const [showKnowledgeRequirements, setKnowledgeRequirements] = useState(true);
	const [showOnlyMyAssessments, setShowOnlyMyAssessments] = useState(false);

	const [dates, setDates] = useState(getUserAssessmentDates());
	const [currentDates, setCurrentDates] = useState(getUserAssessmentDates());
	const views = { overview: 1, mixed: 2, assessment: 3 }
	const [currentView, setCurrentView] = useState(views.mixed);
	const [verdictsLoading, setVerdictsLoading] = useState(false);
	const [adjustmentModal, setAdjustmentModal] = useState(false);

	const services = useSelector((state) => state.Services.availableServices);

	useEffect(() => {
		return () => {
			dispatch(setActiveCourse());
			dispatch(setActiveSection());
		};
	}, []);

	useEffect(async () => {
		dispatch(setPageTitle(translate('New evaluation')));

		setVerdictsLoading(true);

		await Promise.all([
			dispatch(getCourse(params.courseId)),
			dispatch(getSectionVerdicts(params.groupId, params.courseId)),
			dispatch(getUserInfo(params.userId, 'extended')),
			dispatch(getSection(params.groupId)),
			dispatch(getVerdictsForUser(params.userId, params.courseId))]);

		setVerdictsLoading(false);
	}, [params.groupId, params.userId, params.courseId]);

	useEffect(() => {
		if (course != null) {
			dispatch(getStudentAdjustmentsBySubject(params.groupId, course.subjectId, params.userId));
		}
	}, [course]);

	useEffect(() => {
		if (activeStudent != null) {
			let hasSameName = false;

			if (section != null){
				hasSameName = section.students.filter(x =>
					x.firstName === activeStudent.firstName && x.lastName === activeStudent.lastName
					).length > 1;
			}

			if(hasSameName){
				dispatch(setPageTitle(translate('New evaluation') + ' ' 
				+ activeStudent.firstName + ' ' + activeStudent.lastName + ' ('
				+ activeStudent.email + ')'));
			} else{
				dispatch(setPageTitle(translate('New evaluation') + ' ' + activeStudent.firstName + ' ' + activeStudent.lastName));
			}
		}
	}, [activeStudent, section]);

	const getStudents = () => {
		return section.students?.sort((a, b) =>
			(a.lastName || "").localeCompare(b.lastName || "")
		);
	};

	const studentIndex = useMemo(() => {
		if (section == null) {
			return null;
		}

		return getStudents().findIndex((student) => student.userId == params.userId);
	}, [section]);

	const onDateChange = ({ start, end }) => {
		if (start == "Invalid date") {
			start = Moment().subtract(1, "years");
		}
		if (end == "Invalid date") {
			end = Moment().add(1, "years");
		}

		setDates({
			start: new DateTime(start).getDateStamp(),
			end: new DateTime(end).getDateStamp()
		});
	};

	const reload = async () => {
		setVerdictsLoading(true);
		await dispatch(getVerdictsForUser(params.userId, params.courseId));
		setVerdictsLoading(false);
	};

	const onSubmitDates = (event) => {
		event.preventDefault();

		setCurrentDates(dates);
	};

	const onVerdictSubmit = (values) => {
		return new Promise(async (resolve) => {
			values.userId = params.userId;
			values.courses = [{
				"courseCode": course.courseCode,
				"subjectCode": course.subjectCode,
				"courseID": course.id,
			}];

			if (values.id != null) {
				await dispatch(updateVerdict(values));
			} else {
				await dispatch(createVerdict(values));
			}

			await dispatch(getVerdictsForUser(params.userId, params.courseId));
			await dispatch(getSectionVerdicts(params.groupId, params.courseId));

			resolve(1);
		});
	};

	const onDeleteVerdict = (id) => {
		let userId = null;

		if (userVerdicts != null && userVerdicts.length > 0) {
			userId = userVerdicts[0].userId;
		}

		setVerdictsLoading(true);

		return new Promise((resolve) => {
			dispatch(deleteVerdict(id)).then(async () => {
				if (userId != null) {
					await dispatch(getVerdictsForUser(userId, params.courseId));
					setVerdictsLoading(false);
				}

				resolve(1);
			});
		});
	};

	const renderVerdict = (verdict, index) => {
		if (verdict == null || verdict.status == 'VERDICT_DRAFT') {
			return null;
		}

		let date = tenant.currentSchoolYear != null ? tenant.currentSchoolYear : '2017-01-01';
		let start = new DateTime(date).getDateStamp();
		let end = new DateTime(verdict.published).getDateStamp();

		let verdictBefore = userVerdicts[index + 1];
		if (verdictBefore != null) {
			start = new DateTime(verdictBefore.published).getDateStamp();
		}

		const trigger = (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<div style={{ flexGrow: 1 }}>
					{new DateTime(verdict.published).getLongDateWithTime()}
				</div>

				<div style={{ flexGrow: 0 }}>
					<VerdictWidget verdict={verdict} />
				</div>
			</div>
		);

		return (
			<div className="verdict verdict-entry" key={verdict.id}>
				<Collapsible trigger={trigger}>
					<VerdictDisplay
						verdict={verdict}
						reload={reload}
						setLoading={(loading) => console.log('set loading', loading)}
						course={course}
					/>
				</Collapsible>
			</div>
		);
	};

	const toggleIcon = (flip) => {
		return (
			<i className="cl-container" style={{ margin: 0 }}>
				<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" className={flip ? 'i-90' : 'a-90'} style={{ height: '7px', width: '15px' }}>
					<path className="cls-1" d="M11.361,1.4,6.38,5.9,1.4,1.4" />
				</svg>
			</i>
		);
	}

	const renderStatus = () => {
		if (userVerdicts != null && userVerdicts.length > 0 && userVerdicts[0].status == 'VERDICT_DRAFT') {
			return (
				<div className="info-draft">
					{translate('Status')}:

					<span className="draft-badge">
						<div className="dot" style={{ backgroundColor: '#eec157' }} />
						{translate('Draft')}
					</span>
				</div>
			)
		} else {
			return null;
		}
	}

	const goBack = (event) => {
		event.preventDefault();
		if (studentIndex < 1) {
			return '#';
		}

		fixLocalHistory();

		const newStudent = getStudents()[studentIndex - 1].userId;
		history.replace(`/groups/${params.groupId}/course/${params.courseId}/student/${newStudent}`);
	}

	const goNext = (event) => {
		event.preventDefault();
		if (studentIndex + 1 == section?.students.length) {
			return '#';
		}

		fixLocalHistory();

		const newStudent = getStudents()[studentIndex + 1].userId;
		history.replace(`/groups/${params.groupId}/course/${params.courseId}/student/${newStudent}`);
	}

	if (course == null || activeStudent == null) {
		return (
			<div className="attached-user-documents">
				<div className="iframe-section left-side" style={{ backgroundColor: 'transparent' }}>
					<Spinner center />
				</div>
			</div>
		)
	}

	let sidebarClasses = 'sidebar';
	let overviewClasses = 'iframe-section left-side';
	if (currentView == views.assessment) {
		sidebarClasses += ' full-width';
		overviewClasses += ' zero-width'
	}
	else if (currentView == views.overview) {
		sidebarClasses += ' zero-width';
		overviewClasses += ' full-width'
	}

	return (
		<div>
			<div className="verdict view-control">
				<div className={currentView == views.assessment ? 'expand view-button selected' : 'expand view-button'} onClick={() => setCurrentView(views.assessment)}>
					<Icon name="AssessmentView3" />
				</div>
				<div className={currentView == views.mixed ? 'expand view-button selected' : 'expand view-button'} onClick={() => setCurrentView(views.mixed)} >
					<Icon name="AssessmentView2" />
				</div>
				<div className={currentView == views.overview ? 'expand view-button selected' : 'expand view-button'} onClick={() => setCurrentView(views.overview)}>
					<Icon name="AssessmentView1" />
				</div>
			</div>
			<div className="attached-user-documents form form-component verdict">
				<Modal isOpen={adjustmentModal} onClose={() => setAdjustmentModal(false)} title={translate('assign-adjustment')}>
					<StudentAdjustmentForm
						onClose={() => setAdjustmentModal(false)}
						course={course}
						userId={params.userId}
					/>
				</Modal>

				<div className={overviewClasses} style={{ backgroundColor: 'transparent' }}>
					<div className="verdict-assessments-container">
						<div className="inputs-container" style={{ padding: 0 }}>
							<DatePickers
								startDate={dates.start}
								endDate={dates.end}
								onChange={onDateChange}
							/>

							<Button type="secondary" onClick={onSubmitDates}>
								{translate('Update')}
							</Button>

							<div className='row align-items-right'>
								<div className='checkbox mr-3'>
									<Checkbox
										label={translate('Show only my assessments')}
										value={showOnlyMyAssessments}
										onChange={setShowOnlyMyAssessments}
									/>
								</div>

								<div className='checkbox mr-3'>
									<Checkbox
										label={translate('Learning goals')}
										value={showLearningGoals}
										onChange={setLearningGoals}
									/>
								</div>

								<div className='checkbox'>
									<Checkbox
										label={translate('Knowledge requirements')}
										value={showKnowledgeRequirements}
										onChange={setKnowledgeRequirements}
									/>
								</div>
							</div>
						</div>

						<div className="form-row" style={{ marginTop: '1rem' }}>
							<UserAssessments
								studentId={params.userId}
								courseId={params.courseId}
								sectionId={null}
								start={currentDates.start}
								end={currentDates.end}
								showGoals={showLearningGoals}
								showMatrices={showKnowledgeRequirements}
								showOnlyMyAssessments={showOnlyMyAssessments}
							/>
						</div>
					</div>
				</div>

				<div className={sidebarClasses}>
					<div>
						<div style={{ padding: '1.55rem' }}>

							<div className="df aic jcb" style={{ marginBottom: '1.55rem' }}>
								<Link to="#" onClick={goBack}>
									{studentIndex > 0 ? toggleIcon(false) : null}
								</Link>

								<strong>
									{translate('new-verdict') + " " + activeStudent.firstName + " " + activeStudent.lastName}
								</strong>

								<Link to="#" onClick={goNext}>
									{studentIndex < (section?.students.length - 1) ? toggleIcon(true) : null}
								</Link>
							</div>

							<div className="place-verdict-container">
								{!verdictsLoading ?
									<>
										{renderStatus()}

										<InputVerdictForm
											onSubmit={onVerdictSubmit}
											duplicate={false}
											edit={false}
											activeStudent={params.userId}
											deleteVerdict={onDeleteVerdict}
											userVerdicts={userVerdicts}
										/>
									</>
									: <Spinner center />}
							</div>
						</div>

						{services.additionalAdjustments ?
							<Expandable title={translate('additional-adjustments')}>
								<TeacherAdjustments
									adjustments={adjustments}
								/>

								<Button type="secondary" onClick={() => { setAdjustmentModal(true) }}>
									<Icon name="Plus" /> {translate('assign-adjustment')}
								</Button>
							</Expandable>
							:
							<div className="form form-row">
								<div className="form-divider" style={{ marginBottom: 40, marginTop: 15 }} />
							</div>
						}

						{userVerdicts != null && (userVerdicts.length > 1 || (userVerdicts.length == 1 && userVerdicts[0].status != 'VERDICT_DRAFT')) ?
							<Expandable title={translate('past-verdicts')}>
								<div className="past-verdicts">
									{userVerdicts.map(renderVerdict)}
								</div>
							</Expandable>
							: null}

					</div>
				</div>
			</div>
		</div >
	);

}

export default NewVerdictPage;