import api from 'lib/api';

export const GET_VERDICT_SCALE = 'VERDICTS_VERDICT_SCALE';
export const GET_USER_VERDICTS = 'VERDICTS_GET_USER_VERDICTS';
export const SUBMIT_VERDICT = 'VERDICTS_POST_VERDICT';
export const UPDATE_VERDICT = 'VERDICTS_PUT_VERDICT';
export const GET_PLAN_ASSESSMENTS = 'VERDICTS_GET_ASSESSMENTS_PLANS';
export const GET_ASSIGNMENT_ASSESSMENTS = 'VERDICTS_GET_ASSESSMENTS_ASSIGNMENT';
export const GET_SECTION_VERDICTS = 'VERDICTS_GET_SECTION_VERDICTS';
export const DUPLICATE_STUDENT_VERDICT = 'VERDICTS_DUPLICATE_STUDENT_VERDICT';
export const DUPLICATE_STUDENTS_VERDICT = 'VERDICTS_DUPLICATE_MULTIPLE_STUDENTS_VERDICT';
export const GET_STUDY_PLAN = 'VERDICTS_GET_STUDY_PLAN_FOR_USER';
export const GET_STUDENT_LAST_VERDICT = 'VERDICTS_GET_STUDENT_LATEST_VERDICT';
export const CLEAR_VERDICTS = 'VERDICTS_CLEAR_VERDICTS';
export const CLEAR_VERDCIT_SCALE = 'VERDICTS_CLEAR_VERDCIT_SCALE';
export const REQUEST_STUDENT_VERDICT = 'VERDICTS_REQUEST_STUDENT_VERDICT';
export const DELETE_VERDICT = 'VERDICTS_DELETE_VERDICT';
export const SUBMIT_BULK_VERDICTS = 'VERDICTS_SUBMIT_BULK_VERDICTS';
export const GET_VERDICTS_FOR_STUDENTS_IN_SECTION = 'VERDICTS_GET_VERDICTS_FOR_STUDENTS_IN_SECTION';
export const GET_STUDENT_COURSES = 'GET_STUDENT_COURSES';
export const RESET_PLAN_ASSESSMENTS = 'RESET_PLAN_ASSESSMENTS';
export const RESET_ASSIGNMENT_ASSESSMENTS = 'RESET_ASSIGNMENT_ASSESSMENTS';

export const getVerdictScale = () => ({
	type: GET_VERDICT_SCALE,
	payload: new Promise(resolve => {
		api.get(`verdict/scale`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getVerdictsForUser = (userId, courseId, start, end) => ({
	type: GET_USER_VERDICTS,
	payload: new Promise(resolve => {
		let url = `users/${userId}/verdicts/course/${courseId}?_=1`;
		if (start != null) {
			url += `&start=${start}`;
		}

		if (end != null) {
			url += `&end=${end}`;
		}

		api.get(url).then((response) => {
			resolve(response.data);
		})
	}),
});

export const createVerdict = (values) => ({
	type: SUBMIT_VERDICT,
	payload: new Promise(resolve => {
		api.post("verdict", values).then((response) => {
			resolve(response.data);
		});
	}),
});

export const updateVerdict = (values) => ({
	type: SUBMIT_VERDICT,
	payload: new Promise(resolve => {
		api.put("verdict", values).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getPlanAssessments = (user, courseId, start, end) => ({
	type: GET_PLAN_ASSESSMENTS,
	payload: new Promise(resolve => {
		let url = `assessments/${user}/course/${courseId}/plans?`;
		if (start != null && end != null) {
			url = url + `startDate=${start}&endDate=${end}`;
		}

		api.get(url).then((response) => {
			resolve(response.data);
		});
	}),
});

export const resetPlanAssessments = () => ({
	type: RESET_PLAN_ASSESSMENTS,
	payload: []
});

export const getAssignmentAssessments = (user, courseId, start, end) => ({
	type: GET_ASSIGNMENT_ASSESSMENTS,
	payload: new Promise(resolve => {
		let url = `assessments/${user}/course/${courseId}/assignments?`;
		if (start != null && end != null) {
			url = url + `startDate=${start}&endDate=${end}`;
		}

		api.get(url).then((response) => {
			resolve(response.data);
		});
	}),
});

export const resetAssignmentAssessments = () => ({
	type: RESET_ASSIGNMENT_ASSESSMENTS,
	payload: []
});

export const getSectionVerdicts = (sectionId, courseId) => ({
	type: GET_SECTION_VERDICTS,
	payload: new Promise(resolve => {
		api.get(`sections/${sectionId}/verdicts/course/${courseId}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const duplicateStudentVerdict = (verdictId) => ({
	type: DUPLICATE_STUDENT_VERDICT,
	payload: new Promise(resolve => {
		const data = [
			verdictId,
		];

		api.post(`verdict/duplicate`, data).then((response) => {
			resolve(response.data);
		});
	}),
});

export const duplicateStudentsVerdict = (ids) => ({
	type: DUPLICATE_STUDENTS_VERDICT,
	payload: new Promise(resolve => {
		api.post(`verdict/duplicate`, ids).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getUserStudyPlan = (studentId) => ({
	type: GET_STUDY_PLAN,
	payload: new Promise(resolve => {
		api.get(`users/${studentId}/studyplans`).then((response) => {
			resolve(response.data);
		});
	}),
});


export const getStudentVerdicts = (studentId, from, to) => ({
	type: GET_STUDENT_COURSES,
	payload: new Promise(resolve => {
		api.get(`users/${studentId}/verdicts?from=${from}&to=${to}`).then((response) => {
			resolve(response.data);
		})
	}),
});

export const getStudentLatestVerdict = (studentId, courseId) => ({
	type: GET_STUDENT_LAST_VERDICT,
	payload: new Promise(resolve => {
		api.get(`users/${studentId}/verdicts/course/${courseId}`).then((response) => {
			resolve(response.data);
		})
	}),
});

export const deleteVerdict = (id) => ({
	type: DELETE_VERDICT,
	payload: new Promise(resolve => {
		api.delete(`verdict/${id}`).then((response) => {
			resolve(response.data);
		})
	}),
});

export const clearVerdicts = () => ({
	type: CLEAR_VERDICTS,
	payload: null
})

export const clearVerdictScale = () => ({
	type: CLEAR_VERDCIT_SCALE,
	payload: null
})

export const requestVerdictForUser = (user, courseId) => ({
	type: REQUEST_STUDENT_VERDICT,
	payload: new Promise((resolve, reject) => {
		api.post(`verdict/notify?studentId=${user}&courseId=${courseId}`, {}).then((response) => {
			// TODO: Handle error here
			if (false) {
				console.log('action notify reject');
				reject(1);
				return;
			}

			resolve(response.data);
		});
	}),
});

export const submitBulkVerdicts = (values) => ({
	type: SUBMIT_BULK_VERDICTS,
	payload: new Promise((resolve, reject) => {
		api.post(`verdict/bulk`, values).then((response) => {
			// TODO: Handle error here
			if (false) {
				reject(1);
				return;
			}

			resolve(response.data);
		});
	}),
});

export const getVerdictsForStudentsInSection = (students, sectionId, start, end) => ({
	type: GET_VERDICTS_FOR_STUDENTS_IN_SECTION,
	payload: new Promise(resolve => {
		let data = JSON.stringify(students);

		api.post(`sections/${sectionId}/verdicts?startDate=${start}&endDate=${end}`, students).then((response) => {
			resolve(response.data);
		});
	}),
})