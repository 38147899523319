import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Moment from 'moment';

import api from 'lib/api';
import { addError } from 'actions';
import { updateAssignmentInList } from 'actions/assignments';
import { createAssessments, updateAssessment, updateAssessmentStatus } from 'actions/assessments';

import SimpleSelect from 'components/Inputs/SimpleSelect';
import AssessmentForm from 'containers/Forms/AssessmentForm';
import Modal from 'containers/Modals/Modal';
import ClearBlockAssessments from 'containers/Forms/Assessment/ClearBlockAssessments';

import swal from 'sweetalert2';

import { Spinner } from 'UI';
import { Checkbox, Icon, Button, translate } from '@haldor/ui';
import DisplayName from 'components/Presentation/DisplayName';

class UserGroupAssignment extends Component {
	constructor(props) {
		super(props);

		this.state = {
			assessmentOpen: false,
			tasks: [],
			locked: false,
			removeAssessments: false,
			assessTask: null,
			assignment: this.props.assignment,
			allSelected: false,
			loadingPermission: false,
		};
	}

	UNSAFE_componentWillMount() {
		this.parseGroups(null);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.assignment != this.props.assignment || nextProps.assignment.locked) {
			this.parseGroups(nextProps);
		}
	}

	parseGroups(nextProps) {
		var assignment = null;
		if (nextProps != null) {
			assignment = nextProps.assignment;
		} else {
			assignment = this.props.assignment;
		}

		var tasks = [];
		assignment.tasks.forEach((item) => {
			if (item != null && item.assignedTo != null) {
				if (item.id != -1) {
					var assignedUsers = item.assignedTo.split(';');
					var students = [];

					assignedUsers.forEach((user) => {
						var findStudent = this.props.activeSection.students.find((t) => t.id == user);
						if (findStudent != null) {
							findStudent.referenceId = item.id;
							students.push(findStudent);
						}
					});

					students.sort((a, b) => {
						if (a.lastName != null && b.lastName != null) {
							return a.lastName.localeCompare(b.lastName);
						}

						return 0;
					});

					var task = item;
					task.students = students;
					tasks.push(task);
				}
			}
		});

		this.setState({ tasks, locked: assignment.locked });
	}

	publishAllAssessments = () => {
		const { addError, translate, assessments } = this.props;
		const { tasks } = this.props.assignment;

		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('publish-all-assessments-warning'),
			showCancelButton: true,
			focusConfirm: false,
			cancelButtonText: this.props.translate('No'),
			confirmButtonText: this.props.translate('yes-publish'),
		}).then((result) => {
			if (result.value != null) {
				var requests = 0;
				tasks.map((task) => {
					if (task.status == 'ASSIGNMENT_TASK_SUBMITTED') {
						task.students.map((student) => {
							let userAssessment = assessments.find((assessment) => {
								return assessment.referenceId == task.id && assessment.studentId == student.id;
							});

							if (userAssessment != null) {
								if (userAssessment.status != 'ASSESSMENT_PUBLISHED') {
									requests++;
									api.put(`assessments/status?assessmentId=${userAssessment.id}&status=ASSESSMENT_PUBLISHED`, null).then(() => {
										requests--;

										if (requests == 0) {
											addError(translate('changes-saved'), 'info');
											this.props.reload(true);
										}
									});
								}
							}
						});
					}
				});
			}
		});
	};

	closeAssessment = (skipPrompt) => {
		if (skipPrompt) {
			this.props.reload(true);
			this.unselectAll();
			this.setState({ assessmentOpen: false });
		} else {
			this.setState({ assessmentOpen: false });
		}
	};

	openAssessment = (task, user) => {
		if (user != null) {
			this.state.tasks.forEach((task) => {
				task.students.forEach((student) => (student.selected = false));
				return task;
			});

			user.selected = true;
		} else {
			this.state.tasks.forEach((task) => {
				task.students.forEach((student) => {
					var assessment = this.props.assessments.find((t) => t.studentId == student.id);
					if (assessment != null) {
						student.selected = false;
					}
				});
			});
		}

		this.setState({ assessmentOpen: true });
	};

	onAssessmentSubmit = (values) => {
		return new Promise((resolve) => {
			if (values.id != null) {
				const existing = this.props.assessments.find((assessment) => assessment.id == values.id);
				this.props.updateAssessment(values, existing).then(() => {
					resolve(1);
					this.closeAssessment(true);
				});

				return true;
			}

			let data = [];
			let selectedStudents = [];
			this.state.tasks.forEach((task) => {
				task.students.forEach((student) => {
					if (student.selected) {
						selectedStudents.push({
							status: task.status,
							groupName: student.firstName + ' ' + student.lastName,
							assignedTo: student.userId,
							...student
						});
					}
				})
			});

			selectedStudents.forEach((student) => {
				data.push({
					...values,
					assignmentId: this.props.assignment.id,
					referenceId: student.referenceId,
					studentId: student.userId,
				});
			});

			this.props.createAssessments(data).then(() => {
				resolve(1);
				this.closeAssessment(true);
			});
		});
	}

	onStatusSelect = (item, event) => {
		item.disabled = true;
		this.setState({});

		api.put('assignments/Student/Status?assignmentTaskId=' + item.id + '&status=' + event.value)
			.then(() => {
				item.disabled = false;
				item.status = event.value;
				this.setState({});
				this.props.addError(this.props.translate('changes-saved'), 'info');
			});
	};

	onAssessmentStatusSelect = (item, value) => {
		var assignment = this.state.assignment;

		if (assignment != null) {
			/**
			 * Update the publishedAssessments count based on assessment status
			 */
			if (value != null && value.value == 'ASSESSMENT_PUBLISHED') {
				assignment.publishedAssessments = assignment.publishedAssessments == null ? 1 : assignment.publishedAssessments + 1;
			} else {
				assignment.publishedAssessments = assignment.publishedAssessments == null ? 0 : assignment.publishedAssessments - 1;
			}

			this.props.updateAssignmentInList(assignment);
			this.setState({ assignment });
		}

		var assessment = this.props.assessments.find((t) => t.studentId == item.id);
		if (assessment != null) {
			this.props.updateAssessmentStatus(assessment.id, value.value).then(() => {
				this.props.addError(this.props.translate('changes-saved'), 'info');
				this.props.reload(true);
			});
		}
	};

	onSelect = (user, checked) => {
		user.selected = checked;
		this.setState({});
	};

	markForConversation = (task) => {
		const student = task;

		if (this.props.onConversation != null) {
			this.props.onConversation(student);
		}
	};

	handleLocked = (locked) => {
		//get the task for the selected users
		let task = [];
		this.props?.assignment?.tasks.map((item) => {
			return item.assignmentTaskAssignees.map((assignees) => {
				if (locked) {
					if (assignees.permission === '5' || assignees.permission === '2') {
						task.push(assignees);
					}
				}
				else if (assignees.permission === '0') {
					task.push(assignees);
				}
			});
		});
		if (task.length > 0) {
			this.setState({ loadingPermission: true });
			let assignmentId = this.props.assignment.id;
			if (locked) {
				api.delete(`assignments/${assignmentId}/workspacepermissions`, task).then((response) => {
					this.props?.assignment?.tasks.map((item) => {
						return item.assignmentTaskAssignees.map((assignees) => {
							console.log(assignees);
							let findItem = response.data.find(
								(x) => x.assigneeId == assignees.assigneeId
							);
							if (findItem) {
								assignees.permission = findItem.permission;
							}
						});
					});
					this.onCheckAll(false);
					this.setState({ loadingPermission: false });
					return;
				}).catch((e) => {
					this.setState({ loadingPermission: false });
					this.onCheckAll(false);
				});
				return;
			}
			api.put(`assignments/${assignmentId}/workspacepermissions`, task)
				.then((response) => {
					this.props?.assignment?.tasks.map((item) => {
						return item.assignmentTaskAssignees.map((assignees) => {
							console.log(assignees);
							let findItem = response.data.find(
								(x) => x.assigneeId == assignees.assigneeId
							);
							if (findItem) {
								assignees.permission = findItem.permission;
							}
						});
					});
					this.onCheckAll(false);
					this.setState({ loadingPermission: false });
					return;
				})
				.catch((e) => {
					this.setState({ loadingPermission: false });
					this.onCheckAll(false);
				});
		} else {
			this.onCheckAll(false);
		}
	};

	onCheckAll = (checked) => {
		this.state.tasks.forEach((task) => {
			task.students.forEach((student) => {
				var assessment = this.props.assessments.find((t) => t.studentId == student.id);
				if (assessment != null) {
					student.selected = false;
				} else {
					student.selected = student.selected ? false : true;
				}
			});
		});

		this.setState({ assessTask: checked, allSelected: checked });
	};

	unselectAll = () => {
		this.state.tasks.forEach((task) => {
			task.students.forEach((student) => {
				student.selected = false;
			});
		});

		this.setState({});
	};

	renderGroupBody = () => {
		var rootUrl = this.props.activeSection.classroomUrl;
		var rootUri = new URL(rootUrl);
		var segments = rootUri.pathname.split('/');
		rootUrl = rootUrl.substring(0, rootUrl.indexOf('/' + segments[1] + '/'));

		return this.state.tasks.sort((a, b) =>
			isNaN(parseInt(a.groupName)) || isNaN(parseInt(b.groupName)) ?
				a.groupName.localeCompare(b.groupName, undefined, { numeric: true })
				: parseInt(a.groupName) - parseInt(b.groupName))
			.map((item) => {

				let disableAssessmentButtons = false;

				if (item.status == 'ASSIGNMENT_TASK_STARTED' || item.status == 'ASSIGNMENT_TASK_NOT_STARTED') {
					disableAssessmentButtons = true;
				}

				return (
					<tbody key={item.id}>
						<tr>
							<td></td>
							<td className='name' style={{ fontSize: '1.15em' }}>
								{item.groupName}
							</td>

							<td className='status'>
								<SimpleSelect
									selectedValue={item.status}
									disabled={
										this.props.printable ||
										this.props.assignment.created == '0001-01-01T00:00:00' ||
										this.state.locked ||
										item.disabled
									}
									name='statusSwitcher'
									item={item}
									type='assignment'
									onChange={this.onStatusSelect}
								/>
							</td>

							<td className='status'></td>

							<td></td>

							<td>
								{this.props.assignment.created == '0001-01-01T00:00:00' ||
									this.props.printable == true || item.disabled ? null : (
									<div>
										{item.workspace ?
											<a
												className='sp-target-link'
												style={{ marginTop: 0 }}
												href={rootUrl + item.workspace}
												target='_blank'
											>
												<div>
													<Icon name='Folder' />
													<span>
														{` ${this.props.translate('workspace')}`}
													</span>
												</div>
											</a>
											:
											<div title={this.props.translate('Missing-workspace')}>
												<Icon name='Alert_Red' />
												<span>
													{` ${this.props.translate('workspace')}`}
												</span>
											</div>}
									</div>

								)}
							</td>
						</tr>

						{item.students
							.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""))
							.map((user) => {
								var assessment = this.props.assessments.find((t) => t.studentId == user.id);

								let conversation = null;
								if (this.props.conversations != null) {
									conversation = this.props.conversations.find((conv) => {
										return conv.creator == user.id;
									});
								}

								let locked = item?.assignmentTaskAssignees.filter((x) => x.permission === '0' && x.assigneeId == user.id).length > 0;

								return (
									<tr key={user.id} className='no-hover'>
										<td>
											{this.props.printable == false ? (
												<Checkbox
													disabled={
														this.props.assignment.created == '0001-01-01T00:00:00' ||
														this.state.locked ||
														disableAssessmentButtons
													}
													label={
														<span style={{ marginLeft: '1rem' }}>
															{locked ? <Icon name='Lock' /> : null}
														</span>
													}
													value={user.selected}
													onChange={(checked) => this.onSelect(user, checked)}
												/>
											) : null}
										</td>

										<td className='name'>
											<DisplayName
												firstName={user.firstName}
												lastName={user.lastName}
												email={user.email}
												data={this.props.activeSection.students}
											/>
										</td>

										<td></td>

										<td className='status'>
											<SimpleSelect
												selectedValue={assessment?.status}
												name='assessment-status'
												disabled={
													this.props.assignment.created == '0001-01-01T00:00:00' ||
													this.props.printable == true ||
													this.state.locked ||
													assessment == null
												}
												item={user}
												type='assessment'
												onChange={this.onAssessmentStatusSelect}
											/>
										</td>

										<td></td>

										<td className='make_assessment'>
											{this.props.printable == false ? (
												<Button
													disabled={
														this.props.assignment.created == '0001-01-01T00:00:00' ||
														this.state.locked ||
														disableAssessmentButtons ||
														item.disabled
													}
													type='secondary'
													onClick={() => this.openAssessment(item, user)}
												>
													{this.props.translate('assess')}
												</Button>
											) : null}
										</td>

										{this.props.services.conversations ? (
											<td>
												<Link
													style={{ cursor: 'pointer' }}
													onClick={(e) => this.markForConversation(user)}
												>
													<div style={{ width: '1.75rem', position: 'relative' }}>
														{conversation != null ? (
															<Icon name='Message' />
														) : (
															<Icon name='Message' bw />
														)}

														{conversation != null &&
															conversation.unreadMessages > 0 ? (
															<div className='badge'>
																<span>{conversation.unreadMessages}</span>
															</div>
														) : null}
													</div>
												</Link>
											</td>
										) : null}
									</tr>
								);
							})}
					</tbody>
				);
			});
	};

	render() {
		let selectedStudents = [];
		this.state.tasks.forEach((task) => {
			task.students.forEach((student) => {
				if (student.selected) {
					selectedStudents.push({
						status: task.status,
						groupName: student.firstName + ' ' + student.lastName,
						assignedTo: student.userId,
						...student,
					});
				}
			});
		});

		let unpublishedAssessment = this.props.assessments.find((ass) => {
			return ass.status != 'ASSESSMENT_PUBLISHED';
		});

		let disableAssessmentsButton = true;
		if (unpublishedAssessment != null) {
			disableAssessmentsButton = false;
		}

		let assessAllButtonError = false;
		let disableRemoveAssessmentButton = true;
		selectedStudents.map((student) => {
			if (student.status == 'ASSIGNMENT_TASK_STARTED' || student.status == 'ASSIGNMENT_TASK_NOT_STARTED') {
				assessAllButtonError = true;
			}

			const hasAssessment = this.props.assessments.findIndex((assessment) =>
				assessment.studentId == student.userId
			);

			if (hasAssessment > -1) {
				assessAllButtonError = true;
				disableRemoveAssessmentButton = false;
			}
		});

		let assessmentBlocks = null;
		let assessment = null;
		if (this.props.blocks != null) {
			assessmentBlocks = [...this.props.blocks].filter((block) =>
				block.type == 'Haldor.Blocks.AssessmentBlock' && block.resources.length > 0
			);
		}

		if (selectedStudents.length == 1 && this.props.assessments != null) {
			assessment = this.props.assessments.find((assessment) =>
				assessment.studentId == selectedStudents[0].userId
			)
		}

		return (
			<div>
				{/* <Modal
					isOpen={this.state.assessmentOpen}
					onClose={this.closeAssessment}
					title={this.props.translate('assessment')}
				>
					<AssessmentFormV1
						items={this.state.tasks}
						groupAssignment={true}
						referenceId={this.props.assignment.id}
						onClose={this.closeAssessment}
						referenceType='AssignmentTask'
						section={this.props.activeSection}
					/>
				</Modal> */}

				<Modal
					isOpen={this.state.assessmentOpen}
					onClose={this.closeAssessment}
					title={this.props.translate('Assessment')}
				>
					<AssessmentForm
						onClose={this.closeAssessment}
						onSubmit={this.onAssessmentSubmit}

						items={selectedStudents.map((selected) => ({
							firstname: selected.groupName,
							...selected,
						}))}
						blocks={assessmentBlocks}
						referenceType="AssignmentTask"
						initialValues={assessment}
					/>
				</Modal>

				<Modal
					overridePrompt
					isOpen={this.state.removeAssessments}
					type='small'
					title={this.props.translate('Remove assessments')}
					onClose={() => this.setState({ removeAssessments: false })}
				>
					<ClearBlockAssessments
						items={selectedStudents.map(x => {
							return { assignedTo: x.assignedTo, groupName: x.groupName }
						})}
						assessments={this.props.assessments}
						section={this.props.activeSection}
						onAbort={(reload) => {
							if (reload) this.props.reload(true);

							this.setState({ removeAssessments: false });
						}}
					/>
				</Modal>

				<table className='status-list'>
					<thead>
						<tr>
							<th style={{ padding: '1em 1.5em' }}>
								{this.props.printable == true ||
									this.props.assignment.created == '0001-01-01T00:00:00' ||
									this.state.locked ? null : (
									<Checkbox
										onChange={(event) => this.onCheckAll(event)}
										value={this.state.allSelected}
									/>
								)}
							</th>
							<th>{this.props.translate('name')}</th>
							<th>{this.props.translate('Work status')}</th>
							<th>{this.props.translate('assessment-status')}</th>
							<th></th>
							<th></th>
						</tr>
					</thead>

					{this.renderGroupBody()}
				</table>

				{!this.props.printable ? (
					<Fragment>
						<Button
							disabled={selectedStudents.length == 0 ? true : false}
							style={{ marginLeft: '1rem', marginTop: '1rem' }}
							onClick={() => this.handleLocked(true)}
						>
							{this.state.loadingPermission ? <Spinner small center /> : null}
							{this.props.translate('lock assignment')}
						</Button>

						<Button
							disabled={
								this.state.loadingPermission || selectedStudents.length == 0 ? true : false
							}
							style={{ marginLeft: '1rem', marginTop: '1rem' }}
							onClick={() => this.handleLocked()}
						>
							{this.state.loadingPermission ? <Spinner small center /> : null}
							{this.props.translate('unlock assignment')}
						</Button>
					</Fragment>) : null}
				{!this.props.printable ? (
					<Button
						disabled={
							selectedStudents.length == 0 ||
							this.props.assignment.created == '0001-01-01T00:00:00' ||
							this.state.locked ||
							assessAllButtonError
						}
						style={{ marginLeft: '1rem', marginTop: '1rem' }}
						onClick={() => this.openAssessment()}
					>
						{this.props.translate('assess-selected-students')}
					</Button>
				) : null}

				{!this.props.printable ? (
					<Button
						disabled={
							this.props.assignment.created == '0001-01-01T00:00:00' ||
							this.props.assignment.locked ||
							disableAssessmentsButton
						}
						style={{ marginLeft: '1rem', marginTop: '1rem' }}
						onClick={() => this.publishAllAssessments()}
					>
						{this.props.translate('publish-assessments')}
					</Button>
				) : null}

				{!this.props.printable ? (
					<Button
						disabled={
							disableRemoveAssessmentButton ||
							this.props.assignment.created == '0001-01-01T00:00:00' ||
							this.props.assignment.locked ||
							this.state.loading
						}
						style={{ marginLeft: '1rem', marginTop: '1rem' }}
						onClick={() => this.setState({ removeAssessments: true })}
					>
						{this.state.loading ? <Spinner small center /> : null}

						{this.props.translate('Remove assessments')}
					</Button>
				) : null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		services: state.Services.availableServices,
		assessments: state.assessments.assessments,
		conversations: state.Conversation.referenceConversations,
		activeSection: state.assignments.section,
		translate: translate(state.Languages.translations),
		blocks: state.Blocks.reference,
	};
}

export default connect(mapStateToProps, {
	addError,
	createAssessments,
	updateAssessment,
	updateAssessmentStatus,
	updateAssignmentInList
})(UserGroupAssignment);
