import React, { Component } from 'react';
import { getSection } from 'actions/sections';
import { connect } from 'react-redux';
import User from '_class/User';
import { isValidPhoneNumber } from 'libphonenumber-js';

import { getUsersWithoutMentorGroups, updateUserPhoneNumber } from 'actions/user';

import Modal from 'containers/Modals/Modal';
import UserPhoneForm from 'containers/Forms/UserPhone';

import { Expandable, Spinner } from 'UI';
import { Search } from 'UI/Inputs';
import { BlockContent } from 'UI/Elements/Block';

import { Checkbox, Icon, translate } from '@haldor/ui';

import { appInsights } from 'lib/appInsights';

import './UserSelect.scss';
import DisplayName from 'components/Presentation/DisplayName';

class UserSelect extends Component {

	static defaultProps = {
		userSelectAllowed: true,
		multipleSectionsAllowed: true,
		maximumSectionsAllowed: 0,
		autoExpandSections: true,
		selectMultipleSchools: false,
		showUsersWithoutMentorGroups: false,
		showPhonenumbers: false,
		alwaysFetchStudents: false,
	};

	constructor(props) {
		super(props);

		this.state = {
			relations: [],
			sections: [],
			filter: '',
			myGroups: false,
			educationGroups: true,
			mentorGroups: true,
			editStudent: null,
			isLoading: false,
		};
	}

	onChange = () => {
		if (this.props.input != null && this.props.input.onChange != null && typeof (this.props.input.onChange) == 'function') {
			this.props.input.onChange(this.state.relations);
		}

		if (this.props.onChange != null && typeof (this.props.onChange) == 'function') {
			this.props.onChange(this.state.relations);
		}
	}

	componentDidMount = () => {
		let { input, sections } = this.props;
		const user = new User(this.props.user);
		let edit = false;

		if (this.props.input) {
			if (input.value && input.value.length > 0) {
				edit = true;
				let clonedSections = JSON.parse(JSON.stringify(sections));
				clonedSections.forEach((section) => {
					let relation = input.value.find(t => t.referenceId == section.id && t.referenceType == 'SECTION');
					if (relation != null) {
						section.checked = true;
					} else {
						let userRelation = input.value.find(t => t.parentId == section.id && t.parentType == 'SECTION');
						if (userRelation != null) {
							section.checked = false;
						}
					}
				});

				this.setState({ relations: input.value, sections: clonedSections }, () => {
					this.onChange()
				});
			}
		}

		if (edit == false) {
			this.setState({ sections: JSON.parse(JSON.stringify(sections)) }, () => {
				this.onChange()
			});
		}

		if (user.isAdministrator() || user.isSchoolLeader()) {
			if (this.props.showUsersWithoutMentorGroups) {
				this.props.getUsersWithoutMentorGroups();
			}
		}
	}

	isFetching = (fetching = true) => {
		if (this.props.setIsLoading != null && typeof this.props.setIsLoading == 'function') {
			this.setState({ isLoading: fetching });
			this.props.setIsLoading(fetching);
		}
	}

	toggleMyGroups = (checked) => {
		this.setState({ myGroups: checked });
	}

	toggleAll = () => {
		let sections = this.state.sections;
		const foundSectionRelations = [...this.state.relations].filter(relation => {
			return relation.referenceType == 'SECTION';
		});

		if (foundSectionRelations.length != this.state.sections.length) {
			let relations = [];

			if (this.props.alwaysFetchStudents) {
				sections.forEach(section => {
					if (this.state.myGroups) {
						if (section.userRole != 'OWNER') {
							return false;
						}
					}

					if (!this.state.educationGroups) {
						if (section.type == 'EDUCATION_GROUP') {
							return false;
						}
					}

					if (!this.state.mentorGroups) {
						if (section.type == 'MENTOR_GROUP') {
							return false;
						}
					}

					section.checked = true;
					return section;
				})

				this.setState({ relations, sections }, async () => {
					const mapSeries = async (iterable, action) => {
						for (const x of iterable) {
							await action(x, true)
						}
					}

					await this.toggleStudentsWithoutMentorGroup(true);
					mapSeries(sections, this.onSectionCheckboxClick);
				});
			} else {
				sections.forEach(section => {
					if (this.state.myGroups) {
						if (section.userRole != 'OWNER') {
							return false;
						}
					}

					if (!this.state.educationGroups) {
						if (section.type == 'EDUCATION_GROUP') {
							return false;
						}
					}

					if (!this.state.mentorGroups) {
						if (section.type == 'MENTOR_GROUP') {
							return false;
						}
					}

					section.checked = true;
					if (section.students != null) {
						section.students.forEach(student => {
							student.checked = true;
							return student;
						});
					}

					relations.unshift({
						id: -1,
						referenceId: section.id,
						referenceType: 'SECTION',
						section: section,
					});
				})

				this.setState({ relations, sections }, () => {
					this.toggleStudentsWithoutMentorGroup(true);
				});
			}
		} else {
			sections.forEach(section => {
				section.checked = false;
				if (section.students != null) {
					section.students.forEach(student => {
						student.checked = false;
						return student;
					});
				}

				return section;
			});

			if (this.props.usersWithoutMentorGroups != null) {
				this.props.usersWithoutMentorGroups.forEach(user => {
					user.checked = false;
				});
			}

			this.setState({ relations: [], sections });
		}
	}

	toggleSchool = (school) => {
		school.checked = !school.checked;
		let relations = [...this.state.relations];

		let relation = relations.findIndex(t =>
			t.referenceId == school.id && t.referenceType == 'SCHOOL'
		);

		if (relation == -1) {
			relations.push({
				id: -1,
				referenceId: school.id,
				referenceType: 'SCHOOL',
			});
		} else {
			relations.splice(relation, 1);
		}

		this.setState({ relations }, () => {
			this.onChange();
		});
	}

	onSectionCheckboxClick = (section, forceActive = false) => {
		return new Promise(async (resolve) => {
			section.checked = !section.checked;
			if (forceActive) {
				section.checked = true;
			}

			let relations = [...this.state.relations];

			if (section.checked) {
				let relation = relations.find(t =>
					t.referenceId == section.id && t.referenceType == 'SECTION'
				);

				if (relation == null) { //Add checked section
					relations.unshift({
						id: -1,
						referenceId: section.id,
						referenceType: 'SECTION',
						section: section,
					});

					if (section.students != null) {
						section.students.forEach((student) => {
							let studentRelation = relations.find(t =>
								t.referenceId == student.id && t.referenceType == 'USER' && t.parentId == section.id && t.parentType == 'SECTION'
							);

							if (studentRelation == null) {
								// Student doesnt have relation, add it
								let relation = {
									id: -1,
									referenceId: student.id,
									referenceType: 'USER',
									parentId: section.id,
									parentType: 'SECTION',
								}

								if (student.mobilePhone != null) {
									relation.mobilePhone = student.mobilePhone;
								}

								relations.push(relation);
							}

							student.checked = true
						});
					}
				}

				if (this.props.alwaysFetchStudents && section.students == null) {
					section.loading = true;
					this.isFetching(true);
					this.props.getSection(section.id).then(() => {
						this.isFetching(false);
						section.students = this.props.group.students;
						if (this.state.relations.find(t => t.referenceId == section.id && t.referenceType == 'SECTION') != null) {
							if (section.students != null) {
								section.students.forEach((student) => student.checked = true);
							}
						}

						if (section.students != null) {
							section.students.forEach((student) => {
								let relation = relations.find(t =>
									t.referenceId == student.id && t.referenceType == 'USER' && t.parentId == section.id && t.parentType == 'SECTION'
								);

								if (relation != null) {
									student.checked = true;
								} else {
									if (section.checked) {
										let relation = {
											id: -1,
											referenceId: student.id,
											referenceType: 'USER',
											parentId: section.id,
											parentType: 'SECTION',
											student: student,
										};

										if (student.mobilePhone != null) {
											relation.mobilePhone = student.mobilePhone;
										}

										student.checked = true;
										relations = [...relations, relation];
									}
								}
							});
						}

						section.loading = false;

						this.setState({ relations }, () => {
							this.onChange();
							resolve(1);
						});
					});

					return true;
				}

				this.setState({ relations }, () => {
					this.onChange();
					resolve(1);
				});
			} else {
				// Remove section relationship
				relations = [...relations.filter((t) => {
					return (t.referenceId == section.id && t.referenceType == 'SECTION') == true ? false : true;
				})];

				// Remove all user relations on this section
				relations = [...relations.filter((t) => {
					return (t.parentId == section.id && t.referenceType == 'USER') == true ? false : true;
				})];

				// Set all students to unchecked
				if (section.students != null) {
					section.students.forEach((student) => student.checked = false);
				}

				section.checked = false;
			}

			this.setState({ relations }, () => {
				this.onChange()
				resolve(1);
			});
		})
	}

	onStudentSubmit = (values) => {
		return new Promise((resolve) => {
			let { sections, relations } = this.state;
			const mobilePhone = values.mobilePhone == '' || values.mobilePhone == null ? null : values.mobilePhone;

			this.props.updateUserPhoneNumber(values).then(() => {
				resolve(1);

				sections.map(section => {
					if (section.students != null) {
						section.students.map(student => {
							if (student.id == values.id) {
								student.mobilePhone = mobilePhone;
							}

							return student;
						});
					}

					return section;
				});

				relations.map(relation => {
					if (relation.referenceId == values.id) {
						relation.mobilePhone = mobilePhone;
					}

					return relation;
				});

				this.setState({ sections, relations, editStudent: null }, () => {
					const user = new User(this.props.user);
					if (user.isAdministrator() || user.isSchoolLeader()) {
						if (this.props.showUsersWithoutMentorGroups) {
							this.props.getUsersWithoutMentorGroups();
						}
					}

					this.onChange();
				});
			});
		})
	}

	onUserCheckboxClick = (user, section) => {
		user.checked = !user.checked;

		let relations = [...this.state.relations]

		if (user.checked) {
			let relation = relations.find(t =>
				t.referenceId == user.id && t.referenceType == 'USER' && t.parentId == section.id && t.parentType == 'SECTION'
			);

			if (relation == null) { //Add checked user
				section.checked = false;

				let relation = {
					id: -1,
					referenceId: user.id,
					referenceType: 'USER',
					parentId: section.id,
					parentType: 'SECTION',
					student: user,
				};

				if (user.mobilePhone != null) {
					relation.mobilePhone = user.mobilePhone;
				}

				relations = [...relations, relation];
			}
		} else { //Removed unchecked user
			relations = [...relations.filter((t) => {
				return (t.referenceId == user.id && t.referenceType == 'USER' && t.parentId == section.id) == true ? false : true;
			})];
		}

		let sectionRelation = relations.find(t =>
			t.referenceId == section.id && t.referenceType == 'SECTION'
		);

		// Check if there is an unchecked student
		let uncheckedStudent = section.students.find(student => {
			return !student.checked;
		});

		if (sectionRelation != null) {
			// The current section has a reference
			if (uncheckedStudent != null) {
				// Remove section reference
				relations = [...relations.filter((t) => {
					return (t.referenceId == section.id && t.referenceType == 'SECTION') == true ? false : true;
				})];

				// Section is not checked because all students isnt selected
				section.checked = false;
			}
		} else {
			// The current section has no reference
			if (uncheckedStudent == null) {
				// Add section reference if no uncheckedStudent is found (all students checked)
				relations.unshift({
					id: -1,
					referenceId: section.id,
					referenceType: 'SECTION',
					section: section,
				});

				// Section is checked because all students is selected
				section.checked = true;
			}
		}

		this.setState({ relations, section }, () => {
			this.onChange();
		});
	}

	onOpenSection = (section) => {
		section.open = !section.open;

		this.setState({});

		if (section.students != null && section.students.length == 0) {
			appInsights.trackTrace({ message: "EDU022 | UserSelect - no students for: " + section.id });
		}

		if (!section.open || (section.students != null && section.students.length > 0)) {
			return true;
		}

		section.loading = true;
		let relations = [...this.state.relations];
		this.isFetching(true);
		this.props.getSection(section.id).then(() => {
			this.isFetching(false);
			section.students = this.props.group.students;
			if (this.state.relations.find(t => t.referenceId == section.id && t.referenceType == 'SECTION') != null) {
				if (section.students != null) {
					section.students.forEach((student) => student.checked = true);
				}
			}

			if (section.students != null) {
				section.students.forEach((student) => {
					let relation = this.state.relations.find(t =>
						t.referenceId == student.id && t.referenceType == 'USER' && t.parentId == section.id && t.parentType == 'SECTION'
					);

					if (relation != null) {
						student.checked = true;
					} else {
						let relation = {
							id: -1,
							referenceId: student.id,
							referenceType: 'USER',
							parentId: section.id,
							parentType: 'SECTION',
							student: student,
						};

						if (student.mobilePhone != null) {
							relation.mobilePhone = student.mobilePhone;
						}

						if (section.checked) {
							relations = [...relations, relation];
						}
					}
				});
			}

			section.loading = false;

			this.setState({ relations }, () => {
				this.onChange();
			});
		});
	}

	toggleStudentsWithoutMentorGroup = (checked) => {
		return new Promise((resolve) => {
			// Remove or add relationships here
			let relations = [...this.state.relations]

			if (checked) {
				if (this.props.usersWithoutMentorGroups != null) {
					this.props.usersWithoutMentorGroups.forEach(user => {
						let foundRelation = this.state.relations.findIndex(relation =>
							relation.referenceId == user.userId
						);

						if (foundRelation == -1) {
							user.checked = true;

							let relation = {
								id: -1,
								referenceId: user.userId,
								referenceType: 'USER',
								student: user,
							}

							if (user.mobilePhone != null) {
								relation.mobilePhone = user.mobilePhone;
							}

							relations = [...relations, relation];
						}
					});
				}
			} else {
				if (this.props.usersWithoutMentorGroups != null) {
					this.props.usersWithoutMentorGroups.forEach(user => {
						user.checked = false;
						relations = [...relations.filter((t) => {
							return !(t.referenceId == user.userId && t.referenceType == 'USER')
						})];
					});
				}
			}

			this.setState({ relations }, () => {
				this.onChange();
				resolve(1);
			});
		})
	}

	toggleStudentWithoutMentorGroup = (user) => {
		user.checked = !user.checked;

		let relations = [...this.state.relations]

		if (user.checked) {
			let relation = relations.find(t =>
				t.referenceId == user.userId && t.referenceType == 'USER'
			);

			if (relation == null) { //Add checked user
				let relation = {
					id: -1,
					referenceId: user.userId,
					referenceType: 'USER',
					student: user,
				};

				if (user.mobilePhone != null) {
					relation.mobilePhone = user.mobilePhone;
				}

				relations = [...relations, relation];
			}
		} else { //Removed unchecked user
			relations = [...relations.filter((t) => {
				return !(t.referenceId == user.userId && t.referenceType == 'USER')
			})];
		}

		this.setState({ relations }, () => {
			this.onChange();
		});
	}

	renderUsersWithoutMentorGroup = () => {
		if (this.props.usersWithoutMentorGroups == null || this.props.usersWithoutMentorGroups.length == 0) {
			return null;
		}

		let isAllChecked = true;

		this.props.usersWithoutMentorGroups.forEach(user => {
			let foundRelation = this.state.relations.findIndex(relation =>
				relation.referenceId == user.userId
			);

			if (foundRelation == -1) {
				isAllChecked = false;
			}
		})

		const title = (
			<div>
				<Checkbox
					onChange={this.toggleStudentsWithoutMentorGroup}
					label={this.props.translate('Students without mentor groups')}
					value={isAllChecked}
				/>
			</div>
		);

		return (
			<Expandable
				title={title}
				ignorePropsUpdate
				onClick={toggle => { return false; }}
				onPlus={toggle => { toggle() }}
			>
				<div className="user-select-student-list" >
					{this.props.usersWithoutMentorGroups
						.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""))
						.map(user => {
							let label = user.firstName + ' ' + (user.lastName || '');
							let checked = this.state.relations.findIndex(relation =>
								relation.referenceId == user.userId && relation.parentId == null
							) > -1;

							if (this.props.showPhonenumbers) {
								label = (
									<div className="df x">
										<div style={{ flex: 1 }}>
											{user.firstName} {user.lastName || ''}
										</div>

										{user.mobilePhone != null ?
											<div style={{ flex: 1 }}>
												{user.mobilePhone.length > 4 && isValidPhoneNumber(user.mobilePhone) ?
													user.mobilePhone
													:
													<span>
														<Icon name={'Alert_Red'} />
														{this.props.translate('Phone number invalid')}
													</span>
												}
											</div>
											:
											<div style={{ flex: 1 }}>
												<Icon name={'Alert_Red'} />
												{this.props.translate('Phone number missing')}
											</div>
										}

										<div onClick={(e) => this.onStudentEdit(e, user)}>
											<Icon name="Pen_Small" />
										</div>
									</div>
								)
							}

							return <div key={user.userId}>
								<Checkbox
									label={label}
									onChange={() => this.toggleStudentWithoutMentorGroup(user)}
									value={checked}
								/>
							</div>
						})}
				</div>
			</Expandable>
		);
	}

	onStudentEdit = (event, student) => {
		event.stopPropagation();
		this.setState({ editStudent: student });
	}

	renderSection = (section) => {
		if (this.state.myGroups) {
			if (section.userRole != 'OWNER') {
				return null;
			}
		}

		if (!this.state.educationGroups) {
			if (section.type == 'EDUCATION_GROUP') {
				return null;
			}
		}

		if (!this.state.mentorGroups) {
			if (section.type == 'MENTOR_GROUP') {
				return null;
			}
		}

		const title = (
			<div>
				<Checkbox
					value={section.checked}
					onChange={() => this.onSectionCheckboxClick(section)}
					label={(<div style={{ display: 'flex', alignItems: 'center' }}>
						<div>
							{section.title}
						</div>

						<div className="card-meta" style={{ margin: 0, marginLeft: '.55rem' }}>
							{section.loading ?
								this.props.translate('loading') + '...'
								:
								this.props.translate(section.type)
							}
						</div>
					</div>)}
				/>
			</div>
		);

		return (
			<Expandable
				title={title}
				key={section.id}
				onPlus={toggle => { this.onOpenSection(section) }}
				onClick={toggle => { return false; }}
				open={section.open}
				headerStyles={{ padding: '0 !important' }}
			>
				{section.error ?
					<div className="section-error">
						{section.error}
					</div>
					: null}

				{section.loading ?
					<Spinner small center />
					: null}

				{!section.loading ?
					<div className="user-select-student-list" >
						{section.students ? section.students
							.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""))
							.map(student => {
								
								let	label =	<DisplayName 
											firstName={student.firstName} 
											lastName={student.lastName}
											email={student.email}
											data={section.students}
											/>
								if (this.props.showPhonenumbers) {
									label = (
										<div className="df x" style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", width: "100%" }}>
											<div style={{ flex: 1 }}>
											<DisplayName 
											firstName={student.firstName} 
											lastName={student.lastName}
											email={student.email}
											data={section.students}
											/>
											</div>
											<div style={{ display: "flex", flexDirection: "row" }}>											
												{student.mobilePhone != null ?
													<div style={{ display: "flex", flexDirection: "row"}}>
														{student.mobilePhone.length > 4 && isValidPhoneNumber(student.mobilePhone) ?
															student.mobilePhone
															:
															<span style={{ display: "flex", flexDirection: "row"}}>
																<Icon name={'Alert_Red'} />
																{this.props.translate('Phone number invalid')}
															</span>
														}
													</div>
													:
													<div style={{ display: "flex", flexDirection: "row" }}>								
														<Icon name={'Alert_Red'} />
														{this.props.translate('Phone number missing')}
													</div>
												}
												<div onClick={(e) => this.onStudentEdit(e, student)} style={{marginLeft: "1rem"}}>
													<Icon name="Pen_Small" />
												</div>
											</div>
										</div>
									)
								}

								return <div key={student.id}>
									<Checkbox
										onChange={() => this.onUserCheckboxClick(student, section)}
										value={student.checked}
										disabled={this.props.userSelectDisabled}
										label={label}
									/>
								</div>
							}) : null}
					</div>
					: null}
			</Expandable>
		);
	}

	renderSingleSchool = () => {
		let sections = this.state.sections;

		if (this.state.filter != '') {
			sections = [...sections].filter(section =>
				section.title.toLowerCase().indexOf(this.state.filter.toLowerCase()) > -1
			);
		}

		const foundSectionRelations = [...this.state.relations].filter(relation => {
			return relation.referenceType == 'SECTION';
		});

		const schoolSelected = foundSectionRelations.length == this.state.sections.length;
		const school = new User(this.props.user).getActiveSchool();

		let schoolTitle = (
			<div></div>
		);

		if (school != null) {
			schoolTitle = (
				<div>
					<Checkbox
						onChange={this.toggleAll}
						label={this.props.customSingleSchoolName ?? school.title}
						value={schoolSelected}
						disabled={this.state.isLoading}
					/>
				</div>
			)
		}

		if (sections != null && sections.length == 0) {
			return <div style={{ textAlign: 'center', padding: '1.25rem 0', color: 'var(--color--meta)' }}>
				{this.props.translate('You have no connected groups. Contact your school administrator.')}
			</div>
		}

		return (
			<Expandable
				title={schoolTitle}
				open
				ignorePropsUpdate
				onClick={toggle => { return false; }}
				onPlus={toggle => { toggle() }}
				headerStyles={{ padding: '0px !important' }}
			>
				{sections.map(section => {
					return this.renderSection(section)
				})}

				{this.props.showUsersWithoutMentorGroups ?
					this.renderUsersWithoutMentorGroup()
					: null}
			</Expandable>
		)
	}

	renderSchool = (school) => {
		let checked = false;

		if (this.state.relations.length > 0) {
			checked = this.state.relations.find(t =>
				t.referenceId == school.id && t.referenceType == 'SCHOOL'
			) != null;
		}

		return <div key={school.id}>
			<Checkbox
				label={school.title}
				onChange={() => this.toggleSchool(school)}
				value={checked}
			/>
		</div>
	}

	render() {
		const { translate } = this.props;
		const user = new User(this.props.user);

		let schools = this.props.user.schools;
		schools = schools.sort((a, b) => a.title.localeCompare(b.title));

		if (this.props.selectMultipleSchools) {
			return <div className="user-select">
				<BlockContent style={{ position: 'relative', paddingTop: 0, paddingBottom: 0, }}>
					{schools.map(school => {
						return this.renderSchool(school);
					})}
				</BlockContent>
			</div>
		}

		return (
			<div className="user-select">
				{this.state.editStudent != null ?
					<Modal type="small" title={translate('Edit phone number')} isOpen={true} onClose={() => this.setState({ editStudent: null })}>
						<UserPhoneForm
							user={this.state.editStudent}
							onCancel={() => this.setState({ editStudent: null })}
							onSubmit={this.onStudentSubmit}
						/>
					</Modal>
					: null}

				<BlockContent style={{ position: 'relative', paddingTop: 0, paddingBottom: 0, }}>
					{this.state?.sections.length > 0 ? (<div className="form-row" style={{ padding: 0 }}>
						<Search
							value={this.state.filter}
							onChange={(value) => this.setState({ filter: value })}
							placeholder={this.props.translate('Search group')}
						/>

						<div style={{ marginTop: '1.5rem' }}></div>
					</div>) : null}

					{this.renderSingleSchool()}

					{this.state?.sections.length > 0 && (user.isOperationManager() || user.isSchoolLeader() || user.isAdministrator()) ?
						<div className="controls">
							<div>
							</div>

							<div className="checkboxes">
								<Checkbox
									label={this.props.translate('Mentor groups')}
									onChange={(c) => this.setState({ mentorGroups: c })}
									value={this.state.mentorGroups}
								/>

								<Checkbox
									label={this.props.translate('Education groups')}
									onChange={(c) => this.setState({ educationGroups: c })}
									value={this.state.educationGroups}
								/>

								<Checkbox
									label={this.props.translate('Show only my groups')}
									onChange={this.toggleMyGroups}
									value={this.state.myGroups}
								/>
							</div>
						</div>
						: null}
				</BlockContent>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user.currentUser,
		translate: translate(state.Languages.translations),
		group: state.sections.activeSection,
		usersWithoutMentorGroups: state.user.usersWithoutMentorGroups,
	};
}

export default connect(mapStateToProps, {
	getSection,
	getUsersWithoutMentorGroups,
	updateUserPhoneNumber,
})(UserSelect);