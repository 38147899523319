import React, { Component } from 'react';
import { connect } from 'react-redux';

import { GraphFileBrowser } from '@microsoft/file-browser';

import api from 'lib/api';
import Modal from 'containers/Modals/Modal';

import { Spinner } from 'UI';
import { Button, Icon, translate } from '@haldor/ui';
import { getOnBehalfOfAccessToken } from 'actions/user';

const modalStyles = {
	content: {
		width: '60%',
		margin: '0 auto',
		marginBottom: '40px',
		padding: '0',
		borderRadius: '7px',
	},
};

class OnedrivePicker extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loadingPicker: false,
			loading: false,
			modal: false,
			graphPickerUrl: 'https://graph.microsoft.com/beta/me',
			activeSelection: 'me',
		}

		if (this.props.openPicker != null) {
			this.props.openPicker(this.openModal);
		}
	}

	openModal = () => {
		this.setState({ modal: true });
		this.hideNewMenuItem();
	}
	hideNewMenuItem = () => {
		var i = 0;
		var interval = setInterval(function () {
			var newBtn = document.getElementsByName("New");
			if (++i == 10 || newBtn.length > 0) {
				if (newBtn[0].style.display !== 'none') {
					newBtn[0].style.display = 'none';
				}
			}
		}, 100)
	}

	closeModal = () => {
		this.setState({ modal: false });
	}

	getAuthenticationToken = async () => {
		return new Promise(async (resolve) => {
			await this.props.getOnBehalfOfAccessToken(this.props.user.userId, "Files.ReadWrite.All");
			if (this.props.access_token == null) {
				await this.props.getOnBehalfOfAccessToken(this.props.user.userId, "Sites.ReadWrite.All");
			}
			resolve(this.props.access_token);
		});
	}
	getFile = (file) => {
		return new Promise((resolve) => {
			api.getOneDriveFile('https://graph.microsoft.com/beta/me/drives/' + file.driveItem_203[1] + '/items/' + file.driveItem_203[2], this.props.access_token)
				.then((response) => {
					if (response.data != null && response.data.package != null) {
						if (response.data.package.type != null && response.data.package.type == "oneNote") {
							resolve();
						}
					}

					resolve({
						name: response.data.name,
						url: response.data['@microsoft.graph.downloadUrl'],
						id: 0,
						type: response.data['file'].mimeType,
					});
				});
		})
	}

	onBrowserSuccess = (keys) => {
		let { addError } = this.props;

		this.setState({ loadingPicker: true });

		let pickedItems = [];

		Promise.all(keys.map((file) => {
			return new Promise((resolve, reject) => {
				this.getFile(file).then(data => {
					resolve(data);
				});
			})
		}))
			.then(selected => {
				selected.map(function (sel) {
					if (sel != null) {
						pickedItems.push(sel);
					} else {
						addError("OneNote files is not allowed", "error");
					}
				});

				// Filter duplicate items with the same key property
				pickedItems = pickedItems.filter((obj, pos, arr) => {
					return arr.map(mapObj => mapObj['name']).indexOf(obj['name']) === pos;
				});

				this.setState({ modal: false, loadingPicker: false }, () => {
					this.props.onUpdate(pickedItems);
				});
			});
	}

	onGroupSelect = (group) => {
		this.setState({ graphPickerUrl: 'https://graph.microsoft.com/beta/groups/' + group.graphId, loading: true, activeSelection: group.graphId }, () => {
			this.setState({ loading: false });
		});
	}

	onGoOneDrive = () => {
		this.setState({ graphPickerUrl: 'https://graph.microsoft.com/beta/me/', loading: true, activeSelection: 'me' }, () => {
			this.setState({ loading: false });
		});
	}

	renderModalContent = () => {
		if (this.state.loading) {
			return <div>
				<Spinner center />
			</div>
		}

		let meClassname = 'section-item';
		if (this.state.activeSelection == 'me') {
			meClassname = meClassname + ' active';
		}

		return <div>
			{this.state.loadingPicker ?
				<div className="is_sending"><p><span className="loading-spinner" /></p></div>
				: null}

			<h3 style={{ padding: '0.5rem 0' }}>{this.props.translate('places')}</h3>

			<div style={{ marginBottom: '1.5rem' }}>
				<div onClick={this.onGoOneDrive} className={meClassname}>
					My OneDrive
				</div>

				{this.props.groups.map(group => {
					let classname = 'section-item';
					if (this.state.activeSelection == group.graphId) {
						classname = classname + ' active';
					}

					return <div key={'group-' + group.graphId} className={classname} onClick={e => { this.onGroupSelect(group) }}>
						{group.title}
					</div>
				})}
			</div>

			<div className="ms-graph-file-picker">
				<GraphFileBrowser
					getAuthenticationToken={this.getAuthenticationToken}
					itemMode="multiple"
					endpoint={this.state.graphPickerUrl}
					onSuccess={this.onBrowserSuccess}
					onCancel={this.closeModal}
					key="upload-wrapper-new_picker-element"
				/>
			</div>
		</div>
	}

	render() {
		return (
			<div className="upload-wrapper" key="upload-wrapper-picker">
				<Modal isOpen={this.state.modal} type="file-picker editor-block-modal" onClose={this.closeModal} style={modalStyles}>
					{this.renderModalContent()}
				</Modal>

				<div id="upload" className="upload-container">
					<Button onClick={(e) => { e.preventDefault(); this.openModal(); }}>
						{this.props.translate('Add files')}
					</Button>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		groups: state.sections.educationGroups,
		user: state.user.currentUser,
		access_token: state.user.accessToken
	}
}

export default connect(mapStateToProps, { getOnBehalfOfAccessToken })(OnedrivePicker);