import Moment from 'moment';
import React, { Component, Fragment } from 'react';
import { TooltipMenu, translate, getActiveLanguage } from '@haldor/ui';
import { connect } from 'react-redux';

import { submitCalendarEvent, updateCalendarEvent } from 'actions/schedule';
import { getMicrosoftAssignmentDetails } from 'actions/assignments';
import { getSection } from 'actions/sections';

import { Field, reduxForm, formValueSelector } from 'redux-form';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import sv from 'date-fns/locale/sv';
import no from 'date-fns/locale/nb';
import en from 'date-fns/locale/en-GB';

import DateTime from '_class/DateTime';
import GroupSelector from './Partials/GroupSelector';
import Modal from 'containers/Modals/Modal';
import { Expandable, Spinner } from 'UI';
import { TimePicker, Editor } from 'UI/Inputs';
import { Checkbox, Button } from '@haldor/ui';
import AssignmentForm from './AssignmentForm/AssignmentForm';
import MultipleAssignmentForm from './AssignmentForm/MultipleAssignmentForm';
import AssignmentSelect from './Partials/AssignmentSelect';
import DisplayAssignment from 'components/BlockEditor/Blocks/AssignmentBlock/DisplayAssignment';
import { getTaskDetails } from 'actions/assignments';

class CalendarEventForm extends Component {
	constructor(props) {
		super(props);

		registerLocale('en-GB', en);
		registerLocale('sv-SE', sv);
		registerLocale('nb-NO', no);
		setDefaultLocale('en-GB');

		let sectionId = 0;
		if (props.sectionId != null) {
			sectionId = props.sectionId;
		}

		let startTime = Moment().second(0);
		if (props.date != null) {
			startTime = props.date;
		}

		this.state = {
			sectionId,
			startTime: this.props.hasDate ? Moment(this.props.hasDate) : startTime,
			courses: null,
			loadingSection: false,
			createTeamsMeeting: false,
			formName: '',
			createEvent: false,
			sectionObj: {},
			selectedAssigments: [],
			isLoading: false,
			examination: false,
		};
	}

	componentDidMount() {
		if (this.props.sectionId != null) {
			this.onGroupChange(this.props.sectionId);
		}

		if (this.props.initialValues != null && this.props.sectionId == null) {
			if (
				this.props.initialValues.section != null &&
				this.props.initialValues.section.id != null
			) {
				this.setState({ sectionId: this.props.initialValues.section.id });
			}
		}
		if (this.props.initialValues && this.props.initialValues.examination && this.props.initialValues.examination == true) {
			this.setState({ examination: true });
		}
		if (this.props.group == null && this.props.initialValues !== null) {
			if (this.props.initialValues.section) {
				this.props
					.getSection(this.props.initialValues.section.id)
					.then(() => {
						this.setState({ loadingSection: false });
					})
					.catch((e) => {
						console.log(e);
					});
			}
		}
		if (this.props.initialValues?.relationships?.length > 0) {
			this.setState({
				isLoading: true,
			});
			this.props.initialValues?.relationships.map((items) => {
				let containsLetters = /[a-zA-Z]/.test(items.referenceId);
				if (containsLetters) {
					this.props.getMicrosoftAssignmentDetails(items.referenceId).then(() => {
						if (this.props.details != null) {
							this.setState((prevState) => {
								return {
									selectedAssigments: [
										...prevState.selectedAssigments,
										this.props.details,
									],
								};
							});
						}
					});
				} else {
					this.props.getTaskDetails(items.referenceId).then(() => {
						this.setState((prevState) => {
							{
								return {
									selectedAssigments: [
										...prevState.selectedAssigments,
										this.props.assignment,
									],
								};
							}
						});
					});
				}
			});
			this.setState({
				isLoading: false,
			});
		}
	}
	closeModal = (value) => {
		this.setState((prevState) => {
			{
				return {
					selectedAssigments: this.props.createdGroupAssignment
						? [...prevState.selectedAssigments, this.props.createdGroupAssignment]
						: prevState.selectedAssigments,
					createEvent: false,
				};
			}
		});
	};
	closeGroupModal = (value) => {
		this.setState((prevState) => {
			{
				return {
					selectedAssigments: this.props.createdAssignments
						? [...prevState.selectedAssigments, this.props.createdAssignments[0]]
						: prevState.selectedAssigments,
					createEvent: false,
				};
			}
		});
	};
	handleModal = (name) => {
		this.setState({
			formName: name,
			createEvent: true,
		});
	};
	handleTestToggle = (checked) => {
		this.setState({
			examination: checked,
		});
	};

	submit = (values) => {
		const editing = this.props.initialValues != null;

		return new Promise((resolve) => {
			// edit values object here
			if (this.state.selectedAssigments.length > 0) {
				let relation = [];
				this.state.selectedAssigments.map((items) => {
					let obj = {
						CalendarEventId: 0,
						ReferenceType: 'ASSIGNMENT',
						ReferenceId: items?.externalID ?? items.id,
					};
					relation.push(obj);
				});
				values.relationships = relation;
			} else {
				values.relationships = [];
			}
			values.examination = this.state.examination;
			if (!editing) {
				values.startTime = new DateTime(this.state.startTime).format();
				values.startTimeUTC = Moment.utc(this.state.startTime).format();
				values.section = { id: this.state.sectionId };
				values.course = this.state.courses;
				values.length = parseInt(values.length, 10);
				values.addTeamsMeeting = this.state.createTeamsMeeting;
			}

			if (editing) {
				// Submit form
				this.props
					.updateCalendarEvent(values)
					.then(() => {
						resolve(1);
						console.log(values);
						this.props.onSubmit(values);
					})
					.catch(() => {
						// Something went wrong in submission
						resolve(0);
					});
			} else {
				// Submit form
				this.props
					.submitCalendarEvent(values)
					.then(() => {
						resolve(1);

						this.props.onSubmit(values.startTime);
						this.props.onAbort(true);
					})
					.catch(() => {
						// Something went wrong in submission
						resolve(0);
					});
			}
		});
	};

	createTeamsMeetingChange = (active) => {
		this.setState({ createTeamsMeeting: active });
	};

	onGroupChange = (sectionId, data) => {
		this.setState({ sectionId: sectionId, loadingSection: true, sectionObj: data });
		if (data && this?.props?.setModalTitle) {
			this?.props?.setModalTitle(data?.title);
		}
		this.props
			.getSection(sectionId)
			.then(() => {
				this.setState({ loadingSection: false });
			})
			.catch((e) => {
				console.log(e);
			});
	};

	onStartChange = (date) => {
		this.setState({ startTime: Moment(date).second(0) });
	};

	onStartTimeChange = (time) => {
		this.setState({ startTime: Moment(time).second(0) });
	};

	renderInput = ({ input, label, type, placeholder, meta: { touched, error, warning } }) => {
		return (
			<div>
				<input
					{...input}
					placeholder={placeholder || label}
					type={type}
					style={
						touched && error
							? {
								border: '1px solid red',
							}
							: {}
					}
				/>

				{touched &&
					((error && <span className='error'>{error}</span>) ||
						(warning && <span className='error'>{warning}</span>))}
			</div>
		);
	};

	number = (value) => {
		if (value != null && value != '') {
			// Value is not undefined and not empty
			const number = parseInt(value, 10);

			if (number > 10 && number < 1400) {
				// Field passes validation, return a undefined error for this field
				// Rule: Not undefined and not an empty string and not below zero and not over 1400
				return null;
			}
		}

		// Field failed validation, return error for this field (String)
		return this.props.translate('number-field-validation');
	};

	required = (value) => {
		if (typeof value !== 'undefined' && value !== '') {
			// Field passes validation, return a undefined error for this field
			// Rule: Not undefined and not an empty string
			return undefined;
		}

		// Field failed validation, return error for this field (String)
		return this.props.translate('field-needs-input');
	};

	quillValidation = (value) => {
		var strValue = value;

		var text = '';
		if (typeof strValue == 'string') {
			text = strValue.replace(/<(?:.|\n)*?>/gm, '');
		}

		if (text == '' || text == null) {
			return this.props.translate('needs-description');
		}

		return undefined;
	};

	onCourseSelect = (course) => {
		let { courses } = this.state;
		let { group } = this.props;

		group.courses.forEach((cour) => {
			if (course.id != cour.id) {
				cour.selected = false;
			}
		});

		if (course.selected) {
			courses = null;
			course.selected = false;
		} else {
			course.selected = true;
			courses = course;
		}

		this.setState({ courses });
	};
	selectAssignmentForPlan = (assignment) => {
		const { plan } = this.props;
		const planId = plan.id;

		let assignments = plan.associatedAssignments;

		let foundIndex = assignments.findIndex(function (as) {
			return as.id == assignment.id;
		});

		if (foundIndex == -1) {
			let newAssignment = null;
			if (assignment.displayName != null) {
				newAssignment = {
					assignmentId: assignment.id,
					key: 'assignment-' + assignment.id,
					title: assignment.displayName,
					teams: true,
					planningId: planId,
				};
			} else {
				newAssignment = {
					assignmentId: assignment.id,
					key: 'assignment-' + assignment.id,
					title: assignment.title,
					teams: false,
					planningId: planId,
				};
			}

			assignments.push(newAssignment);
			plan.associatedAssignments = assignments;

			// plan.assignments.push({
			// 	'id': assignment.id,
			// 	'associatedMatrices': [],
			// 	'new': true,
			// 	...newAssignment,
			// });

			this.setState({ searchAssignments: !this.state.searchAssignments });

			this.props.updatePlan(plan).then(() => {
				this.props.getPlanDetails(this.props.match.params.planId);
			});
		}
	};

	renderCourses = () => {
		const { group } = this.props;

		if (group != null && group.courses != null) {
			return group.courses.map((course) => {
				if (course.status == 'COURSE_DRAFT') return null;

				const courseTitle = `${course.title} ${course.year != null ? course.year : ''} (${course.courseCode
					})`;

				return (
					<div key={course.id} style={{ marginBottom: '0.75rem' }}>
						<Checkbox
							value={course.selected}
							onChange={(c) => {
								this.onCourseSelect(course);
							}}
							label={courseTitle}
						/>
					</div>
				);
			});
		}

		return null;
	};

	onCancel = (event) => {
		event.preventDefault();

		if (this.props.onAbort != null) {
			this.props.onAbort(false);
		}
	};
	searchAssigment = (value) => {
		let item = value?.reference ?? value;
		this.setState((prevState) => {
			{
				return {
					selectedAssigments: value
						? prevState.selectedAssigments.filter((x) => x.id !== item.id).concat(item)
						: prevState.selectedAssigments,
					createEvent: false,
				};
			}
		});
	};
	removeAssigment = (id) => {
		this.setState((prevState) => {
			{
				return {
					selectedAssigments: prevState.selectedAssigments.filter((x) => x.id !== id.id),
				};
			}
		});
	};

	getDateFormat = () => {
		let format = Moment.localeData(getActiveLanguage()).longDateFormat("L");
		let newFormat = format.replace("YYYY", "yyyy");
		let days = newFormat.replace("DD", "dd");
		return days;
	};

	normalizeLocale = (locale) => {
		const [language, region] = locale.split('-');
		return `${language}-${region.toUpperCase()}`;
	};

	render() {
		const { handleSubmit, submitting, valid } = this.props;
		const editing = this.props.initialValues != null;
		let sectionValid = true;
		if (!editing && (this.state.sectionId == null || this.state.sectionId == 0)) {
			sectionValid = false;
		}
		return (
			<div className='form-container'>
				{submitting ? (
					<div className='is_sending'>
						<p>
							<span className='loading-spinner' />
						</p>
					</div>
				) : null}

				<form onSubmit={handleSubmit(this.submit)} className='form form-component'>
					{!editing ? (
						<div className='form-row'>
							<GroupSelector
								disabled={this.props.sectionId != null ? true : false}
								onChange={this.onGroupChange}
								required
								sectionId={this.state.sectionId}
							/>
						</div>
					) : null}

					{!editing ? (
						<div className='form-row'>
							<div className='form-row-half'>
								<label htmlFor='date-start'>{this.props.translate('date')}</label>

								<DatePicker
									selected={this.state.startTime.toDate()}
									onChange={this.onStartChange}
									dateFormat={this.getDateFormat()}
									popperModifiers={{
										preventOverflow: {
											enabled: true,
										},
										positionFixed: false,
									}}
									popperPlacement='bottom-start'
									showWeekNumbers
									fixedHeight
									previousMonthButtonLabel=''
									nextMonthButtonLabel=''
									locale={this.normalizeLocale(getActiveLanguage())}
									{...this.state.startTime}
								/>
							</div>

							<div className='form-row-half last'>
								<div className='form-row-half'>
									<label>{this.props.translate('start-time')}*</label>

									<TimePicker
										placeholder={this.props.translate('pick-start-time')}
										time={this.state.startTime}
										onChange={this.onStartTimeChange}
									/>
								</div>

								<div className='form-row-half last'>
									<label>{this.props.translate('length')}*</label>

									<Field
										name='length'
										type='text'
										component={this.renderInput}
										placeholder={this.props.translate('length-in-minutes')}
										validate={this.number}
									/>
								</div>
							</div>
						</div>
					) : null}
					{!editing ||
						(this.props.initialValues != null && this.props.initialValues.sourceId == null) ? (
						<div className='form-row'>
							<label>{this.props.translate('title')}*</label>

							<Field
								name='title'
								type='text'
								component={this.renderInput}
								placeholder={this.props.translate('title')}
								validate={this.required}
							/>
						</div>
					) : null}
					<div className='form-row'>
						<label>{this.props.translate('description')}</label>

						<Field
							component={Editor}
							name='description'
							placeholder={this.props.translate('description')}
							translate={this.props.translate}
						/>
					</div>
					<div className='form-row'>
						<div className='form-row-half'>
							<label>{this.props.translate('room')}</label>

							<Field
								name='room'
								type='text'
								component={this.renderInput}
								placeholder={this.props.translate('room')}
							/>
						</div>
					</div>

					<div className='clearfix'></div>

					{!editing ? (
						<div style={{ marginTop: '2rem' }}>
							<Expandable title={this.props.translate('courses-header-overview')}>
								{this.state.sectionId != 0 ? (
									this.state.loadingSection ? (
										<Spinner center small />
									) : (
										this.renderCourses()
									)
								) : (
									<div>{this.props.translate('select-relation-error')}</div>
								)}
							</Expandable>
						</div>
					) : null}

					{!editing ? (
						<div className='form-row'>
							<label>{this.props.translate('should-this-lesson-be-repeated')}?</label>

							<Field
								component={Checkbox}
								value={this.state.repeatWeekly}
								name='repeatWeekly'
								label={
									this.props.translate('repeat-lesson-every') +
									' ' +
									Moment(this.state.startTime).format('dddd')
								}
							/>
						</div>
					) : null}

					{!editing && this.props.services.teamsMeeting ? (
						<div className='form-row'>
							<label>{this.props.translate('create-teamsmeeting-for-this-lesson')}?</label>

							<Checkbox
								value={this.state.createTeamsMeeting}
								onChange={this.createTeamsMeetingChange}
								label={this.props.translate('create-teamsmeeting-for-this-lesson')}
							/>
						</div>
					) : null}
					{this.props.services.advancedSchedule ?
						<Fragment>
							<div className='form-row'>
								<label>{this.props.translate('Is the lesson a test')}</label>
								<Checkbox
									value={this.props.initialValues?.examination ?? this.state.examination}
									onChange={this.handleTestToggle}
									label={this.props.translate('Show as test')}
								/>
							</div>
							<div className='form-row'>
								<label>{this.props.translate('Assignments')} </label>

								{this.state.isLoading ? (
									<Spinner center small />
								) : this.state.selectedAssigments.length > 0 ? (
									<div
										className='form-row editor-block active'
										style={{ padding: 0, width: '80%' }}
									>
										<div className='block--assignment-block'>
											{this.state.selectedAssigments.map((items) => (
												<div className='assignment-resource' key={items.id}>
													<DisplayAssignment
														remove={() => this.removeAssigment(items)}
														assignment={items}
													/>
												</div>
											))}
										</div>
									</div>
								) : null}

								<TooltipMenu
									trigger={
										<Button
											style={{ 'margin-top': '0.775rem' }}
											type={'secondary'}
											disabled={
												this.props.initialValues != null
													? false
													: this.state.sectionObj?.id && this.props.group?.id
														? false
														: true
											}
										>
											{this.props.translate('Add assignment')}
										</Button>
									}
								>
									<TooltipMenu.Item onClick={() => this.handleModal('Search assignment')}>
										{this.props.translate('Search assignment')}
									</TooltipMenu.Item>

									<TooltipMenu.Item onClick={() => this.handleModal('Create assignment')}>
										{this.props.translate('Create assignment')}
									</TooltipMenu.Item>
									<TooltipMenu.Item
										onClick={() => this.handleModal('Create group assignment')}
									>
										{this.props.translate('Create group assignment')}
									</TooltipMenu.Item>
								</TooltipMenu>
							</div>
						</Fragment>
						: null}

					<div className='form-divider' />

					<div className='form-row spacing submit'>
						<Button disabled={submitting || !valid || !sectionValid}>
							{this.props.translate('save')}
						</Button>

						<div className='align-right'>
							<Button onClick={this.onCancel} disabled={submitting} type='secondary'>
								{this.props.translate('Cancel')}
							</Button>
						</div>
					</div>
				</form>
				<Modal
					isOpen={this.state.createEvent}
					onClose={this.closeModal}
					/* title={this.props.translate('create-lesson')} */
					title={
						this.state.formName === 'Search assignment'
							? this.props.translate(this.state.formName)
							: `${this.props.translate(this.state.formName)} ${this.props.translate(
								'for'
							)} ${this.props?.group?.title}`
					}
				>
					{this.state.formName.includes('Search') ? (
						<AssignmentSelect
							filterResults={this.props.filterResults}
							onSelect={this.searchAssigment}
							includeAssignmentsWithPlans={false}
							section={this.props.group}
						/>
					) : this.state.formName.includes('group') ? (
						<AssignmentForm
							onAbort={this.closeModal}
							editView={false}
							cloneView={false}
							groupid={this.props.group?.id}
							time={this.state.startTime}
							dontRedirect={true}
							length={this.props.length}
						/>
					) : (
						<MultipleAssignmentForm
							onAbort={this.closeGroupModal}
							editView={false}
							cloneView={false}
							section={this.props.group}
							groupid={this.props.group?.id}
							time={this.state.startTime}
							length={this.props.length}
						/>
					)}
				</Modal>
			</div>
		);
	}
}
const selector = formValueSelector('CalendarEvent');
function mapStateToProps(state) {
	return {
		initialValues: state.schedule.activeScheduleItem,
		translate: translate(state.Languages.translations),
		group: state.sections.activeSection,
		createdAssignments: state.assignments.newAssignments,
		createdGroupAssignment: state.assignments.newAssignment,
		services: state.Services.availableServices,
		assignment: state.assignments.active_assignment,
		details: state.assignments.teamsAssignmentDetails,
		length: selector(state, 'length'),
	};
}

export default connect(mapStateToProps, {
	submitCalendarEvent,
	updateCalendarEvent,
	getSection,
	getTaskDetails,
	getMicrosoftAssignmentDetails
})(
	reduxForm({
		form: 'CalendarEvent',
		destroyOnUnmount: true,
	})(CalendarEventForm)
);
