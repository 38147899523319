import React, { Component } from "react";
import { translate } from '@haldor/ui';
import { connect } from "react-redux";

import { addError } from "actions";
import { saveBlocksOnReference } from 'actions/blocks';
import { createBulkPlans } from 'actions/plans';
import { createAssessmentBlock } from "actions/blocks";

import { Form, Field } from "react-final-form";

import Select from "components/Inputs/select";

import DatePickerFromTo from "../Partials/DatePickerFromTo";
import MultipleSectionSelector from "../Partials/MultipleSectionSelector";
import TeacherAdjustments from "containers/AdditionalAdjustments/Display/TeacherAdjustments";
import BlockEditor from 'components/BlockEditor';
import BlockAssessments from 'components/BlockAssessments';
import BlockPurpose from "components/Inputs/BlockPurposeField";
import BlockCoreContentField from "components/Inputs/BlockCoreContentField";

import moment from "moment";

import { Expandable, Spinner } from "UI";
import { Icon, Button, Checkbox } from '@haldor/ui';
import api from "lib/api";

class MultiplePlanForm extends Component {

	constructor(props) {
		super(props);

		this.state = {
			dateStart: null,
			dateEnd: null,
			status: null,
			sections: [],
			associatedMatrices: [],
			loading: false,
		};
	}

	/* Redux form functions */
	required = value => {
		if (typeof value !== "undefined" && value !== "") {
			if (value.length > 199) {
				// Field failed validation, return error for this field (String)
				return this.props.translate("field-max-200-chars");
			}

			// Field passes validation, return a undefined error for this field
			// Rule: Not undefined and not an empty string and not over 200 characters
			return undefined;
		}

		// Field failed validation, return error for this field (String)
		return this.props.translate("field-needs-input");
	};

	submit = values => {
		return new Promise((resolve) => {
			let blocks = [];
			let assessmentBlocks = [];
			let promises = [];

			if (values.assessmentBlocks != null) {
				assessmentBlocks = [...values.assessmentBlocks];
			}

			if (values.blocks != null) {
				blocks = [...values.blocks];

				blocks.forEach(block => {
					if (block.type == 'Haldor.Blocks.Assignment') {
						if (block.resources != null) {
							let resources = [];
							block.resources.forEach((resource) => {
								if (resource.existing) {
									assessmentBlocks.forEach((block) => {
										promises.push(new Promise((resolve) => {
											if (resource['@odata.type'] == 'haldor.assignment.block.teamsassignment') {
												api.get(`assignments/${resource.id}/details`).then((response) => {
													this.props.createAssessmentBlock(block, response.data.id, 'ASSIGNMENT')
														.then(() => {
															resolve(1);
														});
												});

												return true;
											}

											this.props.createAssessmentBlock(block, resource.id, 'ASSIGNMENT')
												.then(() => {
													resolve(1);
												});
										}));
									});
								}

								resources.push({
									id: resource.id,
									'@odata.type': resource['@odata.type'],
								});
							});

							block.resources = resources;
						}
					}

					return block;
				})
			}

			delete values.blocks;
			delete values.assessmentBlocks;

			values.timeStart = moment(this.state.dateStart).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
			values.timeEnd = moment(this.state.dateEnd).set({ hour: 23, minute: 59 });
			values.description = values.description;
			values.sections = this.state.sections;

			values.sections.forEach(section => {
				section.courses = []; //We dont need to send courses
			});

			const that = this;
			this.props.createBulkPlans(values).then((plan) => {
				if (this.props.createdPlans != null && blocks != null && blocks.length > 0) {
					this.props.createdPlans.forEach((createdPlan) => {
						promises.push(new Promise((resolve) => {
							this.props.saveBlocksOnReference(blocks, createdPlan.id, 'PLAN')
								.then(() => {
									resolve(1);
								});
						}));

						assessmentBlocks.forEach((block) => {
							promises.push(new Promise((resolve) => {
								this.props.createAssessmentBlock(block, createdPlan.id, 'PLAN')
									.then(() => {
										resolve(1);
									});
							}));
						});
					});
				}

				Promise.all(promises).then(() => {
					that.props.onAbort(true, true);
				});
			});
		});
	};

	/* Begin custom endpoint input events */
	onDescriptionChange = content => {
		this.setState({ description: content });
	};

	onDateChange = dates => {
		this.setState({ dateStart: dates.start, dateEnd: dates.end });
	};

	onStatusSelect = event => {
		this.setState({ status: event.target.value });
	};

	/* End custom input change events */
	renderInput = ({
		input,
		label,
		type,
		placeholder,
		meta: { touched, error, warning }
	}) => {
		return (
			<div>
				<input
					type={type}
					{...input}
					placeholder={placeholder || label}
					onKeyPress={e => {
						if (e.key == 'Enter') {
							e.preventDefault();
						}
					}}
					style={
						touched && error
							? {
								border: "1px solid red"
							}
							: {}
					}
				/>

				{touched &&
					((error && (
						<span style={{ marginTop: "1rem", color: "red" }}>
							{error}
						</span>
					)) ||
						(warning && (
							<span style={{ marginTop: "1rem", color: "red" }}>
								{warning}
							</span>
						)))}
			</div>
		);
	};

	onSectionsChange = (groups) => {
		this.setState({ sections: groups });
	};

	onAddSectionCourse = (newGroups) => {
		let { sections } = this.state;

		sections.forEach(section => {
			let newData = newGroups.find((group) => {
				return group.id == section.id;
			});

			if (newData != null) {
				section.courses = newData.courses;
			}

			return section;
		});

		this.setState({ sections });
	}

	render() {
		var statuses = this.props.status.planningStatus.filter(t => t.selectable);

		let groups = [];
		if (this.state.sections.length > 0) {
			this.state.sections.forEach(section => groups.push(section.id));
		}

		if (this.state.loading) {
			return <Spinner center />;
		}

		let disabledBlocks = [];
		if (this.props.selectedSections.length == 0 || this.props.selectedSections.length > 1) {
			disabledBlocks.push({ name: 'Haldor.Blocks.Assignment', text: 'Before the planning is saved, you can only add assignments if you have selected one (1) group. When the planning is saved, you can go into the planning for each group and assign assignments.' });
		}

		return (
			<div className="form-container form">
				<Form
					onSubmit={this.submit}
					mutators={{
						setStatusDraft: (args, state, utils) => {
							utils.changeValue(state, 'status', () => 'PLANNING_DRAFT');
						},
						setStatusOpen: (args, state, utils) => {
							utils.changeValue(state, 'status', () => 'PLANNING_OPEN');
						},
					}}
					initialValues={this.props.initialValues}
					render={({ handleSubmit, form, valid, submitting, initialValues }) => {
						let disableGroups = false;
						let blockValues = form.getFieldState('blocks')?.value;
						let assessmentBlocks = form.getFieldState('assessmentBlocks')?.value;
						let subjects = [];
						if (assessmentBlocks?.length > 0) {
							assessmentBlocks?.forEach((content) => {
								content?.resources?.forEach(resources => {
									if (resources?.value != null) {
										if (subjects.indexOf(resources.value?.subjectId) == -1) {
											subjects.push(parseInt(resources.value?.subjectId));
										}
									}
								})
							});
						}

						if (blockValues != null && blockValues.length > 0) {
							disableGroups = blockValues.findIndex(block => block.type == 'Haldor.Blocks.Assignment') > -1;
						}
						return (
							<form onSubmit={handleSubmit} className="form form-component">
								{submitting ? (
									<div className="is_sending">
										<p>
											<span className="loading-spinner" />
										</p>
									</div>
								) : null}

								<Expandable
									contentStyles={{ padding: "1em 0rem" }}
									contentStylesClosed={{ padding: "0" }}
									headerStyles={
										this.state.error != null
											? { border: "1px solid red" }
											: {}
									}
									whiteBackground={true}
									title={this.props.translate("choose-groups")}
									open
									ignorePropsUpdate
								>
									<MultipleSectionSelector
										onChange={this.onSectionsChange}
										disabled={disableGroups}
										type="plan"
									/>

									{disableGroups ?
										<div className="group-selector-info">
											<Icon name="Alert" />
											{this.props.translate("To be able to add or delete groups, you must first delete the assignments that are in this plan. Assignments that you have already created are saved outside the planning and are searchable.")}
										</div>
										: null}
								</Expandable>

								{this.props.editView && initialValues.status != "PLANNING_DRAFT" ?
									<div className="form-row">
										<Select
											name="type"
											label={this.props.translate("status")}
											dataType="plan"
											options={statuses}
											placeholder={false}
											onChange={this.onStatusSelect}
											selectedValue={initialValues.status}
										/>
									</div>
									: null}

								<div className="form-row">
									<DatePickerFromTo
										type="plan"
										onChange={this.onDateChange}
										values={{
											timeStart: this.state.dateStart,
											timeEnd: this.state.dateEnd,
										}}
									/>
								</div>

								<div className="form-row input">
									<label>{this.props.translate("title")}*</label>

									<Field
										component={this.renderInput}
										type="text"
										name="title"
										placeholder={this.props.translate("title")}
										validate={this.required}
									/>
								</div>

								<div className="form-divider" />

								<Expandable title={this.props.translate('Assessments')}>
									<Field
										component={BlockAssessments}
										name="assessmentBlocks"
									/>
								</Expandable>

								<Expandable title={this.props.translate('purpose')}>
									<div className="form-row" style={{ padding: 0, marginBottom: "1em" }}>
										<div className="conditional-groups-container">
											<Field
												name="viewablePurpose"
												component={Checkbox}
												label={this.props.translate("Show purpose to students and guardians")}
											/>
										</div>
									</div>

									<Field
										component={BlockPurpose}
										name="purpose"
										assessmentBlocks={assessmentBlocks}
									/>
								</Expandable>

								<Expandable title={this.props.translate("central-content")}>
									<div className="form-row" style={{ padding: 0, marginBottom: "1em" }}>
										<div className="conditional-groups-container">
											<Field
												name="viewableCoreContent"
												component={Checkbox}
												label={this.props.translate("Show-subject-lesson-content-to-students-and-guardians")}
											/>
										</div>

										<div style={{ clear: "both" }} />
									</div>

									<Field
										component={BlockCoreContentField}
										name="associatedCoreContent"
									/>
								</Expandable>

								{this.props.services.additionalAdjustments ?
									<Expandable title={this.props.translate("additional-adjustments")}>
										<TeacherAdjustments disableCommenting={true} groups={groups} subjects={subjects} />
									</Expandable>
									: null}

								<Field
									component={BlockEditor}
									name="blocks"
									disabled={disabledBlocks}
									planningAssessments={assessmentBlocks?.length > 0 ? assessmentBlocks : []}
								/>

								<div className="form-divider" />

								<div className="form-row spacing submit">
									<Button
										onClick={(e) => {
											e.preventDefault();
											form.mutators.setStatusOpen();
											form.submit();
										}}
										disabled={submitting || !valid || this.state.sections.length == 0}
									>
										{this.props.translate("publish")}
									</Button>

									<Button
										onClick={(e) => {
											e.preventDefault();
											form.mutators.setStatusDraft();
											form.submit();
										}}
										type="secondary"
										disabled={submitting || !valid || this.state.sections.length == 0}
									>
										{this.props.translate("save-draft")}
									</Button>

									<div className="align-right">
										<Button
											type="secondary"
											onClick={e => {
												e.preventDefault();
												this.props.onAbort(false);
											}}
										>
											{this.props.translate("Cancel")}
										</Button>
									</div>
								</div>
							</form>
						)
					}}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		status: state.status.status,
		groups: state.sections.educationGroups,
		services: state.Services.availableServices,
		createdPlans: state.planning.createdPlans,
		selectedSections: state.sections.selected,
		initialValues: {
			viewablePurpose: false,
			viewableCoreContent: true,
		},
	};
}

export default connect(mapStateToProps, {
	createBulkPlans,
	addError,
	saveBlocksOnReference,
	createAssessmentBlock,
})(MultiplePlanForm);