import { clearFormTemplate, createForm, getFormTemplate, getFormTemplates, updateForm } from 'actions/templates';
import PreviewFormTemplate from 'containers/FormTemplate/Display/PreviewFormTemplate';
import Moment from 'moment';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import sv from 'date-fns/locale/sv';
import no from 'date-fns/locale/nb';
import en from 'date-fns/locale/en-GB';
import { translate, getActiveLanguage } from '@haldor/ui';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Expandable, List, Spinner } from 'UI';
import MultipleSectionSelector from './Partials/MultipleSectionSelector';
import { Checkbox, Button } from '@haldor/ui';

class SendDynamicForm extends Component {

	constructor(props) {
		super(props);

		registerLocale('en-GB', en);
		registerLocale('sv-SE', sv);
		registerLocale('nb-NO', no);
		setDefaultLocale('en-GB');

		let form = '0';
		if (props.activeForm != null) {
			form = props.activeForm.id;
		}

		let dueDate = Moment().set({ hours: 23, minutes: 59, seconds: 59 });
		if (props.initialValues != null && props.initialValues.dueDate != null) {
			dueDate = Moment(props.initialValues.dueDate);
		}

		this.state = {
			form,
			dueDate,
			loading: false,
			sections: [],
			dueDate,
			guardians: false,
		};
	}

	/* Helper methods */
	required = (value) => {
		if (typeof (value) !== 'undefined' && value !== '') {
			// Field passes validation, return a undefined error for this field
			// Rule: Not undefined and not an empty string and not over 200 characters
			return undefined;
		}

		// Field failed validation, return error for this field (String)
		return this.props.translate('field-needs-input');
	}

	/* Events */
	onCancel = (event) => {
		event.preventDefault();

		if (this.props.onAbort != null) {
			this.props.onAbort(false);
		}
	}

	componentDidMount = () => {
		if (this.props.templates == null) {
			this.props.getFormTemplates();
		}
	}

	onDateChange = (date) => {
		let dueDate = Moment(date).set({ hours: 23, minutes: 59, seconds: 59 });
		this.setState({ dueDate });
	}

	onSubmit = (values) => {
		return new Promise(resolve => {
			values.formTemplateId = this.state.form;
			values.status = 'PUBLISHED';
			values.dueDate = this.state.dueDate.utc().format();

			if (values.formInstances == null) {
				values.formInstances = [];
				this.state.sections.forEach((section) => {
					if (this.state.guardians) {
						section.guardians.forEach(guardian => {
							let instance = {
								assignedTo: guardian.id,
							};

							let inArray = values.formInstances.find(inst => instance.assignedTo == inst.assignedTo);
							if (inArray == null) {
								values.formInstances.push(instance);
							}
						});
					} else {
						section.students.forEach(student => {
							let instance = {
								assignedTo: student.id,
							};

							let inArray = values.formInstances.find(inst => instance.assignedTo == inst.assignedTo);
							if (inArray == null) {
								values.formInstances.push(instance);
							}
						});
					}
				});
			}

			if (this.props.initialValues == null) {
				this.props.createForm(values).then(() => {
					resolve(1);
					this.props.onAbort(true);
					this.props.history.push(`form/${this.props.createdForm}`);
				});
			} else {
				this.props.updateForm(values).then(() => {
					resolve(1);
					this.props.onAbort();
				});
			}
		});
	}

	onFormChange = (event) => {
		this.setState({ form: event.target.value });

		if (event.target.value != '0') {
			this.setState({ loading: true });
			this.props.getFormTemplate(event.target.value)
				.then(() => {
					this.setState({ loading: false });
				});
		} else {
			this.props.clearFormTemplate();
		}
	}

	onSectionsChange = (selected) => {
		this.setState({ sections: selected });
	}

	onGuardiansChange = (checked) => {
		this.setState({ guardians: checked });
	}

	/* Render methods */
	renderInput = ({ input, label, type, placeholder, meta: { touched, error, warning } }) => {
		return (
			<div>
				<input
					{...input}
					placeholder={placeholder || label}
					type={type}
					style={touched && error ? {
						border: '1px solid red'
					} : {}}
				/>

				{touched && ((error &&
					<span className="error">{error}</span>) || (warning &&
						<span className="error">{warning}</span>))
				}
			</div>
		);
	}

	renderTextarea = ({ input, label, type, placeholder, meta: { touched, error, warning } }) => {
		return (
			<div>
				<textarea {...input} placeholder={placeholder || label} type={type}>
					{input.value}
				</textarea>

				{touched && ((error &&
					<span className="error">{error}</span>) || (warning &&
						<span className="error">{warning}</span>))}
			</div>
		);
	}

	getDateFormat = () => {
		let format = Moment.localeData(getActiveLanguage()).longDateFormat("L");
		let newFormat = format.replace("YYYY", "yyyy");
		let days = newFormat.replace("DD", "dd");
		return days;
	};

	normalizeLocale = (locale) => {
		const [language, region] = locale.split('-');
		return `${language}-${region.toUpperCase()}`;
	};

	render() {
		const { handleSubmit, submitting, valid, translate } = this.props;

		let disableSave = false;
		if (this.state.sections.length == 0) {
			disableSave = true;
		}

		if (this.state.form == '0') {
			disableSave = true;
		}

		return (
			<div className="form-container">
				{submitting ? <div className="is_sending"><p><span className="loading-spinner" /></p></div> : null}

				<form onSubmit={handleSubmit(this.onSubmit)} className="form form-component">
					<div className="form-row">
						<label style={{ marginTop: 0 }}>{translate('title')}*</label>

						<Field
							component={this.renderInput}
							type="text"
							name="title"
							validate={this.required}
						/>
					</div>

					<div className="form-row">
						<label>{translate('description')}*</label>

						<Field
							component={this.renderTextarea}
							type="textarea"
							name="text"
							validate={this.required}
						/>
					</div>

					<div className="form-row">
						<label>{translate('pick-end-time')}</label>

						<DatePicker
							selected={this.state.dueDate.toDate()}
							onChange={this.onDateChange}
							dateFormat={this.getDateFormat()}
							minDate={Moment()}
							popperModifiers={{
								preventOverflow: {
									enabled: true,
								},
								positionFixed: false,
							}}
							popperPlacement="bottom-start"
							selectsStart
							showWeekNumbers
							fixedHeight
							previousMonthButtonLabel=""
							nextMonthButtonLabel=""
							locale={this.normalizeLocale(getActiveLanguage())}
							{...this.state.dueDate}
						/>
					</div>

					{this.props.initialValues == null ?
						<div className="form-row">
							<label>{this.props.translate('select-form-template')}*</label>

							<div className="select">
								<select value={this.state.form} onChange={this.onFormChange}>
									<option value="0">{this.props.translate('select')}</option>

									{this.props.templates != null ?
										this.props.templates.map(form => {
											return <option value={form.id} key={form.id}>
												{form.title}
											</option>
										})
										: null}
								</select>
							</div>
						</div>
						: null}

					<div style={{ marginTop: '1.55rem' }} />

					{this.props.initialValues == null ?
						<div className="form-row" style={{ marginBottom: '0.55rem' }}>
							<Checkbox
								value={this.state.guardians}
								onChange={this.onGuardiansChange}
								label={this.props.translate('select-guardians')}
							/>
						</div>
						: null}

					{this.props.initialValues == null ?
						<Expandable
							contentStyles={{ padding: '1em 0rem' }}
							contentStylesClosed={{ padding: '0' }}
							headerStyles={this.state.error != null ? { border: '1px solid red' } : {}}
							whiteBackground={true}
							title={this.props.translate('choose-recipients')}>
							<List style={{ border: '0', margin: '0', boxShadow: '0', }}>
								<MultipleSectionSelector
									mentorGroups={true}
									selectGuardians={this.state.guardians}
									onChange={this.onSectionsChange}
								/>
							</List>
						</Expandable>
						: null}

					{this.state.loading ?
						<Spinner center />
						: null}

					{this.state.form != '0' && this.props.activeForm != null ?
						<div className="form-divider" />
						: null}

					{this.state.form != '0' && this.props.activeForm != null && !this.state.loading ?
						<div style={{ marginTop: 40 }}>
							<div className="form-row">
								<h3 style={{ marginBottom: '.5rem' }}>{translate('preview')}</h3>
							</div>

							<PreviewFormTemplate fields={this.props.activeForm.fields} />
						</div>
						: null}

					<div className="form-divider" />

					<div className="form-row spacing submit">
						<Button disabled={submitting || !valid || disableSave}>
							{translate('save')}
						</Button>

						<div className="align-right">
							<Button onClick={this.onCancel} disabled={submitting} type="secondary">
								{translate('Cancel')}
							</Button>
						</div>
					</div>
				</form>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		templates: state.Templates.templates,
		activeForm: state.Templates.formTemplate,
		initialValues: state.Templates.form,
		createdForm: state.Templates.createdForm
	};
}

export default withRouter(connect(mapStateToProps, {
	getFormTemplate,
	clearFormTemplate,
	createForm,
	getFormTemplates,
	updateForm,
})(reduxForm({
	form: 'SendDynamicForm',
	destroyOnUnmount: true,
})(SendDynamicForm)));