import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import store from '../../../index';
import moment from 'moment';
import DateTime from '_class/DateTime';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { Spinner } from 'UI';
import { Field } from 'UI/Inputs';
import { Icon, Button, Flex } from '@haldor/ui';
import PersistentFormListener from 'components/Inputs/PersistentFormListener';
import { FormPrompt } from 'components/Inputs/FormPrompt';

import { updateFormInstance } from 'actions/templates';
import ColorScale from '_class/ColorScale';
import PermissionManager from '_class/PermissionManager';

class FormInstance extends Component {

	constructor(props) {
		super(props);

		let edited = null;

		if (this.props.instance != null) {
			edited = moment.utc(this.props.instance.edited).local().format();
		}

		this.state = {
			edited: null,
			hiddenFields: [],
			submitting: false,
			updating: false,
			isAllFieldsShrinked: false,
			permissionTypes: new PermissionManager().getPermissionTypes()
		};

		this.form = React.createRef();
		this.interval = null;
	}

	componentDidMount = () => {
		if (this.props.instance == null) {
			this.setState({ submitting: true });
			let values = {};

			this.props.fields.forEach((field) => {
				if (field.type.indexOf('INPUT') > -1) {
					values[field.name] = '';
				}
			});

			this.submit(values);
		}

		this.interval = setInterval(() => {
			this.setState({ edited: this.state.edited });
		}, 30 * 1000);
	}

	componentWillMount = () => {
		if (this.interval != null) {
			clearInterval(this.interval);
		}
	}

	_addToast = (message, type = 'warning') => {
		const error = {
			translateMsg: message,
			type: type,
			target: 'API',
			time: new DateTime(),
		};

		store.dispatch({
			type: 'ADD_ERROR',
			payload: error,
		});
	}

	_addTimeStampedToast = (message, timeStamp, type = 'warning') => {
		message = this.props.translate(message) + ' ' + timeStamp;
		const error = {
			translateMsg: message,
			type: type,
			target: 'API',
			time: new DateTime(),
		};

		store.dispatch({
			type: 'ADD_ERROR',
			payload: error,
		});
	}

	submit = (values) => new Promise((resolve) => {
		this.setState({ updating: true });
		let fieldResults = [];
		let status = values.status;

		if (status == null) {
			status = 'SAVED';
		}

		Object.keys(values).map((key, index) => {
			if (key == 'status') {
				return;
			}

			var templateField = this.props.fields.find(t => t.name == key);
			var instanceField = this.props.instance?.fieldResults.find(t => t.fieldName == key);

			let result = {
				'fieldName': key,
				'value': values[key],
				'FieldTemplateId': templateField != null ? templateField.id : null,
				'id': instanceField != null ? instanceField.id : null
			};

			fieldResults.push(result);
		});

		if (this.props.onSubmit != null && this.props.instance == null) {
			this.setState({ submitting: true });

			let data = {
				fieldResults,
				status,
			};

			data.formID = this.props.form;

			this.props.onSubmit(data).then(() => {
				this.setState({ edited: moment().format(), submitting: false, updating: false, error: false });
				resolve(1);

				if (this.props.onAbort != null) {
					this.props.onAbort(true);
				}
			}).catch(() => {
				this._addToast('Form could not be saved. Please try again.', 'error');
			});
		} else {
			let data = this.props.instance;
			data.fieldResults = fieldResults;
			data.status = status;

			this.props.updateFormInstance(data).then(() => {
				this.setState({ edited: moment().format(), updating: false });
				resolve(1);

				if (this.props.onAbort != null) {
					this.props.onAbort(true);
				}
			}).catch((error) => {
				this._addToast('Form could not be saved. Please try again.', 'error');
				console.log('promise catch FormInstance', error)
				this.setState({ updating: false });
				resolve({ error: 'Could not sync' });
			});
		}
	});

	copyAnswerToTextField = (answer, value, setValue) => {
		const field = answer.formInstance.formTemplate.fields.find(t => t.name == answer.formField.fieldName);

		if (value != null && value != '') {
			if (field.type == "FIELDS_INPUT_TEXTAREA") {
				setValue(answer.formField.fieldName,
					value + " \n\n" +
					"<blockquote><p>" + answer.formField.value + "</p>" +
					"<strong>- " + answer.formInstance.user.firstName + " " + answer.formInstance.user.lastName + "</strong></blockquote> \n\n" +
					"<br /><p> </p>"
				);
			} else {
				setValue(answer.formField.fieldName,
					value + " " +
					answer.formInstance.user.firstName + " " + answer.formInstance.user.lastName + ": " + answer.formField.value
				);
			}
		} else {
			if (field.type == "FIELDS_INPUT_TEXTAREA") {
				setValue(
					answer.formField.fieldName,
					"<blockquote><p>" + answer.formField.value + "</p>" +
					"<strong>- " + answer.formInstance.user.firstName + " " + answer.formInstance.user.lastName + "</strong></blockquote> \n\n" +
					"<br /><p> </p>"
				);
			} else {
				setValue(
					answer.formField.fieldName,
					answer.formInstance.user.firstName + " " + answer.formInstance.user.lastName + ": " + answer.formField.value
				);
			}
		}
	}

	shrinkAllFields = () => {
		if (!this.props.shrinkFields) {
			return false;
		}

		let titleWasFound = false;

		this.props.fields.forEach((field, index) => {
			if (field.type == 'FIELDS_ELEMENT_TITLE') {
				this.shrinkField(field, index, this.state.isAllFieldsShrinked);
				titleWasFound = true;
			}
		});

		if (!titleWasFound && this.props.fields.length > 0) {
			this.shrinkField(this.props.fields[0], -1, this.state.isAllFieldsShrinked);
		}

		this.setState({ isAllFieldsShrinked: !this.state.isAllFieldsShrinked });
	}

	findNextTitleIndex = (array, currentIndex, override) => {
		var tempList = [];

		if (currentIndex < array.length)
			tempList = array.slice(currentIndex + 1);

		var object = tempList.find(item => item.type == 'FIELDS_ELEMENT_TITLE');

		if (!object && !override)
			return -1;

		var nextIndex = array.findIndex(item => item.id == object.id);

		return nextIndex;
	}

	shrinkField = (clickedElement, index, visible) => {
		if (!this.props.shrinkFields) {
			return false;
		}

		let { hiddenFields } = this.state;

		if (clickedElement.type === 'FIELDS_ELEMENT_TITLE') {
			clickedElement.visible = visible ? false : true;
			clickedElement.toggled = visible;
		}

		var nextTitleIndex = null;

		nextTitleIndex = this.findNextTitleIndex(this.props.fields, index);

		if (!nextTitleIndex || nextTitleIndex === -1)
			nextTitleIndex = this.props.fields.length;

		while (index <= nextTitleIndex) {
			index = index + 1;

			const field = this.props.fields[index];

			if (field != null) {
				if (field.type == 'FIELDS_ELEMENT_TITLE') {
					return false;
				}

				const foundIndex = hiddenFields.findIndex(hidden => {
					return field.id == hidden.id;
				});

				if (visible) {
					if (foundIndex > -1) {
						hiddenFields.splice(foundIndex, 1);
					}
				} else {
					if (foundIndex == -1) {
						hiddenFields.push(field);
					}
				}

				this.setState({ hiddenFields });
			}
		}
	}

	containsAnswer = (answer) => {
		var doc = new DOMParser().parseFromString(answer, 'text/html');
		if (doc.body.textContent.trim() == "") {
			return false;
		}
		else {
			return true;
		}
	}

	render() {
		const { submitting, user, followUp, translate, instance } = this.props;

		if (this.props.fields == null) {
			return null;
		}

		var isStudent = user.isStudent();
		let containerClass = 'form form-component form-instance';
		let isDisabled = true;

		if (this.props.shrinkFields) {
			containerClass += ' shrinkable';
		}

		if (user.isMentor()) {
			isDisabled = false;
		}

		if (!followUp && isStudent && this.props.instance != null && this.props.instance.assignedTo == user.getUserId()) {
			isDisabled = false;
		}

		if (followUp && isStudent && this.props.studentLed) {
			isDisabled = false;
		}

		if (this.props.locked) {
			isDisabled = true;
		}

		let shrinkAllclassName = "shrink-all";
		if (!this.state.isAllFieldsShrinked)
			shrinkAllclassName += " flip";

		let fields = [...this.props.fields];
		fields.sort((a, b) => {
			return a.sortOrder > b.sortOrder ? 1 : -1;
		});

		return (
			<Form
				initialValues={this.props.initialValues}
				mutators={{
					setStatusSubmitted: (args, state, utils) => {
						utils.changeValue(state, 'status', () => 'SUBMITTED');
					},
					setValue: ([field, value], state, utils) => {
						utils.changeValue(state, field, () => value)
					},
				}}
				onSubmit={this.submit}
				keepDirtyOnReinitialize
				render={({ handleSubmit, form }) => {
					return (
						<form onSubmit={handleSubmit} className="form form-component" style={{ position: 'relative' }} ref={this.form}>
							<FormPrompt hasUnsavedChanges={(form.getState().dirty && !form.getState().submitSucceeded) || form.getState().dirtySinceLastSubmit} formPromptMessage={this.props.translate('forminstance-error-leave-message')} />

							<PersistentFormListener name={`form-instance-${instance?.id}`} initialValues={this.props.initialValues} lastModifiedUTC={instance?.edited} addTimeStampedToast={this._addTimeStampedToast} />

							{this.state.submitting ?
								<div className="is_sending">
									<p>
										<span className="loading-spinner" />
									</p>
								</div>
								: null}

							<div className={containerClass}>
								{this.props.shrinkFields ?
									<div onClick={this.shrinkAllFields} className={shrinkAllclassName}>
										<Icon name="Chevron" /> {this.state.isAllFieldsShrinked ? this.props.translate('fold-out-all') : this.props.translate('fold-them-all-up')}
									</div>
									: null}

								{fields.map((field, index) => {
									const value = form.getFieldState(field.name)?.value;
									var answers = [];

									if (this.props.references != null) {
										this.props.references.forEach((form) => {
											if (form.form.reference.formTemplate.id == this.props.formTemplate.id) {
												let formField = form.formInstance?.reference?.fieldResults?.find(t => t.fieldTemplateId == field.id);
												if (formField != null) {
													answers.push({ formField, formInstance: form.formInstance.reference });
												}
											}
										})
									}

									field.onBlur = () => {
										if (this.props.followUp == true && !this.props.user.isStudent()) {
											this.form.current.dispatchEvent(new Event('submit', {
												cancelable: true,
												bubbles: true,
											}));
										}
									}

									let foundInHidden = null;
									if (this.props.shrinkFields) {
										field.onLabelClick = () => this.shrinkField(field, index, field.visible);

										foundInHidden = this.state.hiddenFields.find((hidden) => {
											return hidden.id == field.id;
										});
									}

									field.disabled = isDisabled;

									let className = null;
									if (foundInHidden != null) className = 'hidden-field';
									if (field.toggled) className = 'flip';

									return (
										<div key={'field-' + field.id} className={className}>
											{user != null && (!user.isStudent() || this.props.studentLed) && !isDisabled ?
												<div className="user-answers">
													{answers.map((answer) => {
														if (!this.containsAnswer(answer.formField.value)) {
															return null;
														}

														return (
															<div className="user-answer" key={answer.formInstance.id}>
																<div className="answer-value"
																	dangerouslySetInnerHTML={{
																		__html: answer.formField.value
																	}}
																/>

																<Flex center>
																	<div className="creator">
																		{answer.formInstance.user.firstName + " " + answer.formInstance.user.lastName}
																		{' • ' + new DateTime(answer.formInstance.created).getDateStamp()}
																	</div>

																	<div className="quote-button" onClick={() => {
																		this.copyAnswerToTextField(answer, value, form.mutators.setValue);
																	}}>
																		<Icon name="Quote" />
																		{this.props.translate('quote')}
																	</div>
																</Flex>
															</div>
														);
													})}
												</div>
												: null}

											<Field {...field} />
										</div>
									)
								})}

								<FormSpy subscription={{ submitFailed: true }}>
									{({ submitFailed }) => submitFailed ? (
										<div className="df aic form-save-error">
											<Icon name="Alert_Red" /> {this.props.translate('Form could not be saved. Please try again.')}
										</div>
									) : null}
								</FormSpy>

								{!isDisabled ?
									<div className="form-row submit spacing" style={this.props.followUp ? { marginBottom: 0 } : {}}>
										<Button
											onClick={(e) => {
												e.preventDefault();
												form.mutators.setStatusSubmitted();
												form.submit();
											}}
										>
											{this.props.instance?.status == 'SUBMITTED' || this.props.followUp ?
												this.props.translate('save')
												: this.props.translate('submit')}

											{submitting || this.state.updating ?
												<Spinner small />
												: null}
										</Button>

										{this.props.instance?.status != 'SUBMITTED' && !this.props.followUp ?
											<Button disabled={submitting || this.state.updating} type="secondary">
												{submitting || this.state.updating ?
													<Spinner small center />
													: this.props.translate('save')}
											</Button>
											: null}

										<FormSpy subscription={{ submitSucceeded: true, modifiedSinceLastSubmit: true }}>
											{({ submitSucceeded, modifiedSinceLastSubmit }) => submitSucceeded && !modifiedSinceLastSubmit ?
												<span className="dif aic size-12 save-indicator">
													<Icon name="Check" />

													{translate('saved')}
												</span>
												: null}
										</FormSpy>

										<div className="clearfix" />
									</div>
									: null}
							</div>
						</form>
					);
				}}
			/>
		);
	}

}

function mapStateToProps(state, ownProps) {
	let initialValues = null;

	if (ownProps.instance != null) {
		initialValues = {};

		ownProps.instance.fieldResults.forEach(result => {
			initialValues[result.fieldName] = result.value;
		});
	}

	return {
		translate: translate(state.Languages.translations),
		initialValues,
	};
}

export default connect(mapStateToProps, {
	updateFormInstance,
})(FormInstance);
