import Moment from 'moment';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import sv from 'date-fns/locale/sv';
import no from 'date-fns/locale/nb';
import en from 'date-fns/locale/en-GB';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Select, getActiveLanguage } from '@haldor/ui';

class DatePickers extends Component {
	constructor(props) {
		super(props);

		Moment.locale(getActiveLanguage());
		registerLocale('en-GB', en);
		registerLocale('sv-SE', sv);
		registerLocale('nb-NO', no);
		setDefaultLocale('en-GB');

		let dStartDate;
		let dDueDate;
		let startDate;
		let dueDate;

		if (props.startDate != null) {
			dStartDate = Moment(props.startDate);
			startDate = props.startDate;
		} else {
			dStartDate = Moment();
			startDate = Moment().format('YYYY-MM-DD');
		}

		if (props.endDate != null) {
			dDueDate = Moment(props.endDate);
			dueDate = props.endDate;
		} else {
			dDueDate = Moment();
			dueDate = Moment().format('YYYY-MM-DD');
		}

		this.state = {
			dtPickerStartDate: dStartDate,
			dtPickerDueDate: dDueDate,
			startDate: startDate,
			endDate: dueDate,
		}
	}

	onStartChange = (time) => {
		let { dtPickerStartDate, startDate } = this.state;
		if (time != "Invalid date" && time != null) {
			dtPickerStartDate = Moment(time);
			startDate = Moment(time).format('YYYY-MM-DD');

			this.setState({ dtPickerStartDate, startDate }, () => {
				this.onChange();
			});
		} else {
			let dStartDate = Moment();
			let startDate = Moment().format('YYYY-MM-DD');
			let dDueDate = Moment();
			let dueDate = Moment().format('YYYY-MM-DD');
			this.setState({
				dtPickerStartDate: dStartDate,
				dtPickerDueDate: dDueDate,
				startDate: startDate,
				endDate: dueDate,
			});
		}
	}

	onEndChange = (time) => {
		let { dtPickerDueDate, endDate } = this.state;
		if (time != "Invalid date" && time != null) {
			dtPickerDueDate = Moment(time);
			endDate = Moment(time).format('YYYY-MM-DD');

			this.setState({ dtPickerDueDate, endDate }, () => {
				this.onChange();
			});
		} else {
			let dDueDate = Moment();
			let dueDate = Moment().format('YYYY-MM-DD');
			this.setState({
				dtPickerDueDate: dDueDate,
				endDate: dueDate,
			}, () => {
				this.onChange();
			});
		}
	}

	onTermChange = (value) => {
		if (value != '') {
			let dStartDate = Moment();
			let startDate = Moment().format('YYYY-MM-DD');
			let dDueDate = Moment();
			let dueDate = Moment().format('YYYY-MM-DD');

			switch (value) {
				case 'year':
					if (Moment().month() < 7) {
						dStartDate = Moment().subtract(1, 'year').month(7).date(1);
						startDate = Moment().subtract(1, 'year').month(7).date(1).format('YYYY-MM-DD');

						dDueDate = Moment().month(6).date(31);
						dueDate = Moment().month(6).date(31).format('YYYY-MM-DD');
					}
					else {
						dStartDate = Moment().month(7).date(1);
						startDate = Moment().month(7).date(1).format('YYYY-MM-DD');

						dDueDate = Moment().add(1, 'year').month(6).date(31);
						dueDate = Moment().add(1, 'year').month(6).date(31).format('YYYY-MM-DD');
					}

					break;
				case 'previous-year':
					if (Moment().month() < 7) {
						dStartDate = Moment().subtract(2, 'year').month(7).date(1);
						startDate = Moment().subtract(2, 'year').month(7).date(1).format('YYYY-MM-DD');

						dDueDate = Moment().subtract(1, 'year').month(6).date(31);
						dueDate = Moment().subtract(1, 'year').month(6).date(31).format('YYYY-MM-DD');
					}
					else {
						dStartDate = Moment().subtract(1, 'year').month(7).date(1);
						startDate = Moment().subtract(1, 'year').month(7).date(1).format('YYYY-MM-DD');

						dDueDate = Moment().subtract(0, 'year').month(6).date(31);
						dueDate = Moment().subtract(0, 'year').month(6).date(31).format('YYYY-MM-DD');
					}
					break;
				case 'semester':
					if (Moment().month() < 7) {
						dStartDate = Moment().month(0).date(1);
						startDate = Moment().month(0).date(1).format('YYYY-MM-DD');

						dDueDate = Moment().month(6).date(31);
						dueDate = Moment().month(6).date(31).format('YYYY-MM-DD');
					}
					else {
						dStartDate = Moment().month(6).date(1);
						startDate = Moment().month(6).date(1).format('YYYY-MM-DD');

						dDueDate = Moment().month(11).date(31);
						dueDate = Moment().month(11).date(31).format('YYYY-MM-DD');
					}

					break;
			}

			this.setState({
				dtPickerStartDate: dStartDate,
				dtPickerDueDate: dDueDate,
				startDate: startDate,
				endDate: dueDate,
			}, () => {
				this.onChange();
			});
		}
	}

	onChange = () => {
		if (this.props.onChange) {
			const dates = {
				start: this.state.startDate,
				end: this.state.endDate,
			};

			this.props.onChange(dates);
		}
	}

	getDateFormat = () => {
		let format = Moment.localeData(getActiveLanguage()).longDateFormat("L");
		let newFormat = format.replace("YYYY", "yyyy");
		let days = newFormat.replace("DD", "dd");
		return days;
	};

	normalizeLocale = (locale) => {
		const [language, region] = locale.split('-');
		return `${language}-${region.toUpperCase()}`;
	};

	render() {
		return (
			<div className="form">
				<div className="form-row">
					<div className="form-row-half static">
						<label htmlFor="date-start" style={{ marginTop: 0 }}>
							{this.props.translate('start')}
						</label>

						<DatePicker
							selected={this.state.dtPickerStartDate.toDate()}
							startDate={this.state.dtPickerStartDate.toDate()}
							endDate={this.state.dtPickerDueDate.toDate()}
							onChange={this.onStartChange}
							dateFormat={this.getDateFormat()}
							popperModifiers={{
								preventOverflow: {
									enabled: false,
								},
								positionFixed: true,
							}}
							selectsStart
							popperPlacement="bottom-start"
							showWeekNumbers
							fixedHeight
							previousMonthButtonLabel=""
							nextMonthButtonLabel=""
							locale={this.normalizeLocale(getActiveLanguage())}
							{...this.state.startDate}
						/>
					</div>

					<div className="form-row-half static last">
						<label htmlFor="date-end" style={{ marginTop: 0 }}>
							{this.props.translate('end')}
						</label>

						<DatePicker
							selected={this.state.dtPickerDueDate.toDate()}
							startDate={this.state.dtPickerStartDate.toDate()}
							endDate={this.state.dtPickerDueDate.toDate()}
							minDate={this.state.dtPickerStartDate.toDate()}
							onChange={this.onEndChange}
							dateFormat={this.getDateFormat()}
							popperModifiers={{
								preventOverflow: {
									enabled: true,
								},
								positionFixed: true,
							}}
							popperPlacement="bottom-start"
							selectsEnd
							showWeekNumbers
							fixedHeight
							previousMonthButtonLabel=""
							nextMonthButtonLabel=""
							placeholderText={this.props.translate('today')}
							isClearable
							locale={this.normalizeLocale(getActiveLanguage())}
							{...this.state.endDate}
						/>
					</div>

					<div style={{ display: 'inline-block' }}>
						<Select
							onChange={this.onTermChange}
							options={[
								{
									label: this.props.translate('current-schoolyear'),
									value: 'year',
								},
								{
									label: this.props.translate('previous-schoolyear'),
									value: 'previous-year',
								},
								{
									label: this.props.translate('current-semester'),
									value: 'semester',
								},
							]}
						>
							{this.props.translate('select-period')}
						</Select>
					</div>
				</div>

			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		languages: state.Languages.languages
	};
}

export default connect(mapStateToProps)(DatePickers);
