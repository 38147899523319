import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';

import DateTime from '_class/DateTime';

import PostContent from 'components/Presentation/PostContent';
import { Spinner, Collapsible } from 'UI';
import { Editor } from 'UI/Inputs';
import { Flex, Button, translate } from '@haldor/ui';

import './EditStudentAdjustmentForm.scss'
class EditStudentAdjustmentForm extends Component {

	submit = (values) => {
		return new Promise(resolve => {
			if (this.props.onSubmit != null) {
				// Clean up properties that doesnt need to be sent to the api
				delete values.comments;
				delete values.createdBy;
				delete values.editedBy;
				delete values.versions;
				delete values.student;

				this.props.onSubmit(values).then(() => {
					resolve(1)
				})
			} else {
				resolve(1)
			}
		})
	}

	cancel = (event) => {
		event.preventDefault();

		if (this.props.onClose != null) {
			this.props.onClose();
		}
	}

	render() {
		const { submitting, handleSubmit, valid } = this.props;

		if (this.props.adjustment == null) {
			return null;
		}

		return (
			<div className="form-container additional-adjustments edit-student-adjustment">
				{submitting ? <div className="is_sending"><p><span className="loading-spinner" /></p></div> : null}

				<form onSubmit={handleSubmit(this.submit)} className="form form-component">
					<div className="form-row textarea">
						<label>{this.props.translate('description')}*</label>

						<Field
							component={Editor}
							name="description"
						/>
					</div>

					<div className="form-divider" />

					<div className="form-row submit spacing">
						<Button disabled={submitting}>
							{this.props.translate('save')}
						</Button>

						<div className="pull-right">
							<Button type="secondary" onClick={this.cancel}>
								{this.props.translate('Cancel')}
							</Button>
						</div>
					</div>
				</form>

				<div style={{ marginTop: '3rem' }}></div>

				<div className="form-row">
					<Collapsible trigger={this.props.translate('previous-versions')}>
						{this.props.adjustment.versions != null ?
							this.props.adjustment.versions.map((version) => {
								return <div style={{ padding: '0.25rem 0', paddingLeft: 26, marginBottom: '.95rem' }} key={version.id}>
									<span style={{ fontWeight: 500, fontSize: '1.12rem' }}>
										{version.additionalAdjustment.title}
									</span>

									<p style={{ marginTop: '0.5rem' }}>
										<PostContent>
											{version.description}
										</PostContent>
									</p>

									<div className="createdBy color--meta">
										{version.createdBy.firstName} {version.createdBy.lastName}
										{' • ' + new DateTime(version.created).getShortDate(true)}
									</div>
								</div>
							}) : null}
					</Collapsible>
				</div>

				<div style={{ marginTop: '1rem' }}></div>

				<div className="form-row">
					<Collapsible trigger={this.props.translate('Template')}>
						<div style={{ padding: '0.25rem 0', paddingLeft: 26 }}>
							<span style={{ fontWeight: 500, fontSize: '1.12rem' }}>
								{this.props.adjustment.additionalAdjustment.title}
							</span>

							<p style={{ marginTop: '0.5rem' }}>
								<PostContent>
									{this.props.adjustment.additionalAdjustment.description}
								</PostContent>
							</p>

							<div className="createdBy color--meta">
								{this.props.adjustment.additionalAdjustment.createdBy.firstName} {this.props.adjustment.additionalAdjustment.createdBy.lastName}
								{' • ' + new DateTime(this.props.adjustment.created).getShortDate(true)}
							</div>
						</div>
					</Collapsible>
				</div>

				<div style={{ marginTop: '2rem' }}></div>
			</div>
		);
	}

}

function mapStateToProps(state, ownProps) {
	return {
		translate: translate(state.Languages.translations),
		user: state.user.currentUser,
		initialValues: JSON.parse(JSON.stringify(ownProps.adjustment)),
	}
}

export default connect(mapStateToProps)(reduxForm({
	form: 'EditStudentAdjustmentForm',
	destroyOnUnmount: true,
})(EditStudentAdjustmentForm));